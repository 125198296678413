import React from 'react';
import PropTypes from 'prop-types';
import {
  SearchResultComponentComponent,
  ImageWrapper,
  ContentWrapper,
} from './SearchResultComponent.styles.js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import translate from 'helpers/translate';

const SearchResultComponent = ({ image, title, searchDescription, itemUrl, pageType, sitecoreContext }) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;

  const urlTitle = pageType => {
    if (pageType === 'article') {
      return translate(viewBag, 'BushsBeans.Search.ResultItems.ViewThisArticle');
    }
    if (pageType === 'general') {
      return translate(viewBag, 'BushsBeans.Search.ResultItems.ViewThisPage');
    }
    if (pageType === 'product') {
      return translate(viewBag, 'BushsBeans.Search.ResultItems.ViewThisProduct');
    }
    if (pageType === 'recipe') {
      return translate(viewBag, 'BushsBeans.Search.ResultItems.ViewThisRecipe');
    }
  };
  return (
    <SearchResultComponentComponent>
      {image?.src && (
        <ImageWrapper className={pageType}>
          <img src={image?.src} alt={image?.alt} />
        </ImageWrapper>
      )}
      <ContentWrapper>
        {title && (
          <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
        )}
        {searchDescription && (
          <p
            dangerouslySetInnerHTML={{
              __html: trademarkFilterHelper(searchDescription, trademarkReplacements),
            }}
          />
        )}
        {itemUrl && <a href={itemUrl}>{urlTitle(pageType)}</a>}
      </ContentWrapper>
    </SearchResultComponentComponent>
  );
};

SearchResultComponent.propTypes = {};

export default withSitecoreContext()(SearchResultComponent);
