import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Icon from 'components/shared/Icon';

import { AlertRibbonComponent, NotificationWrapper, CloseWrapper } from './AlertRibbon.styles.js';

import { addToStorage, getFromStorage } from 'helpers/storageHelpers';

const Notification = ({ title, text, type, style }) => {
  return (
    <AlertRibbonComponent variant={style}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </AlertRibbonComponent>
  );
};

const AlertRibbon = ({ sitecoreContext = {} }) => {
  const { notifications = [] } = sitecoreContext;
  const [visible, setVisible] = useState(false);
  const sessionStorageKey = 'alert-ribbon-visible';

  useEffect(() => {
    if (!isServer()) {
      // check session storage is ribbon should be visible
      setVisible(!getFromStorage({ key: sessionStorageKey, type: 'session' }));
    }
  }, []);

  const closeHandle = () => {
    setVisible(false);
    addToStorage({ key: sessionStorageKey, value: false, type: 'session' });
  };

  if (visible && notifications && notifications.length > 0) {
    return (
      <NotificationWrapper aria-label="alert notification" className="hide-on-print">
        {notifications &&
          notifications?.map((notification, index) => (
            <Notification key={`notfication-${index}`} {...notification} />
          ))}
        <CloseWrapper>
          <button aria-label="close notification" title="close notification" onClick={() => closeHandle()}>
            <Icon icon="cross" size="md" />
          </button>
        </CloseWrapper>
      </NotificationWrapper>
    );
  }

  return null;
};

AlertRibbon.propTypes = {
  sitecoreContext: PropTypes.shape({
    notifications: PropTypes.array,
  }),
};

Notification.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default withSitecoreContext()(AlertRibbon);
