import styled from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
  max-width: 860px;
  height: auto;
`;

export const VideoWrapperInner = styled.div`
  position: relative;
  height: 0;
  padding-bottom: ${({ ratio }) => `calc((${ratio[1]} / ${ratio[0]}) * 100%)`};

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;