import styled from "styled-components";

export const ThirtyKSvgObject = styled.svg`
	position: absolute;
	left: -8%;
	top: 0;
	z-index: 5;
	transform: scale(1) rotate(-25deg);

    @media (max-width: 1150px){
		transform: scale(0.7) rotate(-25deg);
    }
	@media (max-width: 800px){
		transform: scale(0.6) rotate(-25deg);
	}
    @media (max-width: 650px){
		transform: scale(0.5) rotate(-29deg);
		left: -90px;
		top: -12px;
    }
`;