import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';


export const RelatedCarouselComponent = styled.section`
	position: relative;
	overflow: hidden;
	padding: 0;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin: 80px auto;
	${({theme}) => theme.breakPoints.minBp('tablet')} {
		margin: 160px auto;
	}
    .heading-row{
        text-align: center;
        display: block;
        margin-bottom: ${({ theme }) => theme.spacing(48)};
        h2{
            color: ${({ theme }) => theme.colours.brown};
            text-transform: uppercase;
            font-size: clamp(2rem, 4vw, 3.5rem);
            font-weight: 700;
            font-family: 'apercu condensed pro', apercu condensed pro, 'apercu pro', apercu pro, impact;
            line-height: clamp(2.125rem, 4vw, 3.75rem);
            text-align: center;
            margin: 0 auto;
			max-width: 80%;
        }
		p{
			width: 1000px;
			margin: 20px auto 0 auto;
			max-width: 80%;
		}
    }
    .related-cards-container{
        width: 1550px;
        max-width: 95%;
        margin: 0 auto;
		@media (max-width: 1580px){
            width: 95%;
        }
        .realated-grid{
			@media (min-width: ${({ theme }) => theme.spacing(1199)}) {
				gap: 80px;
			}
        }
		.slick-slider{
			@media (max-width: ${({ theme }) => theme.spacing(650)}) {
				width: 80%;
				margin: auto;
			}
			.slick-list{
				overflow: visible;
				.slick-track{
					display: flex;
				}
			}
		}
    }
	@media (max-width: 1024px){
        min-height: ${({ theme }) => theme.spacing(300)};
    }
	.slick-slider {
		.slick-dots {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
			bottom: -50px;
			li {
				button {
					width: 16px;
					height: 16px;
					border: 2px solid ${({theme}) => theme.colours.red};
					border-radius: 50%;
					transition: all 0.4s ease-in-out;

					&:hover {
						border-color: ${({theme}) => theme.colours.brown};
					}

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						background-color: ${({theme}) => theme.colours.red};
						border: 2px solid ${({theme}) => theme.colours.red};

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
	.button-container{
		display: flex;
		justify-content: center;
	}

`;

export const SurveyPageRelatedWrapper = styled.section`
	position: relative;
	overflow: hidden;
	padding: 0;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
	width: 1440px;
	max-width: 90%;
	.heading-row{
		text-align: center;
		display: block;
		margin-bottom: ${({ theme }) => theme.spacing(48)};
		h2{
			color: #C75127;
			line-height: 1.2;
			font-weight: 700;
			font-size: clamp(2rem, 4vw, 3.5rem);
			font-family: "apercu condensed pro", impact;
			text-align: center;
			text-transform: uppercase;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 5rem
		}
		p{
			width: 1000px;
			margin: 20px auto 0 auto;
			max-width: 80%;
		}
	}
	@media (max-width: 650px){
		margin-top: 40px;
		.heading-row{
			h2{
				margin-bottom: 3rem

			}
		}
	}
	.related-cards-container{
		width: 100%;
		.realated-grid{
			@media (min-width: ${({ theme }) => theme.spacing(1199)}) {
				gap: 80px;
			}
		}

	}
	.button-container{
		display: flex;
		justify-content: center;
	}
`;

export const RelatedCarouselControls = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    z-index: 3;
    display: flex;
    justify-content: space-between;
	width: 1500px;
	max-width: 100%;
	margin: auto;
    .prev{
        position: absolute;
        left: 0;
    }
    .next{
      position: absolute;
      right: 0;
    }
`;

export const PlaceholderWrapper = styled.div`
    position: relative;
    display: block;
    min-height: 300px;
`;

export const SpHeadingRow = styled.div`
	text-align: center;
	display: block;
	margin-bottom: ${({ theme }) => theme.spacing(48)};
	margin-top: 5rem;
	h2{
		color: #C75127;
		line-height: 1.2;
		font-weight: 700;
		font-size: clamp(2rem, 4vw, 2.5rem);
		font-family: "apercu condensed pro";
		text-align: center;
		text-transform: uppercase;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem
	}
	p{
		width: 1000px;
		margin: 20px auto 0 auto;
		max-width: 80%;
	}
`;

export const RelatedCarouselProdouctGrid = styled.div`
	width: 1440px;
	max-width: 90%;
	margin: auto;
	.grid{
		max-width: 100% !important;
		.product-card{
			footer{
				h3{
					font-size: 25px;
					color: #C75127;
					margin-bottom: 0;
				}
				p{
					color: #5c5b5b;
					max-width: 250px;
					margin: auto;
				}
			}
		}
	}
`;