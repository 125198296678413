import styled from 'styled-components';
import IcoMoon from 'react-icomoon';

const renderSize = ({ size, theme }) => {
  switch (size) {
    case 'sm':
      return theme.spacing(12);
    case 'lg':
      return theme.spacing(20);
    case 'xl':
      return theme.spacing(28);
    case 'xxl':
      return theme.spacing(32);
    default:
      return theme.spacing(15);
  }
};

export const IconComponent = styled(IcoMoon)`
  width: ${({ size, theme }) => renderSize({ size, theme })};
  min-width: ${({ size, theme }) => renderSize({ size, theme })};
  height: ${({ size, theme }) => renderSize({ size, theme })};
  color: black;
  &.mail {
    transform: scale(2) translate(25%, 25%);
  }
  &.facebook {
    transform: scale(1.4) translate(0%, 22%);
  }
  &.twitter {
    transform: scale(2) translate(0, 26%);
    stroke-width: 10px;
  }
  &.tiktok{
    transform: scale(2) translate(25%, 26%);
  }
  &.threads{
    transform: scale(2) translate(25%, 26%);
  }
`;