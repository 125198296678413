import styled from "styled-components";
const getVariantColours = ({ variant, theme } = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
            text: '#fff',
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.yellow,
        },
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
        },
        brown: {
            background: theme.colours.brown,
            text: theme.colours.yellow,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
    const alignments = {
        left: 'left',
        center: 'center',
        right: 'right',
    };

    if (alignment in alignments) return alignments[alignment];

    return alignments.left;
};

export const ModalWrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
	background: rgba(00, 00, 00, 0.5);
	width: 100%;
	height: 100%;
    display: flex;
    .modal-content{
		outline: 2px solid #000;
		padding: 0;
		margin: auto;
		height: fit-content;
		width: 650px;
		max-width: 90%;
        background-color: ${({ theme }) => theme.colours.tan};
        position: relative;
		border-bottom: 10px solid ${({ theme }) => theme.colours.blue};
        overflow: visible;
        .modal-top{
			background-color: ${({ theme }) => theme.colours.blue};
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 10px;
			gap: 6px;
            position: relative;
            .close-modal{
                span{
					width: 40px;
					border: 2px solid #000;
                    display: block;
                    transition: all 0.3s;
                }
				position: absolute;
				right: 50px;
				top: 0;
				bottom: 0;
				text-align: center;
				color: ${({ theme }) => theme.colours.yellow};
				background-color: ${({ theme }) => theme.colours.blue};
				font-size: 25px;
				padding: 10px;
                cursor: pointer;
                &:hover{
                    span{
                        background-color: ${({ theme }) => theme.colours.yellow};
                        color: ${({ theme }) => theme.colours.blue};
                    }
                }
            }
            .stripe{
                width: 100%;
                content: '';
                height: 2px;
                background-color: #000;
            }
            
        }
		.modal-body{
			padding: 60px;
			border-right: 10px solid ${({ theme }) => theme.colours.blue};
			background: ${({ theme }) => theme.colours.tan};
			border-left: 10px solid ${({ theme }) => theme.colours.blue};
			height: 100%;
            text-align: center;
			justify-content: center;
			display: flex;
			flex-flow: column;
            .button-link{
				text-decoration: none;
				text-align: center;
				align-self: center;
				display: flex;
            }
        }
        h2{
            color: ${({theme}) => theme.colours.brown};
            span{
                color: ${({theme}) => theme.colours.red};
            }
            display: inline-block;
            font-family: 'chunk';
        }
        p{
            color: ${({theme}) => theme.colours.brown};
			display: inline-block;
        }
        #modalbtn{
            background-color: ${({ theme }) => theme.colours.blue};
			&:hover{
				background-color: ${({ theme }) => theme.colours.yellow};
                color: ${({ theme }) => theme.colours.blue};
				span:nth-child(2) {
					background-color: ${({ theme }) => theme.colours.blue};
				}
				span:nth-child(3) {
					svg{
						fill: ${({ theme }) => theme.colours.blue};
						path{
							stroke: ${({ theme }) => theme.colours.blue};
						}
					}
				}
			}
        }
    }
`;

export const CenteredVideoOuterWrapper = styled.section`
    position: relative;
	background-color: ${({ theme, backgroundColor }) =>
		getVariantColours({ variant: backgroundColor, theme }).background};
	padding: ${({ theme }) => theme.spacing({ top: 120, bottom: 100, left: 35, right: 35 })};
	${({ backgroundImage }) =>
		backgroundImage &&
		`
        background-image: url(${backgroundImage});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    `}
	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spacing({ top: 60, bottom: 60, left: 35, right: 35 })};
	}
    .sdg-graphic{
		position: absolute;
		top: 80px;
		left: 13%;
		width: 123px;
		height: auto;
		transform: rotate(13deg);
		@media (max-width: 1788px){
            left: 6%;
        }
		@media (max-width: 1588px){
			left: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .secret-family-recipe-can{
        position: absolute;
        width: 103px;
		bottom: 23%;
        right: 10%;
		transform: rotate(-25deg);
        @media (max-width: 1788px){
			right: 10%;
        }
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .beanTV{
		width: 180px;
		position: absolute;
		top: -110px;
		right: 8%;
		transform: rotate(-25deg);
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .NineteenNfourSticker{
		position: absolute;
		width: 130px;
		right: 8%;
		top: 10%;
		@media (max-width: 1588px){
			right: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
`;

export const CenteredVideoComponentWrapper = styled.div`
    position: relative;
    .container{
		display: flex;
		flex-flow: column;
        .text-content{
            text-align: center;
        }
    }
	h2 {
		font-family: 'Chunk';
		font-style: normal;
		font-weight: 900;
		font-size: 69px;
		line-height: 1;
		text-align: center;
		color: ${({theme}) => theme.colours.red};
		display: block;
		margin: 0 auto 40px auto;
		@media (max-width: 900px) {
			margin: 0 auto 20px auto;
            font-size: 36px;
		}
		&.decorated {
			width: 760px;
			max-width: 100%;
            span{
                display: inline-block;
            }
			span:nth-child(4n + 1) {
				color: ${({theme}) => theme.colours.red};
			}
			span:nth-child(4n + 2) {
				color: ${({theme}) => theme.colours.blue}
			}
			span:nth-child(4n + 3) {
				color: ${({theme}) => theme.colours.brown}
			}
			span:nth-child(4n + 4) {
				color: #24336D;
			}
		}
	}

	p {
		width: 750px;
		max-width: 100%;
		margin: auto;
		text-align: center;
        font-size: 20px;
        display: block;
        color: ${({theme}) => theme.colours.brown};
        span{
            display: inline-block;
            &.bold{
                font-weight: 700;
				color: ${({theme}) => theme.colours.red};
            }
        }
	}
    .videoSubText{
		width: 750px;
		max-width: 100%;
		margin: auto;
		text-align: center;
		font-size: 24px;
		display: block;
        font-weight: 500;
		color: ${({theme}) => theme.colours.brown};
        margin-bottom: 15px;
		span{
			display: inline-block;
			color: ${({theme}) => theme.colours.red};
			&.bold{
				font-weight: 700;
				color: ${({theme}) => theme.colours.red};
			}
		}   
    }
    #sweepbtn{
            background-color: ${({ theme }) => theme.colours.blue};
			&:hover{
				background-color: ${({ theme }) => theme.colours.yellow};
                color: ${({ theme }) => theme.colours.blue};
				span:nth-child(2) {
					background-color: ${({ theme }) => theme.colours.blue};
				}
				span:nth-child(3) {
					svg{
						fill: ${({ theme }) => theme.colours.blue};
						path{
							stroke: ${({ theme }) => theme.colours.blue};
						}
					}
				}
			}
    }

    .disclosureText{
		margin: 20px auto 0 auto;
		text-align: center;
		font-size: 16px;
		line-height: 1.3;
        a{
            color: ${({theme}) => theme.colours.brown};
        }
    }
    .button-link{
		margin: 20px auto;
		display: block;
		align-self: center;
    }
	.video-holder {
		display: block;
		max-width: 100%;
		width: 958px;
		margin: 40px auto;
        position: relative;
	}
    .bottom-links{
        display: flex;
        justify-content: center;
        gap: 25px;
        margin-top: 15px;
        a{
			color: ${({theme}) => theme.colours.brown};
			text-decoration: underline;
			font-size: 20px;
            font-weight: 500;
            transition: all 0.4s ease-in-out;
            &:hover{
                color: ${({theme}) => theme.colours.red};
            }
        }
    }
	.video-div {
		background-color: #000;
		position: relative;
		width: 100%;
		padding-top: 56.2%;
		border-radius: 56px;
		background-size: 102%;
		background-position: center center;
        overflow: hidden;
        cursor: pointer;
		iframe {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		.inner-content {
			height: 100%;
			width: 100%;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: end;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			justify-content: end;
			img {
				align-self: center;
				display: block;
				margin: auto;
			}
			svg {
				align-self: center;
				display: block;
				margin: auto;
			}
			span {
				font-family: 'Chunk';
				font-style: normal;
				font-weight: 900;
				font-size: 45px;
				line-height: 1;
				text-align: center;
				text-transform: uppercase;
				color: #FFFFFF;
				margin: 0 auto 50px auto;
				max-width: 100%;
                width: 380px;
			}
			.play-video-button {
				width: 100px;
				height: 100px;
				margin: auto;
				position: absolute;
				border: 0;
				background: none;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
                cursor: pointer;
				.shadow {
					width: 100px;
					height: 100px;
                    min-width: 100px;
                    min-height: 100px;
					border-radius: 50%;
					content: "";
					background-color: #000;
					position: absolute;
					left: -10px;
					top: 0;
					bottom: 0;
					z-index: 0;
				}
				.self {
					background-color:  ${({theme}) => theme.colours.red};
					text-align: center;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					content: "";
					width: 100px;
					height: 100px;
					min-width: 100px;
					min-height: 100px;
					align-self: center;
					color: #fff;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					animation-timing-function: cubic-bezier(.3, .7, .4, 1.5);
					transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
                    border: 1px solid #000;
					svg {
						width: 31px;
						height: 38px;
						fill: #fff;
					}
					&:hover {
						animation: bounce .4s ease-in-out;
						transform: translate(-10px, 0);
						background-color: #0c54a2;
					}
				}
                @media (max-width: 650px){
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
                    .shadow{
						width: 50px;
						height: 50px;
						min-width: 50px;
						min-height: 50px;
                    }
                    .self{
						width: 50px;
						height: 50px;
						min-width: 50px;
						min-height: 50px;
						svg{
							width: 20px;
							height: 20px;
						}
                    }
                }
			}
		}
		@media (max-width: 900px) {
			border-radius: 25px;
			.inner-content{
                span{
                    font-size: 28px;
                    max-width: 80%;
					margin-bottom: 5%;
                }
            }
		}
		@media (max-width: 650px) {
			.inner-content{
				span{
					font-size: 20px;
				}
			}
		}
	}
`;