import React from 'react';
import styled from 'styled-components';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import translate from 'helpers/translate';

const Wrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  text-align: center;

  h1 {
    max-width: 800px;
    margin: 0 auto 32px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colours.red};
  }
`;

// needs to be two states
// when a filter is applied & not
const NoResults = ({ sitecoreContext = {} }) => {
  const { viewBag = {} } = sitecoreContext;
  return (
    <Wrapper>
      <h1>
        {translate(
          viewBag,
          'BushsBeans.Search.Labels.FilteredTooMuchTitle',
          'Please remove a filter or two to see those beautiful bean recipes!'
        )}
      </h1>
      <p>
        {translate(
          viewBag,
          'BushsBeans.Search.Labels.FilteredTooMuchSubtitle',
          'Sorry, it seems like you have filtered too much'
        )}
      </p>
    </Wrapper>
  );
};

export default withSitecoreContext()(NoResults);
