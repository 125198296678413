import styled from 'styled-components';

export const ContentBlockComponent = styled.div`
    
`;

export const ContentBlockComponentFs = styled.div`
    margin-top: 160px;
    margin-bottom: 160px;
    @media (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
`;