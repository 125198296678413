import styled from 'styled-components';

export const MediaCarouselComponent = styled.div``;

export const PlaceholderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

export const ExperienceEditorCarouselWrapper = styled.section`
  .divider {
    padding: 3rem 0;

    h1 {
      margin-bottom: 0;
    }
  }
`;
