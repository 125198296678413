export const checkBadges = (isNewItem, webBadge, language, country) => {
  // ! newlook takes priority over other badges.
  // webBadge will be newlook string if present
  // empty strings are false.

  const isNone = !isNewItem && !webBadge;
  const isNew = isNewItem && !webBadge;
  const isNewLook = webBadge && !isNewItem;
  const hasBoth = webBadge && isNewItem;

  if (isNone) return;

  if (isNew) {
    return `/-/media/BushsBeans/badges/badge_new_${language}-${country.toLowerCase()}.svg}`;
  }
  if (isNewLook) {
    return `/-/media/BushsBeans/badges/badge_newlook_${language}-${country.toLowerCase()}.svg`;
  }

  if (hasBoth) {
    return `/-/media/BushsBeans/badges/badge_newlook_${language}-${country.toLowerCase()}.svg`;
  }
};

export const languageFilter = (language) => {
  if (language.includes('-')) {
    return language.slice(0, 2);
  } else {
    return language;
  }
};
