import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Wrapper } from './Ribbon.styles';

import translate from 'helpers/translate';

export const Ribbon = ({ sitecoreContext = {}, variant = 'red' }) => {
  const { viewBag = {} } = sitecoreContext;
  return (
    <Wrapper className="ribbon" variant={variant}>
      <span> {translate(viewBag, 'BushsBeans.Search.Grids.HoverRibbon', 'View')} </span>
    </Wrapper>
  );
};

export default withSitecoreContext()(Ribbon);
