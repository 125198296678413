import styled from 'styled-components';

export const SocialLinksList = styled.ul`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &.large {
    button {
      padding: ${({ theme }) => theme.spacing(12)};
    }
  }
  &.xlarge {
    button {
      padding: ${({ theme }) => theme.spacing(16)};
    }
  }

  li {
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-right: ${({ theme }) => theme.spacing(20)};
      margin-bottom: ${({ theme }) => theme.spacing(20)};

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
