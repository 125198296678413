import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {BeanGameWrapper, EmojiBar, EmojiButtonWrapper} from './BeanGameComponent.styles';
import Button from "../../shared/Button";
import BeautifulBeanFootageTVSvg from "../../FeaturedDetailHeroWithVideo/InlineSvg/BeautifulBeanFootageTVSvg.jsx";
import NineteenNfourSvg from "./InlineSvg/NineteenNfourSvg.jsx";
import {RichText} from "../../shared/JssOverrides";


const BeanGameComponent = (
    {
        title,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
    }
) => {
    const [activeEmoji, setActiveEmoji] = useState('🤌');
    const [recipeData, setRecipeData] = useState({
        link: "https://www.bushbeans.com/en_US/bean-recipes/chili-walking-tacos",
        img: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Images/chili-walking-tacos.jpg",
        alt: "Chili Walking Tacos",
        isVisible: true
    });
    const buttonRef = useRef(null);
    const emojiBarRef = useRef(null);
    const handleEmojiClick = (emojiData) => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 650) {
            emojiBarRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setActiveEmoji(emojiData.emoji);
        setRecipeData({
            link: emojiData.dataLink,
            img: emojiData.dataImg,
            alt: emojiData.dataAlt,
            isVisible: true
        });

        const emojiElements = document.querySelectorAll('.emoji');
        emojiElements.forEach((emojiElement) => {
            if (emojiElement.getAttribute('data-emoji') === emojiData.emoji) {
                emojiElement.classList.add('active');
            } else {
                emojiElement.classList.remove('active');
            }
        });

        const recipeImageUpdate = document.getElementById('recipeImageUpdate');
        if (recipeImageUpdate) {
            recipeImageUpdate.classList.add('animate');
            setTimeout(() => {
                if (recipeImageUpdate) {
                    recipeImageUpdate.classList.remove('animate');
                }
            }, 300);
        }

        const recipeButton = document.getElementById('recipeButtonUpdate');
        if (recipeButton) {
            recipeButton.classList.add('animate');
            setTimeout(() => {
                if (recipeButton) {
                    recipeButton.classList.remove('animate');
                }
            }, 300);
        }

        const emojiSpan = document.querySelector('.emoji-transition');
        if (emojiSpan) {
            emojiSpan.classList.add('animate');
            setTimeout(() => {
                if (emojiSpan) {
                    emojiSpan.classList.remove('animate');
                }
            }, 300);
        }
    };


    const clearAll = () => {
        setActiveEmoji(null);
        setRecipeData({
            link: '',
            img: '',
            alt: '',
            isVisible: false
        });
    };


    return (
        <BeanGameWrapper className="emoji-bean-recipe">
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap" rel="stylesheet" />
            </Helmet>
            <BeautifulBeanFootageTVSvg className="beanTV" />
            <NineteenNfourSvg />
            <RichText className="section-titles" field={field} tag={tag} editable={editable}/>
            <EmojiBar ref={emojiBarRef}>
                <div className="item beans">
                    <span className="emoji-font">🫘</span>
                </div>
                <div className="item plus">
                    <span role="img" aria-label="beans" className="chunk">+</span>
                </div>
                <div className="item emoji">
                    <span className="emoji-transition">{activeEmoji}</span>
                </div>
                <div className="item equals">
                    <span className="chunk">=</span>
                </div>
                <div className="recipe-square">
                    <div className="item recipe">
                        <img id="recipeImageUpdate" src={recipeData.img} alt="{recipeData.alt}"
                             style={{display: recipeData.isVisible ? 'block' : 'none'}}/>
                    </div>
                    <Button
                        ref={buttonRef}
                        id="recipeButtonUpdate"
                        text="Go to Recipe"
                        href={recipeData.link}
                        variant="yellow"
                        style={{display: recipeData.isVisible ? 'flex' : 'none'}}
                    />
                </div>
            </EmojiBar>
            <EmojiButtonWrapper className="emoji-selector">
                <div className="emoji-container">
                    <div
                        className="emoji active"
                        role="img"
                        data-emoji="🤌"
                        onClick={() => handleEmojiClick({
                            emoji: "🤌",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chili-walking-tacos",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Images/chili-walking-tacos.jpg",
                            dataAlt: "Chili Walking Tacos"
                        })}
                    >🤌
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🤠"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🤠",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/baked-beans-burnt-ends-bacon",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-100-173_1920x1280/108_20CL_BUB_3C_AF42530_UL_1920x1280.jpg",
                            dataAlt: "Baked Beans Burnt Ends Bacon"
                        })}
                    >🤠
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🍳"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🍳",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/spicy-baked-beans-and-eggs-toast",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/64_20CL_BUB_3C_AF42509_UL_1920x1280_02.jpg",
                            dataAlt: "Spicy Baked Beans and Eggs Toast"
                        })}
                    >🍳
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥪"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥪",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/tangy-chickpea-smash",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/222_1920x1280_TangyChickpeaSmash.jpg",
                            dataAlt: "Tangy Chickpea Smash"
                        })}
                    >🥪
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🌱"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🌱",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/traditional-falafel-with-dilly-cucumber-salad",
                            dataImg: "https://www.bushbeans.com/-/media/BushsBeans/Recipe_Images/BUSH16531Q4-Recipes-Web-SelectsTraditional-Falafel-with-Dilly-Cucumber-SaladHero217V1AR3FINAL1920x77.jpg",
                            dataAlt: "Traditional Falafel with Dilly Cucumber Salad"
                        })}
                    >🌱
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🏈"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🏈",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chili-magic-potato-skins",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/217_1920x1280_ChiliMagicPotatoSkins.jpg",
                            dataAlt: "Chili Magic Potato Skins"
                        })}
                    >🏈
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🏄‍♂️"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🏄‍♂️",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/sweet-n-savory-simmerin-caribbean-veggie-wrap",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/186_SweetSavoury_1920x1280.jpg",
                            dataAlt: "Sweet N Savory Simmerin Caribbean Veggie Wrap"
                        })}
                    >🏄‍♂️
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🍁"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🍁",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/maple-glazed-chicken-kabobs",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/45_20CL_BUB_3C_AF42512_UL_1920x1280.jpg",
                            dataAlt: "Maple Glazed Chicken Kabobs"
                        })}
                    >🍁
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥤"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥤",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/cola-baked-beans",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/213_Cola_Beans_ORL_1920x1280.jpg",
                            dataAlt: "Cola Baked Beans"
                        })}
                    >🥤
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🐓"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🐓",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/buffalo-chicken-chili-bean-dip",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/220_1920x1280_BuffaloChickChiliCheeseDip.jpg",
                            dataAlt: "Buffalo Chicken Chili Bean Dip"
                        })}
                    >🐓
                    </div>
                    <div
                        className="emoji"
                        data-emoji="⚾"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "⚾",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/cheddar-chili-dogs",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-100-173_1920x1280/123_109-hero-cheddar-chili-dogs_1920x1280.jpg",
                            dataAlt: "Cheddar Chili Dogs"
                        })}
                    >⚾
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🐝"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🐝",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/blackened-salmon",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/31_recipe-1702-blackened_salmon_1920x1280.jpg",
                            dataAlt: "Blackened Salmon"
                        })}
                    >🐝
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🫨"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🫨",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/banana-blueberry-bean-smoothie",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/53_BananaBlueberryBeanSmoothie_v1_LB_1920x1280.jpg",
                            dataAlt: "Banana Blueberry Bean Smoothie"
                        })}
                    >🫨
                    </div>
                    <div
                        className="emoji"
                        data-emoji="👀"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "👀",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chuckwagon-rollups",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Images/chuckwagon-rollups-800x500.jpg",
                            dataAlt: "Chuckwagon Rollups"
                        })}
                    >👀
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥞"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥞",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chickpea-pancakes-with-strawberries-and-cream",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/206_ChickpeaPancakes_cut_1920x1280_v3.jpg",
                            dataAlt: "Chickpea Pancakes with Strawberries and Cream"
                        })}
                    >🥞
                    </div>
                    <div
                        className="emoji"
                        data-emoji="⌚️"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "⌚️",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/instant-pot-taco-soup",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/57_Slow_Cooker_Taco_Soup_1920x1280.jpg",
                            dataAlt: "Instant Pot Taco Soup"
                        })}
                    >⌚️️
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🍗"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🍗",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/simmerin-caribbean-jerk-chicken",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/187_Jerk_Chicken_1920x1280.jpg",
                            dataAlt: "Simmerin Caribbean Jerk Chicken"
                        })}
                    >🍗
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🎉"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🎉",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/southwest-egg-rolls",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/219_1920x1280_ChiliBeanEggRolls.jpg",
                            dataAlt: "Southwest Egg Rolls"
                        })}
                    >🎉
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥵"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥵",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/ghost-pepper-bison-chili-with-quinoa",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/26_Ghost_Pepper_Bison_Chili_Quinoa_1920x1280.jpg",
                            dataAlt: "Ghost Pepper Bison Chili with Quinoa"
                        })}
                    >🥵
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥶"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥶",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/grandad-macks-chili-with-a-twist",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/224_Grandad-Macks-Chili---1920x1280.png",
                            dataAlt: "Grandad Macks Chili with a Twist"
                        })}
                    >🥶
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🌮"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🌮",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chicken-tacos",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/21_recipes-hero-chicken_tacos_1920x1280_02.jpg",
                            dataAlt: "Chicken Tacos"
                        })}
                    >🌮
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🌯"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🌯",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/easy-chicken-and-black-bean-burritos",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-100-173_1920x1280/115_bushs-easy-chick-bean-burrito_1920x1280_02.jpg",
                            dataAlt: "Easy Chicken and Black Bean Burritos"
                        })}
                    >🌯
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🥗"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🥗",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/sweet-potato-black-bean-quinoa-salad",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/07_ChelseaSweetPotatoBlackBeanBowl_1920x1280.jpg",
                            dataAlt: "Sweet Potato Black Bean Quinoa Salad"
                        })}
                    >🥗
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🎃"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🎃",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/bourbon-and-brown-sugar-pumpkin-hand-pies",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/66_bushs_hbb_empanadas_1920x1280.jpg",
                            dataAlt: "Bourbon and Brown Sugar Pumpkin Hand Pies"
                        })}
                    >🎃
                    </div>
                    <div
                        className="emoji"
                        data-emoji="✈️"
                        onClick={() => handleEmojiClick({
                            emoji: "✈️",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/three-bean-salsa-flight",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/205_ThreeBeanSalsaFlight_1920x1280.jpg",
                            dataAlt: "Three Bean Salsa Flight"
                        })}
                    >✈️
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🤏"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🤏",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/chili-cheese-totchos",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Details-176-250_1920x1280/218_1920x1280_ChiliCheeseTotchos.jpg",
                            dataAlt: "Chili Cheese Totchos"
                        })}
                    >🤏
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🛶"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🛶",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/grilled-chile-rellenos-i",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-100-173_1920x1280/127_GrilledChiliRellenos_v1_LB_1920x1280.jpg",
                            dataAlt: "Grilled Chile Rellenos"
                        })}
                    >🛶
                    </div>
                    <div
                        className="emoji"
                        data-emoji="💅"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "💅",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/roasted-garlic-beet-hummus",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/13_recipe-thumbnail-roasted_garlic_beet_hummus_1920x1280.jpg",
                            dataAlt: "Roasted Garlic Beet Hummus"
                        })}
                    >💅
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🫘"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🫘",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/best-3-bean-chili",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-100-173_1920x1280/163_Best3BeanChili_v1_LB_1920x1280.jpg",
                            dataAlt: "Best 3 Bean Chili"
                        })}
                    >🫘
                    </div>
                    <div
                        className="emoji"
                        data-emoji="🐭"
                        role="img"
                        onClick={() => handleEmojiClick({
                            emoji: "🐭",
                            dataLink: "https://www.bushbeans.com/en_US/bean-recipes/baked-bean-bacon-mac-cheese",
                            dataImg: "https://www.bushbeans.com/-/jssmedia/BushsBeans/Recipe_Detail-02-99_1920x1280/47_baked-bean-bacon-mac-cheese_1920x1280.jpg",
                            dataAlt: "Baked Bean Bacon Mac Cheese"
                        })}
                    >🐭
                    </div>

                </div>
                <button style={{display: 'none'}} className="clear-btn" onClick={clearAll}>Clear all</button>
            </EmojiButtonWrapper>
        </BeanGameWrapper>
    );
};

export default BeanGameComponent;