import styled from 'styled-components';

/* the !important rule is used several times to override CSS styles, and is employed only for formatting the printed recipe page */

export const RecipeDirectionDetailsComponent = styled.div``;

export const ImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    transform: translate(-50%, 30px);
  }

  img {
    max-width: 140px;
    height: auto;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 180px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: 250px;
    }
  }
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(80)};
  margin-bottom: ${({ theme }) => theme.spacing(80)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(140)};
  }

  > span {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colours.blue};
    font-size: 50px;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      font-size: inherit;
    }
  }
`;

export const Table = styled.div`
  max-width: ${({ theme }) => theme.grid.maxWidth.default};
  margin: 0 auto ${({ theme }) => theme.spacing(40)};
  @media print {
    max-width: 100vw;
  }

  header {
    display: flex;
    padding: ${({ theme }) => theme.spacing({ top: 20, bottom: 20, left: 20, right: 20 })};
    background-color: ${({ theme }) => theme.colours.red};
    border: 2px solid ${({ theme }) => theme.colours.red};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(30)};
    }

    h2 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  ol {
    list-style: inherit;
    border: 2px solid ${({ theme }) => theme.colours.red};
    border-top: 0;
  }

  .instructions-row-on-print {
    @media print {
      border: none !important;
      color: black !important;
      padding: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      h2 {
        color: black !important;
        font-size: 18px !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .instructions-header-on-print {
    @media print {
      font-size: 18px !important;
      margin: 0 !important;
      padding: 0px, 0px, 5px, 0px !important;
    }
  }

  .instructions-on-print {
    @media print {
      top: 0;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`;

export const TableRow = styled.li`
  display: grid;
  grid-template-areas:
    'number step step step'
    'paragraph paragraph paragraph paragraph'
    'ingredients ingredients ingredients ingredients';
  grid-template-rows: auto auto auto;
  grid-template-columns: 70px 1fr 1fr 1fr;
  @media print {
    grid-template-columns: 20px 1fr 1fr 1fr;
  }
  border-bottom: 2px solid ${({ theme }) => theme.colours.red};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-areas: 'number step step paragraph paragraph paragraph paragraph paragraph paragraph ingredients ingredients';
    grid-template-rows: 1fr;
    grid-template-columns: repeat(11, 1fr);
    align-items: center;
    box-sizing: border-box;
    padding: 0px;
    @media print {
      padding: 0px !important;
    }
    overflow: hidden;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .number span,
  .step span {
    font-weight: 400;
    font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 2vw, ${theme.spacing(72)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(52)}, 2vw, ${theme.spacing(76)})`};
    @media print {
      line-height: 0px !important;
    }
    text-align: center;
    text-transform: uppercase;
  }

  .description p {
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 1vw, ${theme.spacing(24)})`};
    @media print {
      line-height: 1rem !important;
      font-size: 12px !important;
    }
  }

  > * {
    &:nth-child(4n + 1) {
      grid-area: number;
      max-width: 100px;
      padding: ${({ theme }) => theme.spacing({ top: 16, bottom: 10, left: 20, right: 20 })};
      border-right: 2px solid ${({ theme }) => theme.colours.red};
      border-bottom: 2px solid ${({ theme }) => theme.colours.red};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: ${({ theme }) => theme.spacing(40)} 0;
        border-bottom: 0;
      }
    }

    &:nth-child(4n + 2) {
      grid-area: step;
      padding: ${({ theme }) => theme.spacing({ top: 16, bottom: 10, left: 20, right: 20 })};
      color: ${({ theme }) => theme.colours.red};
      border-bottom: 2px solid ${({ theme }) => theme.colours.red};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-left: ${({ theme }) => theme.spacing(20)};
        padding: 0;
        text-align: left;
        border-bottom: 0;
      }
    }
    &:nth-child(4n + 3) {
      grid-area: paragraph;
      padding: ${({ theme }) => theme.spacing(20)};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        padding: 0 ${({ theme }) => theme.spacing(40)};
      }

      @media print {
        padding: 0px 0px 5px 0px !important;
      }

      p {
        margin-bottom: 0;
      }
    }

    &:nth-child(4n + 4) {
      grid-area: ingredients;
      padding: ${({ theme }) => theme.spacing({ top: 0, left: 20, right: 20, bottom: 20 })};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        padding: 0;
      }

      li {
        display: flex;
        align-items: center;
        margin: ${({ theme }) => theme.spacing({ top: 10, bottom: 10, left: 0, right: 0 })};
        text-transform: capitalize;

        p {
          margin-bottom: 0;
          font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 1vw, ${theme.spacing(24)})`};
        }

        svg {
          margin-right: ${({ theme }) => theme.spacing(16)};
          color: ${({ theme }) => theme.colours.red};
        }
      }
    }
  }
`;
