import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    blue: {
      background: theme.colours.blue,
    },
    yellow: {
      background: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const ContentCardContainerComponent = styled.section`
  padding: ${({ theme }) => theme.spacing({ top: 40, bottom: 40, left: 0, right: 0 })};
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
  }
`;