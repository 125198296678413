import React from 'react';
import PropTypes from 'prop-types';
import { HamburgerComponent } from './Hamburger.styles.js';
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";

const Hamburger = ({ onClick, active, ...props }) => (

  <HamburgerComponent
    {...props}
    active={active}
    onClick={(event) => {
      if (typeof onClick === 'function') {
        onClick(event);
      }
    }}
    aria-label="toggle menu"
  >
    <span></span>
    <span></span>
  </HamburgerComponent>
);

Hamburger.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

export default withSitecoreContext()(Hamburger);