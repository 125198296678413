import { checkArrayForValueMatch, findValueIndex } from 'helpers/arrayHelpers';
import { addToStorage, getFromStorage } from 'helpers/storageHelpers';

export const checkIfFavoriteInStorage = ({ itemId = '', key = 'favorite-list' }) => {
  let data = [];

  if (window !== 'undefined') {
    const favoriteList = getFromStorage({ key, type: 'local' });

    if (favoriteList) {
      data = JSON.parse(favoriteList);
    }

    return checkArrayForValueMatch({ key: 'itemId', data, value: itemId });
  }

  return false;
};

export const addFavoriteToList = ({ key = 'favorite-list', value = {}, stateCallback = () => {} }) => {
  let data = [];

  if (window !== 'undefined' && key) {
    const favoriteList = getFromStorage({ key, type: 'local' });

    if (favoriteList) {
      data = JSON.parse(favoriteList);
    }

    if (checkArrayForValueMatch({ key: 'itemId', data, value: value?.itemId })) {
      const index = findValueIndex({ key: 'itemId', data, value: value?.itemId });

      data.splice(index, 1);
      if (stateCallback) stateCallback(false);
    } else {
      data.push(value);
      if (stateCallback) stateCallback(true);
    }

    const stringData = JSON.stringify(data);
    addToStorage({ key, value: stringData, type: 'local' });
  }
};
