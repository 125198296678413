import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FavoritesGridComponent } from './FavoritesGrid.styles.js';
import { getFromStorage } from 'helpers/storageHelpers';
import { searchConfig, globalizationConfig } from 'helpers/searchHelpers';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { useFetch } from 'hooks';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import Grid from 'components/shared/Grid';
import Card from 'components/shared/Card';
import Error from 'components/shared/Listing/components/Error';
import Button from 'components/shared/Button';

import translate from 'helpers/translate';
import { useDeviceResize } from 'hooks/index.js';
import { isServer } from '@sitecore-jss/sitecore-jss';

const FavoritesGrid = (props) => {
  const { viewBag = {}, site } = props.sitecoreContext;
  const [storageFavoritesList, setStorageFavoritesList] = useState(null);
  const maxResults = 10;
  const [favListStart, setFavListStart] = useState(0);
  const [favListEnd, setFavListEnd] = useState(maxResults);
  const [onPageFavList, setOnPageFavList] = useState([]);
  const { endpoint } = searchConfig({ type: 'ids' });
  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        ids: storageFavoritesList ? storageFavoritesList.slice(favListStart, favListEnd) : [],
      },
      context: {
        globalization: globalizationConfig(),
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site.name
          },
        },
        content: {},
      },
    },
  });
  const device = useDeviceResize();
  useEffect(() => {
    setStorageFavoritesList(
      typeof getFromStorage({ key: 'favorite-list', type: 'local' }) === 'string'
        ? JSON.parse(getFromStorage({ key: 'favorite-list', type: 'local' }))
            .map(({ itemId }) => itemId)
            .reverse()
        : []
    );
  }, []);
  useEffect(() => {
    if (Array.isArray(data?.subject?.results)) {
      setOnPageFavList((prevState) => [...prevState, ...data?.subject?.results]);
    }
  }, [data]);

  if (Array.isArray(storageFavoritesList) && storageFavoritesList.length === 0) {
    return (
      <FavoritesGridComponent>
        <div className="no-favorites-message">
          <h1>
            {translate(
              viewBag,
              'BushsBeans.Search.Grids.Favorites.NoneFound',
              "It looks like you haven't added any favorites yet"
            )}
          </h1>
        </div>
      </FavoritesGridComponent>
    );
  }

  return (
    <FavoritesGridComponent>
      {error ? (
        <Error />
      ) : (
        <>
          {onPageFavList.length > 0 && (
            <Grid>
              {onPageFavList.map(
                (
                  { searchTitle, searchDesription, searchImage, searchHoverImage, itemId, itemUrl, pageType },
                  index
                ) => {
                  return (
                    <Card
                      itemId={itemId}
                      key={`result-${itemUrl}-${index}`}
                      type={pageType}
                      title={searchTitle}
                      description={searchDesription}
                      image={searchImage}
                      promoImage={searchHoverImage}
                      link={itemUrl}
                    />
                  );
                }
              )}
            </Grid>
          )}
          {loading && (
            <div className="spinner-container">
              <LoadingSpinner />
            </div>
          )}
          {!isServer() &&
            onPageFavList.length !== 0 &&
            onPageFavList.length < storageFavoritesList?.length &&
            !loading && (
              <div className="load-more-container">
                <Button
                  type="loadmore"
                  variant="yellow"
                  size={device === 'desktop' ? 'large' : 'small'}
                  aria-label="Load More"
                  text={translate(viewBag, 'BushsBeans.Search.Grids.Favorites.ShowMore', 'Show More')}
                  onClick={() => {
                    setFavListStart((prevState) => prevState + maxResults);
                    setFavListEnd((prevState) => prevState + maxResults);
                  }}
                />
              </div>
            )}
        </>
      )}
    </FavoritesGridComponent>
  );
};

FavoritesGrid.propTypes = {
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(FavoritesGrid);
