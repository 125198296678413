import styled from "styled-components";

export const JayDukeSvg = styled.svg`
	width: 130px;
	height: auto;
	top: -70px;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 5;
	position: absolute;
	.st0{fill:#bb5400}
    .st1{fill:#1471d2}
    .st9{fill:#012a72}
    .st24{fill:#d26e00}
    .st25{fill:#a03b1d}
    .st26{fill:#22376e}
    .st30{fill:#cc3530}
    .st47{fill:#c55026}
    .st48{fill:#b68400}
    .st49{fill:#f6d8c0}
    .st50{fill:#872b05}
`;