import styled from 'styled-components';

export const TextInputComponent = styled.div`
  position: relative;
  & label {
    position: relative;
    margin-left: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.adaBrown};
    font-size: ${({ theme }) => theme.spacing(12)};
    text-transform: uppercase;
  }

  & input {
    width: 100%;
    min-width: ${({ theme }) => theme.spacing(300)};
    height: ${({ theme }) => theme.spacing(42)};
    margin-top: ${({ theme }) => theme.spacing(10)};
    //color: ${({ theme }) => theme.colours.adaBrown};
    color: #757575;
    font-weight: 600;
    font-size: ${({ theme }) => theme.spacing(14)};
    line-height: ${({ theme }) => theme.spacing(40)};
    text-indent: ${({ theme }) => theme.spacing(20)};
    //background-color: ${({ theme }) => theme.colours.white};
    background: #fff;
    border: none;
    border-radius: ${({ theme }) => theme.spacing(100)};
    outline: none;

    &::placeholder {
      color: #757575;
    }

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colours.blue};
    }
    
  }
  & .error-message {
    margin-top: ${({ theme }) => theme.spacing(10)};
    margin-bottom: ${({ theme }) => theme.spacing(0)};
    margin-left: ${({ theme }) => theme.spacing(20)};
    color: #ef3826;
    font-size: ${({ theme }) => theme.spacing(10)};
    line-height: ${({ theme }) => theme.spacing(16)};
    text-align: left;
  }
`;

export const CheckboxComponent = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.spacing(32)};
  cursor: pointer;
  user-select: none;

  & label {
    position: absolute;
    padding-left: ${({ theme }) => theme.spacing(42)};
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(32)};
    cursor: pointer;
  }

  & input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 30px;
    width: 30px;
    top: -2px;
    left: -2px;
  }
  /* Create a custom checkbox */
  & .check-mark {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ theme }) => theme.spacing(28)};
    height: ${({ theme }) => theme.spacing(28)};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colours.adaBrown};
    border-radius: ${({ theme }) => theme.spacing(5)};
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: ${({ theme }) => theme.spacing(11)};
      top: ${({ theme }) => theme.spacing(5)};
      width: ${({ theme }) => theme.spacing(7)};
      height: ${({ theme }) => theme.spacing(15)};
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  & .bean-check-mark {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ theme }) => theme.spacing(28)};
    height: ${({ theme }) => theme.spacing(28)};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colours.adaBrown};
    border-radius: ${({ theme }) => theme.spacing(5)};
    pointer-events: none;
    .beanCheck {
		position: relative;
		top: 3px;
		left: 4px;
    }
  }  

  &:hover input ~ .check-mark {
    border: 2px solid ${({ theme }) => theme.colours.red};
  }
	&:hover input ~ .bean-check-mark {
		border: 2px solid ${({ theme }) => theme.colours.blue};
	}    

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .check-mark {
    border: none;
    background-color: ${({ theme }) => theme.colours.red};
    width: ${({ theme }) => theme.spacing(32)};
    height: ${({ theme }) => theme.spacing(32)};
  }
	& input:checked ~ .bean-check-mark {
		border: none;
		background-color: ${({ theme }) => theme.colours.adaBrown};
		width: ${({ theme }) => theme.spacing(32)};
		height: ${({ theme }) => theme.spacing(32)};
	}    

  & input:checked ~ .check-mark:after {
    display: block;
  } 
  & input:checked ~ .bean-check-mark .bean {
	display: block;
  }
	.bean-check-mark {
		.beanCheck{
			display: none;
		}
	}
    .bean-check-mark.active {
        .beanCheck{
            display: block;
        }
    }
`;

export const RadioComponent = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: ${({ theme }) => theme.spacing(32)};
  user-select: none;

  & label {
    position: absolute;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(32)};
    padding-left: ${({ theme }) => theme.spacing(42)};
    cursor: pointer;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  & .check-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ theme }) => theme.spacing(28)};
    width: ${({ theme }) => theme.spacing(28)};
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colours.adaBrown};
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: ${({ theme }) => theme.spacing(5)};
      left: ${({ theme }) => theme.spacing(5)};
      width: ${({ theme }) => theme.spacing(18)};
      height: ${({ theme }) => theme.spacing(18)};
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colours.red};
    }
  }

  &:hover input ~ .check-mark {
    border: 2px solid ${({ theme }) => theme.colours.red};
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .check-mark {
    border: 2px solid ${({ theme }) => theme.colours.red};
    background-color: ${({ theme }) => theme.colours.white};
  }

  & input:checked ~ .check-mark:after {
    display: block;
  }
`;