import styled from 'styled-components';

export const ProductDetailHeroComponent = styled.section`
  position: relative;

  /* This is the CSS for the destini modal that is recommended, integrating with our modal didn't work well on mobile  */
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    padding-left: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  #widget-modal {
    .modal-body {
      position: relative;
      text-align: center;
      padding: 3%;
      width: 100%;
      max-width: 1200px;
      min-width: 300px;
      height: 500px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      @media (min-width: 360px) {
        height: 600px;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        height: 650px;
      }
    }
    > button {
      position: relative;
      color: #fff;
      font-size: 40px;
      position: absolute;
      top: 3px;
      right: 20px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        top: 10px;
      }
    }
  }
`;

export const SideBySide = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;

    ${({ alignment }) => `
      ${alignment === 'right' ? 'flex-direction: row-reverse;' : ''}
    `}
  }

  > div {
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;
    }
  }
`;

export const Block = styled.div`
  position: relative;
  overflow: hidden;

  ${({ backgroundImage }) =>
    backgroundImage?.src
      ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `
      : ''}

  > div {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(50)}, 8vw, ${theme.spacing(170)})`};

    h1 {
      text-transform: uppercase;
    }

    & p {
      margin-bottom: ${({ theme }) => theme.spacing(0)};
    }

    & .available-sizes {
      margin-top: ${({ theme }) => `clamp(${theme.spacing(24)}, 2vw, ${theme.spacing(48)})`};
      margin-bottom: ${({ theme }) => theme.spacing(24)};
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(88)};
      }
    }
  }

  &.background-image {
    min-height: ${({ theme }) => theme.spacing(50)};
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(50)}, 8vw, ${theme.spacing(170)})`};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: 0;
    }
  }

  > img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: absolute;
      top: 0;
      left: 0;
      max-height: unset;
    }
  }
`;

export const FloatingImage = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 200px;
    height: auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 21vw;
      max-width: 360px;
    }
  }

  .badge {
    width: 90px;
    position: absolute;
    top: 73%;
    right: 0px;
  }
`;
