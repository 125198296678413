export const generateQueryString = (obj = {}) => {
  // removes unneeded object keys / when generating query string
  Object.keys(obj).forEach(
    (key) =>
      (obj[key] === undefined || obj[key] === null || obj[key] === '' || obj[key].length === 0) &&
      delete obj[key]
  );

  const queryString = Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        const arr = [];
        obj[key].forEach((item) => arr.push(encodeURI(item)));

        return `${key}=${arr.join()}`;
      }

      if (typeof obj[key] === 'string') {
        if (key === 'search') {
          return `${key}=${encodeURI(obj[key])}`;
        }
        return `${key}=${obj[key].replace(/\s/g, '')}`;
      }

      if (typeof obj[key] === 'number' || typeof obj[key] === 'boolean') {
        return `${key}=${obj[key]}`;
      }
    })
    .join('&');

  return queryString.length > 1 ? `?${queryString}` : '';
};

export const parseQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    const queryObject = {};
    for (const [key, value] of params.entries()) {
        queryObject[key] = value;
    }
    return queryObject;
};

export const queryStringToObj = (query) =>
  [...query.entries()].reduce((acc, item) => {
    const [key, value] = item;
    acc[key] = value.split(',');

    return acc;
  }, {});