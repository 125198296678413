import React from 'react';
import PropTypes from 'prop-types';
import { NavLinkComponent } from './NavLink.styles.js';
import Icon from 'components/shared/Icon';

const NavLink = ({ text, active, subNav, mobile, ...props }) => {
  return (
    <NavLinkComponent {...props} active={active} mobile={`${true}`} link={subNav ? 'false' : 'true'}>
      {text}
      {subNav && <Icon icon={mobile ? 'chevron-right' : 'chevron-down'} />}
    </NavLinkComponent>
  );
};

NavLink.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  subNav: PropTypes.bool,
  mobile: PropTypes.bool,
  active: PropTypes.string,
};

export default NavLink;