import styled from "styled-components";


export const AlchemerSurveyWrapper = styled.div`
   max-width: 100%;
   width: 100%;
   z-index: 99999999999999999;
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   height: 100%; 
   background: #ffca2e;
  .view-survey-action-wrapper{
    background-color: rgb(255, 202, 46);
    padding: 100px 0;
    h3{
      color: ${({ theme }) => theme.colours.red};
      font-size: 30px;
      display: block;
      line-height: 1;
    }
    button{
      padding: 10px 0;
      display: block;
      width: 80%;
      margin: auto;
      text-align: center;
      background: #d43631;
      color: #ffca2e;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: 20px;
      letter-spacing: 1px;
      max-width: 300px;
      margin-top: 30px;
      border: 2px solid #d43631;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      &:hover{
        background: #ffca2e;
        color: #d43631;
        border: 2px solid #d43631;
      }
    }
  }
`;

export const AlchemerSurveyInPageWrapper = styled.div`
   max-width: 100%;
   width: 100%;
   z-index: 992000;
   position: relative;
   background: #ffca2e;
   .red-btn-a{
	   width: 250px;
	   display: block;
	   margin: auto;
   } 
  .view-survey-action-wrapper{
    background-color: rgb(255, 202, 46);
    padding: 100px 0;
    h3{
      color: ${({ theme }) => theme.colours.red};
      font-size: 30px;
      display: block;
      line-height: 1;
    }
    button{
      padding: 10px 0;
      display: block;
      width: 80%;
      margin: auto;
      text-align: center;
      background: #d43631;
      color: #ffca2e;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: 20px;
      letter-spacing: 1px;
      max-width: 300px;
      margin-top: 30px;
      border: 2px solid #d43631;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      &:hover{
        background: #ffca2e;
        color: #d43631;
        border: 2px solid #d43631;
      }
    }
  }
`;

export const ContentInPageAlchemerComponent = styled.section`
  width: 100%;
  padding: 0;
  color: ${({ theme }) => theme.colours.brown};
  text-align: left;
  background-color: ${({ theme }) => theme.colours.yellow};
  position: relative;
  height: 100%;  
  iframe{
      height: 100% !important;
      width: 100% !important;
      max-width: 100% !important;
  }  
  @media (max-width: 640px) {
      height: 100%;
  }  
  &.fs-text-component{
    padding: 0;
    margin-top: 160px;
    margin-bottom: 160px;
    @media (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
  }
  &.noMargin{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .content-container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 800px;
      font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto !important;
      margin-bottom: ${({ theme }) => theme.spacing(48)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    font-weight: 400;
  }

  p {
    line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
  }

  span {
    display: block;
  }

  ul {
    margin-bottom: 16px;
    list-style: disc;

    li {
      margin-left: ${({ theme }) => theme.spacing(16)};
    }
  }

  ol {
    margin-bottom: 16px;
    list-style-type: decimal;

    li {
      margin-left: ${({ theme }) => theme.spacing(28)};
    }
  } 
  .sg-question-title{
      label{
          border: 0 !important;
      }
  }
  .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-back-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-next-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-submit-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-back-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-next-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-submit-button:hover{
    border: 2px solid ${({ theme }) => theme.colours.red};
    color: ${({ theme }) => theme.colours.red} !important;
    background-color: ${({ theme }) => theme.colours.yellow};
  }
	.sg-survey .sg-body.sg-int-virtual-page .sg-button-bar, .sg-survey .sg-mobile-optimized .sg-button-bar{
        
    }
	.sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-back-button, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-next-button, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-submit-button, .sg-survey .sg-button, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-back-button, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-next-button, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-submit-button{
        background-color: ${({ theme }) => theme.colours.red};
        color: #fff !important;
    }
	.sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-back-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-next-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-submit-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-back-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-next-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-submit-button:hover{
		background-color: ${({ theme }) => theme.colours.red} !important;
		color: #fff !important; 
    }
	.sg-survey .sg-body.sg-int-virtual-page .sg-footer, .sg-survey .sg-mobile-optimized .sg-footer{
        background: transparent;
    }
    #sg_NextButton{
		background-color: ${({ theme }) => theme.colours.red} !important;
		color: #fff !important;
		&:hover{
			background-color: ${({ theme }) => theme.colours.red} !important;
			color: #fff !important;
		}
	}
    .sg-mobile-back{
		background-color: ${({ theme }) => theme.colours.red} !important;
		color: #fff !important;
        &:hover{
			background-color: ${({ theme }) => theme.colours.red} !important;
			color: #fff !important;  
        }
    }
    .sg-footer{
        background: transparent !important;
    }
    .sg-mobile-next{
		background-color: ${({ theme }) => theme.colours.red} !important;
		color: #fff !important;
		&:hover{
			background-color: ${({ theme }) => theme.colours.red} !important;
			color: #fff !important;
		}
    }
  .sg-question-options{
	  textarea{
          width: 100%;
          max-width: 100%;
      }
  }  
  .sg-survey{
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    .sg-mobile-optimized {
      input[type=checkbox]:checked+label{
        background: #fff;
      }
      .sg-wrapper{
        padding-bottom: 100px;
        background-color: rgb(255, 202, 46);
      }
      #sg_FormFor8067419 .sg-question label{
        border: 2px solid #6d3102;
      }
    }
    .sg-body{
      background-color: ${({ theme }) => theme.colours.yellow} !important;
      .sg-page-title{
        font-family: "apercu condensed pro" !important;
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  .sg-survey-form{
    background-color: ${({ theme }) => theme.colours.yellow};
    width: 100%;
    margin: auto;
    max-width: 100%;
    padding-top: 10px;
    .sg-error-display{
      height: fit-content;
    }
    .sg-question-set .sg_question legend{
      color: #6d3102 !important;
      font-size: 22px;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-header{
      display: none;
    }
    .sg-question-title label{
      font-size: 20px;
      font-family: "apercu condensed pro" !important;
      font-weight: 700;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-question-title{
      font-size: 20px;
      font-family: "apercu condensed pro" !important;
      font-size: 22px !important;
      color: #6d3102 !important;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-content{
      width: 100%;
      max-width: 100%;
      background-color: ${({ theme }) => theme.colours.yellow};
      font-family: "apercu condensed pro" !important;
      input [type="text"]{
        background-color: white;
      }
      .sg-back-button{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
      .sg-mobile-back{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
      input[type="submit"]{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
    }
    .sg-question {
      border-bottom: 0;
      label{
        outline: none;
        color: #6d3102 !important;
        font-size: 18px;
      }
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
    .sg-question-number{
      display: inline-block;
    }
    .sg-input-text, .sg-input-menu, .sg-input-essay, .sg-question input[type="text"], .sg-question input[type="number"], .sg-question input[type="password"], .sg-question textarea{
      background-color: #fff;
    }
  }
  .sg-survey .sg-body .sg-page-title {
    font-weight: 700;
    font-size: 45px;
    font-family: "apercu condensed pro" !important;
    color: #d43631;
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
  .sg-progress-bar{
      display: none;
  }
  .sg-quizscore-item{
      color: #6d3102 !important;
  }  
  .sg-quizscore-message{
      color: #6d3102 !important;
  }  
`;

export const ContentAlchemerComponent = styled.section`
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 0, right: 0 })};
  color: ${({ theme }) => theme.colours.brown};
  text-align: left;
  background-color: ${({ theme }) => theme.colours.yellow};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &.fs-text-component{
    padding: 0;
    margin-top: 160px;
    margin-bottom: 160px;
    @media (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
  }
  &.noMargin{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .content-container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 800px;
      font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto !important;
      margin-bottom: ${({ theme }) => theme.spacing(48)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    font-weight: 400;
  }

  p {
    line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
  }

  span {
    display: block;
  }

  ul {
    margin-bottom: 16px;
    list-style: disc;

    li {
      margin-left: ${({ theme }) => theme.spacing(16)};
    }
  }

  ol {
    margin-bottom: 16px;
    list-style-type: decimal;

    li {
      margin-left: ${({ theme }) => theme.spacing(28)};
    }
  } 
  .sg-question-title{
      label{
          border: 0 !important;
      }
  }
  .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-back-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-next-button:hover, .sg-survey .sg-body.sg-int-virtual-page .sg-button-bar .sg-submit-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-back-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-next-button:hover, .sg-survey .sg-mobile-optimized .sg-button-bar .sg-submit-button:hover{
    border: 2px solid ${({ theme }) => theme.colours.red};
    color: ${({ theme }) => theme.colours.red} !important;
    background-color: ${({ theme }) => theme.colours.yellow};
  }
  .sg-survey .sg-mobile-optimized.safari-mobile .sg-question.sg-type-radio .sg-question-options label{
      background-color: ${({ theme }) => theme.colours.red};
	  color: #fff !important;
      border: 1px solid ${({ theme }) => theme.colours.red};
  }
    #sg_NextButton{
		background-color: ${({ theme }) => theme.colours.red} !important;
		color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red} !important;
		&:hover{
			background-color: ${({ theme }) => theme.colours.yellow} !important;
			color: ${({ theme }) => theme.colours.red} !important;
			border: 2px solid ${({ theme }) => theme.colours.red} !important;
		}
    }
   #sg_BackButton{
	   background-color: ${({ theme }) => theme.colours.red} !important;
	   color: ${({ theme }) => theme.colours.yellow} !important;
	   border: 2px solid ${({ theme }) => theme.colours.red} !important;
	   &:hover{
		   background-color: ${({ theme }) => theme.colours.yellow} !important;
		   color: ${({ theme }) => theme.colours.red} !important;
		   border: 2px solid ${({ theme }) => theme.colours.red} !important;
	   }
   } 
   #sg_SubmitButton{
	   background-color: ${({ theme }) => theme.colours.red} !important;
	   color: ${({ theme }) => theme.colours.yellow} !important;
	   border: 2px solid ${({ theme }) => theme.colours.red} !important;
	   &:hover{
		   background-color: ${({ theme }) => theme.colours.yellow} !important;
		   color: ${({ theme }) => theme.colours.red} !important;
		   border: 2px solid ${({ theme }) => theme.colours.red} !important;
	   }
   } 
  .sg-question-options{
	  textarea{
          width: 100%;
          max-width: 100%;
      }
  }  
  .sg-survey{
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    .sg-mobile-optimized {
      input[type=checkbox]:checked+label{
        background: #fff;
      }
      .sg-wrapper{
        padding-bottom: 100px;
        background-color: rgb(255, 202, 46);
      }
      #sg_FormFor8067419 .sg-question label{
        border: 2px solid #6d3102;
      }
    }
    .sg-body{
      background-color: ${({ theme }) => theme.colours.yellow} !important;
      .sg-page-title{
        font-family: "apercu condensed pro" !important;
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  .sg-survey-form{
    background-color: ${({ theme }) => theme.colours.yellow};
    width: 100%;
    margin: auto;
    max-width: 100%;
    padding-top: 10px;
    .sg-error-display{
      height: fit-content;
    }
    .sg-question-set .sg_question legend{
      color: #6d3102 !important;
      font-size: 22px;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-header{
      display: none;
    }
    .sg-question-title label{
      font-size: 20px;
      font-family: "apercu condensed pro" !important;
      font-weight: 700;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-question-title{
      font-size: 20px;
      font-family: "apercu condensed pro" !important;
      font-size: 22px !important;
      color: #6d3102 !important;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .sg-content{
      padding-left: 0;
      padding-right: 0;  
      width: 100%;
      max-width: 100%;
      background-color: ${({ theme }) => theme.colours.yellow};
      font-family: "apercu condensed pro" !important;
      input [type="text"]{
        background-color: white;
      }
      .sg-back-button{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
      .sg-mobile-back{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
      input[type="submit"]{
        background-color: ${({ theme }) => theme.colours.red};
        font-family: "apercu condensed pro" !important;
        font-size: 22px !important;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border: 2px solid ${({ theme }) => theme.colours.red};
        position: relative;
        padding: 0;
        height: 45px;
        line-height: 45px;
        &:hover{
          border: 2px solid ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
    }
    .sg-question {
      border-bottom: 0;
      label{
        outline: none;
        color: #6d3102 !important;
        font-size: 18px;
      }
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
    .sg-question-number{
      display: inline-block;
    }
    .sg-input-text, .sg-input-menu, .sg-input-essay, .sg-question input[type="text"], .sg-question input[type="number"], .sg-question input[type="password"], .sg-question textarea{
      background-color: #fff;
    }
  }
  .sg-survey .sg-body .sg-page-title {
    font-weight: 700;
    font-size: 45px;
    font-family: "apercu condensed pro" !important;
    color: #d43631;
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
  .sg-progress-bar-background{
        display: none;
  }  
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 800px;
  height: 90%;
  background-color: rgb(255, 202, 46);
  color: white;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin: auto;
  overflow: scroll;
  box-shadow: 0px 0px 10px rgba(00, 00, 00, 0.5);
  box-sizeing: border-box;
  &:before{
    content: '';
    background-color: rgba(00,00,00,0.5);
    z-index: -1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  @media (max-width: 820px) {
    width: 90%;
    max-width: 90%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  color: rgb(212, 54, 49);
  cursor: pointer;
  z-index: 999;
  font-size: 40px;
  padding: 3px 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(00, 00, 00, 0.5);
  transition: all 0.4s ease-in-out;
  &:hover{
    background: #d43631;
    color: #ffca2e;
  }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background-color: ${({ theme }) => theme.colours.yellow};
    width: 1000px;
    max-width: 90%;
    padding: 50px 20px 20px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    height: 90%;
    margin: auto;
    overflow: hidden;
	@media (max-width: 640px) {
		height: 80%;
	}
`;