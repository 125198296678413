import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { FeaturedProductComponent, ImageWrapper, NewTextWrapper } from './FeaturedProduct.styles.js';

import Link from 'components/shared/Link';

import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import translate from 'helpers/translate';
import { checkBadges, languageFilter } from 'helpers/badgeHelpers.js';

const FeaturedProduct = ({ sitecoreContext = {}, title = '', image = {}, link = '', ...rest }) => {
  const { viewBag = {}, salsify = {}, language = '' } = sitecoreContext;
  const { countryofOrigin } = salsify;
  const { isNewProduct: isNewItem, webBadge } = rest;

  const filteredLanguage = languageFilter(language);
  const activeImgSrc = checkBadges(isNewItem, webBadge, filteredLanguage, countryofOrigin);

  return (
    <FeaturedProductComponent>
      <ConditionalComponentWrapper
        condition={link}
        wrapper={(children) => <Link href={link}>{children}</Link>}
      >
        <>
          {image && (
            <ImageWrapper>
              {isNewItem && (
                <NewTextWrapper>
                  <span>
                    {activeImgSrc && (
                      <img className="featured-product-badge" src={activeImgSrc} alt={'new look'} />
                    )}
                  </span>
                </NewTextWrapper>
              )}

              <img src={image?.url} alt={image?.url} />
            </ImageWrapper>
          )}

          {title && <h3 className="h5" dangerouslySetInnerHTML={{ __html: title }} />}
        </>
      </ConditionalComponentWrapper>
    </FeaturedProductComponent>
  );
};

FeaturedProduct.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.object,
  sitecoreContext: PropTypes.object,
  isNewItem: PropTypes.bool,
  webBadge: PropTypes.string,
  language: PropTypes.string,
};

export default withSitecoreContext()(FeaturedProduct);
