import styled from 'styled-components';

const getVariantColours = ({variant, theme} = {}) => {
    const variants = {
        red: {
            background: theme.colours.yellow,
        },
        yellow: {
            background: theme.colours.red,
        },
        blue: {
            background: theme.colours.yellow,
        },
        brown: {
            background: theme.colours.yellow,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.red;
};

export const SideBySideLargeImageComponent = styled.section`
	position: relative;
	background-color: ${({ theme, backgroundColor }) =>
		getVariantColours({ variant: backgroundColor, theme }).background};
	padding:  ${({ theme }) => theme.spacing({ top: 80, bottom: 80, left: 35, right: 35 })};
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
        
		padding:  ${({ theme }) => theme.spacing({ top: 80, bottom: 100, left: 35, right: 35 })};
	}

	${({ theme }) => theme.breakPoints.minBp('1920px')} {
		padding:  ${({ theme }) => theme.spacing({ top: 80, bottom: 120, left: 35, right: 35 })};
	}
    .container{
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 0;
        width: 1550px;
        max-width: 100%;
        margin: auto;
    }
    &.page-type-article{
		padding: 30px 15px;
        width: 100%;
		background-color: ${({theme}) => theme.colours.tan};
        .container{
			width: 100%;
            margin: auto;
            align-self: center;
            max-width: 100%;
			padding: 0;
			.promoboxWrap{
				order: 1;
				padding: 0 15px;
				grid-column: span 12 / span 12;
				p{
					font-size: 1rem;
					line-height: 1.5;
				}
			}
			.cont-img-block{
				order: 0;
				padding: 15px;
				grid-column: span 12 / span 12;
				img{
					margin: auto;
				}
			}
        }
		@media (min-width: 1200px){
			padding: 30px 15px;
            .container{
                .promoboxWrap{
                    p{
						font-size: clamp(1rem, 4vw, 1.5rem); 
                    }
                }
            }
			
        }
		@media (min-width: 800px){
			padding: 30px 0;
            .container{
                padding: 0;
                width: 800px;
				.promoboxWrap{
                    order: 0;
					padding: 0;
					padding-right: 15px;
					grid-column: span 6 / span 6;
				}
				.cont-img-block{
                    order: 1;
					padding: 0;
					grid-column: span 6 / span 6;
					img{
						margin: auto;
						width: 90%;
					}
				}
            }
		}
	}
`;
export const ContentCol = styled.div`
    display: flex;
    flex-flow: column;
	grid-column: span 5 / span 5;
    align-self: center;
    padding-right: 140px;
    h2{
		color: ${({theme}) => theme.colours.red};
        font-size: 55px;
        line-height: 1.2;
        text-align: left;
    }
    p{
		color: ${({theme}) => theme.colours.brown};
        font-size: 20px;
        line-height: 1.5;
    }
	@media (max-width: 1300px){
		padding-right: 80px;
	}
	@media (max-width: 900px){
		grid-column: span 12 / span 12;
        padding-right: 0;
        h2{
            font-size: 40px;
        }
		p{
            font-size: 18px;
        }
    }
	@media (max-width: 650px){
		h2{
			font-size: 32px;
		}
		p{
			font-size: 16px;
		}
	}
`;
export const ImageCol = styled.div`
    display: flex;
    flex-flow: column;
	grid-column: span 7 / span 7;
	position: relative;
	overflow: hidden;
	> img {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
	}
	@media (max-width: 900px){
		grid-column: span 12 / span 12;
	}
`;