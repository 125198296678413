import React, { useRef, useState } from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { useOnClickOutside } from 'hooks';

import Icon from 'components/shared/Icon';
import { Wrapper, ToolTipWrapper, TitleWrap, ImageWrap } from './Tooltip.styles';
import translate from 'helpers/translate';

const Tooltip = ({ text, image, sitecoreContext = {} }) => {
  const toolTipRef = useRef(null);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const { viewBag = {} } = sitecoreContext;

  useOnClickOutside(toolTipRef, () => {
    setToolTipOpen(false);
  });

  return (
    <Wrapper>
      <button type="button" className="details-flag" onClick={() => setToolTipOpen(!toolTipOpen)}>
        {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.WhatsThisHelperText', "What's This?")}
      </button>
      {toolTipOpen && (
        <ToolTipWrapper ref={toolTipRef}>
          <TitleWrap>
            {text && <Text field={text} tag="span" />}
            <button type="button" onClick={() => setToolTipOpen(!toolTipOpen)}>
              <Icon icon="cross" size="sm" />
            </button>
          </TitleWrap>
          <ImageWrap>{image && <Image field={image} />}</ImageWrap>
        </ToolTipWrapper>
      )}
    </Wrapper>
  );
};

export default withSitecoreContext()(Tooltip);
