import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';

export const SearchPageComponent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.tan};

  & .search-box-container {
    padding: ${({ theme }) => theme.spacing({ top: 36, bottom: 36, left: 0, right: 0 })};
    background-color: ${({ theme }) => theme.colours.yellow};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing({ top: 60, bottom: 60, left: 0, right: 0 })};
    }

    & h3 {
      max-width: 90%;
      margin: 0 auto 24px;
      color: ${({ theme }) => theme.colours.red};
      font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(36)})`};
      text-align: left;
      text-indent: 24px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: 0 auto 36px;
        text-indent: 48px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin: 0 auto 40px;
      }
    }
    & h1 {
      max-width: 90%;
      margin: 0 auto 24px;
      color: ${({ theme }) => theme.colours.red};
      font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(36)})`};
      text-align: left;
      text-indent: 24px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: 0 auto 36px;
        text-indent: 48px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin: 0 auto 40px;
      }
    }
    .search-top-heading{
      max-width: 90%;
      margin: 0 auto 24px;
      color: ${({ theme }) => theme.colours.red};
      font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(36)})`};
      text-align: left;
      text-indent: 24px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: 0 auto 36px;
        text-indent: 48px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin: 0 auto 40px;
      }
    }
  }

  & .filters-container {
    position: relative;
    display: flex;
    height: 64px;
    border-bottom: 1px solid ${({ theme }) => theme.colours.adaBrown};
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      height: 80px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      height: 100px;
    }

    .filters-container-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 90%;
      margin: 0 auto;
    }

    & .filters {
      display: flex;
      align-content: center;
      align-self: center;
      width: 50%;
      height: 100%;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: 80%;
      }

      & .filter-note {
        display: none;
        align-items: center;
        height: 100%;

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          display: flex;
        }

        & svg {
          margin-right: 12px;
          color: ${({ theme }) => theme.colours.brown};
        }

        & h4 {
          margin-bottom: 0;
          color: ${({ theme }) => theme.colours.brown};
          font-weight: normal;
          font-family: ${({ theme }) => theme.typography.fontFamily.main};
        }
      }

      & .filter-button {
        display: flex;
        align-items: center;
        height: 100%;
        border-bottom: ${({ showOptions, theme }) =>
          showOptions ? `4px solid ${theme.colours.red}` : 'none'};

        &:focus {
          outline: none;
        }

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          display: none;
        }

        & svg {
          margin-right: 12px;
          color: ${({ theme }) => theme.colours.brown};
        }
        & h3 {
          margin-bottom: 0;
          color: ${({ theme }) => theme.colours.brown};
        }
      }

      & .search-options {
        position: absolute;
        top: 65px;
        left: 0;
        z-index: 799;
        display: ${({ showOptions }) => (showOptions ? 'block' : 'none')};
        width: 100%;
        margin-left: 0;
        padding-top: 24px;
        background-color: ${({ theme }) => theme.colours.tan};
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.38);

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          top: 81px;
        }

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          position: relative;
          top: 0;
          left: 0;
          z-index: 0;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          align-self: center;
          justify-content: flex-start;
          margin-left: 24px;
          background-color: transparent;
          box-shadow: none;
        }

        & div:nth-child(1) {
          max-width: 100px;
        }

        & > div {
          max-width: 150px;
          margin-left: 48px;
          margin-bottom: 24px;

          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            margin-left: 0;
            margin-left: 24px;
            margin-bottom: 24px;
          }
        }
      }
    }

    & .search-counter {
      align-self: center;

      & h4 {
        text-align: right;
        font-family: ${({ theme }) => theme.typography.fontFamily.main};
        font-weight: normal;
        margin-bottom: 0;
      }
    }
  }

  & .heading {
    position: relative;

    & h2 {
      color: ${({ theme }) => theme.colours.red};
      padding: ${({ theme }) =>
        `clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(88)}) 
      clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(88)})`};
      font-size: clamp(40px, 6vw, 128px);
      margin-bottom: 0;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: 32px;
      }

      > u {
        font-size: 70%;
        vertical-align: top;
      }
    }
  }

  & h1 {
    color: ${({ theme }) => theme.colours.red};
    padding: ${({ theme }) =>
            `clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(88)}) 
      clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(88)})`};
    font-size: clamp(40px, 6vw, 128px);
    margin-bottom: 0;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: 32px;
    }

    > u {
      font-size: 70%;
      vertical-align: top;
    }
  }
}

  & .search-results {
    max-width: ${({ theme }) => theme.spacing(900)};
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding-left: ${({ theme }) => theme.spacing(24)};
      padding-right: ${({ theme }) => theme.spacing(24)};
    }

    & .spinner-container {
      display: flex;
      min-height: ${({ theme }) => theme.spacing(750)};
      border-bottom: none;
      & > div {
        align-self: center;
        width: 100%;
      }
      .loading-spinner {
        height: 50vh;
      }
    }

    & > div {
      border-bottom: 4px solid ${({ theme }) => theme.colours.red};
    }

    & div:last-child {
      border-bottom: none;
    }
  }

  & .pagination-section {
    padding: ${({ theme }) => theme.spacing({ top: 48, bottom: 48, left: 0, right: 0 })};
    background-color: ${({ theme }) => hexToRgba(theme.colours.adaTan, 0.5)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      padding: ${({ theme }) => theme.spacing({ top: 70, bottom: 70, left: 0, right: 0 })};
    }

    h3 {
      margin-bottom: ${({ theme }) => theme.spacing(48)};
      color: ${({ theme }) => theme.colours.red};
      font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 6vw, ${theme.spacing(50)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(38)}, 6vw, ${theme.spacing(60)})`};
    }
  }

  & .pagination-wrap {
    display: flex;
    justify-content: space-between;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      justify-content: center;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0 auto;

      & li {
        margin: ${({ theme }) => `0 ${theme.spacing(8)}`};
        color: ${({ theme }) => theme.colours.adaBrown};
        font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 6vw, ${theme.spacing(38)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(38)}, 6vw, ${theme.spacing(38)})`};
        text-decoration: none;
        list-style: none;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          margin: ${({ theme }) => `0 ${theme.spacing(16)}`};

          &.pagination-previous {
            margin: 0 140px 0 0;
          }
          &.pagination-next {
            margin: 0 0 0 140px;
          }
        }

        & button {
          color: ${({ theme }) => theme.colours.adaBrown};
          font-size: ${({ theme }) => theme.spacing(32)};
          font-family: ${({ theme }) => theme.typography.fontFamily.heading};
          line-height: ${({ theme }) => theme.spacing(38)};
          text-decoration: none;
          list-style: none;
          outline: none;
          cursor: pointer;

          &.active {
            color: ${({ theme }) => theme.colours.red};
          }
        }
      }
    }
  }
`;

export const PaginationSection = styled.div``;

export const NoResults = styled.div`
  padding: 100px 0;

  h1 {
    color: ${({ theme }) => theme.colours.red};
  }
`;
