import React, { useState } from 'react';
import {
  MainNavComponent,
  MobileNavComponentFs
} from './MobileNav.styles.js';
import { SearchButton } from '../../MainNav.styles';
import Icon from 'components/shared/Icon';
import Link from 'components/shared/Link';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Hamburger from '../FsHamburger';

import NavLink from '../NavLink';

import Overlay from 'components/shared/Overlay';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from "../../../shared/Button";

const MobileNav = ({ headerContext = {}, sitecoreContext: { globalContent, route }, search, setSearch, className, ...props }) => {


  if (headerContext) {
    if (Array.isArray(headerContext.globalContent)) {
      globalContent = [...globalContent, ...headerContext.globalContent];
    } else if (typeof headerContext.globalContent === 'object' && headerContext.globalContent !== null) {
      globalContent = {...globalContent, ...headerContext.globalContent};
    } else {
      const globalContent = [headerContext.globalContent];
    }
  }


  const links = globalContent?.navigation;
  const mobileUtilityLinks = globalContent?.mobileUtilityLinks;
  const utilityLinks = globalContent?.utilityLinks;
  const logo = globalContent?.logo;
  const homePageLink = globalContent?.homepageLink;
  const linkColumns = globalContent?.footer?.linkColumns;
  const languageSelector = globalContent?.languageSelectorItems;
  const navigation = globalContent?.navigation;
  const [subNav, setSubNav] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeLink, setActiveLink] = useState('');

  const ProductsText =
    Array.isArray(links) &&
    links.find(
      ({ type, cards }) =>
        type === 'megamenu-cards' && cards && cards.filter(({ type }) => type === 'product').length > 0
    )?.text;

  const supportingLinks = navigation?.reduce((acc, el) => {
    if (el?.supportingLinks?.length) {
      return el.supportingLinks;
    }
    return [...acc];
  }, []);

  const handleLanguageSelector = (newLangCode) => {
    if (!newLangCode) return;

    if (!isServer()) {
      let splitPath = window.location.pathname.split('/');
      splitPath[1] = newLangCode;
      return (window.location.href = splitPath.join('/'));
    }
  };

  return (
    <>
      <MainNavComponent className={`NAV_WRAP mobile-nav-${className}`} search={search}>
        <Hamburger
            className={`hamburger ${mobileNav ? 'active' : ''}`}
            active={mobileNav}
            onClick={() => {
              setMobileNav((prevState) => !prevState);
            }}
        />
        <div className="logo">
          <Link href={homePageLink}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={logo?.src} alt={logo?.alt}/>
          </Link>
        </div>
        <div className="nav-end" id="nav-bar-search-button">
          <>
            <SearchButton
                id="search-button"
                className="search-button"
                aria-label="Open Search"
                title="Open Search"
                disabled={route?.name === 'search'}
                onClick={() => {
                  setSearch((prevState) => !prevState);
                }}
            >
              <Icon className="search" icon={search ? 'cross' : 'search'} size="lg"/>
            </SearchButton>
          </>
        </div>


        <MobileNavComponentFs active={mobileNav} className={`mobile-content-${className}`}>
          <ul>
            {Array.isArray(utilityLinks) &&
                utilityLinks.map(({title, path, href}, index) => {
                  return (
                      <li>
                      <NavLink
                          className="nav-end-link"
                          role="menuitem"
                          key={`${index}-${title}`}
                          text={title}
                          path={path}
                          href={href}
                      />
                      </li>
                  );
                })}
            {Array.isArray(links) &&
                links.map(({path, title, type, cards}, index) => {
                  if (type && type === 'megamenu-cards') {
                    if (cards && cards.length > 0) {
                      return (
                          <li key={`${index}-${title}`}>
                            <NavLink
                                text={title}
                                subNav
                                mobile
                                className={activeLink === title ? 'active' : ''}
                                active={activeLink === title ? 'true' : 'false'}
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (activeLink === title) {
                                    setActiveIndex(null);
                                    setActiveLink('');
                                  } else {
                                    setActiveIndex(index);
                                    setActiveLink(title);
                                  }
                                }}
                            />
                            <ul className={`subMenuList ${activeIndex === index ? 'active' : ''}`}>
                              {Array.isArray(cards) &&
                                  cards.map(({title, image: {src}, link}, index) => {
                                    let fullpath = `${link.path}${link.text ? link.text : ''}`;
                                    return (
                                        <li key={`${index}-${title}`}>
                                          <NavLink
                                              text={title}
                                              path={fullpath}
                                              query={link?.text}
                                              href={fullpath}
                                          />
                                        </li>
                                    );
                                  })}
                            </ul>
                          </li>
                      );
                    }
                    return (
                        <li key={`${index}-${title}`}>
                          <NavLink mobile text={title} path={path}/>
                        </li>
                    );
                  }
                  return (
                      <li key={`${index}-${title}`}>
                        <NavLink mobile text={title} path={path}/>
                      </li>
                  );
                })}


            {/*{Array.isArray(languageSelector) && languageSelector.length*/}
            {/*    ? languageSelector.map(({text, code}, index) => {*/}
            {/*      return (*/}
            {/*          <li key={`languageSelector-${index}-${text}`}>*/}
            {/*            <NavLink mobile text={text} onClick={() => handleLanguageSelector(code)}/>*/}
            {/*          </li>*/}
            {/*      );*/}
            {/*    })*/}
            {/*    : null}*/}

          </ul>

          <Button className="mobile-nav-button" size="small" text="Contact a Rep" href="/contact" variant={"yellowBlue"}/>

        </MobileNavComponentFs>
      </MainNavComponent>
      {mobileNav && <Overlay id="overlay" onClick={() => setMobileNav(false)}/>}
    </>
  );
};

export default withSitecoreContext()(MobileNav);