import React from 'react';
import PropTypes from 'prop-types';
import { ContentComponent } from './Content.styles';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const Content = ({ fields: { heading, content }, sitecoreContext }) => {
  return (
    <ContentComponent>
    <h1>Content</h1>
    </ContentComponent>
  );
};

Content.propTypes = {
  sitecoreContext: PropTypes.shape(),
  fields: PropTypes.shape({
    heading: PropTypes.shape({ value: PropTypes.string }),
    content: PropTypes.shape({ value: PropTypes.string }),
  }),
};

// Wrapping with "withSitecoreContext" HOC
export default withSitecoreContext()(Content);
