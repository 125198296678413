import styled from 'styled-components';

export const InRecipeProductsPromoComponent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 16, left: 0, right: 0, bottom: 0 })};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ top: 40, left: 0, right: 0, bottom: 0 })};
  }

  > h2 {
    margin: 0 ${({ theme }) => theme.spacing(64)};
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(32)};
    }
  }

  .loading-spinner {
    max-height: 200px;
  }
`;

export const ProductList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: ${({ theme }) => theme.spacing(48)};
  }

  li {
    display: flex;
    flex-direction: column;
    max-width: calc(50% - 48px);
    margin: ${({ theme }) => theme.spacing({ top: 0, left: 16, right: 16, bottom: 16 })};
    overflow: hidden;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 200px;
      margin: ${({ theme }) => theme.spacing({ top: 0, left: 32, right: 32, bottom: 0 })};
    }

    img {
      display: block;
      max-width: 96px;
      margin: 0 auto ${({ theme }) => theme.spacing(12)};

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 160px;
      }
    }

    a {
      position: relative;
      text-decoration: none;

      .recipe-card-badge {
        position: absolute;
        width: 50px;
        top: -4px;
        left: 0;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          top: -5px;
          left: 0px;
          width: 80px;
        }
      }
    }

    h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.brown};
      text-transform: uppercase;
    }
    .h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.brown};
      text-transform: uppercase;
    }
  }
`;
