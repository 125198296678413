import React from 'react';
import PropTypes from 'prop-types';
import {Image, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';


import {
  OffsetFeaturePromoComponent,
  InnerWrapper,
  IntroBlock,
  ImageWrap,
  ScrollTextContainer,
  LeftImage,
  RightImage,
} from './OffsetFeaturePromo.styles.js';

import Button from 'components/shared/Button';
import ScrollingText from 'components/shared/ScrollingText';

const IntroBlockComponent = ({ titleImage, headlineText, supportingContent, cta, backgroundColor }) => (
  <IntroBlock>
    {titleImage?.value && (
      <>
        <Image field={titleImage} className="title-image" />
        <hr />
      </>
    )}
    {headlineText?.value && <Text field={headlineText} tag="h2" />}
    {supportingContent?.value && (
        <p
            dangerouslySetInnerHTML={{
              __html: supportingContent.value,
            }}
        />
    )}
    {cta?.value?.href && (
      <Button
        text={cta?.value?.text}
        href={cta?.value?.href}
        variant={backgroundColor}
        {...(backgroundColor === 'tan' ? {} : { invertColor: true })}
      />
    )}
  </IntroBlock>
);

const OffsetFeaturePromo = ({ fields = {}, params = {} }) => {
  const {
    cta = {},
    foregroundImageLeft = {},
    foregroundImageRight = {},
    headlineText = {},
    scrollingVanityTextElements = [],
    supportingContent = {},
    titleImage = {},
  } = fields;

  const {
    backgroundColor = 'yellow',
    id = null,
    componentclass = null,
  } = params;

  const introBlockProps = {
    titleImage,
    headlineText,
    supportingContent,
    cta,
    backgroundColor,
  };

  return (
    <OffsetFeaturePromoComponent id={id} className={componentclass} backgroundColor={backgroundColor}>
      <InnerWrapper>
        <IntroBlockComponent {...introBlockProps} />
        <ImageWrap>
          {foregroundImageLeft?.value?.src && (
            <LeftImage>
              <Image field={foregroundImageLeft} />
            </LeftImage>
          )}
          <IntroBlockComponent {...introBlockProps} />
          {foregroundImageRight?.value?.src && (
            <RightImage>
              <Image field={foregroundImageRight} />
            </RightImage>
          )}
        </ImageWrap>
      </InnerWrapper>
      {scrollingVanityTextElements.length && (
        <ScrollTextContainer>
          <ScrollingText scrollingVanityTextElements={scrollingVanityTextElements} />
        </ScrollTextContainer>
      )}
    </OffsetFeaturePromoComponent>
  );
};

OffsetFeaturePromo.propTypes = {
  fields: PropTypes.shape({
    cta: PropTypes.object,
    foregroundImageLeft: PropTypes.object,
    foregroundImageRight: PropTypes.object,
    headlineText: PropTypes.object,
    scrollingVanityTextElements: PropTypes.array,
    supportingContent: PropTypes.object,
    titleImage: PropTypes.object,
  }),
};
export default withSitecoreContext()(OffsetFeaturePromo);