import styled from 'styled-components';

export const MainNavAboutUsLinkComponent = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: ${({ theme }) => theme.spacing(44)};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.adaBrown};
  }

  & h2 {
    font-size: ${({ theme }) => theme.spacing(16)};
    margin-bottom: ${({ theme }) => theme.spacing(18)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      font-size: ${({ theme }) => theme.spacing(20)};
      margin-bottom: ${({ theme }) => theme.spacing(22)};
    }
  }

  & .image {
    position: relative;
    & img {
      width: 100%;
      object-fit: cover;
      background-position: center;
    }

    & div {
      width: 100%;
      height: ${({ theme }) => theme.spacing(28)};
      margin-top: -3px;
      color: ${({ theme }) => theme.colours.yellow};
      font-size: ${({ theme }) => theme.spacing(16)};
      line-height: ${({ theme }) => theme.spacing(32)};
      background-color: ${({ theme }) => theme.colours.red};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        display: none;
      }
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      &:hover {
        & div {
          position: absolute;
          bottom: 0;
          display: block;
        }
      }
    }
  }
`;
