import styled from 'styled-components';

export const MainNavRibbonCtaComponent = styled.div`
  text-align: center;
  & .content {
    display: inline-flex;
    margin: 0 auto;

    @media (max-width: 470px) {
      display: block;
      & button {
        margin: 0 auto;
      }
    }

    & p {
      font-size: ${({ theme }) => theme.spacing(24)};
      line-height: ${({ theme }) => theme.spacing(48)};
      color: ${({ theme }) => theme.colours.yellow};
      text-transform: uppercase;
      margin-right: ${({ theme }) => theme.spacing(30)};
    }
  }
`;
