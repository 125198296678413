import React from 'react';
import styled from 'styled-components';

import Link from 'components/shared/Link';

const SetComponent = (props) => {
  if (props?.link !== 'true') {
    return <button {...props} />;
  }
  return <Link {...props} />;
};

export const NavLinkComponent = styled(SetComponent)`
  font-size: ${({ theme }) => theme.spacing(18)};
  display: inline-block;
  align-items: center;
  position: relative;
  height: 100%;
  text-transform: uppercase;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colours.brown};
  margin-left: ${({ theme, mobile }) => (mobile ? 0 : theme.spacing(50))};
  margin-bottom: ${({ theme }) => theme.spacing(40)};
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakPoints.desktop}) {
    line-height: ${({ theme }) => theme.spacing(85)};
    margin-bottom: ${({ theme }) => theme.spacing(0)};
  }

  &:hover {
    @media (min-width: ${({ theme }) => theme.breakPoints.desktop}) {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: ${({ theme }) => theme.spacing(4)};
        width: 100%;
        background-color: ${({ theme }) => theme.colours.red};
      }
    }
  }

  &:focus {
    outline: none;
  }

  & svg {
    position: relative;
    left: 8px;
    color: ${({ theme }) => theme.colours.red};
    transform: ${({ active, mobile }) => (active === 'true' && !mobile ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 0.5s;
  }
`;
