import React from 'react';
import PropTypes from 'prop-types';

import { IconComponent } from './Icon.styles';

const Icon = ({ icon, className, size, ...props }) => {
  return (
    <IconComponent
      {...props}
      icon={icon}
      iconSet={require('./selection.json')}
      className={className}
      size={size}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'lg', 'xl']),
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
};

export default Icon;