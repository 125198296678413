import * as React from "react";
import {SvgSpiral} from './SvgSpiral.styles.js';

const SVGSpiral = ({ isActive, ...props }) => (
    <SvgSpiral
        className={`spiral ${isActive ? 'active' : ''}`}
        width={204}
        height={204}
        viewBox="0 0 204 204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M102.899 203.044L102.899 165.835L100.156 165.835L100.156 203.044L102.899 203.044Z"
            fill="#D43631"
        />
        <path
            d="M102.899 37.2092L102.899 0L100.156 -1.19927e-07L100.156 37.2092L102.899 37.2092Z"
            fill="#D43631"
        />
        <path
            d="M203.022 102.898L203.022 100.154L165.821 100.154L165.821 102.898L203.022 102.898Z"
            fill="#D43631"
        />
        <path
            d="M37.2236 102.898L37.2236 100.154L0.0224342 100.154L0.0224341 102.898L37.2236 102.898Z"
            fill="#D43631"
        />
        <path
            d="M125.453 200.175L117.167 163.9L114.492 164.512L122.778 200.786L125.453 200.175Z"
            fill="#D43631"
        />
        <path
            d="M88.5215 38.5098L80.2354 2.23535L77.5607 2.84659L85.8469 39.121L88.5215 38.5098Z"
            fill="#D43631"
        />
        <path
            d="M200.796 80.2435L200.185 77.5684L163.919 85.8627L164.531 88.5378L200.796 80.2435Z"
            fill="#D43631"
        />
        <path
            d="M39.1594 117.212L38.5479 114.537L2.28267 122.831L2.89425 125.507L39.1594 117.212Z"
            fill="#D43631"
        />
        <path
            d="M146.854 192.374L130.692 158.859L128.221 160.052L144.383 193.566L146.854 192.374Z"
            fill="#D43631"
        />
        <path
            d="M74.823 42.9996L58.6611 9.48535L56.19 10.6776L72.3519 44.1918L74.823 42.9996Z"
            fill="#D43631"
        />
        <path
            d="M193.538 58.6514L192.346 56.1797L158.839 72.345L160.031 74.8167L193.538 58.6514Z"
            fill="#D43631"
        />
        <path
            d="M44.2164 130.702L43.0244 128.23L9.51734 144.396L10.7093 146.868L44.2164 130.702Z"
            fill="#D43631"
        />
        <path
            d="M165.977 179.977L142.753 150.909L140.61 152.622L163.834 181.69L165.977 179.977Z"
            fill="#D43631"
        />
        <path
            d="M62.4831 50.4367L39.2588 21.3691L37.1155 23.0823L60.3398 52.1499L62.4831 50.4367Z"
            fill="#D43631"
        />
        <path
            d="M181.685 39.2238L179.973 37.0801L150.911 60.3094L152.624 62.4531L181.685 39.2238Z"
            fill="#D43631"
        />
        <path
            d="M52.1728 142.75L50.46 140.606L21.3986 163.836L23.1114 165.979L52.1728 142.75Z"
            fill="#D43631"
        />
        <path
            d="M181.82 163.643L152.706 140.479L150.998 142.627L180.112 165.79L181.82 163.643Z"
            fill="#D43631"
        />
        <path
            d="M52.0544 60.4221L22.9404 37.2588L21.2325 39.4064L50.3465 62.5697L52.0544 60.4221Z"
            fill="#D43631"
        />
        <path
            d="M165.778 22.9222L163.631 21.2139L140.473 50.3341L142.62 52.0424L165.778 22.9222Z"
            fill="#D43631"
        />
        <path
            d="M62.5798 152.716L60.4326 151.008L37.2743 180.128L39.4215 181.836L62.5798 152.716Z"
            fill="#D43631"
        />
        <path
            d="M193.652 144.18L160.108 128.091L158.922 130.565L192.466 146.654L193.652 144.18Z"
            fill="#D43631"
        />
        <path
            d="M44.1608 72.4701L10.6172 56.3809L9.43085 58.8553L42.9745 74.9445L44.1608 72.4701Z"
            fill="#D43631"
        />
        <path
            d="M146.671 10.5792L144.197 9.39258L128.111 42.9434L130.585 44.13L146.671 10.5792Z"
            fill="#D43631"
        />
        <path
            d="M74.9797 160.096L72.5059 158.909L56.42 192.46L58.8939 193.647L74.9797 160.096Z"
            fill="#D43631"
        />
        <path
            d="M200.821 122.593L164.537 114.381L163.932 117.057L200.216 125.269L200.821 122.593Z"
            fill="#D43631"
        />
        <path
            d="M39.115 85.9922L2.83105 77.7803L2.22554 80.4568L38.5095 88.6688L39.115 85.9922Z"
            fill="#D43631"
        />
        <path
            d="M125.258 2.80681L122.582 2.20117L114.372 38.4929L117.048 39.0985L125.258 2.80681Z"
            fill="#D43631"
        />
        <path
            d="M88.675 164.557L85.999 163.951L77.7888 200.243L80.4648 200.849L88.675 164.557Z"
            fill="#D43631"
        />
        <path
            d="M189.273 72.2342L188.365 69.6445L170.42 75.9371L171.328 78.5268L189.273 72.2342Z"
            fill="#D43631"
        />
        <path
            d="M32.771 127.116L31.8633 124.526L13.9184 130.819L14.8261 133.409L32.771 127.116Z"
            fill="#D43631"
        />
        <path
            d="M78.5959 31.7827L72.3047 13.834L69.7156 14.7419L76.0068 32.6906L78.5959 31.7827Z"
            fill="#D43631"
        />
        <path
            d="M133.458 188.313L127.167 170.364L124.578 171.272L130.869 189.221L133.458 188.313Z"
            fill="#D43631"
        />
        <path
            d="M180.526 53.4198L179.064 51.0977L162.973 61.2329L164.435 63.555L180.526 53.4198Z"
            fill="#D43631"
        />
        <path
            d="M40.1935 141.813L38.7314 139.491L22.6405 149.626L24.1025 151.949L40.1935 141.813Z"
            fill="#D43631"
        />
        <path
            d="M63.6429 38.6685L53.5127 22.5723L51.1908 24.0342L61.321 40.1304L63.6429 38.6685Z"
            fill="#D43631"
        />
        <path
            d="M152.019 179.024L141.889 162.928L139.567 164.39L149.697 180.486L152.019 179.024Z"
            fill="#D43631"
        />
        <path
            d="M167.822 37.0295L165.879 35.0918L152.452 48.5596L154.394 50.4973L167.822 37.0295Z"
            fill="#D43631"
        />
        <path
            d="M50.7132 154.49L48.7705 152.553L35.3431 166.021L37.2859 167.958L50.7132 154.49Z"
            fill="#D43631"
        />
        <path
            d="M50.5772 48.7066L37.1123 35.2764L35.175 37.2195L48.6399 50.6498L50.5772 48.7066Z"
            fill="#D43631"
        />
        <path
            d="M168.006 165.833L154.541 152.402L152.604 154.345L166.069 167.776L168.006 165.833Z"
            fill="#D43631"
        />
        <path
            d="M151.786 23.8898L149.461 22.4336L139.37 38.5545L141.696 40.0108L151.786 23.8898Z"
            fill="#D43631"
        />
        <path
            d="M63.803 164.492L61.4775 163.036L51.3867 179.157L53.7121 180.613L63.803 164.492Z"
            fill="#D43631"
        />
        <path
            d="M40.0841 61.4086L23.9648 51.3184L22.5093 53.6446L38.6286 63.7348L40.0841 61.4086Z"
            fill="#D43631"
        />
        <path
            d="M180.666 149.41L164.547 139.319L163.091 141.646L179.211 151.736L180.666 149.41Z"
            fill="#D43631"
        />
        <path
            d="M133.215 14.6472L130.624 13.7461L124.38 31.7113L126.971 32.6124L133.215 14.6472Z"
            fill="#D43631"
        />
        <path
            d="M78.7858 171.342L76.1943 170.44L69.9501 188.406L72.5416 189.307L78.7858 171.342Z"
            fill="#D43631"
        />
        <path
            d="M32.6968 76.1301L14.7344 69.8877L13.8339 72.4799L31.7963 78.7223L32.6968 76.1301Z"
            fill="#D43631"
        />
        <path
            d="M189.353 130.562L171.391 124.319L170.49 126.912L188.453 133.154L189.353 130.562Z"
            fill="#D43631"
        />
        <path
            d="M113.044 9.79237L110.316 9.49219L108.236 28.3979L110.963 28.6981L113.044 9.79237Z"
            fill="#D43631"
        />
        <path
            d="M94.9088 174.688L92.1816 174.388L90.1016 193.293L92.8287 193.594L94.9088 174.688Z"
            fill="#D43631"
        />
        <path
            d="M28.7522 92.1303L9.85059 90.0498L9.55047 92.7775L28.4521 94.858L28.7522 92.1303Z"
            fill="#D43631"
        />
        <path
            d="M193.62 110.281L174.719 108.2L174.419 110.928L193.32 113.008L193.62 110.281Z"
            fill="#D43631"
        />
        <path
            d="M94.5128 28.4192L92.3271 9.52539L89.6017 9.8408L91.7873 28.7346L94.5128 28.4192Z"
            fill="#D43631"
        />
        <path
            d="M113.574 193.199L111.389 174.306L108.663 174.621L110.849 193.515L113.574 193.199Z"
            fill="#D43631"
        />
        <path
            d="M28.8144 111.329L28.499 108.603L9.60929 110.789L9.92463 113.515L28.8144 111.329Z"
            fill="#D43631"
        />
        <path
            d="M193.559 92.2622L193.244 89.5361L174.354 91.7222L174.67 94.4482L193.559 92.2622Z"
            fill="#D43631"
        />
    </SvgSpiral>
);
export default SVGSpiral;