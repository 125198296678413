import styled from "styled-components";

export const SoDarnGoodC = styled.svg`
	top: 10%;
	right: 5%;
	position: absolute;
	width: 150px;
    @media (max-width: 1550px){
        right: 0;
    }
	@media (max-width: 1280px){
		display: none;
	}
	.st0 {
		fill: #BB5400;
	}

	.st1 {
		fill: #1471D2;
	}

	.st2 {
		fill: #FFCA2E;
		stroke: #FFCA2E;
		stroke-width: 0;
		stroke-miterlimit: 10;
	}

	.st3 {
		fill: #D43631;
	}

	.st4 {
		fill: #C1272D;
	}

	.st5 {
		fill: none;
		stroke: #FFC60B;
		stroke-width: 10.4208;
		stroke-miterlimit: 10;
	}

	.st6 {
		fill: #003464;
	}

	.st7 {
		fill: #FFCA2E;
		stroke: #FFCA2E;
		stroke-miterlimit: 10;
	}

	.st8 {
		clip-path: url(#SVGID_00000050642295723565540500000009308657269047423159_);
	}

	.st9 {
		fill: #012A72;
	}

	.st10 {
		fill: #FFFFFF;
	}

	.st0{fill:#bb5400}.st9{fill:#012a72}
`;