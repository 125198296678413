import styled from 'styled-components';

export const SearchResultComponentComponent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 32, left: 0, right: 0 })};
  background-color: ${({ theme }) => theme.colours.tan};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    max-width: 100%;
    margin: 0;
    padding: ${({ theme }) => theme.spacing(60)} 0;

    &:first-of-type {
      padding-top: 0;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: block;
    margin-right: ${({ theme }) => theme.spacing(50)};
  }

  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colours.yellow};
  }

  &.product {
    img {
      box-sizing: border-box;
      padding: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

export const ContentWrapper = styled.div`
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.brown};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(38)})`};
    text-align: left;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 ${({ theme }) => theme.spacing(32)};
  }

  a {
    color: ${({ theme }) => theme.colours.red};
    font-weight: bold;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(20)}, 4vw, ${theme.spacing(32)})`};

    &:hover {
      color: ${({ theme }) => theme.colours.blue};
    }
  }
`;
