import styled from "styled-components";


export const SampleRequestFormWrapper = styled.section`
    position: relative;
    width: 800px;
    max-width: 80%;
    margin: auto;
	.sample-request-form{
        position: relative;
		input {
			//width: calc(100% - 2.5rem); /* Adjust this value to match your padding */
            width: 100%;
			min-width: 18.75rem;
			height: 2.625rem;
			margin-top: 0.625rem;
			color: rgb(117, 117, 117);
			font-weight: 600;
			font-size: 0.875rem;
			line-height: 2.5rem;
			text-indent: 1.25rem;
			background: rgb(255, 255, 255);
			border: none;
			border-radius: 6.25rem;
			outline: none;
		}
        select{
			height: 2.625rem;
			width: 100%;
			font-size: 0.875rem;
			color: rgb(128, 128, 128);
			font-weight: 600;
			border: none;
			background-color: #fff;
			border-radius: 6.25rem;
			padding-left: 18px;
			box-sizing: border-box;
			appearance: none;
			cursor: pointer;
			outline: none;
			margin: 0.625rem 0 0 0;
			/* Adding padding to make space for the chevron */
			padding-right: 2rem; /* Adjust as needed to match chevron size */
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5H7z" fill="%23808080"/></svg>');
			background-repeat: no-repeat;
			background-position: right 1rem center;
			background-size: 14px;
        }
        .submit-button-container{
          display: flex;
          max-width: 320px;
          margin: auto;
          justify-content: center;
          align-items: center; 
          padding: 30px 0;
          width: 100%;
            button{
                color: ${({ theme }) => theme.colours.tan} !important;
                background: ${({ theme }) => theme.colours.blue} !important;
                border: 2px solid ${({ theme }) => theme.colours.blue} !important;
                font-size: 24px;
				padding: 10px 40px;
				border-radius: 30px;
                cursor: pointer;
                margin: auto;
                transition: all 0.4s ease-in-out;
				font-family: "apercu pro", helvetica, verdana, sans-serif;
				font-weight: 900;
                &:hover{
                    background: ${({ theme }) => theme.colours.tan} !important;
                    color: ${({ theme }) => theme.colours.blue} !important;
                    border: 2px solid ${({ theme }) => theme.colours.blue} !important;
                }
            }
       }
        .field{
            label{
                display: block;
            }
        }
    }
    .fields-wrapper{
        display: flex;
        flex-flow: column;
        gap: 30px;
        .fields-row-two-col{
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 20px;
        }
		.fields-row-one-col{
			grid-template-columns: 1fr;
        }
    }
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.modal-content {
		background-color: #C75128;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		position: relative;
		text-align: center;
        width: 450px;
        max-width: 80%;
        .close-window{
            color: #fff;
            transition: all 0.4s ease-in-out;
            &:hover{
                color: ${({ theme }) => theme.colours.yellow};
            }
        }
        h2{
            color: ${({ theme }) => theme.colours.yellow};
            display: block;
            text-align: left;
        }
        h3{
            color: ${({ theme }) => theme.colours.yellow};
			display: block;
			text-align: left;
        }
        p{
            color: #fff;
			display: block;
			text-align: left;
        }
        a{
			color: ${({ theme }) => theme.colours.yellow};
			display: block;
			text-align: left;
        }
	}

	.close-window {
		position: absolute;
		top: 10px;
		right: 15px;
		cursor: pointer;
		font-size: 1.2rem;
	}
	@media (max-width: 700px) {
		.sample-request-form input{
            margin-top: 5px;
        }
		.fields-wrapper{
            gap: 20px;
			.fields-row-two-col{
				grid-template-columns: 1fr;
                gap: 20px;
			}
		}
	}
`;