import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {VendorFormComponent, FormContent, ContactUsCardsContainer, SuccessMessage} from './VendorForm.styles.js';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';

import VendorFormHero from './components/VendorFormHero.jsx';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Button from 'components/shared/Button';
import Grid from 'components/shared/Grid';
import Spinner from 'components/shared/LoadingSpinner';

import translate from 'helpers/translate';
import { useForm } from 'react-hook-form';

import { useFetch } from 'hooks';
import { searchConfig } from 'helpers/searchHelpers';
import { zipCodeRegex } from 'helpers/regexHelpers';

// TODO: form endpoint needs hooking up with success/error state
const VendorForm = ({ fields, copy, sitecoreContext: { viewBag, ...context }, rendering, ...props }) => {
    const { handleSubmit, register, errors, setValue, getValues, reset } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const [formSegment, setFormSegment] = useState(null);
    const { endpoint = 'forms/foodservice-sample' } = searchConfig({ type: 'contactForm' });
    const [formSubject, setFormSubject] = useState(null);
    const formRef = useRef(null);
    const [requestBody, setRequestBody] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleReset = () => {
        reset();

        if (formRef?.current) {
            const selects = formRef.current.querySelectorAll('select');
            selects.forEach(select => (select.selectedIndex = 0));
        }
    };

    const { data, loading, error, fetchLazy } = useFetch(
        undefined,
        {
            method: 'POST',
            ...(requestBody ? { body: { ...requestBody } } : {}),
        },
        'text'
    );

    if (error) {
        return (
            <VendorFormComponent>
                <h1>FAILED TO SUBMIT.</h1>
                {error && (
                    <div className="error-message">
                        <p>Error details:</p>
                        <pre>{JSON.stringify(error, null, 2)}</pre>
                    </div>
                )}
            </VendorFormComponent>
        );
    }

    if (data === 'Submission successful.') {
        return (
            showSuccessMessage && (  // Conditionally render based on showSuccessMessage
                <SuccessMessage>
                    <div className="succes-wrapper">
                        <div className="text-content">
                            <div className="close" onClick={() => setShowSuccessMessage(false)}>
                                <span className="close-icon">✕</span>
                            </div>
                            <h3>Congratulations,<br/>
                                {requestBody.FirstName} {requestBody.LastName}</h3>
                            <h4>Big flavor is on the way.</h4>
                            <p>While you're waiting for your sample to arrive, check out our new website with recipes and
                                inspiration just for your channel.
                                <a href="https://www.bushbeansfoodservice.com/" target="_blank" rel="noopener noreferrer">Visit BushBeansFoodservice.com</a>
                            </p>
                        </div>
                    </div>
                </SuccessMessage>
            )
        );
    }

    return (
        <VendorFormComponent id="vendor-form">

            {loading ? (
                <Spinner />
            ) : (
                <form ref={formRef} onSubmit={handleSubmit(async data => {
                    setRequestBody({
                        subject: fields?.subjectOptions[Number(data.subjectLabel) - 1]?.value || 'Vendor Form Submission',
                        FirstName: data.firstName,
                        LastName: data.lastName,
                        EmailAddress: data.email,
                        Segment: data.segment,
                        Distributor: data.distributor,
                    });
                    setShowSuccessMessage(true);
                    fetchLazy(endpoint);
                })}
                >

                    <FormContent>
                        <div className="copy-row-top">
                        {copy && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: typeof copy === 'string' ? copy : copy.value,
                                }}
                            />
                        )}
                        </div>
                        <div className="form-inner-wrapper">
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNameLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNamePlaceholder')}
                                        name="firstName"
                                        error={errors?.firstName?.message}
                                        inputRef={register({
                                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation'),
                                            validate: value => {
                                                const validation = Joi.string().pattern(
                                                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                                );
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation');
                                            },
                                        })}
                                    />
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        required
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNameLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNamePlaceholder')}
                                        name="lastName"
                                        error={errors?.lastName?.message}
                                        inputRef={register({
                                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation'),
                                            validate: value => {
                                                const validation = Joi.string().pattern(
                                                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                                );
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation');
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="field-row">
                                <div>
                                    <Select
                                        required
                                        label="Segment"
                                        placeholder="Segment"
                                        options={[
                                            { value: 'k12', label: 'K-12' },
                                            { value: 'colleges and university', label: 'Colleges and University' },
                                            { value: 'restaurants', label: 'Restaurants' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                        error={errors?.segmentLabel?.message}
                                        defaultValue={
                                            formSegment && fields?.segmentOptions?.map(({ value }) => value).indexOf(formSegment.value) + 1
                                        }
                                        name="segmentLabel"
                                        inputRef={register({
                                            required: false,
                                            validate: {
                                                selectOne: value =>
                                                    value !== '0' ||
                                                    translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                                            },
                                        })}
                                        onChange={event => {
                                            const segment = Array.isArray(fields?.segmentOptions) && fields?.segmentOptions[event.target.value - 1];
                                            setFormSegment(segment);
                                        }}
                                    />
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        required
                                        label="Distributor Name"
                                        placeholder="Enter your distributor name"
                                        name="distributor"
                                        error={errors?.distributor?.message}
                                        inputRef={register({
                                            required: 'Distributor name is required',
                                            validate: value => {
                                                return value.trim() !== '' || 'Please enter a valid distributor name';
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressPlaceholder')}
                                        name="email"
                                        error={errors?.email?.message}
                                        inputRef={register({
                                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation'),
                                            validate: value => {
                                                const validation = Joi.string().email({tlds: {allow: false}});
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation');
                                            },
                                        })}
                                    />
                                </div>
                                <div className="submit-button-container">
                                    <Button
                                        text="Request Sample"
                                        type="submit"
                                        variant="solidBlue"
                                        noIcon
                                    />
                                </div>
                            </div>
                        </div>
                    </FormContent>
                </form>
            )}
        </VendorFormComponent>
    );
};

VendorForm.propTypes = {
    fields: PropTypes.object,
    sitecoreContext: PropTypes.object,
    rendering: PropTypes.object,
};

export default withSitecoreContext()(VendorForm);