import React from 'react';

const HandleSvg = () => (
    <svg className="image-comparison__thumb-icon" width="18" height="10" viewBox="0 0 18 10" fill="currentColor">
        <path className="image-comparison__thumb-icon--left"
              d="M12.121 4.703V.488c0-.302.384-.454.609-.24l4.42 4.214a.33.33 0 0 1 0 .481l-4.42 4.214c-.225.215-.609.063-.609-.24V4.703z">
        </path>
        <path className="image-comparison__thumb-icon--right"
              d="M5.879 4.703V.488c0-.302-.384-.454-.609-.24L.85 4.462a.33.33 0 0 0 0 .481l4.42 4.214c.225.215.609.063.609-.24V4.703z">
        </path>
    </svg>
);

export default HandleSvg;