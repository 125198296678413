/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {SelectComponent, SelectWrapper, IconWrapper, IconWrapperFs} from './Select.styles.js';

import Tooltip from 'components/shared/Tooltip';
import Icon from 'components/shared/Icon';

const Select = ({
  label,
  options,
  className,
  error,
  details,
  onChange,
  placeholder,
  inputRef,
  required,
  defaultValue,
  disabled,
  ariaLabel,
  sitecoreContext,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [selectedItemValue, setSelectedItemValue] = useState(null);
  const {site} = sitecoreContext;
  return (
    <SelectComponent className={className} touched={touched}>
      {label && <label>{`${required ? '*' : ''}${label}`}</label>}
      <SelectWrapper>
        <select
          {...props}
          disabled={disabled}
          ref={inputRef}
          onChange={(event) => {
            if (typeof onChange === 'function') {
              onChange(event);
            }
            if (!touched) {
              setTouched(true);
            }
          }}
          aria-label={ariaLabel || placeholder}
        >
          <option value={0} disabled selected={defaultValue ? false : true}>
            {placeholder}
          </option>
          {Array.isArray(options) &&
            options.map(({ value, text }, index) => {
              return (
                <option
                  key={`${index}-${value}`}
                  value={index + 1}
                  selected={defaultValue === index + 1}
                  onClick={() => {
                    setSelectedItemValue(value);
                  }}
                >
                  {text || value}
                </option>
              );
            })}
        </select>
        {site.name === 'bushs-beans-foodservices' ? (
            <IconWrapperFs>
              <Icon icon="chevron-down" size="md" />
            </IconWrapperFs>
        ) : (
            <IconWrapper>
              <Icon icon="chevron-down" size="md" />
            </IconWrapper>
        )}

      </SelectWrapper>
      {error && <p className="error-message">{error}</p>}
      {details && <Tooltip {...details} />}
    </SelectComponent>
  );
};

Select.propTypes = {};
export default withSitecoreContext()(Select);