export const printElement = ({ selector }) => {
  window.print();

  // const element = document.querySelector(selector);
  // const element = document.body;

  // html2canvas(element).then((canvas) => {
  //   const base64image = canvas.toDataURL('image/png');
  //   // debugBase64(base64image);
  //   // window.location.href = base64image;
  //   // console.log(base64image);
  //   // window.open(base64image, '_blank');
  //   let hiddenElement = document.createElement('a');
  //   hiddenElement.href = base64image;
  //   hiddenElement.target = '_blank';
  //   hiddenElement.download = 'testing image';
  //   hiddenElement.click();
  // });
};
