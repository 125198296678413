import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const animationStyles = css`
  transform-origin: 50% 50%;
  animation: ${rotate} 2s linear infinite;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const Container = styled.div`
  z-index: ${({ theme }) => theme.zindex.global.loadingSpinner};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;

  svg {
    width: ${({ theme }) => theme.spacing(193)};
    height: ${({ theme }) => theme.spacing(193)};

    .outer-ring {
      ${animationStyles}
    }

    .bean-icon {
      ${animationStyles}
      animation-direction: reverse;
    }
  }
`;
