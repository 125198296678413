import styled from 'styled-components';

export const MediaCarouselImageSlideComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .contentWrap{
    max-width: 80%;
    width: 1200px;
    margin: auto;
    position: relative;
    @media (max-width: 1200px){
      width: 66%;
    }
  }
  .carouselCopyWrap{
    padding: 50px;
    background: ${({ theme }) => theme.colours.yellow};
    max-width: 100%;
    margin: 0px auto 0px 60px;
    text-align: center;
    width: 500px;
    @media (max-width: 1200px) {
      margin: auto;
      padding: 5%;
      max-width: 100%;
      text-align: center;
      width: auto;
      display: block;
      overflow: hidden;
      h2{
          font-size: 30px !important;
          margin-bottom: 5px !important;
      }
      p{
          font-size: 13px !important;
      }
    }
    h2{
      color: ${({ theme }) => theme.colours.red};
      font-size: 50px;
      line-height: 1.2;
    }
    p{
      color: ${({ theme }) => theme.colours.red};
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
  &.w-text-content{
    display: flex;
  }
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 85%;
  height: auto;
  margin: 0 auto;
  z-index: 10;
  .w-text-content & {
    display: none;
  }
`;

export const Media = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    content: '';
  }

  > * {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform: translate(0, -50%);
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
