// https://developers.google.com/youtube/iframe_api_reference#Example_Video_Player_Constructors
import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { VideoWrapper, VideoWrapperInner } from './Video.styles';

import { useAsyncScript, useObserver } from 'hooks';

const youtubeAPI = 'https://www.youtube.com/iframe_api';

const waitForYoutube = () =>
  new Promise((resolve, reject) => {
    (function checkForYoutube() {
      if (typeof window !== 'undefined' && window?.YT?.ready && typeof window.YT.ready !== 'undefined')
        return resolve();
      setTimeout(checkForYoutube, 100);
    })();

    //  timeout to stop checking after 10 seconds
    setTimeout(() => {
      reject();
    }, 10000);
  });

const Video = ({ youtubeId, width = '960', height = '540', ratio = [16, 9] }) => {
  const [scriptLoaded, scriptError] = useAsyncScript({
    src: youtubeAPI,
    crossOrigin: true,
  });

  const videoRef = useRef(null);
  const elInView = useObserver({ ref: videoRef });
  const videoId = `youtube-${youtubeId}`;
  const [player, setPlayer] = useState(null);

  const loadVideo = useCallback(() => {
    if (!videoId || !youtubeId || typeof window === 'undefined') return;
    window.YT.ready(() => {
      const initPlayer = new window.YT.Player(videoId, {
        height,
        width,
        videoId: youtubeId,
        frameborder: '0',
        playerVars: { modestbranding: 1, rel: 0 },
      });

      setPlayer(initPlayer);
    });
  }, [videoId, youtubeId, window?.YT?.ready]);

  useEffect(() => {
    if (scriptLoaded && !isServer()) {
      waitForYoutube().then(() => {
        loadVideo({ videoId, youtubeId, width, height });
      });
    }
  }, [scriptLoaded]);

  useEffect(() => {
    if (!elInView && player) {
      if (player.pauseVideo) player.pauseVideo();
    }
  }, [elInView]);

  return (
    <VideoWrapper ref={videoRef} className="video-wrapper">
      <VideoWrapperInner ratio={ratio}>
        <div id={videoId} />
      </VideoWrapperInner>
    </VideoWrapper>
  );
};

export default Video;

Video.propTypes = {
  youtubeId: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  ratio: PropTypes.array,
};
