// search
export const postData = async ({ endpoint, key = 'no key', data = {} }) => {
  const myHeaders = new Headers({
    'XID-EXP-T': key,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  const options = {
    method: 'POST',
    cache: 'no-cache',
    headers: myHeaders,
    body: JSON.stringify(data),
  };
  const response = await fetch(endpoint, options);

  return response;
};
