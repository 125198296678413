import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const getVariantColours = ({ variant, theme, site } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    yellow: {
      background: theme.colours.yellow,
      title: theme.colours.red,
      text: theme.colours.adaBrown,
    },
    brown: {
      background: theme.colours.brown,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    blue: {
      background: theme.colours.blue,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
  };

  if (site && site.name === 'bushs-beans-foodservices') {
    return variants.brown;
  }

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const HeroCarouselSlideComponent = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing(600)};
  overflow: hidden;

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    min-height: ${({ theme }) => theme.spacing(650)};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    min-height: ${({ theme }) => theme.spacing(900)};
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:before {
      display: block;
      width: 100%;
      padding-top: calc((8 / 16) * 100%);
      content: '';
    }
  }
  .fs-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:before {
      display: block;
      width: 100%;
      padding-top: calc((4/10) * 100%);
      content: '';
    }
  }
  .placeholder-slide {
    position: relative;
    width: 100%;
    height: 100%;
    .front-image {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 25%;
      height: auto;
      transform: translate(-50%, 0%);

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        width: 28%;
      }
    }
  }
`;

export const StyledAnimatedDiv = styled(animated.div)`
  &.bushs-beans-foodservices-slide-wrapper {
    .slide{
      flex-flow: column;
      display: flex;
      align-content: center;
      .foodservice{
        text-align: left;
        padding: 40px 16px;
        background-color: ${({ theme }) => theme.colours.brown};
        max-width: 100%;
        h1{
          font-size: 72px;
          font-style: normal;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
          text-align: left;
          color: ${({ theme }) => theme.colours.yellow};
          @media (max-width: 800px) {
            font-size: 40px;
            margin-bottom: 15px;
            line-height: 1;
          }
        }
        p{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          color: ${({ theme }) => theme.colours.tan};
          margin-left: 0;
          text-align: left;
          margin-right: 0;
          width: 100%;
          max-width: 100%;
          margin-bottom: 24px;
          @media (max-width: 800px) {
            font-size: 16px;
            line-height: 20px;
          }
          a{
            color: ${({ theme }) => theme.colours.tan};
            text-decoration: underline;
            transition: all 0.3s ease-in-out;
            &:hover{
              color: ${({ theme }) => theme.colours.yellow};
            }
          }
        }
        .button-link{
          justify-content: flex-start;
          display: flex;
          width: 100%;
          button{
            margin-bottom: 0;
            margin-left: 0;
          }
        }
        ${({ theme }) => theme.breakPoints.minBp('desktop')}{
          display: flex;
          align-self: center;
          bottom: auto;
          top: auto;
          flex-flow: column;
          height: fit-content !important;
          max-width: 515px;
          box-sizing: border-box;
        }
      }
      ${({ theme }) => theme.breakPoints.minBp('desktop')}{
        flex-flow: row;
        display: flex;
        align-self: center;
        bottom: auto;
        top: auto;
      }
      ${({ theme }) => theme.breakPoints.minBp('tablet')}{
        .foodservice{
          padding: 40px;
        }
      }
      ${({ theme }) => theme.breakPoints.minBp('1440px')}{
        display: flex;
        align-self: center;
        bottom: auto;
        top: auto;
      }
    }
  }
`;

export const PromoWrap = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 8px;
  overflow: auto;
  text-align: center;
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};
  z-index: 2;
  overflow: auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(40)};
    left: ${({ theme }) => theme.spacing(40)};
    max-width: ${({ theme }) => theme.spacing(470)};
    padding: 0;
  }


  ${({ theme }) => theme.breakPoints.minBp('1440px')} {
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(70)};
    left: ${({ theme }) => theme.spacing(70)};
    max-width: ${({ theme }) => theme.spacing(470)};
  }
  



  &.promo-align-right {
    left: unset;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      right: ${({ theme }) => theme.spacing(40)};
    }

    ${({ theme }) => theme.breakPoints.minBp('1440px')} {
      right: ${({ theme }) => theme.spacing(70)};
    }
  }
  
  h2 {
    max-width: 90%;
    margin: 0 auto 16px auto;
    padding-top: ${({ theme }) => theme.spacing(34)};
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).title};
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(24)};

    @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
      margin-bottom: ${({ theme }) => theme.spacing(22)};
    }
  }

  p {
    max-width: 90%;
    margin: ${({ theme }) => theme.spacing(0, 'auto')};
    margin-bottom: ${({ theme }) => theme.spacing(32)};
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).text};
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(40)};
    }
  }

  button {
    margin-right: auto;
    margin-bottom: ${({ theme }) => theme.spacing(32)};
    margin-left: auto;
  }
`;

export const Slide = styled.div`
  position: relative;
  height: auto;
`;