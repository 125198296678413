import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './components/TextInput.jsx';
import Checkbox from './components/Checkbox.jsx';
import Radio from './components/Radio.jsx';

const Input = ({ type, ...props }) => {
  if (type === 'text' || type === 'password') {
    return <TextInput {...props} />;
  }
  if (type === 'checkbox') {
    return <Checkbox {...props} />;
  }

  if (type === 'radio') {
    return <Radio {...props} />;
  }

  if (type === 'select') {
    return <Radio {...props} />;
  }
  return null;
};

Input.propTypes = {};

export default Input;
