import { useReducer, useEffect, useState } from 'react';
import { searchConfig } from 'helpers/searchHelpers';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case 'FETCH_RESET':
      return {
        loading: false,
        data: null,
        error: false,
      };
    default:
      throw new Error(
        `Unknown action type received 
        for dataFetchReducer.
        Please make sure you are passing
        one of the following actions:
          * FETCH_INIT
          * FETCH_SUCCESS
          * FETCH_FAILURE
          :`
      );
  }
};

const useFetch = (url, options, responseBody) => {
  const initialState = {
    loading: url ? true : false,
    data: null,
    error: false,
  };
  const [state, dispatch] = useReducer(dataFetchReducer, initialState);
  const [fetchUrl, setFetchUrl] = useState(url);
  const method = options?.method;
  const body = options?.body ? JSON.stringify(options.body) : null;
  const contentType = options?.headers?.['Content-Type'];
  const [reload, setReload] = useState(0);
  const { searchKey } = searchConfig();

  useEffect(() => {
    if (fetchUrl) {
      dispatch({ type: 'FETCH_INIT' });
      fetch(fetchUrl, {
        method,
        headers: {
          'XID-EXP-T': searchKey,
          ...(contentType ? { 'Content-Type': contentType } : { 'Content-Type': 'application/json' }),
        },
        ...(body ? { body } : {}),
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`
            Failed request with status: ${response.status}
            `);
          }
          const body = responseBody ? responseBody : 'json';
          return response[body]();
        })
        .then((data) => {
          dispatch({ type: 'FETCH_SUCCESS', payload: data });
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            console.log(error);
          }
          dispatch({ type: 'FETCH_FAILURE' });
        });
    }
  }, [fetchUrl, method, body, reload, responseBody]);
  return {
    ...state,
    reset: () => {
      setFetchUrl(undefined);
      dispatch({ type: 'FETCH_RESET' });
    },
    fetchLazy: (url) => {
      setFetchUrl(url);
    },
    reload: () => {
      setReload(reload + 1);
    },
  };
};

export default useFetch;
