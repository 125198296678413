import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';

const getVariantColours = ({ variant, theme, invertColor = false } = {}) => {
  const variants = {
    red: {
      background: invertColor ? theme.colours.red : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.red,
      hover: {
        background: invertColor ? theme.colours.yellow : theme.colours.red,
        text: invertColor ? theme.colours.red : theme.colours.yellow,
      },
    },
    redYellow: {
      background: invertColor ? theme.colours.red : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.red,
      hover: {
        background: invertColor ? theme.colours.yellow : theme.colours.red,
        text: invertColor ? theme.colours.red : theme.colours.yellow,
      },
    },
    solidred: {
      background: invertColor ? theme.colours.red : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.red,
      hover: {
        background: invertColor ? theme.colours.yellow : theme.colours.red,
        text: invertColor ? theme.colours.red : theme.colours.yellow,
      },
    },
    yellow: {
      background: invertColor ? theme.colours.yellow : theme.colours.red,
      text: invertColor ? theme.colours.red : theme.colours.yellow,
      hover: {
        background: invertColor ? theme.colours.red : theme.colours.yellow,
        text: invertColor ? theme.colours.yellow : theme.colours.red,
      },
    },
    yellowRed: {
      background: invertColor ? theme.colours.yellow : theme.colours.red,
      text: invertColor ? theme.colours.red : theme.colours.yellow,
      hover: {
        background: invertColor ? theme.colours.red : theme.colours.yellow,
        text: invertColor ? theme.colours.yellow : theme.colours.red,
      },
    },
    blue: {
      background: invertColor ? theme.colours.blue : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.blue,
      hover: {
        background: invertColor ? theme.colours.yellow : theme.colours.blue,
        text: invertColor ? theme.colours.blue : theme.colours.yellow,
      },
    },
    SolidBlue: {
      background: invertColor ? theme.colours.white : theme.colours.blue,
      text: invertColor ? theme.colours.white : theme.colours.blue,
      hover: {
        background: invertColor ? theme.colours.white : theme.colours.blue,
        text: invertColor ? theme.colours.white : theme.colours.blue,
      },
    },
    tan: {
      background: invertColor ? theme.colours.red : theme.colours.tan,
      text: invertColor ? theme.colours.tan : theme.colours.red,
      hover: {
        background: theme.colours.yellow,
        text: theme.colours.red,
      },
    },
    blueYellow: {
      background: invertColor ? theme.colours.blue : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.blue,
      hover: {
        background: theme.colours.blue,
        text: theme.colours.yellow,
      },
    },
    yellowBlue: {
      background: invertColor ? theme.colours.yellow : theme.colours.blue,
      text: invertColor ? theme.colours.blue : theme.colours.yellow,
      hover: {
        background: theme.colours.yellow,
        text: theme.colours.blue,
      },
    },
    brownYellow: {
      background: invertColor ? theme.colours.brown : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.brown,
      hover: {
        background: theme.colours.brown,
        text: theme.colours.yellow,
      },
    },
    brown: {
      background: invertColor ? theme.colours.brown : theme.colours.yellow,
      text: invertColor ? theme.colours.yellow : theme.colours.brown,
      hover: {
        background: theme.colours.brown,
        text: theme.colours.yellow,
      },
    },
    yellowBrown: {
      background: invertColor ? theme.colours.yellow : theme.colours.brown,
      text: invertColor ? theme.colours.brown : theme.colours.yellow,
      hover: {
        background: theme.colours.yellow,
        text: theme.colours.brown,
      },
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

const getSizes = ({ type = 'default', size = 'small', theme }) => {
  const types = {
    default: {
      small: {
        text: theme.spacing(16),
        height: theme.spacing(50),
        border: '2px',
        radius: '100px',
        icon: '36px',
      },
      large: {
        text: theme.spacing(24),
        height: theme.spacing(64),
        border: '3px',
        radius: '100px',
        icon: '48px',
      },
    },
    loadmore: {
      small: {
        text: theme.spacing(16),
        height: theme.spacing(120),
        border: '0',
        radius: '100%',
        lineHeight: '100%',
        icon: '36px',
      },
      large: {
        text: theme.spacing(24),
        height: theme.spacing(160),
        border: '0',
        radius: '100%',
        lineHeight: '120%',
        icon: '42px',
      },
    },
    recipe: {
      small: {
        text: theme.spacing(16),
        height: theme.spacing(120),
        border: '2px',
        radius: '100%',
        lineHeight: '110%',
      },
      large: {
        text: theme.spacing(32),
        height: theme.spacing(208),
        border: '2px',
        radius: '100%',
        lineHeight: '110%',
      },
    },
    love: {
      small: {
        height: theme.spacing(44),
        border: '0',
        radius: '100%',
        padding: theme.spacing(12),
        overlayHeight: theme.spacing(26),
        overlayWidth: theme.spacing(26),
      },
      large: {
        height: theme.spacing(64),
        border: '0',
        radius: '100%',
        padding: theme.spacing(16),
        overlayHeight: theme.spacing(32),
        overlayWidth: theme.spacing(32),
      },
    },
    circle: {
      small: {
        height: theme.spacing(34),
        border: '0',
        radius: '100%',
        padding: theme.spacing(10),
      },
      large: {
        height: theme.spacing(48),
        border: '0',
        radius: '100%',
        padding: theme.spacing(14),
      },
      xlarge: {
        height: theme.spacing(64),
        border: '0',
        radius: '100%',
        padding: theme.spacing(18),
      },
    },
    basketful: {
      small: {
        text: theme.spacing(16),
        height: theme.spacing(50),
        border: '2px',
        radius: '100px',
        icon: '36px',
        image: '102px',
        fontSize: '14px',
      },
      large: {
        text: theme.spacing(24),
        height: theme.spacing(64),
        border: '3px',
        radius: '100px',
        icon: '48px',
        image: '127px',
        fontSize: '16px',
      },
    },
    chip: {
      small: {
        text: theme.spacing(14),
        height: theme.spacing(40),
        padding: theme.spacing(12),
        radius: '100px',
      },
      large: {
        text: theme.spacing(14),
        height: theme.spacing(48),
        padding: theme.spacing(16),
        radius: '100px',
      },
    },
    clear: {
      small: {
        text: theme.spacing(14),
        height: theme.spacing(40),
      },
      large: {
        text: theme.spacing(16),
        height: theme.spacing(48),
      },
    },
  };

  if (type in types && size in types[type]) return types[type][size];

  if (type === 'submit') return types.default.small;

  return types.default.small;
};

// base button styles
export const ButtonComponent = styled.button`
  align-content: center;
  height: ${({ theme, type, size }) =>
    `calc(${getSizes({ type, size, theme }).height} - (${getSizes({ type, size, theme }).border} * 2))`};
  color: ${({ theme, variant, invertColor }) => getVariantColours({ theme, variant, invertColor }).text};
  font-size: ${({ theme, type, size }) => getSizes({ type, size, theme }).text};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  background-color: ${({ theme, variant, invertColor }) =>
    getVariantColours({ theme, variant, invertColor }).background};
  outline: 0;
  cursor: pointer;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
  user-select: none;
  text-decoration: none;
  &:hover {
    color: ${({ theme, variant, invertColor }) =>
      getVariantColours({ theme, variant, invertColor }).hover.text};
    background-color: ${({ theme, variant, invertColor }) =>
      getVariantColours({ theme, variant, invertColor }).hover.background};
    border-color: ${({ theme, variant, invertColor }) =>
      getVariantColours({ theme, variant, invertColor }).hover.text};

    svg {
      color: ${({ theme, variant, invertColor }) =>
        getVariantColours({ theme, variant, invertColor }).hover.text};
    }
  }

  svg {
    color: ${({ theme, variant, invertColor }) => getVariantColours({ theme, variant, invertColor }).text};
    transition: color 0.25s ease-in-out, fill 0.25s ease-in-out;
    pointer-events: none;
  }

  &.button-default,
  &.button-submit {
    display: flex;
    align-items: center;
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};
    font-size: 24px;
    font-weight: bold;
    & span:nth-child(1) {
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: ${({ theme }) =>
        theme.spacing({
          top: 0,
          bottom: 0,
          left: 16,
          right: 16,
        })};
    }

    & span:nth-child(2) {
      display: flex;
      width: ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
      height: calc(100% + (${({ theme, type, size }) => getSizes({ type, size, theme }).border} * 2));
      margin-top: ${({ theme }) => theme.spacing(-1)};
      background-color: ${({ theme, variant, invertColor }) =>
        getVariantColours({ theme, variant, invertColor }).text};
      transition: background-color 0.25s ease-in-out;
    }

    & span:nth-child(3) {
      display: flex;
      max-width: ${({ theme, type, size }) => getSizes({ type, size, theme }).icon};

      & svg {
        width: 100%;
        padding: ${({ theme }) =>
          theme.spacing({
            top: 0,
            bottom: 0,
            left: 10,
            right: 10,
          })};
      }
    }

    &:hover {
      & span:nth-child(2) {
        background-color: ${({ theme, variant, invertColor }) =>
          getVariantColours({ theme, variant, invertColor }).hover.text};
      }

      & span:nth-child(3) {
        svg {
          color: ${({ theme, variant, invertColor }) =>
            getVariantColours({ theme, variant, invertColor }).hover.text};
        }
      }
    }
  }

  &.button-loadmore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme, type, size }) => getSizes({ type, size, theme }).lineHeight};
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};

    > span {
      max-width: 50%;
      margin-bottom: 5px;
    }

    > svg {
      width: ${({ theme, type, size }) => getSizes({ type, size, theme }).icon};
      height: ${({ theme, type, size }) => getSizes({ type, size, theme }).icon};
    }
  }

  &.button-recipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme, type, size }) => getSizes({ type, size, theme }).lineHeight};
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};
    transform: rotate(5deg);

    > span {
      max-width: 75%;
    }
  }

  &.button-love {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    padding: ${({ theme, type, size }) => getSizes({ type, size, theme }).padding};
    background-color: ${({ theme }) => theme.colours.tan};
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};

    &:hover {
      color: ${({ theme }) => theme.colours.red};

      svg {
        color: ${({ theme }) => theme.colours.red};
      }

      .heart-overlay {
        opacity: 0.4;
      }
    }

    > svg {
      width: 100%;
      height: auto;
      fill: none;
      stroke: ${({ theme }) => theme.colours.red};
      margin-top: 5px;
    }

    .heart-outline {
      position: relative;

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        top: 2px;
      }
    }

    .heart-overlay {
      position: absolute;
      width: ${({ theme, type, size }) => getSizes({ type, size, theme }).overlayWidth};
      height: ${({ theme, type, size }) => getSizes({ type, size, theme }).overlayHeight};
      opacity: 0;
      transition: opacity 0.2s;

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        margin-top: 2px;
      }
    }

    &.selected {
      .heart-overlay {
        opacity: 1;
      }
    }
  }

  &.button-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    padding: ${({ theme, type, size }) => getSizes({ type, size, theme }).padding};
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};

    > svg {
      width: 100%;
      height: auto;

      &.chevron-right {
        position: relative;
        left: 1px;
      }
      &.chevron-left {
        position: relative;
        left: -1px;
      }
    }
  }

  &.button-basketful {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    padding: ${({ theme, type, size }) => getSizes({ type, size, theme }).padding};
    font-size: ${({ theme, type, size }) => getSizes({ type, size, theme }).fontSize};
    border: solid ${({ theme, type, size }) => getSizes({ type, size, theme }).border};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};
    span {
      font-size: 20px;
    }
    img {
      width: ${({ theme, type, size }) => getSizes({ type, size, theme }).image};
      width: 147px;
      height: auto;
      margin: 0;
      margin-left: 12px;
    }
  }

  &.button-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    padding: ${({ theme, type, size }) => getSizes({ type, size, theme }).padding};
    font-size: ${({ theme, type, size }) => getSizes({ type, size, theme }).text};
    text-transform: capitalize;
    background-color: ${({ theme }) => hexToRgba(theme.colours.adaTan, 0.5)};
    border-radius: ${({ theme, type, size }) => getSizes({ type, size, theme }).radius};

    svg {
      margin-left: 12px;
    }
  }
  &.yellow-red-solid{
        text-decoration: none !important;
        background-color: ${({ theme }) => theme.colours.yellow} !important;
        color: ${({ theme }) => theme.colours.red} !important;
        border-color: ${({ theme }) => theme.colours.yellow} !important;
        &:hover {
          background-color: ${({ theme }) => theme.colours.red};
          color: ${({ theme }) => theme.colours.red} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
        }
  }  
  &.yellow-clear-hover{
      background-color: transparent;
      text-decoration: none !important;
      &:hover {
        background-color: transparent !important;
        color: ${({ theme }) => theme.colours.yellow} !important;
        border-color: ${({ theme }) => theme.colours.yellow} !important;
      }
  }
  &.button-clear {
    font-size: ${({ theme, type, size }) => getSizes({ type, size, theme }).text};
    height: ${({ theme, type, size }) => getSizes({ type, size, theme }).height};
    text-decoration: underline;
    background-color: transparent;
  }
`;