import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      headings: theme.colours.yellow,
      subscribe: theme.colours.tan,
      logo: theme.colours.yellow,
      list: theme.colours.tan,
      social: theme.colours.red,
    },
    yellow: {
      background: theme.colours.yellow,
      headings: theme.colours.red,
      subscribe: theme.colours.red,
      logo: theme.colours.red,
      list: theme.colours.adaBrown,
      social: theme.colours.red,
    },
    blue: {
      background: theme.colours.blue,
      headings: theme.colours.yellow,
      subscribe: theme.colours.tan,
      logo: theme.colours.yellow,
      list: theme.colours.tan,
      social: theme.colours.blue,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const Slogan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: ${({ theme }) => theme.spacing({ top: 40, bottom: 40, left: 0, right: 0 })};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    margin: ${({ theme }) => theme.spacing({ top: 90, bottom: 90, left: 0, right: 0 })};
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  ${({ hideSubscribe }) => `
    ${hideSubscribe ? `width: 80vw` : ``};
    `}

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-areas: 'subscribe social links';
    grid-template-rows: 1fr;
    ${({ hideSubscribe }) => `
    ${
      hideSubscribe ? `grid-template-columns: 1fr 1fr` : `grid-template-columns: minmax(380px, 1fr) 220px 1fr`
    };
    `}
    gap: 60px;
  }

  ${({ theme }) => theme.breakPoints.minBp('1600px')} {
    ${({ hideSubscribe }) => `
  ${hideSubscribe ? `grid-template-columns: 1fr 1fr` : `minmax(480px, 1fr) 360px 1fr`};
  `}
    gap: ${({ theme }) =>
      `clamp(${theme.spacing(40)}, 10vh, ${theme.spacing(100)})
clamp(${theme.spacing(40)}, 10vw, ${theme.spacing(100)})`};
  }
`;

export const FsGrid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  ${({ hideSubscribe }) => `
    ${hideSubscribe ? `width: 80vw` : ``};
    `}

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-areas: 'subscribe social links';
    grid-template-rows: 1fr;
    ${({ hideSubscribe }) => `
    ${
    hideSubscribe ? `grid-template-columns: 1fr 1fr` : `grid-template-columns: minmax(380px, 1fr) 380px 1fr`
};
    `}
    gap: 60px;
  }

  ${({ theme }) => theme.breakPoints.minBp('1600px')} {
    ${({ hideSubscribe }) => `
  ${hideSubscribe ? `grid-template-columns: 1fr 1fr` : `minmax(480px, 1fr) 360px 1fr`};
  `}
    gap: ${({ theme }) =>
    `clamp(${theme.spacing(40)}, 10vh, ${theme.spacing(100)})
clamp(${theme.spacing(40)}, 10vw, ${theme.spacing(100)})`};
  }
`;


export const FooterComponent = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.spacing({ top: 40, bottom: 40, left: 16, right: 16 })};
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};
  .bushs-beans-foodservices &{
    background-color: ${({ theme }) => theme.colours.blue};
  }
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ top: 120, bottom: 60, left: 60, right: 60 })};
  }

  ${({ theme }) => theme.breakPoints.minBp('1500px')} {
    padding: ${({ theme }) => theme.spacing({ top: 150, bottom: 60, left: 120, right: 120 })};
  }

  h1 {
    max-width: 75%;
    margin: 0 auto;
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).subscribe};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: inherit;
      text-align: left;
    }
  }

  .h1 {
    max-width: 75%;
    margin: 0 auto;
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).subscribe};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: inherit;
      text-align: left;
    }
  }

  .list-title {
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).headings};
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(32)};
    text-align: center;
    text-transform: capitalize;

    &.emphasis {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      font-size: ${({ theme }) => theme.spacing(48)};
      text-align: left;

      &.emphasis {
        margin-bottom: ${({ theme }) => theme.spacing(30)};
      }
    }
  }

  ${Slogan} {
    svg {
      fill: ${({ theme, variant }) => getVariantColours({ theme, variant }).logo};
    }
  }
`;

export const SubscribeWrapper = styled.div`
    .h1{
      font-size: clamp(2rem, 4vw, 3.5rem);
    }
`;



export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ul {
    text-align: left;

    li {
      margin-bottom: ${({ theme }) => theme.spacing(15)};

      a {
        color: #fff;
        font-size: ${({ theme }) => theme.spacing(16)};
        text-transform: capitalize;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: ${({ theme }) => theme.colours.yellow};
        }
      }
    }
  }
  ${({ theme }) => theme.breakPoints.minBp('1200px')} {
    display: flex;
    justify-content: space-between;
    ul {
      text-align: left;

      li {
        margin-bottom: ${({ theme }) => theme.spacing(15)};

        a {
          color: #fff;
          font-size: ${({ theme }) => theme.spacing(16)};
          text-transform: capitalize;
          text-decoration: none;
          transition: all 0.4s ease-in-out;
          &:hover {
            color: ${({ theme }) => theme.colours.yellow};
          }
        }
      }
    }
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  ul {
    text-align: left;

    li {
      margin-bottom: ${({ theme }) => theme.spacing(15)};

      a {
        color: #fff;
        font-size: ${({ theme }) => theme.spacing(16)};
        text-transform: capitalize;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        &:hover{
          color: ${({ theme }) => theme.colours.yellow};
        }
      }
    }
  }
`;

export const FsSocialWrapper = styled.div`
    max-width: 300px;
    min-width: 300px;
    justify-self: center;
    ul{
      li{
        a{
          color: ${({ theme }) => theme.colours.blue};
          button{
            color: ${({ theme }) => theme.colours.blue};
            svg{
              color: ${({ theme }) => theme.colours.blue};
            }
          }
          &:hover{
            svg{
              color: ${({ theme }) => theme.colours.blue};
            }
          }
        }
      }
    }
`;

export const SocialWrapper = styled.div``;

export const Row = styled.div`
  text-align: center;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    &:first-of-type {
      flex-wrap: wrap;
      min-width: 500px;
    }
  }

  ${({ smaller }) => `
    ${
      smaller
        ? `
    max-width: 90%;
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.maxBp('340px')} {
      max-width: 100%;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: 100%;
    }

    `
        : ``
    }
  `}

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    > div {
      flex: 1 1 auto;
    }
  }

  nav {
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    li {
      display: inline-block;
      margin: ${({ theme }) =>
        theme.spacing({
          top: 0,
          bottom: 0,
          left: 4,
          right: 4,
        })};

      &:first-of-type,
      &:last-of-type {
        margin: ${({ theme }) =>
          theme.spacing({
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          })};
      }

      &:after {
        margin: ${({ theme }) =>
          theme.spacing({
            top: 0,
            bottom: 0,
            left: 3,
            right: 0,
          })};
        color: ${({ theme, variant }) => getVariantColours({ theme, variant }).list};
        content: '/';
      }

      &:last-of-type:after {
        margin: 0;
        content: '';
      }

      a {
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        &:hover{
          color: ${({ theme, variant }) => getVariantColours({ theme, variant }).list};
        }
      }
    }
  }

  small {
    color: #fff;
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    transition: all 0.4s ease-in-out;
    &:hover{
      color: ${({ theme, variant }) => getVariantColours({ theme, variant }).list};
    }
  }
`;