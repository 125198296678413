import React from 'react';
import PropTypes from 'prop-types';
import { MainNavRibbonCtaComponent } from './MainNavRibbonCta.styles.js';

import Button from 'components/shared/Button';

const MainNavRibbonCta = ({ text, buttonText, invertColour, className, ...props }) => {
    const buttonVariant = className === 'fs-ribbon' ? 'yellowBlue' : 'yellow';

    return (
    <MainNavRibbonCtaComponent className={className} {...props}>
      <div className="content">
        <p>{text}</p>
        <Button {...props} text={buttonText} invertColour={invertColour} variant={buttonVariant} />
      </div>
    </MainNavRibbonCtaComponent>
  );
};

MainNavRibbonCta.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    className: PropTypes.string,
};


export default MainNavRibbonCta;