import styled from "styled-components";
const getVariantColours = ({ variant, theme } = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
            text: '#fff',
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.yellow,
        },
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
        },
    };

    return variants[variant] || variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
    const alignments = {
        left: 'left',
        center: 'center',
        right: 'right',
    };

    return alignments[alignment] || alignments.left;
};

export const BeanGameWrapper = styled.section`
	position: relative;
	width: calc(100% - 30px);
	background-color: ${({ theme }) => theme.colours.yellow};
	padding: ${({ theme }) => theme.spacing({ top: 120, bottom: 120, left: 15, right: 15 })};
	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spacing({ top: 60, bottom: 60, left: 15, right: 15 })};
	}
    .beanTV{
		width: 180px;
		position: absolute;
		bottom: 40%;
		left: 8%;
		transform: rotate(-25deg);
		@media (max-width: 1588px){
			left: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
    .NineteenNfourSticker{
		position: absolute;
		width: 130px;
		left: 10%;
		bottom: 25%;
		@media (max-width: 1588px){
			left: 3%;
		}
		@media (max-width: 1380px){
			display: none;
		}
    }
	.container{
		width: 90%;
		max-width: 90%;
		display: block;
		margin: auto;
	}
    .section-titles{
        margin-bottom: 50px;
    }
	h2{
		color:  ${({ theme }) => theme.colours.red};
		text-align: center;
		font-size: 69px;
		line-height: 1;
		@media (max-width: 1400px){
			font-size: 55px;
		}
		@media (max-width: 1140px){
			font-size: 45px;
		}
		@media (max-width: 960px){
			font-size: 38px;
		}
		@media (max-width: 900px){
			font-size: 32px;
			text-align: center;
		}
	}
	p{
		text-align: center;
		margin-bottom: 0;
		color:  ${({ theme }) => theme.colours.brown};
		@media (max-width: 900px){
			text-align: center;
		}
	}
`;

export const EmojiBar = styled.div`
	display: flex;
	padding: 20px;
	justify-content: center;
    margin-bottom: 30px;
    .item{
        display: block;
        width: 151px;
        height: 151px;
        &.plus{
			text-align: center;
			justify-content: center;
			align-content: center;
			font-size: 60px;
			color: ${({ theme }) => theme.colours.red};
        }
		&.equals{
			text-align: center;
			justify-content: center;
			align-content: center;
			font-size: 60px;
			color: ${({ theme }) => theme.colours.red};
		}
        &.beans{
            background-color: #fff;
            border-radius: 20px;
            border: 2px solid #fff;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			font-size: 85px;
			align-items: center;
			justify-content: center;
			display: flex;
			aspect-ratio: 1 / 1;
        }
        &.emoji{
            border: 2px dashed #fff;
			border-radius: 20px;
			font-size: 85px;
			align-items: center;
			justify-content: center;
			display: flex;
			aspect-ratio: 1 / 1;
            background-color: rgb(255 215 95);
        }
    }
	.emoji-font{
		font-family: "Noto Color Emoji", sans-serif;
	}
	.emoji-transition {
		transition: all 0.3s ease-in-out;
		font-family: "Noto Color Emoji", sans-serif;
		text-shadow: 1px 1px 0px rgba(00,00,00,0.3);
	}
	#recipeButtonUpdate{
		transition: all 0.3s ease-in-out;
		border-color: ${({ theme }) => theme.colours.red};
		&.animate{
			opacity: 0;
			transform: translate(0, 10px);
		}
    }
	.recipe-square{
		.recipe{
            height: 155px;
            width: 155px;
            border-radius: 20px;
            display: block;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            position: relative;
            overflow: hidden;
			margin-bottom: 20px;
			aspect-ratio: 1 / 1;
            img{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
		}
        .button-link{
            position: relative;
            left: -30px;
			transition: all 0.3s ease-in-out;
        }
        .button-link.animate{
			opacity: 0;
			transform: translate(0, 10px);
		}
	}
    @media (max-width: 650px){
		flex-wrap: wrap;
		justify-content: space-between;
		.item {
			flex-basis: calc(48% - 80px);
			margin: 5px;
			min-width: 0;
            &.beans{
                border-radius: 15px;
                width: 115px;
                height: 115px;
                font-size: 40px;
            }
            &.emoji{
                border-radius: 15px;
				width: 115px;
				height: 115px;
				font-size: 40px;
            }
			&.plus{
				flex-basis: 40px;
			}
			&.equals{
                flex-basis: 40px;
			}
		}
		.recipe-square {
			flex-basis: 100%;
			width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-flow: column;
            .item{
                flex-basis: unset;
                border-radius: 15px;
				width: 115px;
				height: 115px;
            }
            .button-link{
                left: 0;
            }
		}
    }
`;

export const EmojiButtonWrapper = styled.div`
    background-color: rgb(255 215 95);
    border-radius: 30px;
    padding: 20px;
    width: 850px;
    max-width: 90%;
    margin: auto;
	.emoji-container {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 30px;
		padding: 20px;
		margin: 0 auto;
		@media (max-width: 900px){
			grid-template-columns: repeat(4, 1fr);
			width: 90%;
			gap: 0;
		}
		@media (max-width: 650px){
			grid-template-columns: repeat(3, 1fr);
		}
	}
	.emoji {
		font-size: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		cursor: pointer;
		aspect-ratio: 1 / 1;
        transition: all 0.4s ease-in-out;
        border: 2px solid transparent;
		font-family: "Noto Color Emoji", sans-serif;
        text-shadow: 1px 1px 0px rgba(00,00,00,0.3);
        &:hover{
            background-color: rgb(255 202 46);
        }
        &.active{
			background-color: rgba(212,54,49,0.2);
        }
	}
	.clear-btn {
		display: block;
		width: max-content;
		margin: 20px auto 0;
		padding: 10px 20px;
        cursor: pointer;
        font-size: 20px;
        color: #000;
        transition: all 0.4s ease-in-out;
        &:hover{
			color: ${({ theme }) => theme.colours.red};
        }
	}
	@media (max-width: 900px){
        width: 100%;
    }

`;