/* eslint-disable react/display-name */
import React, { useState, useCallback, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { isServer } from '@sitecore-jss/sitecore-jss';
import PropTypes from 'prop-types';

import { Wrapper, Slides } from './Carousel.styles';
import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks';

const Carousel = ({
  animationType = 'horizontal',
  ratio = [16, 9],
  children,
  arrowStyle = 'red',
  backgroundColor = 'yellow',
  }) => {
  const [index, setIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [fromOpposite, setFromOpposite] = useState(false);
  const [wrappedChildren, setWrappedChildren] = useState([]);
  const device = useDeviceResize();

  const setCarouselIndex = useCallback((index, left) => {
    const carouselLength = children.length || 0;
    if (left) {
      if (index === 0) return carouselLength - 1;
      return index - 1;
    } else {
      if (index === carouselLength - 1) return 0;
      return index + 1;
    }
  }, [children]);

  const config = ({ type = 'horizontal' }) => {
    const types = {
      horizontal: {
        from: {
          opacity: 1,
          transform: fromOpposite ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
        },
        initial: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
        enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
        leave: {
          opacity: 1,
          transform: fromOpposite ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
        },
        trail: 20,
        unique: true,
        config: { mass: 1, tension: 320, friction: 60, delay: 0 },
      },
      vertical: {
        from: {
          opacity: 1,
          transform: fromOpposite ? 'translate3d(0, -100%, 0)' : 'translate3d(0, 100%, 0)',
        },
        initial: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
        enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
        leave: {
          opacity: 1,
          transform: fromOpposite ? 'translate3d(0, 100% ,0)' : 'translate3d(0, -100%, 0)',
        },
        trail: 20,
        unique: true,
      },
    };

    if (type in types) return types[type];

    return types.horizontal;
  };

  const onClick = useCallback((left) => setIndex((state) => setCarouselIndex(state, left)), [setCarouselIndex]);

  const handleClick = useCallback(({ left = false }) => {
    setDisabled(true);
    setFromOpposite(left);
    onClick(left);

    // prevents slide overlapping/load issue
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }, [onClick]);

  const bind = useDrag(
      ({ swipe: [swipeX] }) => {
        if (wrappedChildren?.length <= 1) return;

        if (swipeX === 1) {
          handleClick({ left: true });
        } else if (swipeX === -1) {
          handleClick({ left: false });
        }
      },
      { lockDirection: true, axis: 'x' }
  );

  const transitions = useTransition(index, (p) => p, {
    ...config({ type: animationType }),
  });

  const GenerateElements = useCallback(() =>
      React.Children.map(children, (child) => ({ style }) => (
          <animated.div {...bind()} style={{ ...style }} className="animate-slide">
            {React.cloneElement(child)}
          </animated.div>
      )), [bind, children]);

  useEffect(() => {
    setWrappedChildren(GenerateElements());
  }, [children, GenerateElements]);

  const buttons = [
    {
      type: 'circle',
      icon: 'chevron-left',
      size: device === 'mobile' ? 'small' : 'large',
      onClick: () => handleClick({ left: true }),
      disabled,
      variant: arrowStyle,
      className: 'carousel-button-left', // Updated property name to className
      class: 'carousel-button-left',
      invertColor: true,
      ariaLabel: 'Navigate left'
    },
    {
      type: 'circle',
      icon: 'chevron-right',
      size: device === 'mobile' ? 'small' : 'large',
      onClick: () => handleClick({ left: false }),
      disabled,
      variant: arrowStyle,
      className: 'carousel-button-right', // Updated property name to className
      class: 'carousel-button-right',
      invertColor: true,
      ariaLabel: 'Navigate right'
    },
  ];

  if (wrappedChildren?.length > 0) {
    return (
        <Wrapper ratio={ratio} backgroundColor={backgroundColor} className="carousel">
          <Slides>
            {transitions.map(({ item, props, key }) => {
              const Element = wrappedChildren[item];
              return <Element key={key} style={props} />;
            })}
          </Slides>

          {!isServer() && wrappedChildren?.length > 1 && (
              <>
                {buttons?.map((button, index) => (
                    <Button key={`carousel-button-${index}`} {...button} />
                ))}
              </>
          )}
        </Wrapper>
    );
  }

  return null;
};

export default Carousel;

Carousel.propTypes = {
  animationType: PropTypes.string,
  arrowStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  ratio: PropTypes.array,
};