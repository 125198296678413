import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    AlchemerSurveyInPageWrapper,
    ContentInPageAlchemerComponent,
    ModalOverlay,
    ModalContent,
    CloseButton,
} from './AlchemerSurvey.styles.js';
import { Text } from "../../shared/JssOverrides";

const AlchemerSurveyOnPage = ({ backgroundColor, embedurl,alignment, alchemeriframeurl, alchemeriframeheight }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <AlchemerSurveyInPageWrapper id="survey" className="over-the-top-survey-wrapper">
            <button className="red-btn-a" onClick={openModal}>Take the Quiz</button>

            {/* Always render the modal, but hide it initially */}
            <ModalOverlay style={{ display: isModalOpen ? 'flex' : 'none' }} onClick={closeModal}>
                <ModalContent onClick={(e) => e.stopPropagation()}>
                    <CloseButton onClick={closeModal}>×</CloseButton>
                    <ContentInPageAlchemerComponent backgroundColor={backgroundColor} alignment={alignment}>
                        <iframe
                            src={alchemeriframeurl}
                            frameBorder="0"
                            width="1000"
                            height={alchemeriframeheight}
                            style={{overflow: 'hidden'}}
                        ></iframe>
                    </ContentInPageAlchemerComponent>
                </ModalContent>
            </ModalOverlay>
        </AlchemerSurveyInPageWrapper>
    );
};

AlchemerSurveyOnPage.propTypes = {
    backgroundColor: PropTypes.string,
    embedurl: PropTypes.string.isRequired,
    alignment: PropTypes.string,
};

export default AlchemerSurveyOnPage;