import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ResultsComponent, ButtonWrapper, LoadingWrapper, DecorativeItem } from './Results.styles.js';

import Grid from 'components/shared/GridFs';
import Card from 'components/shared/CardFs';
import Button from 'components/shared/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NoResults from './NoResults';
import { useDeviceResize } from 'hooks/index.js';
import translate from 'helpers/translate.js';
import smileImage from './smile.png';

const Results = ({
	sitecoreContext = {},
	results = [],
	type = 'product',
	loadMoreOnClick = () => { },
	currentResults = 0,
	totalResultsCount = 0,
	dataLoading = true,
	initialRequestMade = false,
	globalization = ''
}) => {
	// load more func
	// needs to check there are more results to pull
	const { viewBag = {} } = sitecoreContext;
	const device = useDeviceResize();

	const loadMore = () => {
		const text = {
			recipe: translate(viewBag, 'BushsBeans.Search.Grids.Recipes.ShowMore', 'Show More'),
			product: translate(viewBag, 'BushsBeans.Search.Grids.Products.ShowMore', 'Show More'),
			article: translate(viewBag, 'BushsBeans.Search.Grids.Articles.ShowMore', 'Show More'),
			default: 'Show More',
		};

		if (type in text) return text[type];

		return text.default;
	};
	let specialRenderingFlag = true;
	let specialCardCounter = 0;
	const checkResultsCondition = results && results.length > 0;
	let arrayOfDecoText = ['must-try recipes packed full of beans!', 'our versatile beans can be sweet too', 'A source of plant-based protein'];
	const [decoTextIndex, setDecoTextIndex] = useState(0);
	useEffect(() => {
		setDecoTextIndex((prevIndex) => (prevIndex + 1) % arrayOfDecoText.length);
	}, []);
	if(type === 'recipe'){
		return (
			<ResultsComponent>
				{checkResultsCondition ? (
					<>
						<Grid type={type}>
							{results.map((result, index) => {
								const itemCounter = index + 1;

								if (itemCounter % 4 === 0) {
									specialCardCounter++;
									return (
										<React.Fragment key={`result-${type}-${result.itemUrl}-${index}`}>
											{specialCardCounter % 2 === 0 ? (
												<>
													<Card
														type={type}
														{...result}
														title={result.searchTitle}
														description={result.searchDescription}
														image={result.searchImage}
														promoImage={result.searchHoverImage}
														itemId={result.itemId}
														link={result.itemUrl}
														index={itemCounter}
														globalization={globalization}
														className="item-left recipe-card"
													/>
													<DecorativeItem className="deco-item">
														<div className="inner-deco">
															<img width="178" height="169" src={smileImage} alt="bean smile"/>
															<p>{arrayOfDecoText[index % arrayOfDecoText.length]}</p>
														</div>
													</DecorativeItem>
												</>
											) : (
												<>
													<DecorativeItem className="deco-item">
														<div className="inner-deco">
															<img width="178" height="169" src={smileImage} alt="bean smile"/>
															<p>{arrayOfDecoText[index % arrayOfDecoText.length]}</p>
														</div>
													</DecorativeItem>
													<Card
														type={type}
														{...result}
														title={result.searchTitle}
														description={result.searchDescription}
														image={result.searchImage}
														promoImage={result.searchHoverImage}
														itemId={result.itemId}
														link={result.itemUrl}
														index={itemCounter}
														globalization={globalization}
														className="item-right recipe-card"
													/>
												</>
											)}
										</React.Fragment>
									);
								} else {
									return (
										<Card
											key={`result-${type}-${result.itemUrl}-${index}`}
											type={type}
											{...result}
											title={result.searchTitle}
											description={result.searchDescription}
											image={result.searchImage}
											promoImage={result.searchHoverImage}
											itemId={result.itemId}
											link={result.itemUrl}
											index={itemCounter}
											globalization={globalization}
											className="recipe-card"
										/>
									);
								}
							})}
						</Grid>
						{!isServer() && totalResultsCount > currentResults && !dataLoading && (
							<ButtonWrapper>
								<Button
									type="loadmore"
									variant="yellow"
									size={device === 'desktop' ? 'large' : 'small'}
									text={loadMore()}
									onClick={() => loadMoreOnClick()}
								/>
							</ButtonWrapper>
						)}
					</>
				) : (
					<>{initialRequestMade ? <NoResults /> : <LoadingSpinner />}</>
				)}
			</ResultsComponent>
		);
	} else {
		return (
			<ResultsComponent>
				{type === 'product' && <h2 className="prdocutGridHeadline">Explore Bush's Beans<sup>®</sup> Products</h2>}

				{checkResultsCondition ? (
					<>
						<Grid type={type}>
							{results?.map((result, index) => {
								const {
									searchTitle = '',
									searchDescription = '',
									searchImage = {},
									searchHoverImage = {},
									itemId = '',
									itemUrl = '',
								} = result;
								
								return (
									<Card
										key={`result-${type}-${itemUrl}-${index}`}
										type={type}
										{...result}
										title={searchTitle}
										description={searchDescription}
										image={searchImage}
										promoImage={searchHoverImage}
										itemId={itemId}
										link={itemUrl}
										index={index + 1}
										globalization={globalization}
									/>
								);
							})}
						</Grid>
						{!isServer() && totalResultsCount > currentResults && !dataLoading && (
							<ButtonWrapper>
								<Button
									type="loadmore"
									variant="yellow"
									size={device === 'desktop' ? 'large' : 'small'}
									text={loadMore()}
									onClick={() => loadMoreOnClick()}
								/>
							</ButtonWrapper>
						)}
					</>
				) : (
					<>{initialRequestMade ? <NoResults /> : <LoadingSpinner />}</>
				)}
			</ResultsComponent>
		);
	}


};

Results.propTypes = {
	type: PropTypes.string,
	results: PropTypes.array,
};

export default withSitecoreContext()(Results);