import styled from 'styled-components';

const getbackgroundColor = ({ backgroundColor, theme } = {}) => {
  const backgroundColors = {
    tan: {
      background: theme.colours.tan,
    },
    yellow: {
      background: theme.colours.yellow,
    },
    blue: {
      background: theme.colours.blue,
    },
    red: {
      background: theme.colours.red,
    },
  };

  if (backgroundColor in backgroundColors) return backgroundColors[backgroundColor];

  return backgroundColors.yellow;
};



export const FeaturedHeroComponent = styled.section`
  position: relative;
  margin-bottom: 0;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? getbackgroundColor({ theme, backgroundColor }).background : theme.colours.yellow};
  .wsvg{
    
  }
  &.no-img{
    padding-top: 50px;
    .promobox{
      margin-top: 0 !important;
    }
  }
  .no-img{
    padding-top: 50px;
    .promobox{
      margin-top: 0 !important;
    }
  }
`;

export const FSHeroComponent = styled.section`
  position: relative;
  padding-bottom: 0;
  //margin-bottom: ${({ theme }) => theme.spacing(80)};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? getbackgroundColor({ theme, backgroundColor }).background : theme.colours.yellow};
  .wsvg{
    
  }
  &.no-img{
    padding-top: 50px;
    .promobox{
      margin-top: 0 !important;
    }
  }
  .no-img{
    padding-top: 50px;
    .promobox{
      margin-top: 0 !important;
    }
  }
`;
export const FoodServicesImageWrap = styled.div`
  ${({ backgroundImage }) =>
    backgroundImage
        ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 50%;
   ` : `
    display: none;
    `}
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: 35%;
  }

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    padding-top: 30%;
  }
`;
export const ImageWrap = styled.div`
	${({ backgroundImage }) =>
		backgroundImage
			? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 90%;
   ` : `
    display: none;
    `}
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: calc(2 / 3 * 100%);
  }

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    padding-top: calc(193 / 480 * 100%);
  }
`;

export const SvgHeroWrapper = styled.div`
  position: relative;
  margin-bottom: 0;
  padding-top: 60%;
  overflow: hidden;
  svg{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 767px){
      position: absolute;
      inset: 0px;
      height: 150%;
      width: 150%;
      max-width: 150%;
      max-height: 150%;
      left: -25%;
      top: -25%;
    }
  }
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: 32.52%;
  }
  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    padding-top: 32.52%;
  }
`;

export const InsideTextWrap = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10%;
    width: 670px;
    max-width: 90%;
    h1{
      color: #fff;
      font-weight: 700;
    }
	@media (max-width: 1573px) {
		bottom: 13%;
		width: 30%;
		max-width: 90%;
	}
	@media (max-width: 850px) {
		width: 80%;
        max-width: 80%;
        bottom: 11%;
        padding-top: 70%;
        h1{
            font-size: 40px;
            line-height: 1.2;
        }
	}
	@media (max-width: 767px){
      h1{
        margin-bottom: 0;
      }
    }
    @media (max-width: 690px){
        width: 90%;
    }
	@media (max-width: 615px){
		bottom: 10%;
        h1{
			width: 330px;
			margin: auto;
            max-width: 100%;
        }
	}
	@media (max-width: 370px){
		bottom: 5%;
	}
`;
export const LandingImageWrap = styled.div`
	${({ backgroundImage }) =>
		backgroundImage
			? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
   ` : `
    display: none;
    `}

	background-size: cover;
	background-position: center;
	height: 100%;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: calc(2 / 3 * 100%);
    background-size: cover;
  }

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    padding-top: 50%;
  }

  @media (max-width: 430px) {
    background-size: 1270px 760px;
  }
`;
export const LandingImageWrapLarge = styled.div`
	${({ backgroundImage }) =>
		backgroundImage
			? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   ` : `
    display: none;
    `}
  background-position: bottom center;
  height: 100%;
  padding-top: 50%;
  @media (max-width: 850px) {
	  background-size: auto;
	  background-position: center 60%;
	  height: 100%;
	  padding-top: 70%;
  }
  @media (max-width: 615px) {
    display: none;
  }
`;
export const LandingImageWrapSmall = styled.div`
	${({ backgroundImage }) =>
		backgroundImage &&
		`
      background-image: url(${backgroundImage});
    `}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
  height: 100%;
  width: 100%;
  padding-top: 138%;
  @media (min-width: 615px) {
    display: none;
  }
`;
export const PromoWrap = styled.div`
  position: relative;
  margin-top: -75px;
  &.blue{
    background-color: ${({ theme }) => theme.colours.blue};
  }  
  &.text-yellow{
    h1{
      color: ${({ theme }) => theme.colours.yellow};
    }
    p{
      color: ${({ theme }) => theme.colours.yellow};
    }
  }
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-top: -100px;
  }

  @media print {
    margin-top: 0;
  }

  > div {
    width: 90%;
    margin: auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(90)};
    }

    p {
      margin-bottom: 0;
      sup{
        position: relative;
        top: 0;
        left: 1px;
        font-size: 60%;
        line-height: 0.5em;
        vertical-align: super;
        font-family: 'apercu pro';
        font-weight: 400;
      }
    }
  }
  &.promo-wsvg{
    margin-top: 0;
    div{
      max-width: 100%;
    }
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-top: 0;
    }
    .text-link-yellow{
      transition: all 0.25s ease-in-out;
      margin-top: 30px;
      &:hover{
        color: #fff;
      }
    }
    .link-in-pb{
      margin-top: 30px;
    }
  }
  .p{
    margin-bottom: 0 !important;
  }
`;