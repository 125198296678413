import styled from 'styled-components';

export const CookieAlertComponent = styled.div`
  position: fixed;
  bottom: 0;
  z-index: ${({ theme }) => theme.zindex.global.cookiePolicy};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(16)};
  background-color: ${({ theme }) => theme.colours.tan};
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 50);

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cookiePolicy {
    margin-bottom: ${({ theme }) => theme.spacing(16)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: calc(100% - 10% - 100px);
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(14)};
      line-height: ${({ theme }) => theme.spacing(20)};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        font-size: ${({ theme }) => `clamp(${theme.spacing(14)}, 4vw, ${theme.spacing(16)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(20)}, 4vw, ${theme.spacing(24)})`};
      }

      a {
        margin: 0 ${({ theme }) => theme.spacing(4)};
      }
    }
  }

  button {
    width: max-content;
    margin-left: auto;
  }
`;
