import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MainNavProductLinkComponent } from './MainNavProductLink.styles.js';

import Pop from 'assets/images/pop.svg';
import Link from 'components/shared/Link';

const MainNavProductLink = ({ productName, productImage, mobile, path, ...props }) => (
    <MainNavProductLinkComponent {...props}>
        <Link path={path}>
            {productName && <p className="h4">{productName}</p>}
            {productImage && (
                <div className="image-container" id="">
                    <img src={Pop} className="pop" alt="Opening can animation" />
                    <img src={productImage} alt={`view ${productName} products`} />
                </div>
            )}
        </Link>
    </MainNavProductLinkComponent>
);

MainNavProductLink.propTypes = {
    productName: PropTypes.string,
    productImage: PropTypes.string,
};

export default MainNavProductLink;