import React, { useEffect } from 'react';
import { ContentTextComponent } from "../Text.styles";
import { RichText } from 'components/shared/JssOverrides';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const NewsletterFormComponent = ({ className, field, tag, editable, sitecoreContext, ...props }) => {
    const { params = {} } = props;
    const { backgroundColor = 'tan', alignment = 'center' } = params;
  useEffect(() => {
    var newsletterForm = document.getElementById('newsletterForm');
    var subscribesubmitButton = document.getElementById('subscribesubmit');
    var nlformsubmit = document.getElementById('nlformsubmit');

    nlformsubmit.addEventListener('click', function (event) {
      console.log('submit form button pushed');
      event.preventDefault();
      subscribesubmitButton.click();
    });

    newsletterForm.addEventListener('submit', function (event) {
      console.log('submit form');
      event.preventDefault();
      subscribesubmitButton.click();
    });

    function handleMutation(mutationsList) {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const subscribeNL = document.getElementById('subscribeNL');
          const newsletterFormResponse = document.getElementById('newsletterFormResponse');

          const spanAdded = Array.from(mutation.addedNodes).some(node => node.tagName === 'SPAN' || node.querySelector('span'));

          if (spanAdded) {
            const clonedSpan = subscribeNL.querySelector('span, div span').cloneNode(true);
            newsletterFormResponse.innerHTML = '';
            newsletterFormResponse.appendChild(clonedSpan);
          }
        }
      });
    }

    const subscribeNL = document.getElementById('subscribeNL');
    const observer = new MutationObserver(handleMutation);
    const config = { childList: true, subtree: true };
    observer.observe(subscribeNL, config);

    return () => {
      observer.disconnect();
    };
  }, []);

    const richTextClassname = 'content-container';
    return (
        <ContentTextComponent backgroundColor={backgroundColor} alignment={alignment}>
            <RichText className={richTextClassname} field={field} tag="div" editable={true}/>
        </ContentTextComponent>
    );
};

export default NewsletterFormComponent;