export const getUrl = () => (typeof window !== `undefined` && window.location.href) || '';

export const getMetaDescription = () => {
  if (typeof window !== `undefined`) {
    if (document.head.querySelector('meta[name="description"]'))
      return document.head.querySelector('meta[name="description"]').content;
  }

  return '';
};
