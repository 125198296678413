import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90vw;
  max-width: 600px;
  max-height: 80vh;
  margin: ${({ theme }) => theme.spacing(16)} auto;
  overflow-y: scroll;
`;

export const Table = styled.table`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colours.red};
  border-top: 0;
  border-collapse: collapse;

  thead {
    color: ${({ theme }) => theme.colours.yellow};
  }

  td,
  th {
    padding: ${({ theme }) => theme.spacing(16)};
    font-size: 16px;
    text-align: right;
    border-bottom: 2px solid ${({ theme }) => theme.colours.red};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      font-size: 18px;
    }

    &:nth-child(3n + 1) {
      text-align: left;
    }
  }

  th {
    position: sticky;
    top: -1px;
    padding: ${({ theme }) => theme.spacing({ top: 20, bottom: 20, left: 16, right: 16 })};
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(18)}, 4vw, ${theme.spacing(24)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    line-height: ${({ theme }) => `clamp(${theme.spacing(20)}, 4vw, ${theme.spacing(28)})`};
    background-color: ${({ theme }) => theme.colours.red};
  }

  tr {
    background-color: ${({ theme }) => theme.colours.tan};
  }
`;
