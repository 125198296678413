import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

import {
  RecipeDirectionDetailsComponent,
  ImageWrapper,
  Title,
  Table,
  TableRow,
} from './RecipeDirectionDetails.styles.js';

import InRecipeProductsPromo from 'components/InRecipeProductsPromo';
import InRecipeProductsFoodservices from 'components/InRecipeProductsFoodservices';
import RecipeWrapper from 'components/shared/RecipeWrapper';
import Icon from 'components/shared/Icon';

import { useDeviceResize } from 'hooks';
import translate from 'helpers/translate';

import SocialShare from 'components/SocialShare';

const RenderTableRow = ({ fields = {}, trademarkReplacements = [] }) => {
  const { stepNumber = {}, stepActionLabel = '', stepDirections = {}, ingredients = [] } = fields;

  return (
      <TableRow className="instructions-row-on-print">
        <div className="number instructions-row-on-print">
          {stepNumber && <span className="instructions-header-on-print">{stepNumber}</span>}
        </div>
        <div className="step instructions-row-on-print">
          {stepActionLabel && <span className="instructions-header-on-print">{stepActionLabel}</span>}
        </div>
        <div className="description">{stepDirections && <Text tag="p" field={stepDirections} />}</div>
        <ul className="ingredients hide-on-print">
          {!ingredients?.value &&
              ingredients?.map(
                  (ingredient, index) =>
                      ingredient && (
                          <li key={`ingredient-${ingredient}-${index}`}>
                            {!isServer() && <Icon icon="tick" size="lg" />}
                            <p
                                dangerouslySetInnerHTML={{
                                  __html: trademarkFilterHelper(ingredient, trademarkReplacements),
                                }}
                            />
                          </li>
                      )
              )}
        </ul>
      </TableRow>
  );
};

const RecipeDirectionDetails = ({ fields = {}, params = {}, rendering, sitecoreContext = {} }) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;
  const { site } = sitecoreContext;

    const {
        recipeDirectionsTitleImage = {},
        recipeDirectionsTitle = {},
        steps = [],
        featuredProductPages = [],
        shareText = 'Share this recipe with your bean friends',
        shareOptions = [
            {
                title: 'Facebook',
                value: 'facebook',
            },
            {
                title: 'Twitter',
                value: 'twitter',
            },
            {
                title: 'Pinterest',
                value: 'pinterest',
            },
        ],
    } = fields;

  const device = useDeviceResize();

  return (
      <RecipeDirectionDetailsComponent>
        <RecipeWrapper>
          <Title className="hide-on-print">
            {recipeDirectionsTitle && !isServer() && (
                <Text
                    tag="span"
                    className={`emphasis ${(device === 'laptop' || device === 'desktop') && 'large'}`}
                    field={recipeDirectionsTitle}
                />
            )}
            {recipeDirectionsTitleImage && (
                <ImageWrapper>
                  <Image field={recipeDirectionsTitleImage} />
                </ImageWrapper>
            )}
          </Title>
          <Table className="instructions-on-print">
            <header className="instructions-row-on-print">
              <h2>
                {translate(
                    viewBag,
                    'BushsBeans.Recipes.DirectionDetails.CookingInstructionsTitle',
                    'Cooking Instructions'
                )}
              </h2>
            </header>
            {steps && (
                <ol className="instructions-row-on-print">
                  {steps?.map((step, index) => (
                      <RenderTableRow
                          trademarkReplacements={trademarkReplacements}
                          key={`instruction-step-${index}`}
                          {...step}
                          className="instructions-row-on-print"
                      />
                  ))}
                </ol>
            )}
          </Table>
            <footer className="hide-on-print">
                {featuredProductPages && (
                    site.name === 'bushs-beans-foodservices' ? (
                        <InRecipeProductsFoodservices fields={{ featuredProductPages }} />
                    ) : (
                        <InRecipeProductsPromo fields={{ featuredProductPages }} />
                    )
                )}
            </footer>
        </RecipeWrapper>
      </RecipeDirectionDetailsComponent>
  );
};

RecipeDirectionDetails.propTypes = {
  fields: PropTypes.shape({
    instructionMedia: PropTypes.object,
    title: PropTypes.object,
    steps: PropTypes.array,
    stepsTitle: PropTypes.object,
  }),
  params: PropTypes.object,
  shareOptions: PropTypes.array,
  shareText: PropTypes.string,
};

export default withSitecoreContext()(RecipeDirectionDetails);