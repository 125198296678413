import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
    RecipeOverviewWrapper,
    RecipeOverviewInner,
    RecipeDetailbox,
    DetailBoxList,
    NutritionWindow,
    Overlay,
    ModalContent
} from './RecipeOverview.styles.js';
import DownloadSvg from "../RelatedCarouselSlide/svg/DownloadSvg.jsx";
import ShareIconSvg from "./svg/ShareIconSvg.jsx";
import Button from "../shared/Button";
import SocialShare from "../SocialShare";
import InRecipeProductsFoodservices from 'components/InRecipeProductsFoodservices';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

// Modal component
const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
        <Overlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <button className="closeButton" onClick={onClose}>✕<span>Close</span></button>
                {children}
            </ModalContent>
        </Overlay>
    );
};

const RecipeOverview = (
    {
        title,
        backgroundColor,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
        shareText = 'Share this recipe with your bean friends',
        shareOptions = [
            {
                title: 'Facebook',
                value: 'facebook',
            },
            {
                title: 'Twitter',
                value: 'twitter',
            },
            {
                title: 'Pinterest',
                value: 'pinterest',
            },
        ],
        ...props
    }
) => {

    const { fields = {}, params = {} } = props || {};
    const { globalContent = {}, recipeInfo = {}, route = {} } = sitecoreContext || {};
    const { routeFields = {} } = route;

    const experienceEditor = isExperienceEditorActive();
    const { showBasketfulButton = false } = recipeInfo;
    const { site, trademarkReplacements = [] } = sitecoreContext;
    const isFoodserviceSite = site?.name === 'bushs-beans-foodservices';

    // Extract recipeDirectionsSteps and groupIngredientsList
    const recipeDirectionsSteps = fields?.recipeDirectionsSteps || [];
    const recipeIngredientsGroups = fields?.recipeIngredientGroups || [];
    const optionalToppings = fields?.optionalToppings?.value || '';
    const metadataDescription = fields?.metadataDescription?.value || '';
    const metadataTitle = fields?.metadataTitle?.value || '';
    const ingredientsImage = fields?.ingredientsImage?.value || null;
    const servingSize = fields?.servings?.value || '';
    const totalTime = fields?.totalTimeMinutes?.value || '';
    const cookTime = fields?.cookTimeMinutes?.value || '';
    const steps = fields?.steps || [];
    const featuredProductPages = fields?.featuredProductPages || [];

    const [isModalOpen, setModalOpen] = useState(false);
    const [isShareModalOpen, setShareModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const handleOpenShareModal = () => setShareModalOpen(true);
    const handleCloseShareModal = () => setShareModalOpen(false);

    useEffect(() => {
        const nutritionInfoButtons = document.querySelectorAll('.nutritionInfoButton');
        nutritionInfoButtons.forEach(button => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                handleOpenModal();
            });
        });
        const shareModalButtons = document.querySelectorAll('.shareButton');
        shareModalButtons.forEach(button => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                handleOpenShareModal();
            });
        });
    }, []);
    const directionsObject = {
        recipeDirectionsSteps,
    };

    const ingredientsObject = {
        groupIngredientsList: recipeIngredientsGroups.map(group => group.fields.groupIngredientsList),
    };
    const nutritionInfo = {
        protein: {
            value: fields?.nutritionalInformation?.nutritionProtein || "",
            percentage: fields?.nutritionalInformation?.nutritionProteinPercentage || ""
        },
        niacin: {
            value: fields?.nutritionalInformation?.nutritionNiacin || "",
            percentage: fields?.nutritionalInformation?.nutritionNiacinPercentage || ""
        },
        thiamin: {
            value: fields?.nutritionalInformation?.nutritionThiamin || "",
            percentage: fields?.nutritionalInformation?.nutritionThiaminPercentage || ""
        },
        servingSize: {
            value: fields?.nutritionalInformation?.nutritionServingSize || "",
            percentage: ""
        },
        calories: {
            value: fields?.nutritionalInformation?.nutritionCalories || "",
            percentage: ""
        },
        caloriesFromFat: {
            value: fields?.nutritionalInformation?.nutritionCaloriesFromFat || "",
            percentage: ""
        },
        vitaminA: {
            value: fields?.nutritionalInformation?.nutritionVitaminA || "",
            percentage: fields?.nutritionalInformation?.nutritionVitaminAPercentage || ""
        },
        vitaminD: {
            value: fields?.nutritionalInformation?.nutritionVitaminD || "",
            percentage: fields?.nutritionalInformation?.nutritionVitaminDPercentage || ""
        },
        vitaminB6: {
            value: fields?.nutritionalInformation?.nutritionVitaminB6 || "",
            percentage: ""
        },
        vitaminC: {
            value: fields?.nutritionalInformation?.nutritionVitaminC || "",
            percentage: fields?.nutritionalInformation?.nutritionVitaminCPercentage || ""
        },
        calcium: {
            value: fields?.nutritionalInformation?.nutritionCalcium || "",
            percentage: fields?.nutritionalInformation?.nutritionCalciumPercentage || ""
        },
        iron: {
            value: fields?.nutritionalInformation?.nutritionIron || "",
            percentage: fields?.nutritionalInformation?.nutritionIronPercentage || ""
        },
        sodium: {
            value: fields?.nutritionalInformation?.nutritionSodium || "",
            percentage: fields?.nutritionalInformation?.nutritionSodiumPercentage || ""
        },
        cholesterol: {
            value: fields?.nutritionalInformation?.nutritionCholesterol || "",
            percentage: fields?.nutritionalInformation?.nutritionCholesterolPercentage || ""
        },
        totalCarbohydrates: {
            value: fields?.nutritionalInformation?.nutritionTotalCarbohydrates || "",
            percentage: fields?.nutritionalInformation?.nutritionCarbsPercentage || ""
        },
        dietaryFiber: {
            value: fields?.nutritionalInformation?.nutritionDietaryFiber || "",
            percentage: fields?.nutritionalInformation?.nutritionDietaryFiberPercentage || ""
        },
        totalSugars: {
            value: fields?.nutritionalInformation?.nutritionTotalSugars || "",
            percentage: ""
        },
        addedSugars: {
            value: fields?.nutritionalInformation?.nutritionAddedSugars || "",
            percentage: fields?.nutritionalInformation?.nutritionAddedSugarsPercentage || ""
        },
        fat: {
            value: fields?.nutritionalInformation?.nutritionFat || "",
            percentage: fields?.nutritionalInformation?.nutritionTotalFatPercentage || ""
        },
        saturatedFat: {
            value: fields?.nutritionalInformation?.nutritionSaturatedFat || "",
            percentage: fields?.nutritionalInformation?.nutritionSatFatPercentage || ""
        },
        transFat: {
            value: fields?.nutritionalInformation?.nutritionTransFat || "",
            percentage: fields?.nutritionalInformation?.nutritionTransFatPercentage || ""
        },
        potassium: {
            value: fields?.nutritionalInformation?.nutritionPotassium || "",
            percentage: fields?.nutritionalInformation?.nutritionPotassiumPercentage || ""
        },
        magnesium: {
            value: fields?.nutritionalInformation?.nutritionMagnesium || "",
            percentage: fields?.nutritionalInformation?.nutritionMagnesiumPercentage || ""
        },
        folate: {
            value: fields?.nutritionalInformation?.nutritionFolate || "",
            percentage: fields?.nutritionalInformation?.nutritionFolatePercentage || ""
        }
    };

    const formatKey = (key) => {
        const keyWithoutPercentage = key.replace('Percentage', '');
        return keyWithoutPercentage;
    };

    const formatValue = (key, value) => {
        return value;
    };

    const isPercentageKey = (key) => key.includes('Percentage');


    const handleDownloadRecipe = (event) => {
        event.preventDefault();
        const recipeDetails = document.getElementById('recipe-details');
        if (recipeDetails) {
            const printWindow = window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write(`
            <html>
                <head>
                    <title>
                        ${metadataTitle}
                    </title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #f5f5f5;
                            padding: 20px;
                        }
                        h1 {
                            color: #944000;
                            font-size: 24px;
                            margin-bottom: 10px;
                            text-transform: uppercase;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                            margin-bottom: 20px;
                        }
                        ul, ol {
                            margin-left: 20px;
                            margin-bottom: 20px;
                        }
                        li {
                            margin-bottom: 10px;
                        }
                        .hide-on-print {
                            display: none;
                        }
                    </style>
                </head>
                <body>
                <h1>${metadataTitle}</h1>
                    ${recipeDetails.innerHTML}
                </body>
            </html>
        `);
            printWindow.document.close();
            printWindow.print();
        }
    };
    function addSpaceBeforeCaps(str) {
        return str.replace(/([A-Z])/g, ' $1').trim();
    }

    return (
        <RecipeOverviewWrapper backgroundColor={backgroundColor} className={`recipe-detail ${isFoodserviceSite ? 'fs-recipe' : ''}`} id={id}>
            <RecipeOverviewInner>
                <div className="container">
                    <div className="col product-ingredients-desktop">
                        <RecipeDetailbox>
                            <h3>INGREDIENTS</h3>
                            <DetailBoxList>
                                {recipeIngredientsGroups.length > 0 && (
                                    <>
                                        {recipeIngredientsGroups.map((group, index) => (
                                            <div className="ingredients-item" key={group?.id || index}>
                                                {group?.fields?.groupTitle?.value && (
                                                    <h4>{group.fields.groupTitle.value}</h4>
                                                )}
                                                <ul>
                                                    {group?.fields?.ingredients?.map((ingredient, i) => (
                                                        <li key={i}>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: trademarkFilterHelper(
                                                                        ingredient || '',
                                                                        trademarkReplacements
                                                                    )
                                                                }}
                                                            />
                                                        </li>
                                                    )) || []}
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                )}
                                {optionalToppings && (
                                    <div className="ingredients-item">
                                        <h4>Optional Toppings</h4>
                                        <ul>
                                            <li>{optionalToppings}</li>
                                        </ul>
                                    </div>
                                )}
                            </DetailBoxList>
                        </RecipeDetailbox>
                        {
                            Object.values(nutritionInfo).every(info => info.value === "" && info.percentage === "") ?
                                null :
                                <Button onClick={handleOpenModal} className="nutritionInfoButton" text="View Nutrition Info" href="/" variant="yellowBlue"/>
                        }

                    </div>
                    <div className="col recipe-overview" id="recipe-details">
                        {ingredientsImage && (
                            <img
                                className="recipe-detail-image hide-on-print"
                                src={ingredientsImage.src}
                                alt={ingredientsImage.alt || ''}
                            />
                        )}
                        {/*<h2>Overview</h2>*/}
                        {/*{metadataDescription && (<p>{metadataDescription}</p>)}*/}
                        <a href="#" className="download-link hide-on-print" onClick={handleDownloadRecipe}>
                            <span className="link-text">DOWNLOAD RECIPE SHEET</span>
                            <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                        </a>
                        <a href="#" className="download-link hide-on-print shareButton" onClick={handleOpenShareModal}>
                            <span className="link-text">SHARE RECIPE</span>
                            <ShareIconSvg className="shareIconSvg"/>
                        </a>
                        <div className="product-ingredients-mobile">
                            <RecipeDetailbox>
                                <h3>INGREDIENTS</h3>
                                <DetailBoxList>
                                    {recipeIngredientsGroups.length > 0 && (
                                        <>
                                            {recipeIngredientsGroups.map((group, index) => (
                                                <div className="ingredients-item" key={group?.id || index}>
                                                    {group?.fields?.groupTitle?.value && (
                                                        <h4>{group.fields.groupTitle.value}</h4>
                                                    )}
                                                    <ul>
                                                        {group?.fields?.ingredients?.map((ingredient, i) => (
                                                            <li key={i}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: trademarkFilterHelper(
                                                                    ingredient || '',
                                                                    trademarkReplacements
                                                                )
                                                            }}
                                                        />
                                                            </li>
                                                        )) || []}
                                                    </ul>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    {optionalToppings && (
                                        <div className="ingredients-item">
                                            <h4>Optional Toppings</h4>
                                            <ul>
                                                <li>{optionalToppings}</li>
                                            </ul>
                                        </div>
                                    )}
                                </DetailBoxList>
                            </RecipeDetailbox>
                            {
                                Object.values(nutritionInfo).every(info => info.value === "" && info.percentage === "") ?
                                    null :
                                    <Button onClick={handleOpenModal} className="nutritionInfoButton hide-on-print" text="View Nutrition Info"
                                            href="/"
                                            variant="yellowBlue"/>
                            }
                        </div>
                        <h2>DIRECTIONS</h2>
                        <ol id="instruction-list">
                            {steps && Array.isArray(steps) && steps.length > 0 ? (
                                steps.map((step, index) => (
                                    <li key={step?.fields?.id || index}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: trademarkFilterHelper(
                                                    step?.fields?.stepDirections?.value || '',
                                                    trademarkReplacements
                                                )
                                            }}
                                        />
                                    </li>
                                ))
                            ) : (
                                <li>No directions available</li>
                            )}
                            {directionsObject.recipeDirectionsSteps.length > 0 && (
                                directionsObject.recipeDirectionsSteps.map(step => (
                                    <li key={step.id}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: trademarkFilterHelper(
                                                step?.fields?.stepDirections?.value || '',
                                                trademarkReplacements
                                            )
                                        }}
                                    />
                                    </li>
                                ))
                            )}
                        </ol>
                    </div>
                </div>
                <Modal show={isModalOpen} onClose={handleCloseModal}>
                    <NutritionWindow id="nutrition-info-window">
                        <h3>Nutrition Information</h3>
                        {servingSize && (
                            <div className="servings">Serving Size <span>{servingSize}</span></div>
                        )}
                        <h4>% Daily Value*</h4>
                        <ul>
                            {Object.entries(nutritionInfo).map(([key, {value, percentage}]) => (
                                (value || percentage) && (
                                    <li key={key} className={!percentage ? "no-percentage" : ""}>
                                        <strong>{addSpaceBeforeCaps(formatKey(key))}:</strong>
                                        <span className="val">{formatValue(key, value)}</span>
                                        {percentage && <span className="percent">{percentage}%</span>}
                                    </li>
                                )
                            ))}
                        </ul>
                        <div className="info-message">
                            <p>*The % Daily Value tells you how much a nutrient in a serving of food contributes to a
                                daily diet. 2,000 calories a day is used for general nutrition advice.</p>
                        </div>
                    </NutritionWindow>
                </Modal>
                <Modal show={isShareModalOpen} onClose={handleCloseShareModal}>
                    <div className="share-modal">
                        {shareOptions && <SocialShare className="socialShare" fields={{ shareOptions, title: shareText }} />}
                    </div>
                </Modal>
            </RecipeOverviewInner>

            {
                featuredProductPages && (
                    <InRecipeProductsFoodservices fields={{featuredProductPages}}/>
                )
            }
        </RecipeOverviewWrapper>
    );
};

RecipeOverview.propTypes = {
    sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(RecipeOverview);