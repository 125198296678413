import React from 'react';

const LoadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 271.1 271.1"
      xmlSpace="preserve"
      style={{
        fill: '#e42227',
      }}
      className="hide-on-print"
    >
      <g>
        <circle cx="135.6" cy="135.6" r="135.6" fill="#ffca06"></circle>
        <g>
          <g>
            <path
              fill="none"
              stroke="#e42227"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M135.6 269.6c-73.9 0-134.1-60.1-134.1-134.1s60.1-134 134.1-134 134.1 60.2 134.1 134.1-60.2 134-134.1 134zm0-217.2c-45.9 0-83.2 37.3-83.2 83.2s37.2 83.2 83.2 83.2 83.2-37.2 83.2-83.2h0c-.1-45.9-37.3-83.2-83.2-83.2h0z"
            ></path>
            <g className="outer-ring">
              <path
                d="M68.4 29l22.9-11.2 2.6 5.3-8.6 4.2L97.9 53l-5.7 2.8-12.7-25.7-8.5 4.2-2.6-5.3z"
                className="st2"
              ></path>
              <path
                d="M101.8 14.3l6.3-1 1.9 13 13.7-2-1.9-13 6.3-.9 5.1 34.1-6.3.9-2.3-15.3-13.7 2 2.3 15.3-6.3 1-5.1-34.1z"
                className="st2"
              ></path>
              <path
                d="M159.1 40.3l-10.7-2.1-4.1 7.5-6.8-1.3 18.2-31.5 6.3 1.2 4.7 36.1-6.8-1.3-.8-8.6zm-7.8-7.5l7.1 1.4-1.2-12.4-5.9 11z"
                className="st2"
              ></path>
              <path
                d="M177.6 17.5l23 11-2.5 5.3-8.6-4.1-12.4 25.8-5.8-2.8L183.6 27l-8.6-4.1 2.6-5.4z"
                className="st2"
              ></path>
              <path
                d="M231.5 56.2c3.8 4.5 4 9.5-.7 13.5-1.9 1.8-4.6 2.4-7.1 1.5 1.1 3.3.3 6.6-3.1 9.4-5.1 4.3-10.7 3.4-15.3-2l-8.3-9.8 26.3-22.3 8.2 9.7zM212.8 64l-7.1 6 3.8 4.5c2.6 3.1 4.9 3.2 7.1 1.2s2.5-4.2-.1-7.2l-3.7-4.5zm10.2-8.7l-5.7 4.8 3.8 4.5c1.7 1.9 3.3 2.5 5.5.7 2.1-1.8 1.9-3.6.1-5.6l-3.7-4.4z"
                className="st2"
              ></path>
              <path
                d="M254.6 95.9l-5.4 2.3-5.9-13.7-6.7 2.8 4.7 11-5.4 2.3-4.7-11-8.7 3.8 6.1 14.2-5.4 2.3-8.6-20.1 31.7-13.6 8.3 19.7z"
                className="st2"
              ></path>
              <path
                d="M233.9 134.4l-.7-10.9-8.3-2-.4-6.9 35.2 9.4.4 6.4-33.7 13.9-.4-6.9 7.9-3zm5.3-9.5l.5 7.3 11.7-4.4-12.2-2.9z"
                className="st2"
              ></path>
              <path
                d="M232.9 173.2c-8.9-2.2-11.8-8.4-10-15.7s7.3-11.4 16.2-9.2l20.8 5.2-1.5 6.2-20.9-5.2c-4.9-1.2-7.8.2-8.8 4.4s.8 6.9 5.7 8.1l20.9 5.2-1.6 6.2-20.8-5.2z"
                className="st2"
              ></path>
              <path
                d="M250.2 186.9l-12.6 22.2-5.1-2.9 4.7-8.3-24.9-14.1 3.2-5.6 24.9 14.1 4.7-8.3 5.1 2.9z"
                className="st2"
              ></path>
              <path d="M201.4 198.2l4.2-4.8 26 22.6-4.2 4.8-26-22.6z" className="st2"></path>
              <path
                d="M199.9 244l-3.5-4.7 12.4-9.2-4.7-6.3-10 7.4-3.5-4.7 10-7.4-8.8-11.9 5.1-3.8 20.6 27.7-17.6 12.9z"
                className="st2"
              ></path>
              <path
                d="M160.3 237.8c-3.3-8.6.1-14.5 7.1-17.2s13.5-.6 16.9 7.9l7.7 19.9-6 2.3-7.8-20c-1.8-4.7-4.7-6.2-8.7-4.6s-5.1 4.6-3.3 9.3l7.8 20-6 2.3-7.7-19.9z"
                className="st2"
              ></path>
              <path d="M148.4 260.8l-2.6-28.5-14.6 1.3-.5-5.9 21-1.9 3.1 34.4-6.4.6z" className="st2"></path>
              <path
                d="M92.8 254.3c-5.6-1.9-8.3-6.1-6.3-12 .7-2.5 2.7-4.4 5.3-4.9-2.7-2.3-3.6-5.5-2.2-9.6 2.2-6.4 7.4-8.4 14.1-6.2l12.2 4.1-11.1 32.6-12-4zm7.8-3.6l2.4-7.1-5.6-1.9c-2.4-.8-4.1-.4-5 2.2s.2 4 2.8 4.9l5.4 1.9zm4.3-12.7l3-8.8-5.6-1.9c-3.8-1.3-5.8-.2-6.8 2.6s0 4.9 3.8 6.2l5.6 1.9z"
                className="st2"
              ></path>
              <path
                d="M53.6 232l3.5-4.8 12.1 8.8 4.2-5.9-9.7-7 3.5-4.8 9.7 7 5.6-7.7-12.5-9.1 3.5-4.8 17.7 12.8-20.3 27.9L53.6 232z"
                className="st2"
              ></path>
              <path
                d="M52.2 190.2l6.4 8.9 8.1-2.7 4 5.6-34.8 10.7-3.7-5.2 21.2-29.6 4 5.6-5.2 6.7zm.5 10.9l-4.3-5.9-7.6 9.9 11.9-4z"
                className="st2"
              ></path>
              <path
                d="M11.1 160.3l33-10.1 2 6.5L28 175.5l21.8-6.6 1.9 6.1-33 10-2-6.5 18.1-18.8-21.8 6.7-1.9-6.1z"
                className="st2"
              ></path>
              <path
                d="M24.7 105.4c-3.7.7-6.6 2.6-7.5 7.3-1 5.2 1.9 10.3 9.7 11.8s12.4-2.2 13.3-7.5c.9-4.7-1.2-7.5-4.3-9.5l2.5-5.4c6.2 2.8 9 8.6 7.6 16-1.6 8.5-9 14.9-20.3 12.8s-15.9-10.8-14.3-19.3c1.4-7.4 6.1-11.7 12.9-12.1l.4 5.9z"
                className="st2"
              ></path>
              <path
                d="M50.9 66c10.2 6.2 11.1 15.6 6.6 23.1S44.2 99.9 34 93.7s-11.1-15.6-6.6-23.1S40.7 59.7 50.9 66zM37.4 88.2c7 4.2 12.4 2.2 15.1-2.2s2-10.2-5-14.4-12.4-2.2-15.1 2.2c-2.7 4.4-2 10.1 5 14.4z"
                className="st2"
              ></path>
              <path
                d="M65.9 65.2c1.6-1.6 4.3-1.5 5.8.1 1.6 1.6 1.6 4.3-.1 5.8-1.6 1.6-4.3 1.6-5.8-.1-1.6-1.6-1.6-4.1 0-5.7l.1-.1z"
                className="st2"
              ></path>
            </g>
          </g>
          <path
            d="M125.2 107.7c-15.4-10.6-36.5-6.8-47.2 8.6s-6.8 36.5 8.6 47.2c5.7 3.9 12.4 6 19.3 6h58.5c17.7 0 33-13.2 34.5-30.8 1.7-18.6-12-35.1-30.7-36.8-7.8-.7-15.7 1.3-22.2 5.7-6.1 4.3-14.4 4.4-20.8.1z"
            className="st2 bean-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LoadingIcon;
