import React from 'react';
import PropTypes from 'prop-types';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ContentCol, ImageCol, SideBySideLargeImageComponent } from './SideBySideLargeImage.styles.js';
import { RichText } from "../shared/JssOverrides";
import { isServer } from "@sitecore-jss/sitecore-jss";
import Button from "../shared/Button";
import { underlineText } from "../../helpers/stringHelpers";
import { useDeviceResize } from "../../hooks";

const SideBySideLargeImage = ({ fields = {}, params = {}, sitecoreContext }) => {
    const { title = '', copy = '', foregroundMedia = null, backgroundMedia = null, cta = {} } = fields;
    const {
        alignment = 'left',
        contentPanelBackgroundColor = 'red',
        backgroundColor = null,
        contentPanelTitleColor = null,
        contentPanelCopyColor = null,
        contentPanelCtaColor = null,
    } = params;

    const backgroundImageTitle = backgroundMedia?.value?.title || '';

    const device = useDeviceResize();
    const ctaVisible = !!(cta?.hasAction || cta?.value?.href?.length);
    const headingTag = backgroundImageTitle === 'hero' ? 'h1' : 'h2';

    const templateName = sitecoreContext.route.templateName;
    let pageType = templateName;

    if (templateName === 'Article Page') {
        pageType = 'article';
    }

    return (
        <SideBySideLargeImageComponent
            alignment={alignment}
            variant={contentPanelBackgroundColor}
            backgroundColor={backgroundColor}
            backgroundImage={backgroundMedia?.value}
            className={pageType ? `page-type-${pageType}` : ''}
        >
            <div className="container">
                <ContentCol className="promoboxWrap">
                    <h2 className="chunk"
                        dangerouslySetInnerHTML={{
                            __html: underlineText(title.value, {
                                openTag: '<u>',
                                closeTag: '</u>',
                            }),
                        }}
                    />
                    {copy?.value && (
                        <p dangerouslySetInnerHTML={{__html: copy.value}}/>
                    )}
                    {!isServer() && cta && ctaVisible && (
                        <Button
                            text={cta?.text}
                            href={cta?.href}
                            variant={contentPanelCtaColor || contentPanelBackgroundColor}
                            {...cta?.value}
                            size={device === 'desktop' ? 'large' : 'small'}
                        />
                    )}
                </ContentCol>
                {alignment !== 'center' && (
                    <ImageCol className="cont-img-block">
                        {foregroundMedia && <Image field={foregroundMedia}/>}
                    </ImageCol>
                )}
            </div>
        </SideBySideLargeImageComponent>
    );
};

SideBySideLargeImage.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.string,
        copy: PropTypes.string,
        backgroundImage: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        contentImage: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        cta: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
        }),
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        contentPanelBackgroundColor: PropTypes.oneOf(['red', 'yellow', 'blue', 'transparent']),
        backgroundColor: PropTypes.string,
        contentPanelTitleColor: PropTypes.string,
        contentPanelCopyColor: PropTypes.string,
        contentPanelCtaColor: PropTypes.string,
    }),
};

export default withSitecoreContext()(SideBySideLargeImage);