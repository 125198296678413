import styled from 'styled-components';

export const PlaceholderWrapper = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing(900)};
`;

export const HeroCarouselComponent = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing(600)};
  overflow: hidden;

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    min-height: ${({ theme }) => theme.spacing(650)};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    min-height: ${({ theme }) => theme.spacing(900)};
  }

  .carousel-animated {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .controls {
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(75)};
    left: 50%;
    z-index: 3;
    display: flex;
    transform: translateX(-50%);

    ${({ theme }) => theme.breakPoints.minBp('390px')} {
      bottom: ${({ theme }) => theme.spacing(100)};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      right: ${({ theme }) => theme.spacing(100)};
      bottom: ${({ theme }) => theme.spacing(75)};
      left: unset;
      transform: unset;
    }

    button {
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(14)};
      }
    }
  }

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;

    .promo {
      background-color: rgba(00,00,00,0.2);
      .hero {
        margin-bottom: ${({ theme }) => theme.spacing(24)};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          ${({ theme }) => theme.spacing(36)};
        }
      }
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .fs-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  & .ribbon-left {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .ribbon-right {
    display: none;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    position: relative;
    overflow: hidden;

    & button {
      margin-bottom: ${({ theme }) => theme.spacing(0)};
    }

    & .ribbon-left {
      position: absolute;
      bottom: 0;
      left: ${({ theme }) => theme.spacing(48)};
      transform: translateY(100%) rotate(-90deg);
      transform-origin: left bottom;
    }

    & .ribbon-right {
      position: absolute;
      right: ${({ theme }) => theme.spacing(48)};
      bottom: 0;
      display: block;
      transform: translateY(100%) rotate(90deg);
      transform-origin: right bottom;
    }
  }

  & .promo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 20, right: 20 })};
    text-align: center;

    ${({ theme }) => theme.breakPoints.minBp('375px')} {
      padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 35, right: 35 })};
    }

    ${({ theme }) => theme.breakPoints.minBp('1024px')} {
      padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 70, right: 70 })};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) =>
        `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(100)}, 14vw, ${theme.spacing(270)})`};
    }

    p {
      margin-bottom: ${({ theme }) => theme.spacing(40)};
    }
  }
`;