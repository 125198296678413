import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      titleColor: theme.colours.yellow,
      textColor: theme.colours.white,
    },
    yellow: {
      background: theme.colours.yellow,
      titleColor: theme.colours.red,
      textColor: theme.colours.adaBrown,
    },
    blue: {
      background: theme.colours.blue,
      titleColor: theme.colours.yellow,
      textColor: theme.colours.white,
    },
    brown: {
      background: theme.colours.brown,
      titleColor: theme.colours.yellow,
      textColor: theme.colours.tan,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.yellow;
};

export const PromoBoxComponent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 35, right: 35 })};
  color: ${({ theme }) => theme.colours.yellow};
  text-align: center;
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)})`};
  }

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).titleColor};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .p {
    margin: 0 auto 0 auto;
    margin-bottom: 0;
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).textColor};
    font-size: clamp(1rem, 4vw, 1.5rem);
    line-height: clamp(1.5rem, 4vw, 2rem);
    margin: 0 auto;
    a{
      color: ${({ theme, variant }) => getVariantColours({ theme, variant }).textColor};
      transition: all 0.4s ease-in-out;
      &:hover{
        color: ${({ theme, variant }) => getVariantColours({ theme, variant }).titleColor};
      }
    }
  p {
    margin: 0 auto 0 auto;
    margin-bottom: 0;
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).textColor};
    a{
      color: ${({ theme, variant }) => getVariantColours({ theme, variant }).textColor};
      transition: all 0.4s ease-in-out;
      &:hover{
        color: ${({ theme, variant }) => getVariantColours({ theme, variant }).titleColor};
      }
    }
  }
  }
`;

export const PromoBoxInner = styled.div`
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 880px;
  }
  .p {
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).textColor};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(88)};
    }
`;