import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    tan: {
      background: theme.colours.tan,
    },
    yellow: {
      background: theme.colours.yellow,
    },
    blue: {
      background: theme.colours.blue,
    },
    red: {
      background: theme.colours.red,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ theme, variant: backgroundColor }).background};

  &:before {
    display: block;
    width: 100%;
    padding-top: ${({ ratio }) => `calc((${ratio[1]} / ${ratio[0]}) * 100%)`};
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .carousel-button-right {
    position: absolute;
    top: 50%;
    right: 10%;
    left: unset;
    z-index: 10;
    transform: translate(0, -50%);
  }

  .carousel-button-left {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 10;
    transform: translate(0, -50%);
  }
`;

export const Slides = styled.div`
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .animate-slide {
    touch-action: pan-y;
  }
`;