import styled from "styled-components";

export const ThirtyYears = styled.svg`
    position: absolute;
    right: 0;
    top: 20%;
    z-index: 9;
	@media (max-width: 1300px) {
		transform: scale(0.7);
        top: 18%;
        right: -5%;
	}
    @media (max-width: 900px) {
        top: 15%;
        transform: scale(0.8);
    }
	@media (max-width: 600px) {
		transform: scale(0.7);
	}
	@media (max-width: 500px) {
		transform: scale(0.5);
		top: 10%;
		right: -9%;
	}
`;