import React from 'react';
import { Container } from './LoadingSpinner.styles';
import LoadingIcon from './LoadingIcon.jsx';

const LoadingSpinner = () => (
  <Container className="loading-spinner">
    <LoadingIcon />
  </Container>
);

export default LoadingSpinner;
