import React from 'react';
import PropTypes from 'prop-types';
import { MainNavAboutUsLinkComponent } from './MainNavAboutUsLink.styles.js';

import Link from 'components/shared/Link';

const MainNavAboutUsLink = ({ text, src, ...props }) => {
  return (
    <MainNavAboutUsLinkComponent {...props}>
      <Link path={props.path}>
        <h2>{text}</h2>
        <div className="image">
          <img src={src} alt={`view ${text} page`} />
          <div>View</div>
        </div>
      </Link>
    </MainNavAboutUsLinkComponent>
  );
};

MainNavAboutUsLink.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
};

export default MainNavAboutUsLink;
