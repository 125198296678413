import styled from 'styled-components';

export const Wrapper = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-left: ${({ theme }) => theme.spacing(20)};

  .details-flag {
    padding: 0;
    //color: ${({ theme }) => theme.colours.blue};
    color: #1263BA;
    font-size: ${({ theme }) => theme.spacing(12)};
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};
    outline: 0;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colours.blue};
    }
  }
`;

export const ToolTipWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(320)};
  margin-top: ${({ theme }) => theme.spacing(16)};
  padding: ${({ theme }) => theme.spacing(16)};
  background-color: ${({ theme }) => theme.colours.yellow};
  border-radius: 10px;
  box-shadow: 10px 9px 14px 0px rgba(81, 81, 81, 0.4);
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  color: ${({ theme }) => theme.colours.red};
  text-transform: uppercase;

  &:before {
    position: absolute;
    top: -10px;
    left: 35px;
    display: block;
    width: 0;
    height: 0;
    border-right: 12px solid transparent;
    border-bottom: ${({ theme }) => `12px solid ${theme.colours.yellow}`};
    border-left: 12px solid transparent;
    content: '';
  }

  span {
    margin-bottom: 0;
  }

  button {
    cursor: pointer;

    svg {
      /* Have to important to override inline style :( */
      fill: ${({ theme }) => theme.colours.red} !important;
    }
  }
`;

export const ImageWrap = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;