import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/shared/Link';
import { ButtonComponent } from './Button.styles.js';
import { RenderButtonType } from './ButtonTypes';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { checkIfFavoriteInStorage, addFavoriteToList } from 'helpers/favoriteHelpers';

const Button = (props) => {
  const {
    variant = 'red',
    type = 'default',
    size = 'small',
    path = null,
    href = null,
    invertColor = false,
    WithDefaultStyles = false,
    itemId = '',
    ariaLabel = '',
    title = '',
  } = props;

  // only for fav func
  const [favoriteSelected, setFavoriteSelected] = useState(false);

  const buttonAriaLabel = type === 'love' ? 'favorite' : ariaLabel;

  useEffect(() => {
    if (!isServer() && type === 'love') setFavoriteSelected(checkIfFavoriteInStorage({ itemId }));
  }, [itemId, type, favoriteSelected]);

  // Renamed `class` to `className` in props and in JSX
  return (
      <ConditionalComponentWrapper
          condition={(path || href) && !isExperienceEditorActive()}
          wrapper={(children) => (
              <Link {...props} className={null} className="button-link" id={undefined}>
                {children}
              </Link>
          )}
      >
        <ButtonComponent
            {...props}
            class={null}
            variant={variant}
            size={size}
            invertColor={invertColor}
            type={type}
            className={`${WithDefaultStyles && type !== 'default' ? 'button-default' : ''} ${
                props?.className ? props.className : ''
            } button-${type} ${favoriteSelected ? 'selected' : ''}`}
            {...(type === 'love' && itemId
                ? {
                  onClick: () =>
                      addFavoriteToList({
                        value: { itemId },
                        stateCallback: setFavoriteSelected,
                      }),
                }
                : {})}
            aria-label={buttonAriaLabel}
            title={title}
        >
          <RenderButtonType type={type} props={props} />
        </ButtonComponent>
      </ConditionalComponentWrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['red', 'yellow', 'blue', 'tan','blueYellow','yellowBlue','brownYellow','brown','yellowBrown']),
  type: PropTypes.oneOf(['default', 'circle', 'loadmore', 'recipe', 'love', 'submit', 'clear', 'chip']),
  size: PropTypes.oneOf(['small', 'large']),
  invertColor: PropTypes.bool,
  WithDefaultStyles: PropTypes.bool,
  path: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string, // Renamed `class` to `className` in propTypes
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
};

export default Button;