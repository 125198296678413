import React, { useState } from 'react';
import { SampleRequestFormWrapper } from "./SampleRequestForm.styles";
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SampleRequestForm = ({ className, id, submitButton = '', text, sitecoreContext, ...props }) => {
    const [formData, setFormData] = useState({
        email: '',
        eaddress: '',
        firstName: '',
        lastName: '',
        addressOne: '',
        city: '',
        state: '',
        zipCode: '',
        units: '',
        sample: '',
        otherSample: '',
        distributor: '',
        otherDistributor: '',
        segment: '',
        otherSegment: ''
    });

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setFormSubmitted(false);
    };

    return (
        <SampleRequestFormWrapper className={className} id={id}>
            {formSubmitted && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close-window" onClick={handleCloseModal}>X (close)</span>
                        <h2>CONGRATULATIONS,<br />{formData.firstName} {formData.lastName}!</h2>
                        <h3>Big flavor is on the way.</h3>
                        <p>While you’re waiting for your sample to arrive, check out
                            our new website with recipes and inspiration just for
                            your channel.</p>
                        <a href="https://www.bushbeansfoodservice.com" target="_blank" rel="noopener noreferrer">bushbeansfoodservice.com</a>
                    </div>
                </div>
            )}
            {!formSubmitted && (
                <>
            {text && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: typeof text === 'string' ? text : text.value,
                    }}
                />
            )}

            <form id="sample-request-form" className="sample-request-form" action="https://marketing.bushbeansfoodservice.com/l/1081833/2024-10-25/9qbwmf" method="post" >
                <div className="fields-wrapper">
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" placeholder="First Name" name="firstName"
                                   value={formData.firstName} onChange={handleInputChange} required/>
                        </div>
                        <div className="field">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" placeholder="Last Name" name="lastName"
                                   value={formData.lastName} onChange={handleInputChange} required/>
                        </div>
                    </div>
                    <div className="fields-row-one-col">
                        <div className="field">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" id="email" placeholder="Email Address" name="email" onChange={handleInputChange} value={formData.email}
                                   onChange={handleInputChange} required/>
                        </div>
                    </div>
                    <div className="fields-row-one-col">
                        <div className="field">
                            <label htmlFor="addressOne">Address</label>
                            <input type="text" id="addressOne" placeholder="Address" name="addressOne"
                                   value={formData.addressOne} onChange={handleInputChange} required/>
                        </div>
                    </div>
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="city">City</label>
                            <input type="text" id="city" name="city" placeholder="City" value={formData.city}
                                   onChange={handleInputChange} required/>
                        </div>
                        <div className="field">
                            <label htmlFor="state">Select State</label>
                            <select id="state" name="state" value={formData.state} placeholder="Select State"
                                    onChange={handleInputChange} required>
                                {/* State options */}
                                <option value="">Select State</option>
                                <option value="Alabama">Alabama</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Alberta">Alberta</option>
                                <option value="Arizona">Arizona</option>
                                <option value="Arkansas">Arkansas</option>
                                <option value="British Columbia">British Columbia</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Delaware">Delaware</option>
                                <option value="District Of Columbia">District Of Columbia</option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Kentucky">Kentucky</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Manitoba">Manitoba</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">Massachusetts</option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Mississippi">Mississippi</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Brunswick">New Brunswick</option>
                                <option value="New Hampshire">New Hampshire</option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="Newfoundland">Newfoundland</option>
                                <option value="North Carolina">North Carolina</option>
                                <option value="North Dakota">North Dakota</option>
                                <option value="Northwest Territories">Northwest Territories</option>
                                <option value="Nova Scotia">Nova Scotia</option>
                                <option value="Nunavut">Nunavut</option>
                                <option value="Ohio">Ohio</option>
                                <option value="Oklahoma">Oklahoma</option>
                                <option value="Ontario">Ontario</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">Pennsylvania</option>
                                <option value="Prince Edward Island">Prince Edward Island</option>
                                <option value="Quebec">Quebec</option>
                                <option value="Rhode Island">Rhode Island</option>
                                <option value="Saskatchewan">Saskatchewan</option>
                                <option value="South Carolina">South Carolina</option>
                                <option value="South Dakota">South Dakota</option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Utah">Utah</option>
                                <option value="Vermont">Vermont</option>
                                <option value="Virginia">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">West Virginia</option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>
                                <option value="Yukon">Yukon</option>
                            </select>
                        </div>
                    </div>
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="zipCode">Zip Code</label>
                            <input type="text" id="zipCode" placeholder="Zip Code" name="zipCode"
                                   value={formData.zipCode} onChange={handleInputChange} required/>
                        </div>
                    </div>
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="units">Units</label>
                            <input type="number" id="units" placeholder="Units" name="units" value={formData.units}
                                   onChange={handleInputChange} required/>
                        </div>
                        <div className="field">
                            <label htmlFor="sample">Select Sample</label>
                            <select id="sample" name="sample" placeholder="Select Sample" value={formData.sample}
                                    onChange={handleInputChange}
                                    required>
                                {/* Sample options */}
                                <option value="">Select Sample</option>
                                <option value="Easy Entrees™ Texas Ranchero Pinto Beans">Easy Entrees™ Texas Ranchero
                                    Pinto Beans
                                </option>
                                <option value="Easy Entrees™ Taco Fiesta Black Beans">Easy Entrees™ Taco Fiesta Black
                                    Beans
                                </option>
                                <option value="Chili Beans in Mild Sauce">Chili Beans in Mild Sauce</option>
                                <option value="Original Baked Beans">Original Baked Beans</option>
                                <option value="Bean Pot® Vegetarian Baked Beans">Bean Pot® Vegetarian Baked Beans
                                </option>
                                <option value="Bean Pot® Vegetarian Baked Beans Reduced Sodium">Bean Pot® Vegetarian
                                    Baked Beans
                                    Reduced Sodium
                                </option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    {formData.sample === 'Other' && (
                        <>
                            <div className="fields-row-two-col">
                                <div className="field">
                                    <label htmlFor="otherSample">Other Sample</label>
                                    <input type="text" id="otherSample" placeholder="Desired Product Name"
                                           name="otherSample" value={formData.otherSample} onChange={handleInputChange}
                                           required/>
                                </div>
                                <div className="field"></div>
                            </div>
                        </>
                    )}
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="distributor">Select Distributor</label>
                            <select id="distributor" name="distributor" placeholder="Select Distributor"
                                    value={formData.distributor} onChange={handleInputChange} required>
                                {/* Distributor options */}
                                <option value="">Select Distributor</option>
                                <option value="Sysco">Sysco</option>
                                <option value="US Foods">US Foods</option>
                                <option value="Performance Food Group">Performance Food Group</option>
                                <option value="Gordon Foodservice">Gordon Foodservice</option>
                                <option value="Restaurant Depot">Restaurant Depot</option>
                                <option value="Ben E Keith">Ben E Keith</option>
                                <option value="Cheney Brothers">Cheney Brothers</option>
                                <option value="Shamrock">Shamrock</option>
                                <option value="Goldstar">Goldstar</option>
                                <option value="Nicholas">Nicholas</option>
                                <option value="Labatt">Labatt</option>
                                <option value="Don’t know">Don’t know</option>
                                <option value="Not Applicable">Not Applicable</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {formData.distributor === 'Other' && (
                            <>
                                <div className="field">
                                    <label htmlFor="otherDistributor">Other Distributor</label>
                                    <input type="text" id="otherDistributor" placeholder="Distributor Name"
                                           name="otherDistributor" value={formData.otherDistributor}
                                           onChange={handleInputChange} required/>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="fields-row-two-col">
                        <div className="field">
                            <label htmlFor="segment">Segment</label>
                            <select id="segment" name="segment" placeholder="Segment" value={formData.segment}
                                    onChange={handleInputChange} required>
                                {/* Segment options */}
                                <option value="">Select Segment</option>
                                <option value="K12">K12</option>
                                <option value="College & University">College & University</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {formData.segment === 'Other' && (
                            <>
                                <div className="field">
                                    <label htmlFor="otherSegment">Other Segment</label>
                                    <input type="text" id="otherSegment" placeholder="Segment Name" name="otherSegment"
                                           value={formData.otherSegment} onChange={handleInputChange} required/>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="submit-button-container">
                        <button type="submit">Submit</button>
                    </div>
                </div>

            </form>
                </>
            )}
        </SampleRequestFormWrapper>
    );
}
export default withSitecoreContext()(SampleRequestForm);