import { underlinedStringRegex } from './regexHelpers';

export const underlineText = (text = '', { openTag = '<u>', closeTag = '</u>' }) => {
  if (!text) return null;

  if (!underlinedStringRegex.test(text)) return text;

  const foundMatch = text.match(underlinedStringRegex);

  let copyText = text;

  if (foundMatch) {
    foundMatch.forEach((word) => {
      copyText = copyText.replace(word, `${openTag}${word.replace(/~/g, '')}${closeTag}`);
    });
  }

  return copyText;
};
