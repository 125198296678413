import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      text: '#fff',
    },
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.brown,
    },
    blue: {
      background: theme.colours.blue,
      text: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
      text: theme.colours.brown,
    },
    brown: {
      background: theme.colours.brown,
      text: theme.colours.yellow,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
  const alignments = {
    left: 'left',
    center: 'center',
    right: 'right',
  };

  if (alignment in alignments) return alignments[alignment];

  return alignments.left;
};

export const YtTextComponent = styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme, backgroundColor }) =>
      getVariantColours({ variant: backgroundColor, theme }).background};
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 64, left: 0, right: 0 })};
  @media (max-width: 900px) {
    padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 32, left: 0, right: 0 })};
  }
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
  }
  .two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
    .col{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 100%;
        max-width: 100%;
       .content-container{
         max-width: 608px;
         margin-bottom: 0;
         width: auto;
         align-self: flex-start;
       }
      @media (max-width: 900px){
        .content-container{
          max-width: 100%;
          
        }
      }
       h2{
         color:  ${({ theme }) => theme.colours.red};
         text-align: left;
         font-size: 69px;
         line-height: 1;
         @media (max-width: 1400px){
           font-size: 55px;
         }
         @media (max-width: 1140px){
           font-size: 45px;
         }
         @media (max-width: 960px){
           font-size: 38px;
         }
         @media (max-width: 900px){
           font-size: 32px;
           text-align: center;
         }
       }
      p{
        text-align: left;
        margin-bottom: 0;
        @media (max-width: 900px){
          text-align: center;
        }
      }
    }
  }
`;

export const YtVideoComponent = styled.div`
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    background-size: cover;
    iframe {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
`;


export const ContentTextComponent = styled.section`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 32, left: 0, right: 0 })};
  color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
  text-align: ${({ alignment }) => checkAlignment({ alignment })};
  background-color: ${({ theme, backgroundColor }) =>
  getVariantColours({ variant: backgroundColor, theme }).background};
  &.fs-text-component{
    padding: 0;
    margin-top: 160px;
    margin-bottom: 160px;
    @media (max-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
  }
  &.noMargin{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }  
  .content-container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 800px;
      font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto !important;
      margin-bottom: ${({ theme }) => theme.spacing(48)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    font-weight: 400;
    text-align: ${({ alignment }) => checkAlignment({ alignment })};
  }

  p {
    line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
  }

  span {
    display: block;
  }

  ul {
    margin-bottom: 16px;
    list-style: disc;

    li {
      margin-left: ${({ theme }) => theme.spacing(16)};
    }
  }

  ol {
    margin-bottom: 16px;
    list-style-type: decimal;

    li {
      margin-left: ${({ theme }) => theme.spacing(28)};
    }
  }
  .blueFullWidth {
	  width: 100%;
	  max-width: 100%;
	  background: ${({theme}) => theme.colours.blue};
      padding: 80px 0;
      @media (max-width: 900px) {
        padding: 40px 0;
      }
    .h2-hl {
      font-style: normal;
      font-weight: 700 !important;
      font-size: 69px;
      align-items: center;
      text-align: center !important;
      text-transform: uppercase;
      display: block;
      line-height: 1;
      @media (max-width: 900px) {
        font-size: 32px;
      }
    }
    .span-hl {
      font-style: normal;
      font-weight: 700 !important;
      font-size: 40px;
      text-align: center !important;
      text-transform: uppercase;
      margin-bottom: 40px;
      display: block;
      @media (max-width: 900px) {
        font-size: 20px;
      }
    }
    .button-wrapper-tc {
      display: flex;
      justify-content: center;
      align-content: center;
      @media (max-width: 900px) {
        flex-flow: column;
        margin: auto;
        align-self: center;
        justify-self: center;
        width: 300px;
      }
    }
    .button-link{
      .button-default{
        width: 240px;
        span:nth-child(1){
          flex-grow: 1;
        }
      }
      @media (max-width: 900px) {
        .button-default {
          margin: auto;
        }
      }
    }
  }  
  .blueWide{
    width: 1568px;
    max-width: 100%;
    background: ${({ theme }) => theme.colours.blue};
    .hte-inner{
      padding: 80px;
      @media (max-width: 900px) {
        padding: 10% 5%;
      }
    }
    .hte-top-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 755px;
      max-width: 90%;
      margin: auto;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .hte-h2{
      font-family: 'Bordonaro';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      color: ${({ theme }) => theme.colours.yellow};
      display: block;
      text-align: center;
      line-height: 1;
      @media (max-width: 900px) {
        font-size: 48px;
        margin-bottom: 0;
      }
    }
    .hte-p{
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2;
      align-items: center;
      color: #FFFFFF;
      display: block;
      text-align: center;
      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0;
      }
    }
    .hte-p span{
      color: ${({ theme }) => theme.colours.yellow};
      display: inline-block;
    }
    .hte-p a{
      color: ${({ theme }) => theme.colours.yellow};
      display: inline-block;
    }
    .grid-col-two{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 48px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px;
      width: 970px;
      max-width: 100%;
      margin: auto;
    }
    @media (max-width: 900px) {
      .grid-col-two {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
    .grid-col-two .col{
      text-align: center;
    }
    .grid-col-two .col h3{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      display: block;
      text-align: center;
      text-transform: capitalize;
      color: ${({ theme }) => theme.colours.yellow};
      @media (max-width: 900px) {
        font-size: 20px;
      }
    }
    .grid-col-two .col p{
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      width: 430px;
      max-width: 90%;
      margin: 20px auto;
      text-align: center;
      display: block;
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
    .grid-col-two .col img{
      width: 340px;
      margin: auto;
    }
    .grid-col-two .col p span{
      color: ${({ theme }) => theme.colours.yellow};
      display: inline-block;
    }
    .grid-col-two .col p a{
      color: ${({ theme }) => theme.colours.yellow};
      display: inline-block;
    }
  }
`;


export const BrownFullWidthTextComponent = styled.section`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
  color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
  text-align: ${({ alignment }) => checkAlignment({ alignment })};
  background-color: #C75127;
  .content-container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 1100px;
      font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    }
    .button-row-sv{
      display:flex;
      justify-content:center;
      gap: 40px;
      max-width: 100%;
      width: 700px;
      margin: 0 auto;
      padding-top: 20px;
      @media (max-width: 700px) {
        flex-flow: column;
        gap: 20px;
        margin: 20px;
      }
    }
    .white-brown-button{
      padding: 12px 30px 8px 30px;
      border-radius: 25px;
      display: inline-block;
      text-align: center;
      margin: 0;
      background-color: #fff;
      border: 2px solid #fff;
      color: #C75127;
      text-decoration: none;
      text-transform: uppercase;
      font-family: "apercu condensed pro", impact;
      line-height: 1;
      transition: all 0.4s ease-in-out;
      font-size: 24px;
      font-weight: 900;
      &:hover{
        background-color: #C75127;
        color: #fff;
        border: 2px solid #fff;
      }
    }
    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto !important;
      margin-bottom: ${({ theme }) => theme.spacing(48)};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  p {
    font-weight: 400;
    text-align: ${({ alignment }) => checkAlignment({ alignment })};
  }

  p {
    line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
  }

  span {
    display: block;
  }

  ul {
    margin-bottom: 16px;
    list-style: disc;

    li {
      margin-left: ${({ theme }) => theme.spacing(16)};
    }
  }

  ol {
    margin-bottom: 16px;
    list-style-type: decimal;

    li {
      margin-left: ${({ theme }) => theme.spacing(28)};
    }
  }
  
`;

export const ContentNoMarginComponent = styled.section`
    position: relative;
    margin: 0;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: block;
      background-color: #C75127;
      padding-top: 673px;
      z-index: -1;
      @media (max-width: 1440px) {
        display: none;
      }
    }
    .rich-text-img-absolute{
      position: relative;
      width: 1440px;
      max-width: 100%;
      padding-top: 778px;
      display: block;
      z-index: 5;
      margin: auto;
      img{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100% !important;
        height: auto !important;
        margin-top: 0;
        margin-bottom: 0;
      }
      @media (max-width: 1440px) {
        padding-top: 54.03%;
      }
    }
`;

export const OverTheTopIframeWrapper = styled.section`
    display: block;
    position: relative;
    width: 1000px;
    max-width: 100%;
    margin: auto;
    iframe{
      width: 100%;
    }
    @media (max-width: 1010px) {
      width: 100%;
    }
`;

export const OverTheTopWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    background: #ffca2e;
`;