import React from 'react';


class VideoTimelineYoutubePlayer extends React.Component {
    constructor(props) {
        super(props);
        this.player = null;
        this.playerRef = React.createRef();
    }

    componentDidMount() {
        // Adding event listener for YouTube Iframe API ready event
        document.addEventListener('youtubeIframeAPIReady', this.initializePlayer);
        if (window.YT) {
            this.initializePlayer();
        } else {
            console.error('YoutubePlayer: YT object not available yet, waiting for youtubeIframeAPIReady event...');
        }
    }

    componentWillUnmount() {
        document.removeEventListener('youtubeIframeAPIReady', this.initializePlayer);
        if (this.player) {
            this.player.destroy();
        }
    }

    initializePlayer = () => {
        console.log('YoutubePlayer: Initializing player with videoId:', this.props.videoId);
        if (window.YT && window.YT.Player && this.playerRef.current) {
            this.player = new window.YT.Player(this.playerRef.current, {
                videoId: this.props.videoId,
                events: {
                    onReady: (event) => {
                        console.log('YoutubePlayer: Player is ready');
                        this.onPlayerReady(event);
                        // Play video immediately if isClicked is already true during initialization
                        if (this.props.isClicked) {
                            this.player.playVideo();
                        }
                    },
                    onStateChange: (event) => {
                        this.onPlayerStateChange(event);
                    }
                }
            });
        } else {
            console.error('YoutubePlayer: Failed to initialize player, YT or YT.Player not available');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.videoId !== prevProps.videoId) {
            if (this.player) {
                this.player.destroy();
                setTimeout(() => {
                    this.initializePlayer();
                }, 100); // Delay initialization to ensure clean up
            } else {
                this.initializePlayer();
            }
        }

        if (this.props.isClicked && !prevProps.isClicked) {
            if (this.player && this.player.playVideo) {
                this.player.playVideo();
            }
        }
    }


    onPlayerReady = (event) => {
        event.target.playVideo();
    }

    onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.ENDED) {
            if (this.props.onVideoEnd) {
                this.props.onVideoEnd();
            }
        }
    }

    render() {
        return (
            <div ref={this.playerRef}></div>
        );
    }
}

export default VideoTimelineYoutubePlayer;