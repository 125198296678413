import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { FilterOptionsWrapper, AccordionWrapper } from './Filters.styles';

import Input from 'components/shared/Input';
import Icon from 'components/shared/Icon';

import { useDeviceResize } from 'hooks';
import translate from 'helpers/translate';

const FilterOptionsDesktop = ({
  filtersKey = '',
  filterType = {},
  options = [],
  viewBag = {},
  toggleAppliedFilter = () => {},
  checkStatus = () => {},
}) => {

  return (
    <FilterOptionsWrapper
      results={(Array.isArray(options) && options.filter((option) => option?.aggregateCount > 0).length) || 0}
    >
      {translate(viewBag, filterType?.labelKey, filterType?.label) && (
          <h3 className="title">{translate(viewBag, filterType?.labelKey, filterType?.label)}</h3>
      )}
      <ul>
        {options?.map((option, index) => {
          if (option?.aggregateCount)
            return (
              <li key={`filterOptions-${option?.name}-${index}`}>
                <Input
                  type="checkbox"
                  onChange={() =>
                    toggleAppliedFilter({ filtersKey, filterTypeKey: filterType?.filterKey, option })
                  }
                  checked={checkStatus({ option, filterTypeKey: filterType?.filterKey })}
                  label={{ value: `${option?.name} (${option?.aggregateCount})` }}
                  name={option?.name}
                />
              </li>
            );
        })}
      </ul>
    </FilterOptionsWrapper>
  );
};

const Accordion = (
    {
      options = [],
      checkStatus = () => {
      },
      toggleAppliedFilter = () => {
      },
      filtersKey = '',
      filterKey = '',
      labelKey = '',
      label = '',
      viewBag = {},
      toggleFilters = false,
    }
) => {
  const listRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [height, setHeight] = useState(0);

  const expand = useSpring({
    config: {...config.default},
    height: toggle ? height : 0,
  });

  useLayoutEffect(() => {
    setHeight(toggle ? listRef?.current?.offsetHeight : 0);
  }, [listRef, toggle]);

  useEffect(() => {
    if (!toggleFilters && toggle) setToggle(false);
  }, [toggleFilters]);



  return (
      <AccordionWrapper>
        <button type="button" onClick={() => setToggle((state) => !state)}>
          <span className="title">{translate(viewBag, labelKey, label)}</span>
          <Icon icon={toggle ? 'cross' : 'plus'} size="md"/>
        </button>
        <animated.div style={expand}>
          <ul className="options-list" ref={listRef}>
            {options?.map((option, index) => {
              if (option?.aggregateCount !== undefined)
                return (
                    <li key={`filterOptions-${option?.name || index}-${index}`}>
                      <Input
                          type="checkbox"
                          onChange={() => toggleAppliedFilter({filtersKey, filterTypeKey: filterKey, option})}
                          checked={checkStatus({option, filterTypeKey: filterKey})}
                          label={{value: `${option?.name || ''} (${option?.aggregateCount || 0})`}}
                          name={option?.name || `option-${index}`}
                      />
                    </li>
                );
            })}
          </ul>
      </animated.div>
    </AccordionWrapper>
  );
};


// add data prop
const FilterOptionsMobile = (
    {
      filtersKey = '',
      mobileOptions: allOptions = [],
      toggleAppliedFilter = () => {},
      checkStatus = () => {},
      toggleFilters = false,
    }
) => {
  return (
      <FilterOptionsWrapper>
        {Array.isArray(allOptions) &&
            allOptions.map((item, index) => {
              if (item) {
                return (
                    <Accordion
                        key={`accordion-${item.label || index}`}
                        checkStatus={checkStatus}
                        toggleAppliedFilter={toggleAppliedFilter}
                        filtersKey={filtersKey}
                        toggleFilters={toggleFilters}
                        {...item}
                    />
                );
              }
              return null;
            })}
      </FilterOptionsWrapper>
  );
};

const FilterOptions = (props) => {
  const {sitecoreContext = {}, filtersKey = '', filterType = {}, filterState = {}} = props;
  const {viewBag = {}} = sitecoreContext || {};
  const device = useDeviceResize();

  const checkStatus = ({option, filterTypeKey}) => {
    const filtersArray = filterState[filtersKey] && filterState[filtersKey][filterTypeKey] ? filterState[filtersKey][filterTypeKey] : [''];
    const toLowerCaseArray = filtersArray ? filtersArray.map((value) => value ? value.toLowerCase() : '') : [];
    return option && option.name ? toLowerCaseArray.includes(option.name.toLowerCase()) : false;
  };

  return (
      <>
        {device === 'desktop' ? (
            <FilterOptionsDesktop viewBag={viewBag} {...props} checkStatus={checkStatus}/>
        ) : (
            <FilterOptionsMobile viewBag={viewBag} {...props} checkStatus={checkStatus} />
      )}
    </>
  );
};

export default withSitecoreContext()(FilterOptions);