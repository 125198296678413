import React from 'react';
import NutritionLabelTableUS from './Tables/US';
import NutritionLabelTableCA from './Tables/CA';

const NutritionLabelTable = ({ sitecoreContext, translate }) => {
  const {
    globalContent: { nutritionPanelFormat },
  } = sitecoreContext;

  function handleNutritionLabelTableBySite() {
    switch (nutritionPanelFormat.toLowerCase()) {
      case 'us':
        return <NutritionLabelTableUS sitecoreContext={sitecoreContext} translate={translate} />;
      case 'ca':
        return <NutritionLabelTableCA sitecoreContext={sitecoreContext} translate={translate} />;
      default:
        return <NutritionLabelTableUS sitecoreContext={sitecoreContext} translate={translate} />;
    }
  }

  return handleNutritionLabelTableBySite();
};

export default NutritionLabelTable;
