import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { HeroCarouselComponent, PlaceholderWrapper } from './HeroCarousel.styles.js';
import HeroCarouselSlide from 'components/HeroCarouselSlide/HeroCarouselSlide.jsx';

import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const HeroCarousel = ({ sitecoreContext, ...props }) => {
  const experienceEditor = isExperienceEditorActive();
  const { rendering = {} } = props;

  const carouselItems = rendering?.placeholders?.['hero-carousel-content'];

  const options = {
    transform: {
      initial: { transform: 'translateX(0)' },
      from: { transform: 'translateX(100%)' },
      enter: { transform: 'translateX(0)' },
      leave: { transform: 'translateX(-100%)' },
    },
  };

  const slides = Array.isArray(carouselItems)
    ? carouselItems.map((item, index) => ({ ...item, slideIndex: index }))
    : [];

  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = slides.length;
  const transitions = useTransition(slides[currentSlide - 1], (item) => item?.slideIndex, options.transform);

  if (experienceEditor || sitecoreContext?.pageEditing) {
    return (
      <div className="home-hero-carousel">
        <div>
          <h1>Start Hero Carousel</h1>
        </div>
        <PlaceholderWrapper>
          <Placeholder name="hero-carousel-content" rendering={rendering} />
        </PlaceholderWrapper>
        <div>
          <h1>End Hero Carousel</h1>
        </div>
      </div>
    );
  }

  return (
    <HeroCarouselComponent>
      {transitions.map(({ item, key, props }) => {
        return (
          <animated.div key={key} style={{ ...props }} className="carousel-animated">
            <HeroCarouselSlide
              totalSlides={totalSlides}
              setCurrentSlide={setCurrentSlide}
              rendering={{ ...item }}
            />
          </animated.div>
        );
      })}
    </HeroCarouselComponent>
  );
};

HeroCarousel.propTypes = {};

export default HeroCarousel;
