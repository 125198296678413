import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Downshift from 'downshift';

import { SearchBoxComponent } from './SearchBox.styles.js';

import IconButton from './IconButton';

import Button from 'components/shared/Button';
import translate from 'helpers/translate';

const SearchBox = ({
  sitecoreContext = {},
  items,
  className,
  props,
  onChange,
  onSubmit,
  initialInputValue,
  searchValue = '',
  icon = 'chevron-right',
  showError = false,
}) => {
  const { site } = sitecoreContext;
  const { viewBag = {} } = sitecoreContext;
  const placeholder = site.name === 'bushs-beans-foodservices'
      ? 'Search Food Service Site'
      : translate(
          viewBag,
          'BushsBeans.Search.Labels.SearchBoxPlaceholder',
          'Search that beautiful bean website'
      );
  const buttonVariant = site.name === 'bushs-beans-foodservices' ? 'yellowBlue' : 'yellow';

  return (
    <Downshift initialInputValue={initialInputValue}>
      {({ getInputProps, getMenuProps, getRootProps, inputValue, isOpen, getItemProps, ...props }) => (
        <SearchBoxComponent className={className}>
          <div {...getRootProps()} className="input-container">
            <Button
              type="circle"
              variant={buttonVariant}
              icon={icon}
              size="large"
              ariaLabel="Submit Search"
              title="Submit Search"
              onClick={(event) => {
                if (typeof onSubmit === 'function') {
                  onSubmit(event, inputValue);
                }
              }}
            />
            <input
              {...getInputProps()}
              type="search"
              aria-label="Search"
              placeholder={placeholder}
              onChange={(event) => {
                if (typeof onChange === 'function') {
                  onChange(event.target.value, inputValue);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && typeof onSubmit === 'function') {
                  onSubmit(event, inputValue);
                }
              }}
              value={searchValue}
            />
            {showError && (
              <span className="error-label">
                {translate(
                  viewBag,
                  'BushsBeans.Search.Labels.SearchBoxEmptyError',
                  'Please enter a search term.'
                )}
              </span>
            )}
          </div>
          {/* Disable Autocomplete */}
          {/* {isOpen && (
            <ul {...getMenuProps()} className="results-list">
              <p className="list-label">Suggested</p>
              {items
                .filter(({ value }) => !value || value.includes(inputValue))
                .map(({ value, index }) => {
                  return (
                    <li {...getItemProps({ item: value })} key={`${index}-${value}`}>
                      {value}
                    </li>
                  );
                })}
            </ul>
          )} */}
        </SearchBoxComponent>
      )}
    </Downshift>
  );
};

SearchBox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
};

export default withSitecoreContext()(SearchBox);