import styled from 'styled-components';

const desktopFilterHeight = (results = 0) => {
  const height = Math.floor(results / 4);

  const calcHeight = 60 + height * 80;

  return `${calcHeight}px`;
};

export const FiltersComponent = styled.div`
  z-index: ${({ theme }) => theme.zindex.filters.main};
  background-color: ${({ theme }) => theme.colours.tan};

  > header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(16)};
    background-color: ${({ theme }) => theme.colours.tan};
    border-bottom: 1px solid ${({ theme }) => theme.colours.adaBrown};
    z-index: ${({ theme }) => theme.zindex.filters.header};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      display: block;
      flex-direction: row;
      padding: ${({ theme }) => theme.spacing(32)};
    }

    > .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
    }

    > .bottom {
      margin-top: 16px;
    }
  }

  .title {
    font-size: 24px;
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    text-transform: uppercase;
  }

  .animated {
    position: absolute;
    top: -100%;
    z-index: ${({ theme }) => theme.zindex.filters.main};
    width: 100%;
  }

  footer {
    // position: sticky;
    // bottom: 0;
    padding: ${({ theme }) => theme.spacing(16)};
    background-color: ${({ theme }) => theme.colours.tan};
    border-top: 1px solid ${({ theme }) => theme.colours.adaBrown};
    border-bottom: 1px solid ${({ theme }) => theme.colours.adaBrown};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(32)};
    }
  }

  .filters {
    background-color: ${({ theme }) => theme.colours.tan};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(32)};
    }
  }
`;

export const MainWrapper = styled.div``;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 0, right: 40 })};
  cursor: pointer;

  svg {
    margin-right: 16px;
    color: ${({ theme }) => theme.colours.adaBrown};
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 140px;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: inherit;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing(16)};

  select {
    box-sizing: border-box;
    width: 100%;
    height: ${({ theme }) => theme.spacing(48)};
    padding: ${({ theme }) => theme.spacing({ top: 0, left: 16, bottom: 0, right: 48 })};
    color: ${({ theme }) => theme.colours.adaBrown};
    font-weight: 600;
    font-size: ${({ theme }) => theme.spacing(16)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colours.tan};
    border: none;
    border: 2px solid ${({ theme }) => theme.colours.adaBrown};
    border-radius: ${({ theme }) => theme.spacing(100)};
    outline: none;
    cursor: pointer;
    appearance: none;

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colours.blue};
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  padding: ${({ theme }) => theme.spacing({ top: 10, bottom: 10, left: 20, right: 20 })};
  border-radius: 0 25px 25px 0;
  transform: translate(0%, -50%);
  pointer-events: none;

  svg {
    position: relative;
    top: 0px;
    right: 2px;
    color: ${({ theme }) => theme.colours.red};
  }
`;

// Filter option styles
export const TypeOptions = styled.div`
  margin-right: auto;
`;

export const Options = styled.ul`
  display: flex;

  li {
    margin: 0 ${({ theme }) => theme.spacing(10)};
    button {
      color: ${({ theme }) => theme.colours.adaBrown};
      font-size: 1rem;
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      text-transform: uppercase;
      outline: 0;
      cursor: pointer;
    }
  }
`;

export const FiltersSelected = styled.ul`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: calc(100% - 300px);
    margin: ${({ theme }) => theme.spacing(24)} auto 0;
  }

  li {
    margin: ${({ theme }) => theme.spacing({ top: 0, bottom: 8, left: 0, right: 8 })};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing({ top: 0, bottom: 12, left: 0, right: 12 })};
    }
  }
`;

export const FilterOptionsWrapper = styled.div`
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 1080px;
    margin: 0 auto;
  }

  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(32)};
    text-align: left;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: ${({ theme }) => theme.spacing({ top: 16, bottom: 16, left: 0, right: 0 })};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: ${({ results }) => desktopFilterHeight(results)};
    }

    li {
      margin-bottom: ${({ theme }) => theme.spacing(32)};

      &:last-of-type {
        margin-bottom: ${({ theme }) => theme.spacing(16)};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(32)};

        &:last-of-type {
          margin-bottom: ${({ theme }) => theme.spacing(0)};
        }
      }
    }
  }
`;

export const AccordionWrapper = styled.div`
  overflow: hidden;
  border-bottom: 2px solid ${({ theme }) => theme.colours.adaBrown};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colours.adaBrown};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: ${({ theme }) => theme.spacing(16)};
    color: ${({ theme }) => theme.colours.adaBrown};
    text-align: left;
    outline: 0;

    svg {
      color: ${({ theme }) => theme.colours.adaBrown};
    }
  }

  ul {
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
    padding: ${({ theme }) => theme.spacing(16)};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
  }

  button {
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
      justify-content: center;
      width: 100%;
      margin-top: ${({ theme }) => theme.spacing(16)};

      &:nth-of-type(2n + 1) {
        order: 2;
      }

      &:nth-of-type(2n + 2) {
        order: 1;
      }
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      &:nth-of-type(2n + 1) {
        font-size: 1.4rem;
      }
    }
  }
`;
