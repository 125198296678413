import React from 'react';
import PropTypes from 'prop-types';
import { RecipeGridComponent } from './RecipeGrid.styles.js';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Listing from 'components/shared/Listing';
import ListingFs from 'components/shared/ListingFs';

const RecipeGrid = ({ fields = {}, params = {}, sitecoreContext: {site} }) => {
  const { showFilters = true, pageSize = {} } = fields;
  const isBushsBeansFoodservices = site?.name === 'bushs-beans-foodservices';
  const pageSizeValue =
    (pageSize?.value && !isNaN(parseInt(pageSize.value)) && parseInt(pageSize.value)) || 10;

  if (isExperienceEditorActive()) {
      return (
          <div style={{display: 'block', minHeight: '120px'}}>
            <h1>RECIPE GRID PLACEHOLDER</h1>
      </div>
    );
  }

  if (isBushsBeansFoodservices){
    return (
        <RecipeGridComponent className="foodservices-recipe-grid">
          {!isServer() && <ListingFs type="recipe" site={site.name} pageSize={pageSizeValue} showFilters={!!showFilters?.value} />}
        </RecipeGridComponent>
    );
  } else {
    return (
        <RecipeGridComponent className={site?.name}>
          {!isServer() && <Listing type="recipe" site={site.name} pageSize={pageSizeValue} showFilters={!!showFilters?.value} />}
        </RecipeGridComponent>
    );
  }
};

RecipeGrid.propTypes = {};

export default withSitecoreContext()(RecipeGrid);