import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderWrapper, SocialVideoComponent, TextContent, VideoSliderWrapper } from './SocialVideo.styles.js';
import SocialVideoSlide from '../SocialVideoSlide/SocialVideoSlide.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VerticalLineDecorationSvg from "./VerticalLineDecorationSvg.jsx";

const SocialVideo = (props) => {
    const experienceEditor = isExperienceEditorActive();
    const { fields = {}, params = {}, sitecoreContext } = props;
    const { title = '', copy = '', foregroundMedia = null, backgroundMedia = null, cta = {} } = fields;
    const {
        alignment = 'left',
        contentPanelBackgroundColor = 'red',
        backgroundColor = null,
        contentPanelTitleColor = null,
        contentPanelCopyColor = null,
        contentPanelCtaColor = null,
    } = params;
    const { site } = sitecoreContext;
    const { rendering = {} } = props;
    const slideItems = rendering?.placeholders?.['social-video-content'] || [];

    const slides = Array.isArray(slideItems)
        ? slideItems.map((item, index) => ({ ...item, slideIndex: index }))
        : [];

    const backgroundImageTitle = backgroundMedia.value?.title || '';
    const contentBlock = {
        title,
        copy,
        cta,
        backgroundColor: contentPanelBackgroundColor,
        titleColor: contentPanelTitleColor,
        copyColor: contentPanelCopyColor,
        ctaColor: contentPanelCtaColor,
        cssClass: backgroundImageTitle === 'hero' ? 'hero-bg-lg' : '',
    };

    const settings = {
        dots: false,
        infinite: slides.length > 4,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: slides.length > 3,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sliderClassName = `has-${slides.length}${slides.length < 5 ? ' desktop-adjust' : ''}`;
    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <SocialVideoComponent
                site={site.name}
                alignment={alignment}
                variant={contentPanelBackgroundColor}
                backgroundColor={backgroundColor}
                backgroundImage={backgroundMedia?.value}
                className={`${backgroundImageTitle === 'hero' ? 'hero-bg-lg social-bg-lg' : 'social-bg'}`}
            >
                <TextContent className="social-video-text" variant={contentPanelBackgroundColor}>
                    <VerticalLineDecorationSvg />
                    {title?.value && <h2>{title.value}</h2>}
                    {copy?.value && <p>{copy.value}</p>}
                </TextContent>
                <div>
                    <h1>Start Social Video Slides</h1>
                </div>
                <PlaceholderWrapper>
                    <Placeholder name="social-video-content" rendering={rendering}/>
                </PlaceholderWrapper>
                <div>
                    <h1>End Social Video Slides</h1>
                </div>
            </SocialVideoComponent>
        );
    }

    return (
        <SocialVideoComponent
            site={site.name}
            alignment={alignment}
            variant={contentPanelBackgroundColor}
            backgroundColor={backgroundColor}
            backgroundImage={backgroundMedia?.value}
            className={site.name}
        >
            <TextContent className="social-video-text" variant={contentPanelBackgroundColor}>
                <VerticalLineDecorationSvg />
                {title?.value && <h2>{title.value}</h2>}
                {copy?.value && <p>{copy.value}</p>}
            </TextContent>
            <VideoSliderWrapper>
                <Slider {...settings} className={sliderClassName}>
                    {slides.map((item) => (
                        <SocialVideoSlide key={item.slideIndex} {...item} />
                    ))}
                </Slider>
            </VideoSliderWrapper>
        </SocialVideoComponent>
    );
};

SocialVideo.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.string,
        copy: PropTypes.string,
        foregroundMedia: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        backgroundMedia: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
        cta: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
        }),
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        contentPanelBackgroundColor: PropTypes.oneOf(['red', 'yellow', 'blue', 'transparent']),
        backgroundColor: PropTypes.string,
        contentPanelTitleColor: PropTypes.string,
        contentPanelCopyColor: PropTypes.string,
        contentPanelCtaColor: PropTypes.string,
    }),
};

export default withSitecoreContext()(SocialVideo);