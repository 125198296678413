import styled from 'styled-components';

export const ProductListCarouselComponent = styled.div`
  position: relative;
  min-height: 320px;
  background-color: ${({ theme }) => theme.colours.yellow};
  border-bottom: ${({ theme }) => `2px solid ${theme.colours.brown}`};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => `0 ${theme.spacing(50)}`};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: flex;
    align-items: center;
    min-height: 190px;
    padding: ${({ theme }) => `0 ${theme.spacing(80)}`};
  }
`;

export const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  max-width: 140px;
  max-height: 140px;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  .loading-spinner {
    width: 100%;
    height: auto;
    max-height: 140px;
  }
`;

export const ProductContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(20)};
  overflow: hidden;

  ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
    overflow-x: scroll;
    scroll-snap-type: x;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0 auto;
    padding-bottom: ${({ theme }) => `${theme.spacing(32)}`};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding-top: ${({ theme }) => `${theme.spacing(16)}`};
      padding-bottom: ${({ theme }) => `${theme.spacing(32)}`};
    }

    &:before,
    &:after {
      width: 10px;
      content: '';
    }

    li {
      width: 160px;
      scroll-snap-align: center;
      display: inline-block;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: 250px;
      }

      img {
        display: block;
        width: 100px;
        height: auto;
        margin: 0 auto 16px;
        overflow: hidden;
      }

      & h5 {
        padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 16, right: 16 })};

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 32, right: 32 })};
        }
      }
      & h3 {
        padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 16, right: 16 })};
        margin: 0px 10px;
        color: rgb(141, 47, 24);
        text-align: center;
        text-transform: uppercase;
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 32, right: 32 })};
        }
      }
    }
  }
`;

export const Title   = styled.h2`
  padding-top: ${({ theme }) => theme.spacing(48)};
  color: ${({ theme }) => theme.colours.red};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.spacing(24)};
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  line-height: ${({ theme }) => theme.spacing(32)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    min-width: 160px;
    padding: 0;
    text-align: left;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => `${theme.spacing(30)}`};
  text-align: center;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: 0;
  }

  button:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(10)};
    transform: rotate(180deg);
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin: 0 auto;
    transform: translate(-50%, -50%);
  }
`;
