import styled from 'styled-components';

export const HamburgerComponent = styled.button`
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  width: ${({ theme }) => theme.spacing(24)};
  height: 50px;

  & span {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    height: ${({ theme }) => theme.spacing(3)};
    background-color: #1471d2;
    border-radius: 99px;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
    

  & span:nth-child(1) {
    transform: ${({ active }) => {
      return active ? 'rotate(45deg)' : 'rotate(0)';
    }};
    transform-origin: 0% 0%;
  }

  & span:nth-child(2) {
    transform: ${({ active }) => {
      return active ? 'rotate(-45deg) translate(-5px, 5px)' : 'rotate(0)';
    }};
    transform-origin: 0% 100%;
  }
    &.foodservice-menu-toggle{
		& span{
            background-color: #fff;
        }
    }
`;