import styled from 'styled-components';

export const DestiniFrameWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0)};
  background-color: ${({ theme }) => theme.colours.yellow};

  iframe {
    width: 100%;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing(0)};
  }
`;
