import React from 'react';
import PropTypes from 'prop-types';

import { ProductCard, ProductPromoCard } from './product';
import { RecipeCard, RecipePromoCard } from './recipe';
import { ContentCard } from './content';
import { RelatedCard } from './related';
import { ArticleCard } from './article';
import { PageCard } from './page';

export const RenderCardType = ({ type, promo = false, className, props }) => {
  const types = {
    product: !promo ? <ProductCard classValue={className} {...props} /> : <ProductPromoCard classValue={className} {...props} />,
    recipe: !promo ? <RecipeCard classValue={className} {...props} /> : <RecipePromoCard classValue={className} {...props} />,
    content: <ContentCard {...props} />,
    page: <PageCard {...props} />,
    related: <RelatedCard {...props} />,
    article: <ArticleCard {...props} />,
  };

  if (type in types) return types[type];

  return types.content;
};

RenderCardType.propTypes = {
  type: PropTypes.oneOfType(['product', 'page', 'recipe', 'content', 'related', 'article']),
  promo: PropTypes.bool,
};