import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';

export const TestimonialCarouselSlideComponent = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 200px;
  .testimonial-wrapper {
    width: 1400px;
    max-width: 100%;
    position: relative;
    margin: auto;
    display: block;
    .testimonial-content{
      justify-content: center;
      display: flex;
      flex-flow: column;
      gap: 32px;
	  width: calc(100% - 200px);
      margin: auto;  
      text-align: center;  
      @media (max-width: 1024px){
        gap: 24px;  
        width: calc(100% - 100px);
      }
      .title-heading{
        font-family: 'bordonaro';
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        text-transform: capitalize;
		color: ${({ theme }) => theme.colours.red};
		margin-bottom: 0;
		  @media (max-width: 1024px) {
			  font-size: 32px;
			  line-height: 36px;
			  text-transform: capitalize;
          }
      }
      .quote{
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colours.blue};
		margin-bottom: 0;
		@media (max-width: 1024px) {
          font-size: 24px;
          line-height: 32px;
          text-transform: capitalize;
        }
      }
      .author{
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        text-transform: capitalize;
		color: ${({ theme }) => theme.colours.blue};
        text-align: center;
        margin-bottom: 0;  
        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;