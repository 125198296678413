import React from 'react';
import { useDeviceResize } from 'hooks';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Button from 'components/shared/Button';
import { Text } from 'components/shared/JssOverrides';
import { withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import {TestimonialCarouselSlideComponent, TestimonialSlideControls} from './TestimonialCarouselSlide.styles.js';

const TestimonialCarouselSlide = ({ sitecoreContext, setCurrentSlide, totalSlides, rendering, ...props }) => {
    const fields = rendering?.fields;
    const params = rendering?.params;
    const device = useDeviceResize();
    const experienceEditor = isExperienceEditorActive();

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <TestimonialCarouselSlideComponent>
                <div className="testimonial-content">
                    <h3 style={{fontSize: '18px'}}>Testimonial Carousel Slide</h3>
                    {fields?.testimonial && <Text tag="p" field={fields?.testimonial} />}
                    {fields?.testimonial && <Text tag="p" className="author" field={fields?.author} />}
                </div>
            </TestimonialCarouselSlideComponent>
        );
    }

    return (
        <TestimonialCarouselSlideComponent className="slide">
            <div className="testimonial-wrapper">
                <div className="testimonial-content">
                    {fields?.title && <Text className="title-heading" tag="h2" field={fields?.title} />}
                    {fields?.testimonial && <Text className="quote" tag="p" field={fields?.testimonial} />}
                    {fields?.author && <Text tag="p" className="author" field={fields?.author} />}
                </div>
            </div>
        </TestimonialCarouselSlideComponent>
    );
};

TestimonialCarouselSlide.propTypes = {};

export default withSitecoreContext()(TestimonialCarouselSlide);