import React from 'react';
import PropTypes from 'prop-types';
import { MainNavCardLinkComponent, LinkWrapper } from './MainNavCardLink.styles.js';

import Link from 'components/shared/Link';
import Icon from "../../../shared/Icon";

const MainNavCardLink = ({ text, src, ...props }) => {
  return (
    <MainNavCardLinkComponent {...props}>
      <Link path={`${props.path}${props.query ? props.query : ''}`}>
        <LinkWrapper>
          <div className="image">
            <img className="card-image" src={src} alt={`view ${text} page`}/>
            <div className="view">View</div>
          </div>
          <div className="card-label">
            {text}
            <span className="circle"><Icon icon="chevron-right" size="small"/></span>
          </div>
        </LinkWrapper>
      </Link>
    </MainNavCardLinkComponent>
  );
};

MainNavCardLink.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
};

export default MainNavCardLink;