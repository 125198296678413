import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useChain, useSpring, useTrail, animated } from 'react-spring';
import { LandingHeroComponent, TextWrapper, ImageWrapper } from './LandingHero.styles.js';


const LandingHero = ({ fields = {}, sitecoreContext: {site={}}, params = {} }) => {
  const { heroImage = {}, heroText = {} } = fields;
  const { backgroundColor = 'yellow',surveyPage = '' } = params;
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const textRef = useRef(null);


  const config = { mass: 36, tension: 380, friction: 180 };

  const [imageAnimationProps, setImageAnimation] = useSpring(() => ({
    bottom: '-100%',
    from: { bottom: '-100%' },
    config,
    ref: imageRef,
  }));

  const textTrail = useTrail(3, {
    from: { opacity: 0 },
    to: { opacity: imageLoaded ? 1 : 0 },
    config,
    ref: textRef,
  });

  useEffect(() => {
    if (imageLoaded) {
      setImageAnimation({ bottom: '-3%' });
    }
  }, [imageLoaded, setImageAnimation]);

  useEffect(() => {
    // adding fallback incase onLoad isn't triggered
    setTimeout(() => {
      setImageLoaded(true);
    }, 500);
  }, []);

  useChain([textRef, imageRef]);
  if (surveyPage === 'true') {
    return (
        <LandingHeroComponent backgroundColor={backgroundColor}>
          <TextWrapper backgroundColor={backgroundColor}>
            {textTrail.map(({ ...rest }, index) => (
                <animated.div key={`trail-${index}`} className="trails-text" style={{ ...rest }}>
                  <Text field={heroText} tag={index === 0 ? 'h1' : 'span'} className="vanity" />
                </animated.div>
            ))}
          </TextWrapper>
          <ImageWrapper>
            <animated.div className="image-wrapper" style={imageAnimationProps}>
              <Image onLoad={() => setImageLoaded(true)} field={heroImage} />
            </animated.div>
          </ImageWrapper>
        </LandingHeroComponent>
    );
  }

  return (
    <LandingHeroComponent backgroundColor={backgroundColor}>
      <TextWrapper backgroundColor={backgroundColor}>
        {textTrail.map(({ ...rest }, index) => (
          <animated.div key={`trail-${index}`} className="trails-text" style={{ ...rest }}>
            <Text field={heroText} tag={index === 0 ? 'h1' : 'span'} className="vanity" />
          </animated.div>
        ))}
      </TextWrapper>
      <ImageWrapper>
        <animated.div className="image-wrapper" style={imageAnimationProps}>
          <Image onLoad={() => setImageLoaded(true)} field={heroImage} />
        </animated.div>
      </ImageWrapper>
    </LandingHeroComponent>
  );
};

LandingHero.propTypes = {
  fields: PropTypes.shape({
    heroImage: PropTypes.object,
    heroText: PropTypes.object,
  }),
  params: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
};

export default withSitecoreContext()(LandingHero);