import styled from 'styled-components';

const getVariantColours = ({variant, theme = {}, site} = {}) => {
    // console.log('theme', theme);
    // console.log('site', site);
    // console.log('variant', variant);
    const variants = {
        red: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.red,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellow,
        },
        yellow: {
            headingColor: theme.colours?.red,
            background: theme.colours?.yellow,
            title: theme.colours?.red,
            text: theme.colours?.adaBrown,
            button: theme.colours?.brown,
        },
        brown: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.brown,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellow,
        },
        blue: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.blue,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellowBlue,
        },
    };

    if (site && site.name === 'bushs-beans-foodservices') {
        return variants.blue;
    }

    if (variant in variants) return variants[variant];

    return variants.red;
};

export const ProductCTAWrapper = styled.section`
    width: 1550px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
	@media (max-width: 1600px) {
        width: 95%;
		max-width: 95%;
	}
	@media (max-width: 980px){
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 40px;
    }
`;

export const ProductCTAContainer = styled.div`
	display: grid;
	grid-template-columns: 63% 37%;
	align-items: center;
	background-color: ${({theme}) => theme.colours.blue};
	padding: 0 100px;
	margin: 40px auto;
    gap: 20px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 50px;
		background-color: ${({theme}) => theme.colours.tan};
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 50px;
		background-color: ${({theme}) => theme.colours.tan};
	}

	@media (max-width: 1400px) {
		margin: 80px auto;
	}

	@media (max-width: 1200px) {
		margin: 50px auto;
    }

  @media (max-width: 980px) {
      grid-template-columns: 1fr;
	  background-color: ${({theme}) => theme.colours.tan};
      padding: 0;
      width: 100%;
      gap: 0;
      margin: 0;
      &:before{
          display: none;
      }
      &:after{
          display: none;
      }
  }
`;


export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
    justify-content: center;
    position: relative;
    z-index: 5;
    padding: 90px 0;
	@media (max-width: 980px) {
		order: 1;
        padding: 100px 20px 40px 20px;
        background-color: ${({theme}) => theme.colours.blue};
	}
`;

export const HeadingWrapper = styled.h1`
	font-size: 72px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.1;
	text-transform: uppercase;
    text-align: left;
	color: ${({ theme }) => theme.colours.yellow};
    margin-bottom: 15px;
	@media (max-width: 1500px) {
		font-size: 60px;
	}
	@media (max-width: 1300px) {
		font-size: 50px;
	}
	@media (max-width: 980px) {
		text-align: center;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 1.2;
		text-transform: uppercase;
	}
	sup{
		position: relative;
		left: 1px;
		font-weight: 400;
		font-size: 50%;
		line-height: 0.5em;
		vertical-align: super;
		top: 0;
		font-family: 'apercu pro';
	}
`;

export const CopyWrapper = styled.div`
    p{
		position: relative;
		z-index: 5;
		font-size: 18px;
		color: ${({ theme }) => theme.colours.white};
		@media (max-width: 980px) {
			font-size: 16px;
            text-align: center;
		}
    }
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 32px;
	@media (max-width: 980px) {
        justify-content: center;
	}
    @media (max-width: 575px) {
		flex-flow: column;
		justify-content: center;
		align-items: center;
        gap: 16px
    }
`;

export const ImageContainerWrapper = styled.div`
    position: relative;
    z-index: 5;
	img {
		width: 100%;
		object-fit: contain;
    }
	@media (max-width: 980px) {
		order: 0;
        z-index: 20;
        img{
            width: 300px;
            height: auto;
            max-width: 100%;
            margin: 0 auto;
            display: block;
			transform: translate(0px, 60px);
        }
	}
`;