import styled from 'styled-components';

import { hexToRgba } from 'helpers/colorHelpers';

export const SocialShareComponent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(80)} 0;
  background-color: ${({ theme }) => hexToRgba(theme.colours.adaTan, 0.25)};

  & h3 {
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(28)};
    font-weight: 400;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(36)})`};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(48)};
    }
  }

  & .social-links {
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    li {
      padding: 0 ${({ theme }) => theme.spacing(16)};
      margin: 0 5px;  
      .button-circle{
          overflow: hidden;
      }
      button{
          .facebook{
			  transform: scale(1.2) translate(0%, 18%)
          }
      }  
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: 0;
        padding: 0 ${({ theme }) => theme.spacing(10)};
      }
        
    }
  }
`;