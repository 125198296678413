import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Button from 'components/shared/Button';
import { Overlay, Content, ButtonWrapper } from './Modal.style';

const ModalContainer = ({ id, children, callback }) => {
  const modalRoot = document.getElementById(id);
  const element = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(element);
    disableBodyScroll(modalRoot);

    return () => {
      modalRoot.removeChild(element);
      enableBodyScroll(modalRoot);
    };
  });

  return createPortal(
    <>
      <ButtonWrapper>
        <Button type="circle" size="large" icon="cross" variant="tan" onClick={() => callback()} />
      </ButtonWrapper>
      <Overlay onClick={() => callback()} />
      <Content>{children}</Content>
    </>,
    element
  );
};

// SSR check
const Modal = ({ id = 'modal-container', children, className = '', callback = () => {} }) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    return (
      <ModalContainer id={id} className={className} callback={callback}>
        {children}
      </ModalContainer>
    );
  }
  return null;
};

export default Modal;
