import React from 'react';
import PropTypes from 'prop-types';
import { PromoBoxComponent, AuthorWrap, PromoBoxInner } from './ArticlePromo.styles.js';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks';

const ArticlePromoBox = ({ title, copy, author, date, itemId }) => {
  const device = useDeviceResize();

  return (
    <PromoBoxComponent>
      <PromoBoxInner>
        {title && <Text tag="h1" field={title} />}
        {copy && <Text tag="p" field={copy} />}
        <AuthorWrap>
          {author?.length && <p>{author}</p>}
          {date?.length && <p>{date}</p>}
        </AuthorWrap>
        {!isServer() && <Button type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}
      </PromoBoxInner>
    </PromoBoxComponent>
  );
};

ArticlePromoBox.propTypes = {
  title: PropTypes.object,
  copy: PropTypes.object,
  backgroundColor: PropTypes.string,
};

export default ArticlePromoBox;
