import styled from "styled-components";

export const BeanTv = styled.svg`

	.st0 {
		fill: #bb5400
	}

	.st11 {
		fill: #ffca2e
	}

	.st12 {
		fill: #f2ebdf
	}

	.st37 {
		fill: #0e0048
	}
`;