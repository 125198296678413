import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { Wrapper, Table } from './NutritionTable.styles';

import translate from 'helpers/translate';

const nutritionStaticData = [
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Calories',
    fallback: 'Calories',
    key: 'nutritionCalories',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.CaloriesFromFat',
    fallback: 'Calories From Fat',
    key: 'nutritionCaloriesFromFat',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Cholesterol',
    fallback: 'Cholesterol',
    key: 'nutritionCholesterol',
    percentageKey: 'nutritionCholesterolPercentage',
  },

  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Fat',
    fallback: 'Fat',
    key: 'nutritionFat',
    percentageKey: 'nutritionTotalFatPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.SaturatedFat',
    fallback: 'Saturated Fat',
    key: 'nutritionSaturatedFat',
    percentageKey: 'nutritionSatFatPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.TransFat',
    fallback: 'Trans Fat',
    key: 'nutritionTransFat',
    percentageKey: 'nutritionTransFatPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.AddedSugars',
    fallback: 'Added Sugars',
    key: 'nutritionAddedSugars',
    percentageKey: 'nutritionAddedSugarsPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Carbs',
    fallback: 'Carbohydrates',
    key: 'nutritionTotalCarbohydrates',
    percentageKey: 'nutritionCarbsPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.DietaryFiber',
    fallback: 'Dietary Fiber',
    key: 'nutritionDietaryFiber',
    percentageKey: 'nutritionDietaryFiberPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Protein',
    fallback: 'Protein',
    key: 'nutritionProtein',
    percentageKey: 'nutritionProteinPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Sodium',
    fallback: 'Sodium',
    key: 'nutritionSodium',
    percentageKey: 'nutritionSodiumPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Calcium',
    fallback: 'Calcium',
    key: 'nutritionCalcium',
    percentageKey: 'nutritionCalciumPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Folate',
    fallback: 'Folate',
    key: 'nutritionFolate',
    percentageKey: 'nutritionFolatePercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Iron',
    fallback: 'Iron',
    key: 'nutritionIron',
    percentageKey: 'nutritionIronPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Magnesium',
    fallback: 'Magnesium',
    key: 'nutritionMagnesium',
    percentageKey: 'nutritionMagnesiumPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Niacin',
    fallback: 'Niacin',
    key: 'nutritionNiacin',
    percentageKey: 'nutritionNiacinPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Potassium',
    fallback: 'Potassium',
    key: 'nutritionPotassium',
    percentageKey: 'nutritionPotassiumPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.Thiamin',
    fallback: 'Thiamin',
    key: 'nutritionThiamin',
    percentageKey: 'nutritionThiaminPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.VitaminA',
    fallback: 'Vitamin A',
    key: 'nutritionVitaminA',
    percentageKey: 'nutritionVitaminAPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.VitaminB6',
    fallback: 'Vitamin B6',
    key: 'nutritionVitaminB6',
    percentageKey: 'nutritionVitaminB6Percentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.VitaminC',
    fallback: 'Vitamin C',
    key: 'nutritionVitaminC',
    percentageKey: 'nutritionVitaminCPercentage',
  },
  {
    dictionary: 'BushsBeans.Recipes.NutritionalInformation.VitaminD',
    fallback: 'Vitamin D',
    key: 'nutritionVitaminD',
    percentageKey: 'nutritionVitaminDPercentage',
  },
];

const checkNutritionObject = ({ object, key }) => (key in object && object[key] ? object[key] : '');

export const NutritionTable = ({ sitecoreContext = {}, nutritionalInformation = {} }) => {
  const { viewBag = {} } = sitecoreContext;
  return (
    <Wrapper>
      <Table>
        <thead className="heading">
          <th>
            {translate(
              viewBag,
              'BushsBeans.Recipes.NutritionalInformation.TableTitle',
              'Nutritional Information'
            )}
          </th>
          <th></th>
          <th>%</th>
        </thead>
        {nutritionStaticData &&
        // eslint-disable-next-line array-callback-return
          nutritionStaticData?.map((data, index) => {
            const { dictionary, fallback, key, percentageKey } = data;
            const nutritionKey = checkNutritionObject({ key: key, object: nutritionalInformation });
            const nutritionPercentKey = checkNutritionObject({
              key: percentageKey,
              object: nutritionalInformation,
            });
            if (nutritionKey || nutritionPercentKey) {
              return (
                <tr key={`nutrition-tablet-${index}`}>
                  <td>{translate(viewBag, dictionary, fallback)}</td>
                  <td>{nutritionKey}</td>
                  <td>{nutritionPercentKey}</td>
                </tr>
              );
            }
          })}
      </Table>
    </Wrapper>
  );
};

export default withSitecoreContext()(NutritionTable);
