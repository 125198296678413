import React from 'react';
import PropTypes from 'prop-types';
import {
    AlchemerSurveyWrapper,
    ContentAlchemerComponent,
} from './AlchemerSurvey.styles.js';
import {Text} from "../../shared/JssOverrides";

const AlchemerSurvey = ({ backgroundColor, embedurl, id, alignment }) => {
    return (
        <AlchemerSurveyWrapper id={id} className="over-the-top-survey-wrapper">
            <ContentAlchemerComponent backgroundColor={backgroundColor} alignment={alignment}>
                <div dangerouslySetInnerHTML={{
                    __html: `
              <script type="text/javascript">
                document.write('<script src="' + ('http' + (("https:" == document.location.protocol) ? "s" : "") + '://' + '${embedurl}?__output=embedjs&__ref=' + escape(document.location.href)) + '" type="text/javascript"></scr' + 'ipt>');
              </script>
              <style>.sg-survey{display:none;}.sg-header{display:none;}</style>
            `,
                }} />
            </ContentAlchemerComponent>
        </AlchemerSurveyWrapper>
    );
};

AlchemerSurvey.propTypes = {
    backgroundColor: PropTypes.string,
    embedurl: PropTypes.string.isRequired,
    id: PropTypes.string,
    alignment: PropTypes.string,
};

export default AlchemerSurvey;