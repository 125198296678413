import styled from 'styled-components';

export const SocialVideoSlideComponent = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	.video-holder{
		position: relative;
        width: 300px;
        background-size: cover;
        background-position: center center;
		display: flex;
		justify-content: center;
		.video-div {
			background-color: #000;
			position: relative;
			width: 100%;
			padding-top: 177%;
			border-radius: 20px;
			background-position: center center;
			overflow: hidden;
			cursor: pointer;
            background-size: cover;
            max-width: 100%;
			iframe {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
			.inner-content {
				height: 100%;
				width: 100%;
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: end;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				justify-content: end;

				img {
					align-self: center;
					display: block;
					margin: auto;
				}
				svg {
					align-self: center;
					display: block;
					margin: auto;
				}
				.play-video-button {
					width: 128px;
					height: 128px;
					margin: auto;
					position: absolute;
					border: 0px;
					background: none;
					inset: 0px;
					cursor: pointer;
					padding: 0;
				}
			}
		}
        @media (max-width: 1400px){
			margin: 0 15px;
        }
		@media (max-width: 1050px){
			.video-div {
				width: 240px;
			}
		}
	}
`;

export const ContentWrapper = styled.div`
	z-index: 10;
	box-sizing: border-box;
	width: 85%;
	height: auto;
	margin: 0 auto;
`;

export const Media = styled.div`
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:before {
		display: block;
		width: 100%;
		padding-top: calc((9 / 16) * 100%);
		content: '';
	}

	.video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		object-fit: contain;
	}
`;

export const BackgroundImage = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		max-height: 100%;
		object-fit: cover;
	}
`;