import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/shared/Carousel';
import { isExperienceEditorActive, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import {
  MediaCarouselComponent,
  PlaceholderWrapper,
  ExperienceEditorCarouselWrapper,
} from './MediaCarousel.styles.js';
import MediaCarouselImageSlide from 'components/MediaCarouselImageSlide';
import MediaCarouselVideoSlide from 'components/MediaCarouselVideoSlide';

const MediaCarousel = ({ sitecoreContext = {}, params = {}, rendering = {} }) => {
  const { arrowStyle = 'red', backgroundColor = 'yellow' } = params;

  const placeholderData = rendering?.placeholders['media-carousel-content'] || [];

  const RenderComponent = ({ component = {}, componentName = '' }) => {
      const slideComponents = {
          MediaCarouselImageSlide: <MediaCarouselImageSlide {...component} />,
          MediaCarouselVideoSlide: <MediaCarouselVideoSlide {...component} />,
      };

    if (componentName in slideComponents) return slideComponents[componentName];

    return null;
  };

  if (isExperienceEditorActive() || sitecoreContext?.pageEditing) {
    return (
      <ExperienceEditorCarouselWrapper className="media-carousel">
        <div className="divider">
          <h1>Start Media Carousel</h1>
        </div>
        <PlaceholderWrapper>
          <Placeholder name="media-carousel-content" rendering={rendering} />
        </PlaceholderWrapper>
        <div className="divider">
          <h1>End Media Carousel</h1>
        </div>
      </ExperienceEditorCarouselWrapper>
    );
  }

  return (
    <MediaCarouselComponent>
      <Carousel arrowStyle={arrowStyle} backgroundColor={backgroundColor}>
        {placeholderData &&
          placeholderData?.map((component, index) => (
            <RenderComponent
              key={`renderSlides-${index}`}
              component={component}
              componentName={component?.componentName}
            />
          ))}
      </Carousel>
    </MediaCarouselComponent>
  );
};

MediaCarousel.propTypes = {
    params: PropTypes.shape({
        arrowStyle: PropTypes.string,
        backgroundColor: PropTypes.string,
    }),
    rendering: PropTypes.object,
};

export default withSitecoreContext()(MediaCarousel);
