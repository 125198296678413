import React from 'react';
import {DukeInBean} from './DukeInBeanSvg.styles.js';

const DukeInBeanSvg = ({ className }) => {
    return (
        <DukeInBean className={className} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 864 589.3" style="enable-background:new 0 0 864 589.3;">
            <g>
	<path className="st23" d="M277,123.2c0.9-11.8,4.3-31.2,17.1-50c6-8.9,14-20.3,29.1-26c7.5-2.8,14.4-3.3,19-3.2
		c5.9-5.9,38.6-37.6,90.6-37.4c52.3,0.3,84.9,32.7,90.6,38.6c3.8-0.4,9.7-0.6,16.5,1.3c10.3,2.8,16.7,8.7,20.3,12
		c19.9,18.4,27.3,58.9,27.9,62.1c-27.3,13.9-82.2,37.4-155.2,38C359.8,159.3,304.5,136.7,277,123.2z"/>
                <path className="st1" d="M238.9,127.8c-9.5,0.1-19,0.7-28.7,2C112,142.8,34,222.7,23.5,320.6c-14,131,89.2,241.4,217.8,240.7l375.7-2.1
		c113.6-0.6,211.5-85.7,220.8-198.3c10.7-128.6-91.5-236-218.3-235.2c-45.5,0.3-87.6,14.4-122.4,38.3
		c-20.7,14.2-44.6,21.6-68.4,21.7c-22.9,0.1-45.8-6.4-65.7-20C327.7,141.5,284.9,127.5,238.9,127.8"/>
                <path className="st23" d="M238.8,109.5c-10.2,0.1-20.6,0.8-31,2.1l0,0c-26.6,3.5-51.9,11.6-75,23.4c-34.6,17.7-64.3,43.6-86.4,75.1
		c-22.1,31.5-36.8,68.6-41,108.5l0,0.4l0-0.4c-0.9,8.7-1.4,17.3-1.4,25.8c0,32.5,6.6,63.5,18.6,91.6c18,42.2,47.9,78.1,85.6,103.4
		c18.8,12.7,39.6,22.7,61.8,29.5c22.2,6.9,45.8,10.6,70.1,10.6c0.5,0,0.9,0,1.4,0l375.7-2.1c30.7-0.2,60.3-6,87.6-16.7
		c41-15.9,76.8-42.5,103.5-76.6c13.3-17.1,24.3-36,32.5-56.5c8.2-20.4,13.4-42.4,15.3-65.3c0.6-6.7,0.8-13.4,0.8-20
		c0-32.5-6.6-63.5-18.6-91.6c-18-42.2-47.9-78.1-85.6-103.4c-37.7-25.3-83.2-40.1-131.9-40.1c-0.5,0-0.9,0-1.4,0h0
		c-24.6,0.1-48.4,4-70.6,11.1c-22.3,7.1-43.1,17.4-62,30.4l0,0c-17.7,12.2-38,18.4-58.1,18.5c-0.2,0-0.4,0-0.6,0
		c-9.6,0-19.1-1.4-28.3-4.2c-9.2-2.8-18.1-7-26.4-12.7c-19-12.9-39.9-23.2-62.3-30.2c-22.4-7-46.2-10.8-70.9-10.8
		C239.7,109.5,239.2,109.5,238.8,109.5L239,146c0.4,0,0.8,0,1.2,0c20.9,0,41,3.2,60,9.1c18.9,5.9,36.6,14.6,52.7,25.5
		c11.4,7.8,23.7,13.6,36.4,17.5c12.7,3.9,25.8,5.8,38.9,5.8c0.2,0,0.5,0,0.8,0c27.5-0.2,55-8.7,78.7-25l0,0
		c15.9-11,33.5-19.7,52.4-25.7c18.8-6,38.9-9.3,59.8-9.4h0c0.4,0,0.8,0,1.2,0c27.6,0,53.8,5.6,77.6,15.6
		c35.8,15,66.3,40.2,87.8,71.9c10.8,15.8,19.3,33.3,25.1,51.9c5.8,18.6,9,38.5,9,59.1c0,5.6-0.2,11.2-0.7,16.9
		c-2.1,25.7-9.3,49.8-20.5,71.5c-16.8,32.6-42.7,60-74.3,79.3c-31.6,19.3-68.7,30.4-107.9,30.7L241.2,543h0c-0.4,0-0.8,0-1.2,0
		c-27.6,0-53.8-5.6-77.7-15.6c-35.8-15.1-66.3-40.2-87.8-71.9c-10.8-15.8-19.3-33.3-25.1-51.9c-5.8-18.6-9-38.5-9-59.1
		c0-7.2,0.4-14.5,1.2-21.9c2.4-22.3,8.6-43.7,18.1-63.3c14.2-29.5,35.6-55.1,61.8-74.5c26.3-19.4,57.3-32.4,91-36.9l0.6-0.1
		l-0.6,0.1c8.9-1.2,17.7-1.8,26.4-1.8L238.8,109.5z"/>
                <path className="st10" d="M713.7,335.2c1.2,0.1,2.1,1,2.5,2.4c1.2,4.4,2.4,8.9,3.6,13.3c4,13.7,12.6,23,25.7,27.3c4,1.3,8,2.5,12,3.7
		c1.5,0.5,2.3,1.3,2.3,2.5c0,1.3-0.7,2.1-2.3,2.6c-4.7,1.4-9.4,2.5-14,3.9c-12.4,3.9-20.3,12-23.9,24.6c-1.2,4.1-2.3,8.2-3.5,12.3
		c-0.6,2.1-2.7,2.7-4.1,1.2c-0.4-0.5-0.7-1.2-0.9-1.8c-1.3-4.7-2.5-9.4-3.9-14c-4-13.1-12.2-22.2-24.6-27.8
		c-4.3-1.9-8.6-3.7-12.9-5.6c-1.6-0.7-2.4-1.7-2.3-3c0-1.3,0.8-2,2.4-2.2c5-0.7,10.1-1.2,15-2.4c11.8-2.9,19.3-10.8,22.8-22.8
		c1.2-4,2.3-8.1,3.5-12.1C711.7,335.8,712.6,335.1,713.7,335.2z"/>
                <path className="st10" d="M676.4,418.2c0.8,0.1,1.4,0.7,1.7,1.7c0.8,3.1,1.6,6.2,2.5,9.3c2.7,9.6,8.6,16.3,17.6,20
		c2.7,1.1,5.5,2.2,8.2,3.3c1.1,0.4,1.6,1,1.6,1.9c0,0.9-0.5,1.4-1.6,1.6c-3.2,0.6-6.4,1.1-9.6,1.8c-8.5,2-13.9,7.2-16.4,15.7
		c-0.8,2.8-1.6,5.5-2.4,8.3c-0.4,1.4-1.9,1.7-2.8,0.6c-0.3-0.3-0.5-0.8-0.6-1.3c-0.9-3.2-1.7-6.5-2.6-9.8
		c-2.7-9.1-8.4-15.6-16.8-19.8c-2.9-1.4-5.9-2.8-8.8-4.1c-1.1-0.5-1.6-1.2-1.6-2.1c0-0.9,0.5-1.3,1.6-1.5c3.4-0.4,6.9-0.6,10.3-1.3
		c8.1-1.7,13.2-6.8,15.6-15c0.8-2.7,1.6-5.5,2.4-8.2C674.9,418.5,675.6,418.1,676.4,418.2z"/>
                <path className="st10" d="M161.6,216.8c-0.9,0.1-1.7,0.8-2,1.9c-1,3.5-1.9,7-2.9,10.5c-3.1,10.8-9.9,18.1-20.2,21.5
		c-3.1,1-6.3,2-9.5,2.9c-1.2,0.4-1.8,1-1.8,2c0,1,0.6,1.7,1.8,2.1c3.7,1.1,7.4,1.9,11,3.1c9.7,3.1,16,9.5,18.8,19.4
		c0.9,3.2,1.8,6.5,2.7,9.7c0.5,1.6,2.1,2.1,3.2,0.9c0.3-0.4,0.5-0.9,0.7-1.4c1-3.7,1.9-7.4,3-11.1c3.1-10.3,9.6-17.5,19.3-21.9
		c3.4-1.5,6.8-2.9,10.1-4.4c1.2-0.5,1.9-1.3,1.8-2.3c0-1-0.6-1.5-1.9-1.7c-4-0.6-8-1-11.8-1.9c-9.3-2.3-15.2-8.5-17.9-18
		c-0.9-3.2-1.8-6.4-2.7-9.6C163.3,217.3,162.6,216.8,161.6,216.8z"/>
                <path className="st10" d="M191.1,282.2c-0.6,0.1-1.1,0.6-1.3,1.4c-0.7,2.4-1.3,4.9-2,7.3c-2.2,7.5-6.8,12.8-13.9,15.7
		c-2.2,0.9-4.3,1.7-6.5,2.6c-0.8,0.3-1.2,0.8-1.2,1.5c0,0.7,0.4,1.1,1.3,1.3c2.5,0.5,5.1,0.9,7.5,1.4c6.7,1.6,10.9,5.7,12.9,12.3
		c0.6,2.2,1.2,4.3,1.9,6.5c0.3,1.1,1.5,1.4,2.2,0.5c0.2-0.3,0.4-0.7,0.5-1c0.7-2.6,1.3-5.1,2.1-7.7c2.1-7.2,6.6-12.3,13.3-15.6
		c2.3-1.1,4.6-2.2,6.9-3.3c0.8-0.4,1.3-0.9,1.3-1.6c0-0.7-0.4-1-1.3-1.1c-2.7-0.3-5.5-0.5-8.1-1c-6.3-1.3-10.4-5.4-12.3-11.8
		c-0.6-2.1-1.2-4.3-1.9-6.4C192.2,282.4,191.7,282.1,191.1,282.2z"/>
                <path className="st24" d="M563.3,185.8c-0.7,3.5-2,6.2-3,8.1c0.4,2.9,0.8,7.3,0,12.6c-0.7,4.6-1.5,9.9-5.1,14.1
		c-6.6,7.8-18.3,6.8-20.1,6.6c0.2,4.5,1.2,10.9,4,18.2c4.3,10.4,10.7,17.2,14.6,20.6c-0.8,0.5-3.2,1.9-6.6,1.5
		c-3.2-0.3-5.3-1.9-6-2.5c2.3,3.8,5.2,9.8,6.5,17.6c1.8,10.4-0.3,19.2-2.3,25.1c-0.8,2.2-1.6,4-2.2,5.2c3,3.8,7.1,9.6,10.4,17.5
		c1.6,3.9,3.1,8.3,4.2,13.2c1.8,8.3,2,16,1.3,22.9c-0.3,4-1,7.6-1.8,10.9c-1.8,7.5-4.2,13.1-5.7,16.1c4.4,30.8,4.1,55.6,3.1,73
		c-1.2,18-3.7,35.4-6.7,74.8c-19,0-38.1,0.1-57.2,0.1c-4.7,0-9.5,0-14.2,0.1c-15.7,0.1-31.5,0.1-47.2,0.3
		c-11.7,0.1-23.4,0.1-35.1,0.2c-5.1,0-10.1,0.1-15.2,0.1c-19.9,0.1-39.7,0.3-59.5,0.6c-2.9-40.5-5.6-58-6.7-76.2
		c-1.2-17.4-1.4-42.3,3-73c-2.5-5.4-8.6-19.5-7.6-37.7c0.1-2.8,0.4-5.7,1-8.7c0.2-1,0.4-2.1,0.6-3.2l0-0.1l0-0.2
		c0.1-0.4,0.1-0.7,0.3-1c0-0.3,0.1-0.6,0.2-0.9v0c0.1-0.6,0.3-1.2,0.4-1.7c1-3.8,2.3-7.2,3.7-10.4c0.9-2.2,2-4.3,3.1-6.2
		c0.8-1.4,1.6-2.7,2.3-4c1.6-2.6,3.2-4.7,4.6-6.4c-1-2.1-2.5-5.5-3.7-9.9c-1.4-5.4-2.2-12.5-1-20.4c1.3-7.8,4.3-13.8,6.6-17.6
		c-0.7,0.7-2.9,2.3-6,2.5c-3.4,0.3-5.8-1-6.6-1.5c3.9-3.4,10.4-10.2,14.5-20.6c3-7.3,3.8-13.7,4-18.2c-1.8,0.2-13.6,1.3-20.1-6.6
		c-3.5-4.2-4.4-9.5-5-14.1c-0.8-5.3-0.5-9.7,0-12.6c-1-1.8-2.3-4.6-3-8.1c-0.9-4.5-0.4-8.3,0-10.6c8.9-16.8,12-30.9,13-40.8
		c1.2-10.1,0.8-20.2,6.1-34.2c2.1-5.6,4.3-11.2,9-16.7c9.5-11,22.6-13.7,28.2-14.5c4.5-4.7,27-27.9,63.5-29.3
		c3.4-0.1,6.6-0.1,9.7,0.1c3-0.2,6.2-0.2,9.6-0.1c36.5,1.4,59.1,24.5,63.4,29.3c5.8,0.8,18.7,3.5,28.2,14.5c4.8,5.5,7,11.2,9.1,16.7
		c5.3,14,4.8,24.1,6,34.2c1.1,9.9,4.3,23.9,13.1,40.8C563.8,177.5,564.2,181.3,563.3,185.8"/>
                <path className="st25" d="M324.1,385.1c0.1,5.3,0.8,12.2,3,19.9c2.9,10.2,7.2,18.1,10.7,23.4c0.5-3.1,1-6.2,1.5-9.2
		c1.8,4.8,4.1,10.6,7.3,17c8,16.4,13.8,21.9,18,35c1.9,6,3,11.8,3.4,17.5c1.6-1.7,3.7-4.4,5.4-8.3c1.2-2.8,1.7-5.4,1.9-7.3
		c2.3,5.8,6.1,12.9,12.2,20c16.4,18.9,38.2,23.5,46.2,24.8c0.4-1.3,1.2-3.4,2.4-5.8c5.3-10.6,13.2-16,34.1-30.7
		c2.9-2,10.2-7.1,14.6-10.2c0.1,2.1,0.1,4.8-0.5,7.8c-0.5,2.7-1.2,5-1.9,6.8c5.3-4.9,10.7-10.6,16.1-17
		c18.8-22.8,28.7-46.4,34.1-64.2C462.9,398.1,393.5,391.6,324.1,385.1"/>
                <path className="st26" d="M430.7,143.4c-6.4-2.7-13.6-1.8-18.6,2.5c-6.8,5.8-5.8,14.7-5.6,16.3c1,7.3,5.9,11.6,8.7,14
		c6.5,5.6,13.7,6.9,16.9,7.2c2.6-0.4,12-2.3,18.2-10.7c1.8-2.5,6-8.1,4.8-15.7c-0.2-1.1-1.6-9.3-9.4-13.3
		C438.5,140.1,431.7,143,430.7,143.4z"/>
                <path className="st25" d="M433.1,192.5c-4.2,5.8-13.1,16-26.8,19.5c-4.1,1-11,2.8-15.4-0.8c-1.9-1.5-3.3-5.2-6.1-12.4
		c-2.1-5.4-2.9-8.6-6.1-10.9c-1.7-1.3-3.5-1.8-4.8-2c-0.2,1.6-0.3,3.2-0.5,4.8c1.1,0,2.2,0,3.3,0c1.6,3.3,4.1,8.2,7.1,14.2
		c7.7,15.2,8.6,16.7,9.6,18c7.7,10.2,19.5,13.5,24.3,14.9c6,1.7,23.8,5.6,40.2-4.3c2.7-1.6,10.7-6.9,16.2-16.4
		c2.5-4.3,4.4-10,8.1-21.2c1.4-4.3,2.6-7.9,3.3-10.4c0.9-0.3,1.9-0.7,2.8-1c0-1.8,0-3.5,0-5.3c-1.3,0.1-3.3,0.4-4.8,1.8
		c-1.8,1.7-2.1,4.1-2.3,5.1c-0.6,4.3-2.3,16.7-7,21.7c-3.8,3.9-10,3.6-13.7,3.4C444.5,210.1,434.7,195,433.1,192.5z"/>
                <path className="st26" d="M463,120.2c-1.2-8,1.9-15.3,7.3-18c4.1-2.1,8.5-0.9,9.8-0.6c8.4,2.2,11.9,10.3,12.4,11.5
		c-2.6-0.7-5.2-1.5-7.9-2.2c0,0.7,0.3,5.2-2.8,7.9c-3.5,3-10.2,2.6-14.9-2.2C465.7,117.8,464.3,119,463,120.2z"/>
                <path className="st26" d="M399.3,120.2c1.2-8-1.9-15.3-7.3-18c-4.1-2.1-8.5-0.9-9.8-0.6c-8.4,2.2-11.9,10.3-12.4,11.5
		c2.6-0.7,5.2-1.5,7.9-2.2c0,0.7-0.3,5.2,2.8,7.9c3.5,3,10.2,2.6,14.9-2.2C396.6,117.8,397.9,119,399.3,120.2z"/>
                <path className="st23" d="M261.7,325c3.4,0.1,8.4-0.3,13.5-3.2c5.6-3.2,5.8-6.3,11.8-9.5c2.1-1.1,8.5-4.5,15.3-2.5
		c5.6,1.7,8.3,6.2,10,5.1c1.9-1.3-1.4-7.5,1.2-10.7c1.5-1.9,4.3-1.9,8.8-1.3c7.8,1.2,11.8,1.8,18.2,5.7c19.2,11.7,111.4,3.5,180.3,0
		c6.7-0.3,16.4-0.8,28.2-1.3c1.1,8.7,3.1,15.3,4.7,19.6c3.1,8.4,6.2,12.7,7.6,21.5c0.8,5.1,0.8,9.3,0.6,12
		c-7.1,16.3-14.8,28.2-20.6,36c-20.9,28.6-48.6,48.8-52.8,51.8c-13.6,9.7-21.8,13.5-30,25.9c-6.9,10.5-9.8,21.3-11.2,29.1
		c-9.4-1.7-23.5-5.5-38.2-15.2c-4.2-2.8-13.5-9.3-30.5-30.3c-9.5-11.8-20.3-28.7-41.7-62.6c-7.3-11.5-16-25.5-25.8-44.9
		c-2.3-4.4-4.1-8.2-5.3-10.7c-3.1,0.5-7.5,1.1-12.9,1.2c-10.2,0.2-17.4,0.4-23.5-4.4C267.3,334.8,263.9,331.5,261.7,325"/>
                <path className="st27" d="M455.8,404.1c-0.3-0.4-0.9-0.9-2.1-0.9H447c-4.7-3.5-11.3-5.4-18.5-5.4c-7.2,0-13.8,1.9-18.5,5.4h-6.7
		c-1.1,0-1.8,0.5-2.1,0.9c-0.3,0.5-0.5,0.9-0.5,1.7v19.1c0,1.5,0.6,2.3,1.2,2.7c0.4,0.3,0.9,0.5,1.4,0.5c0.3,0,1-0.2,1.3-0.3
		c0.7-0.2,1.2-0.3,1.8-0.5c1.4-0.4,2.6-0.7,3.8-1.1c4.7,3.3,11.3,5.2,18.3,5.2c6.9,0,13.5-1.9,18.3-5.2c1.2,0.4,2.4,0.7,3.8,1.1
		c0.5,0.2,1.1,0.3,1.8,0.5c0.3,0.1,0.8,0.3,1.3,0.3c0.5,0,1-0.2,1.4-0.5c0.5-0.4,1.2-1.1,1.2-2.7v-19.1
		C456.2,405,456.1,404.6,455.8,404.1z"/>
                <path className="st23" d="M449.5,507.1l-3.2-0.3c-7.3-0.8-14.6-2.5-21.7-5.1c-20.3-7.5-33-20.7-43.2-31.2
		c-12.9-13.4-20.9-25.5-30.1-39.5c-2.2-3.3-2.7-4.2-4.6-7.5c-2.2-3.9-6.4-11.1-17.8-30l-1.1-1.8c-6.2-10.4-10.7-17.9-16.2-28.9
		c-3.2-6.5-6.2-13.1-9.2-20.2l-0.4-0.9l0.1-1c0.2-2,0.6-4.2,1.3-6.4c1.7-5,4.6-9.4,8.6-12.8c1.5-1.3,3.8-1,5,0.6
		c1.2,1.6,1,4-0.6,5.4c-2,1.8-4.8,4.8-6.3,9.4c-0.5,1.4-0.7,2.7-0.9,3.6c2.8,6.6,5.6,12.7,8.6,18.7c5.3,10.7,9.8,18.1,15.9,28.4
		l1.1,1.8c11.4,19,15.5,26.3,17.8,30.2c1.8,3.1,2.3,4,4.4,7.1c9.3,14.3,16.8,25.5,29.2,38.4c10.2,10.6,21.8,22.6,40.6,29.5
		c5.5,2.1,11.2,3.5,16.9,4.3c0.7-2.9,1.5-5.8,2.6-8.6c6-15.5,16.5-24.1,25.7-31.7l1.1-0.9c14.8-12.2,27.1-23,36.6-32.3
		c5-4.9,6.1-6.3,9.2-10.2c3.2-3.9,8.4-10.5,21.8-25.8c0.1-0.1,8.2-9.5,15.9-27c0.3-0.7,1.3-2.8,1.4-5.6c0.1-2.5-0.5-4.4-1.2-6.5
		c-1.1-3.1-2.9-8.2-5.8-14.7c-0.9-1.9-0.1-4.2,1.6-5.1c1.7-0.9,3.9-0.1,4.7,1.8c3.1,6.8,5,12.2,6.1,15.4c0.9,2.6,1.8,5.5,1.7,9.4
		c-0.1,2.9-0.8,5.8-2.1,8.6c-8.2,18.6-16.8,28.5-17.1,28.9c-13.2,15.2-18.4,21.7-21.5,25.6c-3.2,4-4.5,5.7-9.8,10.8
		c-9.6,9.4-22,20.5-37,32.7l-1.1,0.9c-9,7.3-18.2,14.9-23.5,28.5c-1.3,3.4-2.3,7-2.9,10.6L449.5,507.1"/>
                <path className="st23" d="M301.3,345.6c-5.8,0-11.5-0.5-17.2-1.4c-13.4-2.3-18.3-5.9-20.7-8.4c-3.6-3.6-5.5-7.8-6.6-10.6l-2.9-8.2
		l7.5,3.3c2.8,1.2,5.3,1.5,7.7,0.8c0.4-0.1,1.4-0.5,7.1-5.2c2.6-2.2,4.1-3.5,5.1-4.4c1.6-1.4,2.3-2,3.9-3c3.1-1.8,7.3-4.4,12.8-3.9
		c4.6,0.3,8.8,2.4,12.7,6.2c2.8,2.8,4.9,6.3,6.1,10.2c0.6,2-0.4,4.2-2.2,4.8c-1.7,0.7-3.9-0.4-4.5-2.4c-0.9-2.7-2.3-5.1-4.2-7
		c-1-1-4-3.9-8.4-4.2c-3.5-0.3-6.3,1.4-8.8,2.9c-1.2,0.7-1.7,1.2-2.9,2.2c-1.1,1-2.6,2.3-5.3,4.6c-6.2,5.1-7.8,5.9-9.3,6.5
		c-1.3,0.4-2.6,0.6-4,0.7c0.3,0.4,0.7,0.8,1.1,1.2c1,1,4.1,4.2,17,6.4c7.1,1.2,14.3,1.6,21.5,1.2c2.4-0.2,3.6,1.5,3.7,3.6
		c0.1,2.1-1.4,3.9-3.3,4C305.1,345.5,303.2,345.6,301.3,345.6"/>
                <path className="st28" d="M545.4,316.1c-4.2,9.8-11,22.1-22,33.8c-28.8,30.8-65.3,34.6-77.2,35.3c6.1,1.9,14.3,3.8,23.9,3.8
		c1.4,0,2.9,0,4.4-0.1c26.1-1.5,44.2-15.9,51.3-22.2c1.4,0.2,4.4,0.7,7.4,3.1c3.8,3.1,5.1,7.2,5.5,8.5c0.6,3,1,5.5,1.4,7.1
		c0.5,2.4,0.7,3.4,0.8,3.9c0.7-0.8,8.5-10.2,15.9-26.9c0.3-0.7,1.3-2.8,1.4-5.6c0.1-2.5-0.5-4.4-1.2-6.5c-1.1-3.1-2.9-8.2-5.8-14.7
		c-0.4-1-0.5-2-0.1-3C549.1,328.3,547.1,322.8,545.4,316.1"/>
                <path className="st28" d="M550.8,332.6c-0.3,0.9-0.3,2,0.1,3c2.9,6.5,4.8,11.6,5.8,14.7c0.7,2.1,1.3,4,1.2,6.5
		c-0.1,2.8-1.1,4.8-1.4,5.6c-7.4,16.7-15.2,26.1-15.9,26.9c0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0c1.4,0,5.5-5.1,16.9-26.9
		c0.3-2.8,0.6-7.1-0.3-12c-1.1-5.9-3.2-9.7-5.1-13.9C552.1,335.6,551.5,334.2,550.8,332.6"/>
                <path className="st28" d="M374.1,451c3.6,4.6,7.6,9.2,12.2,14c10.2,10.6,21.8,22.6,40.6,29.5c4.7,1.8,9.6,3.1,14.4,3.9
		c1-4.1,2.6-8.9,5.1-14c5.9-11.9,14.1-19.1,19.1-22.9c-0.8-0.1-2-0.2-3.5-0.2c-4.8,0-12.4,0.9-20.2,5.9c-7.2,4.6-11.3,10.7-13.3,14
		c-9.4-2.7-22.7-7.6-37-16.9C384.6,459.8,378.8,455.1,374.1,451"/>
                <path className="st28" d="M361.1,438.6C361,438.6,361,438.6,361.1,438.6c-1,1,13.2,23,36.7,40.3c16.4,12.1,32.2,18.1,43.3,21.3
		c0.1-0.6,0.3-1.2,0.4-1.9c-4.9-0.9-9.7-2.2-14.4-3.9c-18.8-7-30.4-19-40.6-29.5c-4.7-4.8-8.6-9.4-12.2-14
		C366.2,443.9,361.8,438.6,361.1,438.6"/>
                <path className="st29"
                      d="M312.1,312.4c0.2,0.3,0.5,0.6,0.7,0.9c0-0.2,0-0.5,0-0.7C312.5,312.5,312.3,312.4,312.1,312.4"/>
                <path className="st28" d="M304.7,318.3c-4,0-8.5,1.5-11.1,2.8c-6.1,3-9.5,8.1-11,10.8c3.4-0.4,7.1-0.6,11-0.6c3.8,0,7.4,0.2,10.8,0.5
		c1.4-3.1,3.3-6,5.6-8.5c-0.6-1.7-1.3-3.3-2.3-4.7C306.8,318.4,305.8,318.3,304.7,318.3 M312.8,312.6c0,0.3,0,0.5,0,0.7
		c1.7,2.3,3.1,4.9,4,7.8c0.1,0.3,0.2,0.6,0.2,0.9c0,0,0,0.1,0.1,0.1c1.2,1.6,1,4-0.6,5.4c-2,1.8-4.8,4.8-6.3,9.4
		c-0.3,0.8-0.5,1.5-0.6,2.2c0.2,0.3,0.4,0.7,0.6,1.1c1.6-0.7,3.2-1.8,4.8-3.3c5-5,7.7-14.5,3.8-20
		C317.3,314.6,314.9,313.1,312.8,312.6"/>
                <path className="st28" d="M317,321.9c0.1,1.7-0.8,3.4-2.4,3.9c-0.3,0.1-0.7,0.2-1.1,0.2c-1.4,0-2.9-1-3.4-2.6c0-0.1,0-0.1-0.1-0.2
		c-2.4,2.5-4.3,5.4-5.6,8.5c1.7,0.2,3.3,0.4,4.9,0.6c-0.5,2-1.3,3.7-2.1,5.3c1.1,0,1.9,0.5,2.4,1.2c0.1-0.7,0.3-1.4,0.6-2.2
		c1.5-4.6,4.3-7.6,6.3-9.4C318,326.1,318.3,323.7,317,321.9C317,322,317,322,317,321.9"/>
                <path className="st28" d="M307.2,337.8c-0.7,1.4-1.6,2.6-2.4,3.7c1.2-0.1,3.2-0.5,5.4-1.5c-0.1-0.4-0.3-0.8-0.6-1.1
		C309.1,338.2,308.2,337.8,307.2,337.8 M311.1,312.3c-0.9,0-1.6,0.2-2.1,0.7c-1.2,1.1-0.5,3.2,0.2,6.1c-0.5-0.2-1-0.4-1.5-0.5
		c1,1.4,1.8,3,2.3,4.7c0,0.1,0,0.1,0.1,0.2c0.5,1.6,2,2.6,3.4,2.6c0.4,0,0.7-0.1,1.1-0.2c1.6-0.6,2.5-2.2,2.4-3.9
		c0-0.3-0.1-0.6-0.2-0.9c-0.9-2.8-2.3-5.5-4-7.8c-0.2-0.3-0.5-0.6-0.7-0.9C311.8,312.4,311.4,312.3,311.1,312.3"/>
                <path className="st24" d="M332.5,294.9c-0.9,5.6-1.4,13.5,0.7,22.5c5.6,23.3,24.4,35.4,29.2,38.2c-0.7-1.4-2.2-5.1-1.1-9.4
		c0.4-1.4,0.9-2.6,1.4-3.5c2.3,4.2,5.4,8.7,9.4,13.1c16.4,18.1,38,22.8,47.7,24.3c-0.7-0.7-2.8-3-3.1-6.5c-0.1-1.4,0.1-2.6,0.4-3.5
		c10.1-0.2,23.4-1.5,38.4-5.9c22.2-6.6,38.4-17.3,48.1-25.1c-0.2,1.8-0.5,3.9-1,6.1c-0.5,2.2-1.2,4.2-1.8,5.9
		c2.9-1,7.1-2.7,11.8-5.5c5.2-3,21.8-13.1,28.4-33.1c1.1-3.3,2.4-8.3,2.7-14.7c-36.9,1.2-75.4,1.6-115.5,0.8
		C395.2,298.1,363.3,296.8,332.5,294.9z"/>
                <path className="st25" d="M361.9,80.5c-8.3,10.5-10.9,19.6-11.7,26.1c-1,7.9,1,8.9,1.2,29.3c0.1,12-0.6,17-1.1,20.3
		c-1.1,6.5-2.8,8.7-3.3,15.1c-0.3,3.3-0.8,9.3,1.1,15.3c2.5,8.3,7.3,9.5,15.9,20.1c5.4,6.7,5.9,8.8,8.9,13.8c0,0,1.7,2.9,4,6.1
		c12.1,17.1,31.4,24,31.4,24c11.8,3.9,21.6,3.7,27.3,3.4c5.9-0.3,13.2-0.7,21.7-4.5c12.5-5.5,19.7-14.8,22.6-18.6
		c3-3.8,5.8-8.3,7.6-11.2c3-4.7,5.2-8.8,6.8-11.8c-1.2,5.7-3.6,13.7-8.2,22.5c-3,5.5-7.2,13.5-14.4,19.6c-14,12-32.3,11.2-45,10.7
		c-19-0.7-32.8-7.1-40.2-10.7c-13.7-6.6-21.1-13.7-22.9-15.5c-4.6-4.5-7.8-8.9-10-12.2c-1,2.2-2,5.1-2.2,8.5
		c-0.9,11.7,7,20.3,8.9,22.1c-0.6,0.4-3.5,2.4-7.4,1.8c-3.6-0.5-5.7-2.7-6.3-3.3c0.2,2.4,0.6,5.9,1.1,10.3c0.7,6.3,0.9,6.2,0.7,7
		c-1.1,6.7-8.7,7.7-10.3,14c-0.6,2.2-0.5,5.4,2.2,10c1.4,1.9,3.5,4.1,6.3,6.3c3.9,3,7.7,4.6,10.3,5.5c-1.4,0.8-3.4,1.7-5.9,2.2
		c-5.3,1-9.6-0.7-11.4-1.5c-0.3,3.1-0.3,6.6,0,10.3c1.5,16.6,10.1,28.5,15.3,34.6c-4.6-2.8-16.8-11.2-23.1-27.3
		c-3.7-9.5-4-18.1-3.7-23.3c-1.6-0.5-3.2-1-4.8-1.5c-0.5-3.9-0.7-9.6,0.7-16.2c2.1-9.8,6.7-16.7,9.6-20.3c-0.6-1.1-3.5-7.3-0.7-13.7
		c1-2.4,2.5-4.1,3.7-5.2c0.7-7.6,1.5-15.3,2.2-22.9c-2.2,0.6-6.6,1.3-11.8,0c-2.2-0.6-8.3-2.1-12.2-7.8c-5.3-7.6-2.2-16.6-1.8-17.7
		c0.7,1.2,1.7,2.5,3,3.7c4.8,4.7,10.7,5.6,13.3,5.9c-2.4-2.3-5.9-6.3-8.5-12.2c-3.3-7.4-3.5-14.1-3.3-17.7c2.2-2.9,5.5-7.7,8.5-14
		c5.1-11,6.5-21,8.1-33.2c2.3-17.4,0.9-17.6,3-22.9C340.2,95.8,347.4,87.4,361.9,80.5z"/>
                <path className="st24" d="M420.4,167.9c0,3.5-3.6,6.3-8,6.3c-4.4,0-8-2.8-8-6.3c0-3.5,3.6-6.3,8-6.3
		C416.8,161.6,420.4,164.4,420.4,167.9z"/>
                <path className="st24" d="M457.3,167.9c0,3.5-3.6,6.3-8,6.3c-4.4,0-8-2.8-8-6.3c0-3.5,3.6-6.3,8-6.3
		C453.7,161.6,457.3,164.4,457.3,167.9z"/>
                <path className="st30" d="M402.7,216.8c3.2,0.2,9.2,0.2,15.9-2.6c7.7-3.1,12.3-8.1,14.4-10.7c1.3,2.2,4,6.3,9,9.5
		c7.1,4.5,14.3,4.3,16.8,4.1c-0.2,2.3-1.4,11.2-9,18.2c-5.8,5.3-12.1,6.6-14.8,7c-12.4,1.8-21.3-5.4-22.4-6.3
		C404,228.7,402.9,218.8,402.7,216.8z"/>
                <path className="st25" d="M502.4,77c2.5,4.3,5.7,11.2,7.3,20.1c1,5.7,0.9,10.3,0.7,19.4c-0.2,10.9-0.9,11.5-1.5,24.1
		c-0.8,19.3,0.5,23.6,1.5,26c1.6,4.2,3.1,5.7,4.4,11c0.8,3.4,2,8.8,0.4,15.4c-1.4,5.7-4.4,9.7-6.6,12.1c-1.1,6.3-1.9,14.9-0.7,24.9
		c2.1,17.1,9,29.7,13.5,36.6c0.2-3.4,0.5-6.8,0.7-10.2c1.8,2.4,4.3,6.4,5.9,11.7c4.3,14.7-2.2,28.1-5.9,35.5
		c-2.4,4.9-6.7,12.3-14.3,19.8c4-0.9,9.8-2.8,15-7.3c14.7-12.5,13.5-34.9,13.2-41.3c-0.5-9.2-2.4-9.9-5.5-31.1
		c-1.1-7.5-1.8-13.7-2.2-17.9c-1.1-0.6-3.2-2.1-4.8-4.8c-2.5-4.4-1.4-8.9-1.1-9.9c1.7,1.4,4.6,3.5,8.8,4.8c2.3,0.7,7.8,2.3,12.8,0
		c5.6-2.5,9.1-9.2,9.1-16.8c-1.2,0.9-4.5,3.5-9.5,3.7c-6.8,0.3-11.3-4-12.1-4.8c1.5-4.3,3.3-10.9,3.3-19c0-7.4-1.5-10.2-5.9-27.4
		c-3.2-13-4.9-19.6-5.9-27.8c-1.4-11.7-0.1-13-1.5-19C520.2,97.2,515.7,87.3,502.4,77z"/>
                <path className="st25" d="M413.2,296.1c4.5,0.8,11.4,1.4,19.4-0.4c16.2-3.7,25.6-14.8,28.5-18.7c-0.4,4-1.7,10.2-5.9,16.5
		c-9.9,14.9-27.7,16.6-30.4,16.8c3.1,0.8,6.8,1.5,11,1.8c7.1,0.5,13.2-0.4,17.6-1.5c-3.2,2.9-7.8,6.5-13.9,9.5
		c-9.3,4.5-17.8,5.6-23,5.9c0,1.6,0.3,3.9,1.5,6.2c3.8,7.2,13.3,7.7,13.9,7.7c-1.6,0.5-14.1,4.5-26-3.3
		c-13.1-8.5-13.5-23.6-13.5-24.9c2.9,0.6,5.9,1.1,9.1,1.5c4.5,0.6,8.8,0.9,12.8,1.1c-1-2.5-2.4-5.4-4.4-8.4
		c-3.6-5.6-7.8-9.5-11-12.1C403.6,294.7,408.4,295.4,413.2,296.1z"/>
                <path className="st25" d="M397.6,527.3c-0.6,3.3-1.3,6.7-2.1,10.2c-0.4,1.5-0.7,2.9-1.1,4.3c-5.1,0-10.1,0.1-15.2,0.1v-0.1
		c2.4-1.4,4.8-3.1,7.4-4.9C390.8,533.6,394.5,530.4,397.6,527.3z"/>
                <path className="st25" d="M490.9,541.2c-4.7,0-9.5,0-14.2,0.1c-0.4-1.3-0.7-2.7-1.1-4c-0.8-3.2-1.4-6.3-1.9-9.2c2.8,2.7,6.1,5.6,10,8.4
		C486.1,538.3,488.6,539.9,490.9,541.2z"/>
                <path className="st25" d="M313.6,473.5c0.2,3.3,0.2,7,0,10.9c-0.5,8.3-2,15.4-3.6,21.3c1.6-0.7,3.2-1.3,4.8-2c0,1.3,0.1,3.4,0,5.9
		c-0.3,9.6-2,13.5-2.2,20.7c-0.1,2.8,0,6.9,1.1,12c2.1,0,4.1,0,6.2,0c-0.5-4.1-0.9-8.5-1.1-13.2c-0.5-11.7,0.3-22.3,1.7-31.4
		c-1.5,0.4-3,0.7-4.5,1.1c0.6-3,1.1-6.9,0.8-11.5C316.3,481.6,314.9,476.8,313.6,473.5z"/>
                <path className="st24" d="M549.2,481.4c-0.7,3.1-3.6,16.7,4.5,26.6c1.1,1.4,2.6,2.9,4.5,4.2c-0.8,0.8-2,2-3.9,2.7
		c-4.2,1.7-8.2,0.1-9.1-0.3C546.6,503.6,547.9,492.5,549.2,481.4z"/>
                <path className="st25" d="M415.9,101.2c0.5,2.4,1.6,8.8-1.4,16c-3.4,8.1-9.7,12-11.7,13.2c2.6-3.3,5.7-7.8,8.3-13.6
		C413.8,110.7,415.2,105.3,415.9,101.2z"/>
                <path className="st25" d="M447,100.1c-0.6,2.5-1.8,9.5,1.5,17.4c2.8,6.6,7.3,10.3,9.5,11.8c-2.4-3.5-5.1-8.3-7.3-14.3
		C448.5,109.3,447.5,104.1,447,100.1z"/>
                <path className="st24" d="M365.4,206.2c-1.9,0.8-5.5,2.6-8.3,6.5c-5.2,7.2-3.2,15.4-2.9,16.7c2.5,3.1,4.9,6.1,7.4,9.2
		c0.1-1.3,0.8-6.9,5.6-11.3c4.6-4.3,9.9-4.6,11.3-4.7C374.1,217.1,369.8,211.7,365.4,206.2z"/>
</g>
</DukeInBean>
    );
};

export default DukeInBeanSvg;