import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { ContentCardContainerComponent } from './ContentCardContainer.styles.js';

import Grid from 'components/shared/Grid';

const ContentCardContainer = ({ params = {}, rendering }) => {
  const { columns = '2', style = 'tan' } = params;

  return (
    <ContentCardContainerComponent variant={style}>
      <Grid type="content" columns={columns}>
        <Placeholder name="content-card-container-content" rendering={rendering} />
      </Grid>
    </ContentCardContainerComponent>
  );
};

export default ContentCardContainer;
