import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

const getItemBackgroundColour = ({ startingCardColor, theme } = {}) => {
  const variants = {
    red: {
      primary: theme.colours.red,
      secondary: theme.colours.blue,
    },
    blue: {
      primary: theme.colours.blue,
      secondary: theme.colours.red,
    },
    brown: {
      primary: theme.colours.brown,
      secondary: theme.colours.yellow,
    },
    yellow: {
      primary: theme.colours.yellow,
      secondary: theme.colours.brown,
    },
  };

  if (startingCardColor in variants) return variants[startingCardColor];

  return variants.red;
};

export const PageCardComponent = styled.article`
  position: relative;
  max-width: 560px;
  overflow: hidden;

  &:hover {
    footer {
      background-color: ${({ theme, startingCardColor }) =>
        getItemBackgroundColour({ theme, startingCardColor }).secondary};
    }
  }

  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.blue};
    max-height: 200px;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 80%;
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 460px;
    }

    > a {
      display: flex;
      flex-direction: column;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: 0.4s;

      ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }

  header {
    .button-love {
      position: absolute;
      top: 0;
      right: 0;
      margin: ${({ theme }) => theme.spacing(16)};
      z-index: 10;
    }
  }

  footer {
    height: 100%;
    padding: ${({ theme }) => theme.spacing({ top: 32, left: 32, right: 32, bottom: 48 })};
    background-color: ${({ theme, startingCardColor }) =>
      getItemBackgroundColour({ theme, startingCardColor }).primary};

    h2 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(48)};
      }
    }
    h3 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(48)};
      }
    }

    span {
      &.emphasis.small {
        display: block;
        color: ${({ theme }) => theme.colours.tan};
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing(32)};

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin-bottom: ${({ theme }) => theme.spacing(48)};
        }
      }
    }

    button {
      margin: 0 auto;
    }
  }
`;

const PageCard = ({ image, title = {}, link = {}, itemId = '', backgroundColor = '', classValue = '',  }) => {
  const device = useDeviceResize();

  return (
    <PageCardComponent backgroundColor={backgroundColor} className={classValue}>
      <header>
        {!isServer() && <Button aria-label="Farvorite" type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}
      </header>

      {!isServer() && (
        <ConditionalComponentWrapper
          condition={link?.path || link?.href}
          wrapper={children => (
            <Link {...link} className="button-link">
              {children}
            </Link>
          )}
        >
          <div className="image-container">{image && <Image field={image} />}</div>
          <footer>
            {title && <Text tag="h3" field={title} />}
            <Button ariaLabel="View Item" type="circle" {...link} />
          </footer>
        </ConditionalComponentWrapper>
      )}
    </PageCardComponent>
  );
};

export default PageCard;

PageCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};