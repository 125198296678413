import styled from 'styled-components';

export const MainNavProductLinkComponent = styled.div`
  position: relative;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colours.brown};
  font-size: ${({ theme }) => theme.spacing(16)};
  text-align: center;
  text-transform: uppercase;
  transition: margin 0.3s ease-out;

  a {
    color: ${({ theme }) => theme.colours.adaBrown};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colours.adaBrown};
    }
  }

  h4 {
    margin-bottom: ${({ theme }) => theme.spacing(12)};
    font-size: 20px;
  }
  .h4 {
    margin-bottom: ${({ theme }) => theme.spacing(12)};
    font-size: 20px;
  }

  & .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: ${({ theme }) => theme.spacing(24)};
  }

  & img {
    max-width: ${({ theme }) => theme.spacing(170)};
    max-height: ${({ theme }) => theme.spacing(170)};
    transform: translate(0, 0);
    transition: top 0.25s ease-out;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: relative;
    }

    @media screen and (min-width: 1200px) and (max-width: 1220px) {
      max-width: ${({ theme }) => theme.spacing(168)};
    }
    
  }

  & img.pop {
    position: relative;
    height: ${({ theme }) => theme.spacing(0)};
    margin-bottom: 0;
    overflow: hidden;
    transform: scaleY(0);
    transition: transform 0.3s ease-out, height 0.3s ease-out, margin 0.3s ease-out;
  }

  &:hover {
    margin-bottom: ${({ theme }) => theme.spacing(-60)};

    img.pop {
      height: ${({ theme }) => theme.spacing(34)};
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      overflow: hidden;
      transform: scaleY(1);
    }

    button {
      visibility: visible;
      opacity: 1;
    }
  }

  button {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
`;
