import React from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ videoUrl }) => {
    const getEmbedUrl = (url) => {
        if (url.includes('youtube')) {
            const videoId = url.split('v=')[1];
            const ampersandPosition = videoId.indexOf('&');
            return `https://www.youtube.com/embed/${
                ampersandPosition !== -1 ? videoId.substring(0, ampersandPosition) : videoId
            }`;
        } else if (url.includes('vimeo')) {
            const videoId = url.split('/').pop();
            return `https://player.vimeo.com/video/${videoId}`;
        } else {
            return '';
        }
    };

    return (
        <div className="video-div">
            <iframe
                src={getEmbedUrl(videoUrl)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video Player"
            />
        </div>
    );
};

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string.isRequired,
};

export default VideoPlayer;