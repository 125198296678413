import styled from 'styled-components';

export const ScrollingImagesPromoSlideComponent = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const SlideImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;