import React from 'react';
import PropTypes from 'prop-types';
import {ContentBlockComponent, ContentBlockComponentFs} from './ContentBlock.styles.js';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const ContentBlock = ({sitecoreContext}) => {
  const {site} = sitecoreContext;

  if (site.name === 'bushs-beans-foodservices') {
    return (
        <ContentBlockComponentFs>
        ContentBlock PlaceHolder Content for Bushs Beans Foodservices
      </ContentBlockComponentFs>
    );
  }

  return (
    <ContentBlockComponent>
      ContentBlock PlaceHolder Content
    </ContentBlockComponent>
  );
};

ContentBlock.propTypes = {
  sitecoreContext: PropTypes.object.isRequired,
};

export default withSitecoreContext()(ContentBlock);