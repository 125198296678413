import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.yellow,
    },
    yellow: {
      background: theme.colours.red,
    },
    blue: {
      background: theme.colours.yellow,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const PlaceholderWrapper = styled.div`
  position: relative;
  display: block;
  min-height: 300px;
`;

export const SocialVideoComponent = styled.section`
  display: block;
  background-color: ${({ theme, variant, backgroundColor }) =>
    theme.colours.checkColourExists(backgroundColor) || getVariantColours({ theme, variant }).background};

  ${({ backgroundImage }) =>
    backgroundImage?.src
        ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `
        : ''}
  

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 5vw, ${theme.spacing(100)});`};
  }

  ${({ theme }) => theme.breakPoints.minBp('1920px')} {
    padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)});`};
  }
  padding: 40px 20px;
`;

export const TextContent = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  h2{
    margin: 0 15px;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 69px;
    line-height: 1.2;
    align-items: center;
    text-transform: uppercase;
    color: ${({theme}) => theme.colours.red};
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
        font-size: 36px;
    }
  }
  p{
    display: block;
    text-align: center;
    color: ${({theme}) => theme.colours.brown};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
  }
`;

export const VideoSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
  .slick-slider{
    padding-bottom: 80px;
    .slick-arrow{
      &:before{
        opacity: 1;
        font-size: 30px;
      }
    }
    .slick-dots{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      bottom: 0;
      li{
        button{
          width: 16px;
          height: 16px;
          border: 2px solid ${({theme}) => theme.colours.red};
          border-radius: 50%;
          transition: all 0.4s ease-in-out;
          &:hover{
            border-color: ${({theme}) => theme.colours.brown};
          }
          &:before{
            display: none;
          }
        }
        &.slick-active{
            button {
				background-color: ${({theme}) => theme.colours.red};
                border: 2px solid ${({theme}) => theme.colours.red};
                &:before{
                  display: none;
                }
			}
        }
      }
    }
    @media (max-width: 1050px){
      .slick-slide{
        max-width: 300px;
      }
    }
    @media (max-width: 1050px){
      .slick-slide{
        max-width: 270px;
      }
    }
  }
  ${({theme}) => theme.breakPoints.minBp('desktop')} {
    .desktop-adjust{
      .slick-list{
        .slick-track{
          width: 100% !important;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  @media (max-width: 1400px){
    padding-bottom: 50px;
  }
  @media (max-width: 1050px){
    .video-div {
      width: 240px;
      max-width: 100%;
    }
  }
`;