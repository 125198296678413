import styled from 'styled-components';

export const PromoBoxComponent = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 35, right: 35 })};
  color: ${({ theme }) => theme.colours.yellow};
  text-align: center;
  background-color: ${({ theme }) => theme.colours.red};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)})`};
  }

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.yellow};
    font-weight: 400;
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
    p {
      color: #fff;
    }
  }

  h4 {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.white};
    font-family: ${({ theme }) => theme.typography.fontFamily.main};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 70%;
      margin-right: auto;
      margin-bottom: ${({ theme }) => theme.spacing(55)};
      margin-left: auto;
    }
  }

  > button {
    margin: 0 auto;
  }

  .button-love {
    margin-top: ${({ theme }) => theme.spacing(30)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: absolute;
      top: 20px;
      right: 20px;
      margin-top: 0;
    }
  }
`;

export const AuthorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    margin-right: ${({ theme }) => theme.spacing(10)};
  }

  p {
    width: unset;
    margin: 0;
    color: #fff;
    font-weight: normal;

    &:not(:first-child) {
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        padding-left: ${({ theme }) => theme.spacing(10)};

        &:before {
          padding-right: ${({ theme }) => theme.spacing(10)};
          content: '|';
        }
      }
    }
  }
`;

export const PromoBoxInner = styled.div`
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 880px;
  }
`;
