import React from 'react';
import PropTypes from 'prop-types';

import ContactUsHeroBg from 'assets/images/contact-us-hero-bg.jpg';
import ContactUsHeroBg2x from 'assets/images/contact-us-hero-bg@2x.jpg';

import styled from 'styled-components';

const ContactRepHeroComponent = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(240)};
  background-image: url(${ContactUsHeroBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  & h1 {
    color: ${({ theme }) => theme.colours.yellow};
  }

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    background-image: url(${ContactUsHeroBg2x});
  }
`;

const VendorFormHero = (props) => {
  return <ContactRepHeroComponent {...props} />;
};

VendorFormHero.propTypes = {};

export default VendorFormHero;