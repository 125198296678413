const hexRegex = /^#([A-Fa-f0-9]{3}){1,2}$/;

export const hexToRgba = (hex, opacity = 1) => {
  if (!hex) return null;

  const cleanHex = hex.trim().replace(/[^a-zA-Z0-9#]/g, '');
  let c;

  if (hexRegex.test(cleanHex)) {
    c = cleanHex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;

    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(', ')}, ${opacity})`;
  }
};
