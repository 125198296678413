import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import {HoverWrapper, RecipeCardPromoComponent, RecipeFooter, RecipePromoPosterImg} from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

import translate from 'helpers/translate';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const RecipePromoCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  link = '',
  itemId = '',
  type = 'recipe',
  recipeTotalTimeHours = '',
  recipeTotalTimeMinutes = '',
   classValue = '',
}) => {
  const device = useDeviceResize();
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;

  const renderTime = () => {
    const time = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Time', 'Time');
    const hour = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Hours', 'hr');
    const min = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Minutes', 'm');

    if (
      recipeTotalTimeHours &&
      recipeTotalTimeHours > 0 &&
      recipeTotalTimeMinutes &&
      recipeTotalTimeMinutes > 0
    )
      return `${time}: ${recipeTotalTimeHours}${hour} ${recipeTotalTimeMinutes}${min}`;

    if (recipeTotalTimeHours && recipeTotalTimeHours > 0) return `${time}: ${recipeTotalTimeHours}${hour}`;

    if (recipeTotalTimeMinutes && recipeTotalTimeMinutes > 0)
      return `${time}: ${recipeTotalTimeMinutes}${min}`;
  };

  return (
        <RecipeCardPromoComponent type={type} className={`${classValue} recipe-card-promo`}>

          {!isServer() && (
              <ConditionalComponentWrapper
                  condition={link}
                  wrapper={children => (
                      <Link href={link} className="button-link">
                        {children}
                      </Link>
                  )}
              >

                <RecipePromoPosterImg className="image-container">
                  {image?.url && <img src={image?.url} alt={image?.alt}/>}
                  {device === 'desktop' && (
                      <HoverWrapper>
                        {promoImage?.url && (
                            <img src={promoImage?.url} alt={promoImage?.alt} className="hover-image"/>
                        )}
                        <Ribbon variant="yellow"/>
                      </HoverWrapper>
                  )}
                </RecipePromoPosterImg>

                <RecipeFooter>
                  {title && (
                      <h3 dangerouslySetInnerHTML={{__html: trademarkFilterHelper(title, trademarkReplacements)}}/>
                  )}
                  <div style={{display: 'none'}}>
                  {(recipeTotalTimeHours || recipeTotalTimeMinutes) && <p>{renderTime()}</p>}
                  </div>
                </RecipeFooter>
              </ConditionalComponentWrapper>
          )}
        </RecipeCardPromoComponent>
  );

};

export default withSitecoreContext()(RecipePromoCard);

RecipePromoCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};