import React, { useState, useRef, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import {
    TestimonialCarouselComponent,
    PlaceholderWrapper,
    TestimonialCarouselControls
} from './TestimonialCarousel.styles.js';
import TestimonialCarouselSlide from 'components/TestimonialCarouselSlide/TestimonialCarouselSlide.jsx';
import {isExperienceEditorActive, Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'components/shared/Button';


const TestimonialCarousel = ({ sitecoreContext,params, ...props }) => {
    const experienceEditor = isExperienceEditorActive();
    const templateName = sitecoreContext.route.templateName;
    let pageType = templateName;
    if (templateName === 'Article Page') {
        pageType = 'article';
    }
    const { rendering = {} } = props;
    const carouselItems = rendering?.placeholders?.['testimonial-carousel-content'] || [];

    const slides = Array.isArray(carouselItems)
        ? carouselItems.map((item, index) => ({ ...item, slideIndex: index }))
        : [];

    const settings = {
        dots: true,
        infinite: slides.length > 3,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    const sliderRef = useRef();

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <TestimonialCarouselComponent>
                <div className="testimonial-carousel">
                    <div style={{height: '80px', maxHeight: '80px', borderBottom: '2px solid #944000', textAlign: 'center'}}>
                        <h1>Start Testimonial Carousel</h1>
                    </div>
                    <PlaceholderWrapper>
                        <Placeholder name="testimonial-carousel-content" rendering={rendering}/>
                    </PlaceholderWrapper>
                    <div style={{ height: '80px', maxHeight: '80px', borderTop: '2px solid #944000', textAlign: 'center' }}>
                        <h1>End Testimonial Carousel</h1>
                    </div>
                </div>
            </TestimonialCarouselComponent>
        );
    }

    return (
        <TestimonialCarouselComponent className={pageType ? `page-type-${pageType}` : ''}>
        <Slider {...settings} className="testimonials" ref={sliderRef} beforeChange={(oldIndex, newIndex) => {
                const prevButton = document.querySelector('.testimonial-slide-prev');
                const nextButton = document.querySelector('.testimonial-slide-next');

                if (newIndex === 0) {
                    prevButton.classList.add('slick-disabled');
                } else {
                    prevButton.classList.remove('slick-disabled');
                }

                if (newIndex === slides.length - 1) {
                    nextButton.classList.add('slick-disabled');
                } else {
                    nextButton.classList.remove('slick-disabled');
                }
            }}>
                {slides.map((item) => (
                    <TestimonialCarouselSlide key={item.slideIndex} rendering={{...item}} />
                ))}
            </Slider>
            {slides.length > 1 && (
            <TestimonialCarouselControls className="testimonial-controls">
                <Button
                    type="circle"
                    icon="chevron-left"
                    variant="yellowBlue"
                    size="large"
                    ariaLabel="Previous slide"
                    className="testimonial-slide-prev"
                    onClick={previous}
                />
                <Button
                    type="circle"
                    icon="chevron-right"
                    variant="yellowBlue"
                    size="large"
                    ariaLabel="Next slide"
                    className="testimonial-slide-next"
                    onClick={next}
                />
            </TestimonialCarouselControls>
            )}
        </TestimonialCarouselComponent>
    );
};

TestimonialCarousel.propTypes = {};

export default withSitecoreContext()(TestimonialCarousel);