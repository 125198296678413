import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
    },
    yellow: {
      background: theme.colours.yellow,
    },
    blue: {
      background: theme.colours.blue,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const NotificationWrapper = styled.section`
  position: relative;
  top: 0;
  z-index: ${({ theme }) => theme.zindex.global.cookiePolicy};
  box-shadow: rgb(0 0 0) 0px -2px 3px 0px;
`;

export const AlertRibbonComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ right: 36, left: 12, top: 12, bottom: 12 })};
  color: ${({ theme }) => theme.colours.tan};
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing({ right: 36, left: 24, top: 12, bottom: 12 })};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ right: 36, left: 36, top: 12, bottom: 12 })};
  }

  div {
    margin: 0 auto;

    * {
      margin-bottom: 0;
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(14)};
      line-height: ${({ theme }) => theme.spacing(16)};
      text-align: center;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        font-size: ${({ theme }) => `clamp(${theme.spacing(14)}, 4vw, ${theme.spacing(16)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(18)})`};
      }
    }

    a {
      color: ${({ theme }) => theme.colours.tan};
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.colours.yellow};
      }
    }
  }
`;

export const CloseWrapper = styled.span`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0px, -50%);

  button {
    cursor: pointer;
  }

  svg {
    position: relative;
    top: 1px;
    color: ${({ theme }) => theme.colours.tan};
  }
`;