import React from 'react';

const VerticalLineDecorationSvg = (props) => (
    <svg
        width={126}
        height={52}
        fill="none"
        {...props}
    >
        <path
            d="m1.996 39.96 13.43 10.072M124.004 39.96l-13.431 10.072M13.85 26.619l11.635 12.103M112.152 26.619l-11.635 12.103M29.79 15.187l6.933 15.29M96.213 15.187l-6.934 15.29M46.218 10.417l3.563 16.405M62.77 7.054v16.788M71.164 2.064l-1.68 30.218M79.784 10.417l-3.563 16.405M2.587 27.616l22.972 20.786M123.414 27.616l-22.972 20.786M17.294 14.972l17.572 25.514M108.708 14.972 91.135 40.486M35.857 6.767l10.55 29.128M90.143 6.767l-10.55 29.128M56.055 2l.634 30.973"
            stroke="#D43631"
            strokeWidth={3}
            strokeLinecap="round"
        />
    </svg>
);
export default VerticalLineDecorationSvg;