import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ProductListCarouselComponent,
  ProductContainer,
  Title,
  ButtonWrap,
  LoadingSpinnerWrapper,
  ErrorWrapper,
} from './ProductListCarousel.styles.js';

import FeaturedProduct from 'components/shared/FeaturedProduct';
import Button from 'components/shared/Button';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { useFetch } from 'hooks';
import { searchConfig, filterConfig, globalizationConfig } from 'helpers/searchHelpers';
import translate from 'helpers/translate';
import LoadingSpinner from 'components/shared/LoadingSpinner';

const ProductListCarouselRender = ({ results = [], viewBag = {}, productTypeText = '' }) => {
  const sliderContainerRef = useRef(null);
  const slideRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(
    sliderContainerRef?.current?.getBoundingClientRect().width
  );
  const slideWidth = slideRef?.current?.getBoundingClientRect()?.width;
  const [slideLeft, setSlideLeft] = useState(0);
  const [showCarouselControl, setShowCarouselControl] = useState(false);

  useEffect(() => {
    setContainerWidth(sliderContainerRef.current.getBoundingClientRect().width);
  }, []);

  useEffect(() => {
    const setSliderWidths = () => {
      setContainerWidth(sliderContainerRef.current.getBoundingClientRect().width);
      setSlideLeft(0);
    };

    window.addEventListener('resize', setSliderWidths);
    return () => {
      window.removeEventListener('resize', setSliderWidths);
    };
  });

  useEffect(() => {
    setShowCarouselControl(slideWidth * results.length > containerWidth);
  }, [containerWidth]);

  const renderTitle = (string) => string.replace('{PRODUCT_TYPE}', productTypeText);

  return (
    <>
      <Title>
        {renderTitle(translate(viewBag, 'Products.ProductListCarousel.Title', 'more {PRODUCT_TYPE} flavors'))}
      </Title>

      <ProductContainer ref={sliderContainerRef}>
        <ul
          style={{
            width: slideWidth * results?.length,

            transform: `translate3d(${slideLeft}px, 0, 0)`,
          }}
        >
          {results?.map((item, index) => (
            <li key={index} ref={slideRef}>
              <FeaturedProduct
                title={item?.searchTitle}
                image={item?.searchImage}
                isNewProduct={item?.productIsNew}
                link={item?.itemUrl}
								webBadge={item?.webBadge}
              />
            </li>
          ))}
        </ul>
      </ProductContainer>

      {showCarouselControl && (
        <ButtonWrap>
          <Button
            type="circle"
            invertColor={true}
            ariaLabel="Previous"
            onClick={() => {
              if (slideLeft === 0 || slideLeft >= containerWidth) {
                return;
              }
              setSlideLeft((prevState) => prevState + slideWidth);
            }}
          />

          <Button
            type="circle"
            invertColor={true}
            ariaLabel="Next"
            onClick={() => {
              const calcSlideWidth = slideWidth * results?.length;
              if (slideLeft * -1 >= calcSlideWidth - containerWidth) {
                return;
              }
              setSlideLeft((prevState) => prevState - slideWidth);
            }}
          />
        </ButtonWrap>
      )}
    </>
  );
};

const ProductListCarousel = (props) => {
  const type = 'product';
  const { fields = {}, sitecoreContext = {} } = props;
  const { viewBag = {}, site } = sitecoreContext;
  const { pageSize = {}, startIndex = {} } = fields;

  const pageSizeValue =
    (pageSize?.value && !isNaN(parseInt(pageSize.value)) && parseInt(pageSize.value)) || 10;

  const startIndexValue =
    (startIndex?.value && !isNaN(parseInt(startIndex.value)) && parseInt(startIndex.value)) || 0;

  const prefilterProductType =
    (fields?.prefilterProductType?.fields?.key?.value && [
      fields?.prefilterProductType?.fields?.key?.value,
    ]) ||
    [];

  const prefilter = { prefilterProductType };

  const [state, setState] = useState({
    filters: {},
    results: [],
    take: pageSizeValue,
    skip: startIndexValue,
    globalization: globalizationConfig(),
  });

  const { endpoint } = searchConfig({ type: 'product' });

  const { results, take, skip, globalization } = state;

  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        filters: filterConfig({ type, prefilter }),
        take,
        skip,
        sortOrder: 'dateAsc',
      },
      context: {
        globalization,
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site.name
          },
        },
        content: {},
      },
    },
  });

  useEffect(() => {
    if (data) setState({ ...state, ...data?.subject });
  }, [data]);

  return (
    <ProductListCarouselComponent>
      {!isServer() && results.length > 0 && !error && !loading && (
        <ProductListCarouselRender
          results={results}
          {...props}
          viewBag={viewBag}
          productTypeText={fields?.prefilterProductType?.fields?.text?.value}
        />
      )}

      {!isServer() && loading && !error && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}

      {!isServer() && error && results.length === 0 && (
        <ErrorWrapper>
          <h2>{translate(viewBag, 'BushsBeans.Search.Labels.ErrorTitle', 'Oops, theres been an error')}</h2>
        </ErrorWrapper>
      )}
    </ProductListCarouselComponent>
  );
};

ProductListCarousel.propTypes = {};

export default withSitecoreContext()(ProductListCarousel);
