import React from 'react';
import PropTypes from 'prop-types';
import { GridComponent, ComplexGrid, AdaptableGrid, OffsetGrid } from './Grid.style';

const RenderGrid = ({ type = 'default', children, className, props }) => {
  const types = {
    default: GridComponent,
    related: GridComponent,
    product: ComplexGrid,
    recipe: ComplexGrid,
    content: AdaptableGrid,
    ingredients: AdaptableGrid,
    offset: OffsetGrid,
    article: OffsetGrid,
  };

  let Component = types.default;

  if (type in types) {
    Component = types[type];
  }

  return (
    <Component {...props} className={`grid ${type} ${className}`}>
      {children}
    </Component>
  );
};

const Grid = (props) => {
  const { type = 'default', children, className = '' } = props;
  return (
    <RenderGrid type={type} className={className} props={props}>
      {children}
    </RenderGrid>
  );
};

export default Grid;

Grid.propTypes = {
  type: PropTypes.oneOf(['default', 'product', 'recipe', 'content', 'offset', 'article']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
