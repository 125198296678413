import React from 'react';
import PropTypes from 'prop-types';
import { OverlayWrapper } from './Overlay.styles';

const Overlay = ({ onClick }) => {
  return <OverlayWrapper {...(onClick && { onClick: () => onClick() })} />;
};

export default Overlay;

Overlay.propTypes = {
  onClick: PropTypes.func,
};
