import styled from "styled-components";

export const DukeInBean = styled.svg`
	position: absolute;
	width: 170px;
	top: 66%;
	left: 6%;
    @media (max-width: 1300px) {
        left: 0;
    }
	@media (max-width: 1280px) {
		display: none;
	}
	.st0 {
		fill: #BB5400;
	}

	.st1 {
		fill: #1471D2;
	}

	.st2 {
		fill: #FFCA2E;
		stroke: #FFCA2E;
		stroke-width: 0;
		stroke-miterlimit: 10;
	}

	.st3 {
		fill: #D43631;
	}

	.st4 {
		fill: #C1272D;
	}

	.st5 {
		fill: none;
		stroke: #FFC60B;
		stroke-width: 10.4208;
		stroke-miterlimit: 10;
	}

	.st6 {
		fill: #003464;
	}

	.st7 {
		fill: #FFCA2E;
		stroke: #FFCA2E;
		stroke-miterlimit: 10;
	}

	.st8 {
		clip-path: url(#SVGID_00000176007390898160879640000003784198656966541186_);
	}

	.st9 {
		fill: #012A72;
	}

	.st10 {
		fill: #FFFFFF;
	}

	.st11 {
		fill: #FFCA2E;
	}

	.st12 {
		fill: #F2EBDF;
	}

	.st13 {
		fill: none;
	}

	.st14 {
		opacity: 0.34;
		fill: #FFF0E5;
	}

	.st15 {
		clip-path: url(#SVGID_00000147923093458511801870000009392586254249301413_);
	}

	.st16 {
		clip-path: url(#SVGID_00000041293094171341285120000005260918535187523242_);
		fill: #FCD800;
	}

	.st17 {
		clip-path: url(#SVGID_00000041293094171341285120000005260918535187523242_);
		fill: #B68400;
	}

	.st18 {
		clip-path: url(#SVGID_00000041293094171341285120000005260918535187523242_);
		fill: #FFD100;
	}

	.st19 {
		clip-path: url(#SVGID_00000041293094171341285120000005260918535187523242_);
		fill: #002E6D;
	}

	.st20 {
		clip-path: url(#SVGID_00000041293094171341285120000005260918535187523242_);
		fill: #1B3768;
	}

	.st21 {
		fill: #FFC512;
	}

	.st22 {
		clip-path: url(#SVGID_00000048481957913552157970000016387878614707295930_);
	}

	.st23 {
		fill: #FFC000;
	}

	.st24 {
		fill: #D26E00;
	}

	.st25 {
		fill: #A03B1D;
	}

	.st26 {
		fill: #22376E;
	}

	.st27 {
		fill: #0072BB;
	}

	.st28 {
		fill: #EFA300;
	}

	.st29 {
		fill: #567089;
	}

	.st30 {
		fill: #CC3530;
	}

	.st31 {
		fill: #F2ECDF;
	}

	.st32 {
		opacity: 0.25;
		clip-path: url(#SVGID_00000120549356384245093580000016773799034730909088_);
	}

	.st33 {
		fill: #D4470F;
	}

	.st34 {
		fill: #FFC60B;
	}

	.st35 {
		fill: none;
		stroke: #FFCA2E;
		stroke-width: 1.1436;
		stroke-miterlimit: 10;
	}

	.st36 {
		fill: #FFCA2E;
		stroke: #FFCA2E;
		stroke-width: 1.087;
		stroke-miterlimit: 10;
	}

	.st37 {
		fill: #0E0048;
	}

	.st38 {
		fill: #121A37;
	}

	.st39 {
		fill: url(#SVGID_00000002365210904888290650000013006815965601894539_);
	}

	.st40 {
		fill: #EA2C33;
	}

	.st41 {
		fill: #D24A27;
	}

	.st42 {
		fill: #FCD700;
	}

	.st43 {
		fill: #C48F0E;
	}

	.st44 {
		fill: #FFDE00;
	}

	.st45 {
		fill: #25336D;
	}

	.st46 {
		fill: #24346D;
	}

	.st47 {
		fill: #C55026;
	}

	.st48 {
		fill: #B68400;
	}

	.st49 {
		fill: #F6D8C0;
	}

	.st50 {
		fill: #872B05;
	}

	.st51 {
		fill: none;
		stroke: #FFC000;
		stroke-width: 0;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	.st52 {
		fill: #D26E00;
		stroke: #A03B1D;
		stroke-width: 1.3821;
		stroke-miterlimit: 10;
	}

	.st53 {
		fill: #D26E00;
		stroke: #000000;
		stroke-width: 1.3821;
		stroke-miterlimit: 10;
	}

	.st54 {
		fill: #F6D8C0;
		stroke: #012A72;
		stroke-width: 3.1346;
		stroke-miterlimit: 10;
	}

	.st55 {
		fill: #F6D8C0;
		stroke: #012A72;
		stroke-width: 1.5673;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
	}

	.st56 {
		fill: #FFFFFF;
		stroke: #012A72;
		stroke-width: 1.5673;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
	}

	.st57 {
		fill: none;
		stroke: #012A72;
		stroke-width: 3.1346;
		stroke-miterlimit: 10;
	}
`;