import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.yellow,
    },
    yellow: {
      background: theme.colours.red,
    },
    blue: {
      background: theme.colours.yellow,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const ProductNutritionLabelPromoComponent = styled.section`
  display: flex;
  flex-direction: column-reverse;

  ${({ backgroundImage }) =>
    backgroundImage?.src
      ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `
      : ''}

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    background-color: ${({ theme, variant, backgroundColor }) =>
      theme.colours.checkColourExists(backgroundColor) || getVariantColours({ theme, variant }).background};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 5vw, ${theme.spacing(100)});`};
  }

  > div {
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;

      ${({ alignment }) => `
      ${
        alignment === 'center'
          ? `width: 50%;
      display: block;
      flex: none;
      margin: 0 auto;`
          : ''
      }
    `}
    }

    h1 {
      text-transform: uppercase;
    }
  }
`;

export const Block = styled.div`
  position: relative;
  overflow: hidden;

  > img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: absolute;
      top: 0;
      left: 0;
      max-height: unset;
    }
  }
`;