import React from 'react';
import PropTypes from 'prop-types';
// import { Link as ReactRouterLink } from 'react-router-dom';

const Link = (props) => {
  /*
  Out of the box JSS does not support client side rendering
  To save time for the initial lunch we are forcing any path to be SSR. 
  This might have some performance implications so we need to explore 
  getting the client side routing working post lunch
  */
  // if (props?.path) {
  //   return <ReactRouterLink to={props.path} {...props} />;
  // }

  if (props?.path) {
    return (
      <a {...props} href={props?.path} aria-label={props.ariaLabel}>
        {props.children}
      </a>
    );
  }
  return <a {...props}>{props.children}</a>;
};

Link.propTypes = {
  path: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
};

export default Link;
