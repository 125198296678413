import styled from "styled-components";

export const SoDarnGoodBBLogo = styled.svg`
	.st1{fill:#1471d2}
    .st9{fill:#012a72}
    .st12{fill:#f2ebdf}
    .st40{fill:#ea2c33}
    .st41{fill:#d24a27}
    .st45{fill:#25336d}
    .st46{fill:#24346d}

`;