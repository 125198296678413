import React from 'react';
import styled from 'styled-components';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';

import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import Link from 'components/shared/Link';

export const ContentCardComponent = styled.article`
  > div {
    display: flex;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(26)};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(60)};
    }

    > a {
      display: flex;
      width: 100%;
    }

    img {
      align-self: center;
      width: 100%;
      height: auto;
    }
  }

  a {
    text-decoration: none;
  }

  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(16)};
    color: ${({ theme }) => theme.colours.red};
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(46)};
    }
  }

  p {
    color: ${({ theme }) => theme.colours.brown};
    text-align: left;
  }
`;


const ContentCard = ({media, title = "", copy = "", link = {}, className = ""}) => {
    
    return (
        <ContentCardComponent>
            {media?.value?.src && (
                <div>
                    <ConditionalComponentWrapper
                        condition={link?.value?.path || link?.value?.href}
                        wrapper={children => (
                            <Link {...link?.value} className="card-link" ariaLabel="Visit Link">
                                {children}
                            </Link>
                        )}
                    >
                        <Image field={media}/>
                    </ConditionalComponentWrapper>
                </div>
            )}

            <ConditionalComponentWrapper
                condition={link?.value?.path || link?.value?.href}
                wrapper={children => (
                    <Link {...link?.value} className="card-link">
                        {children}
                    </Link>
                )}
            >
                <>
                    {title && (typeof title === 'object' ? title.value : title).length > 2 && (
                        <h3 className="c-card-h"
                            dangerouslySetInnerHTML={{__html: typeof title === 'object' ? title.value : title}}></h3>
                    )}
                    {copy && (typeof copy === 'object' ? copy.value : copy).length > 2 && (
                        <p className="c-card-p"
                           dangerouslySetInnerHTML={{__html: typeof copy === 'object' ? copy.value : copy}}></p>
                    )}

                </>
            </ConditionalComponentWrapper>
        </ContentCardComponent>
    );
};


export default ContentCard;