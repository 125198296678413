import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    CenteredVideoComponentWrapper,
    CenteredVideoOuterWrapper,
    ModalWrapper,
} from './CenteredVideoComponent.styles.js';
import {RichText} from 'components/shared/JssOverrides';
import InlineSVG from 'react-inlinesvg';
import Button from 'components/shared/Button';
import YoutubePlayer from './YoutubePlayer.jsx';
import PlayBtnSvg from "./InlineSvg/PlayBtnSvg.jsx";
import NineteenNfourSvg from "./InlineSvg/NineteenNfourSvg.jsx";
import JayDukeSecretFamilyRecipeSvg from "./InlineSvg/JayDukeSecretFamilyRecipeSvg.jsx";
import ThirtyKSvg from "./InlineSvg/ThirtyKSvg.jsx";
import BeautifulBeanFootageTVSvg from "../../FeaturedDetailHeroWithVideo/InlineSvg/BeautifulBeanFootageTVSvg.jsx";



const ContestModal = ({ showModal, onClose, modalHeading, modalCopy, modalLink, modalButton }) => {
    if (!showModal) return null;
    return (
        <ModalWrapper>
            <div className="modal-content">
                <JayDukeSecretFamilyRecipeSvg />
                <div className="modal-top">
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                    <div className="stripe"></div>
                    <button className="close close-modal" onClick={onClose} aria-label="Close Modal"><span>✕</span></button>
                </div>
                <div className="modal-body">
                    {modalHeading ? (
                        <h2 className="chunk" dangerouslySetInnerHTML={{ __html: modalHeading }}></h2>
                    ) : null}
                    {modalCopy ? (
                        <p className="modal-copy" dangerouslySetInnerHTML={{ __html: modalCopy }}></p>
                    ) : null}
                    {modalLink && modalButton && (
                        <Button id="modalbtn" target="_blank" text={modalButton} href={modalLink} variant="yellow" />
                    )}
                </div>
            </div>
        </ModalWrapper>
    );
};


const CenteredVideoComponent = (
    {
        title,
        posterText,
        backgroundColor,
        buttonText,
        buttonUrl,
        buttonColor,
        contestVideoPopup,
        videoId,
        poster,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
        bottomLink1,
        bottomLink2,
        textUnderVideo,
        disclosure,
        backgroundImage,
        modalHeading,
        modalCopy,
        modalLink,
        modalButton,
        iframetitle,
        ...props
    }
) => {
    const {fields = {}, params = {}} = props;
    const {alignment = 'center'} = params;
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const {globalContent = {}} = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const [activeSticker, setActiveSticker] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [includeModal, setIncludeModal] = useState(false);


    const randomId = Math.random().toString(36).substring(2, 12);


    const [modalVisible, setModalVisible] = useState(false);
    const [isVideoClicked, setIsVideoClicked] = useState(false);

    const handleVideoClick = () => {
        setIsVideoClicked(true);
    };

    const handleVideoEnd = () => {
        setModalVisible(true);
    };

    let vidPoster = poster || (videoId ? `http://img.youtube.com/vi/${videoId}/sddefault.jpg` : '');

    useEffect(() => {
        if (includeModal === 'true') {
            setIncludeModal(true);
            const timer = setTimeout(() => {
                setShowModal(true);
                setModalVisible(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [id]);




        const videoEnded = () => {
            if (id === "sweepstakes") {
            setShowModal(true);
            setModalVisible(true);
            }
            setIsVideoClicked(false);
            isVideoClicked(false);
        };

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <CenteredVideoOuterWrapper backgroundColor={backgroundColor} className="centered-video-wrapper">
                <CenteredVideoComponentWrapper>
                    <div className="container">
                        {title && <h2>{title}</h2>}
                        <RichText field={field} tag={tag} className="text-content" editable={editable} />
                        {buttonText && buttonUrl && (
                            <Button text={buttonText} href={buttonUrl} variant={buttonColor} />
                        )}
                        <div className="video-holder">
                            <div className="video-div" style={{ backgroundImage: `url(${poster})` }}>
                                <div className="inner-content">
                                    <InlineSVG
                                        className="svgplaybutton"
                                        cors="anonymous"
                                        width="100"
                                        height="100"
                                        src="https://www.bushbeans.com/-/jssmedia/BushsBeans/rtbbf/Retro-Button-Red.svg"
                                    />
                                    {posterText && <span className="chunk">{posterText}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </CenteredVideoComponentWrapper>
            </CenteredVideoOuterWrapper>
        );
    }

    return (
        <CenteredVideoOuterWrapper backgroundColor={backgroundColor} backgroundImage={backgroundImage} className="centered-video-wrapper" id={id}>
            {id === 'sweepstakes' && <NineteenNfourSvg/>}
            {id === 'sweepstakes' && <BeautifulBeanFootageTVSvg className="beanTV" />}
            {id === 'sweepstakes-end' && (
                <img
                    className="sdg-graphic"
                    src="https://www.bushbeans.com/-/media/BushsBeans/rtbbf/so-darn-good-w-burger.png"
                    alt="So Darn Good with Burger"
                  />
            )}
            {id === 'sweepstakes-end' && (
                <img
                    className="secret-family-recipe-can"
                    src="https://www.bushbeans.com/-/media/BushsBeans/rtbbf/secret-family-recipe-can-graphic.png"
                    alt="Secret Family Recipe"
                />
            )}
            <CenteredVideoComponentWrapper>
                <div className="container">
                    {title && <h2>{title}</h2>}
                    <RichText field={field} tag={tag} editable={editable}/>
                    {!textUnderVideo && buttonText && buttonUrl && (
                        <Button text={buttonText} href={buttonUrl} variant={buttonColor}/>
                    )}
                    <div className="video-holder">
                        {id === 'sweepstakes' && <ThirtyKSvg/>}
                        <div
                            className="video-div"
                            style={{backgroundImage: `url(${vidPoster})`}}
                            onClick={handleVideoClick}
                            data-videoid={videoId}
                        >

                            {!isVideoClicked ? (
                                <div className="inner-content">
                                    <button className="play-video-button" aria-label="Play video">
                                        <span className="shadow"></span>
                                        <span className="self">
                                            <PlayBtnSvg/>
                                        </span>
                                    </button>
                                    {posterText && <span>{posterText}</span>}
                                </div>
                            ) : (
                                <YoutubePlayer title={iframetitle} isClicked={isVideoClicked} videoId={videoId} onVideoEnd={videoEnded}/>
                            )}
                        </div>
                    </div>
                    {textUnderVideo ? (
                        <p className="videoSubText" dangerouslySetInnerHTML={{ __html: textUnderVideo }}></p>
                    ) : null}

                    {textUnderVideo && buttonText && buttonUrl && (
                        <Button id="sweepbtn" target="_blank" text={buttonText} href={buttonUrl} variant={buttonColor}/>
                    )}
                    {bottomLink1 && bottomLink2 && (
                        <div className="bottom-links">
                            <a href={bottomLink1.split(';')[1]}>{bottomLink1.split(';')[0]}</a>
                            <a href={bottomLink2.split(';')[1]}>{bottomLink2.split(';')[0]}</a>
                        </div>
                    )}
                    {disclosure ? (
                        <p className="disclosureText" dangerouslySetInnerHTML={{ __html: disclosure }}></p>
                    ) : null}
                </div>
            </CenteredVideoComponentWrapper>
            {(contestVideoPopup || includeModal) && (
                <ContestModal
                    showModal={showModal}
                    onClose={() => setShowModal(false)}
                    modalHeading={modalHeading}
                    modalCopy={modalCopy}
                    modalLink={modalLink}
                    modalButton={modalButton}
                />
            )}


        </CenteredVideoOuterWrapper>
    );
};

CenteredVideoComponent.propTypes = {
    sitecoreContext: PropTypes.object,
    fields: PropTypes.shape({
        socialMediaContent: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};


export default withSitecoreContext()(CenteredVideoComponent);