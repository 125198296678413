import styled from 'styled-components';

export const TextareaComponent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & label {
    position: relative;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.spacing(12)};
    color: ${({ theme }) => theme.colours.adaBrown};
    margin-left: ${({ theme }) => theme.spacing(20)};
  }

  & .help-text {
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.spacing(10)};
    color: ${({ theme }) => theme.colours.adaBrown};
    margin-left: ${({ theme }) => theme.spacing(20)};
  }

  & textarea {
    width: 100%;
    height: 100%;
    border: none;
    margin-top: ${({ theme }) => theme.spacing(10)};
    //background-color: ${({ theme }) => theme.colours.white};
    background: #fff;
    border-radius: ${({ theme }) => theme.spacing(8)};
    color: #757575;
  }

  & .error-message {
    margin-top: ${({ theme }) => theme.spacing(10)};
    font-size: ${({ theme }) => theme.spacing(10)};
    color: #ef3826;
    margin-left: ${({ theme }) => theme.spacing(20)};
    margin-bottom: 0;
  }

  & .counter {
    position: absolute;
    right: 0;
    font-size: ${({ theme }) => theme.spacing(10)};
  }
`;