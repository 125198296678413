export const trademarkFilterHelper = (originalString, trademarkList) => {
    try {
        if (Array.isArray(trademarkList) && trademarkList?.length > 0) {
            const list = trademarkList.map(regEx => {
                if (regEx === '/(®|™)?/<sup\\>$1\\<\\/sup\\>/gi') {
                    return {
                        //The regex for adding a sup element around the signs fails so we omit it
                        regex: undefined,
                        replacement: undefined,
                    };
                }
                return {
                    regex: RegExp(`${regEx.split('/')[1]}`, 'gi'),
                    replacement: `${regEx.split('/')[2]}`,
                };
            });

            const updatedString = list.reduce((string, {regex, replacement}) => {
                if (typeof string !== 'undefined') {
                    return string.replace(regex, replacement);
                }
                return '';
            }, originalString);

            if (typeof updatedString !== 'undefined') {
                return updatedString
                    .replace(/®/gi, '<sup>®</sup>')
                    .replace(/™/gi, '<sup>™</sup>')
                    .replace(/&reg;/gi, '<sup>®</sup>')
                    .replace(/&trade;/gi, '<sup>™</sup>');
            } else {
                return '';
            }
        }
        return originalString || '';
    } catch (error) {
        console.log('FILTER ERROR', error);
        return originalString;
    }
};
