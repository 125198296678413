import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';
import { AccordionSectionComponent } from './AccordionSection.styles.js';
import { Text, RichText } from 'components/shared/JssOverrides';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Icon from 'components/shared/Icon';

const AccordionSection = ({ fields, rendering, sitecoreContext }) => {
  const [isOpen, setOpen] = useState(
      isExperienceEditorActive() || sitecoreContext?.pageEditing ? true : rendering?.params?.isOpen
  );
  const [height, setHeight] = useState(0);
  const accordionRef = useRef(null);
  const { title, content } = fields;
  const springProps = useSpring({
    config: { ...config.default },
    height: isOpen ? height : 0,
  });

  const offsetHeight = accordionRef?.current?.offsetHeight;

  useLayoutEffect(() => {
    setHeight(isOpen ? offsetHeight : 0);
  }, [offsetHeight, isOpen]);

  return (
      <AccordionSectionComponent isOpen={isOpen} className={`accordion-section ${isOpen ? 'active' : ''}`}>
        <div className="section-wrap">
          {title && (
              <button onClick={() => setOpen((state) => !state)}>
                <Text field={title} tag="h2" />
                <Icon icon={isOpen ? 'minus-alt' : 'plus-alt'} />
              </button>
          )}
          {content && (
              <animated.div style={springProps}>
                <div ref={accordionRef}>
                  <RichText className="accordion-section-content" field={content} tag="div" ref={accordionRef} />
                </div>
              </animated.div>
          )}
        </div>
      </AccordionSectionComponent>
  );
};

AccordionSection.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  firstOpenIem: PropTypes.bool,
  rendering: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(AccordionSection);