import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, WrapperInner, Wave } from './RecipeWrapper.styles';

import Separator from 'components/shared/Separator';

const RecipeWrapper = ({ children }) => {
  return (
    <Wrapper className="shrink-on-print">
      <WrapperInner className="shrink-on-print">
        <Wave position="top" className="hide-on-print">
          <Separator type="wave" />
        </Wave>
        {children}
        <Wave position="bottom" className="hide-on-print">
          <Separator type="wave" />
        </Wave>
      </WrapperInner>
    </Wrapper>
  );
};

export default RecipeWrapper;

RecipeWrapper.propTypes = {
  children: PropTypes.node,
};
