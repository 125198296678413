import styled from 'styled-components';

export const MainNavComponent = styled.header`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.spacing(85)};
  background-color: ${({ theme }) => theme.colours.yellow};
  .bushs-beans-foodservices &{
    background-color:  ${({ theme }) => theme.colours.blue};
  }
  & .hamburger {
    margin-left: ${({ theme }) => theme.spacing(24)};
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    flex: 1 0 0;
    margin-left: ${({ theme }) => theme.spacing(-50)};
    img {
      width: ${({ theme }) => theme.spacing(100)};
      height: ${({ theme }) => theme.spacing(56)};
    }
    @media (max-width: 1600px) and (min-width: 1200px) {
      position: static;
      margin-left: ${({ theme }) => theme.spacing(0)};
      padding-left: ${({ theme }) => theme.spacing(50)};
    }
  }

  nav {
    height: 100%;
    margin-left: ${({ theme }) => theme.spacing(50)};

    a {
      position: relative;
      display: inline-block;
      align-items: center;
      height: 100%;
      margin-left: ${({ theme }) => theme.spacing(50)};
      color: ${({ theme }) => theme.colours.brown};
      font-size: ${({ theme }) => theme.spacing(18)};
      line-height: ${({ theme }) => theme.spacing(85)};
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        &::before {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: ${({ theme }) => theme.spacing(4)};
          background-color: ${({ theme }) => theme.colours.red};
          content: '';
          :hover {
            background-color: green;
          }
        }
      }

      & svg {
        margin-top: ${({ theme }) => theme.spacing(-7)};
        padding-left: ${({ theme }) => theme.spacing(5)};
        color: ${({ theme }) => theme.colours.red};
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .nav-end {
    .find-in-store {
      position: relative;
      display: inline-block;
      align-items: center;
      height: 100%;
      margin-right: ${({ theme }) => theme.spacing(50)};
      color: ${({ theme }) => theme.colours.brown};
      font-size: ${({ theme }) => theme.spacing(18)};
      line-height: ${({ theme }) => theme.spacing(85)};
      text-transform: uppercase;
      text-decoration: none;

      @media (max-width: 1199px) {
        display: none;
      }
    }
  }
`;

export const ProductsSubNav = styled.div`
  /* display: ${({ active }) => (active ? 'block' : 'none')}; */
  position: absolute;
  top: ${({ theme }) => theme.spacing(85)};
  z-index: 99;
  width: 100%;
  max-height: ${({ active }) => (active ? '800px' : '0px')};
  overflow: hidden;
  background-color: #f1e8d7;
  transition: max-height 0.25s;

  & .container {
    height: auto;
    min-height: ${({ theme }) => theme.spacing(781)};
    color: ${({ theme }) => theme.colours.red};
    text-align: center;

    @media (min-width: 1125px) {
      min-height: 100%;
    }

    & h1 {
      margin-top: ${({ theme }) => theme.spacing(35)};
      margin-bottom: ${({ theme }) => theme.spacing(40)};
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};
      text-transform: uppercase;
      & sub {
        text-decoration: underline;
        vertical-align: super;
      }
      
      & .h1 {
        margin-top: ${({ theme }) => theme.spacing(35)};
        margin-bottom: ${({ theme }) => theme.spacing(40)};
        font-weight: 400;
        display: block;
        font-size: ${({ theme }) => theme.spacing(32)};
        text-transform: uppercase;
        & sub {
          text-decoration: underline;
          vertical-align: super;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        width: 400px;
        margin: ${({ theme }) => theme.spacing(40)} auto ${({ theme }) => theme.spacing(40)};
        font-size: ${({ theme }) => theme.spacing(40)};
      }

      @media (min-width: 1125px) {
        width: auto;
        margin: none;
        margin-top: ${({ theme }) => theme.spacing(60)};
        font-size: ${({ theme }) => theme.spacing(80)};
      }
    }

    & .product-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: ${({ theme }) => theme.spacing(300)};
      margin: ${({ theme }) => theme.spacing(0, 'auto')};

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: ${({ theme }) => theme.spacing(670)};
        margin-bottom: ${({ theme }) => theme.spacing(70)};
      }

      @media (min-width: 1125px) {
        flex-flow: row wrap;
        justify-content: flex-start;
        max-width: ${({ theme }) => theme.spacing(1640)};
      }

      & > div {
        width: 50%;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          width: 25%;
        }

        @media (min-width: 1125px) {
          flex: 1 1 0;
          width: auto;
        }
      }
    }
  }
`;

//About Us template
export const AboutUsSubNav = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(85)};
  width: 100%;
  max-height: ${({ active }) => (active ? '800px' : '0px')};
  overflow: hidden;
  text-align: center;
  background-color: #f1e8d7;
  transition: max-height 0.5s ease-in-out;

  .card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${({ theme }) =>
      theme.spacing({
        top: 37,
        left: 24,
        right: 24,
        bottom: 65,
      })};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: ${({ theme }) => theme.spacing(780)};
      margin: ${({ theme }) => theme.spacing(48, 'auto')};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: ${({ theme }) => theme.spacing(1715)};
    }

    > div {
      width: 100%;
      margin: 0 auto;

      @media (min-width: 767px) and (max-width: 844px) {
        width: calc(50% - 48px);
        margin: 0 24px;
      }

      @media (min-width: 1024px) and (max-width: 1551px) {
        width: calc(20% - 48px);
        margin: 0 24px;
      }

      @media (min-width: 1552px) {
        width: 25%;
      }
    }

    @media (min-width: 845px) and (max-width: 1024px) {
      & > div:nth-child(even) {
        width: calc(50% - 30px);
        padding: ${({ theme }) =>
          theme.spacing({
            top: 0,
            left: 30,
            right: 0,
            bottom: 0,
          })};
      }
    }
  }
`;

export const SearchButton = styled.button`
  cursor: pointer;

  .search {
    color: ${({ theme }) => theme.colours.blue};
    pointer-events: none;
  }
`;

export const FavouritesButton = styled.span`
  display: none;
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: inline-flex;
  }
`;