import styled from 'styled-components';

const animationTransition = '0.3s';

export const Container = styled.section`
  max-width: ${({ experienceEditor, isFullWidth }) =>
    isFullWidth && experienceEditor ? '90%' : isFullWidth ? `100%` : `800px`};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(50)} 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colours.tan};

  h1 {
    margin: ${({ theme }) => `0 ${theme.spacing(20)} ${theme.spacing(45)}`};
    color: ${({ theme }) => theme.colours.blue};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(90)};
    }
  }

  & ul {
    & .accordion-section {
      position: relative;
      margin: ${({ theme }) =>
        theme.spacing({
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })};
      overflow: hidden;
      border-top: ${({ theme }) => `4px solid ${theme.colours.red}`};

      button {
        position: relative;
        display: flex;
        flex-direction: row;
        min-width: 100%;
        padding: ${({ theme }) => theme.spacing(20)} ${({ theme }) => theme.spacing(0)};
        cursor: pointer;

        &:focus {
          outline: none;
        }

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          padding: ${({ theme }) => `${theme.spacing(55)} ${theme.spacing(0)}`};
        }

        h2 {
          margin: 0 0 ${({ theme }) => theme.spacing(10)};
          color: ${({ theme }) => theme.colours.red};
          text-align: left;
          text-transform: uppercase;
          pointer-events: none;
          max-width: 90%;
          line-height: 36px;

          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            margin: 0 ${({ theme }) => theme.spacing(35)} 0 0;
            text-align: left;
            line-height: 77px;
          }
        }

        svg {
          width: 36px;
          height: 36px;
          color: ${({ theme }) => theme.colours.blue};
          transition: all ${animationTransition};
          position: absolute;
          right: ${({ theme }) => theme.spacing(13)};

          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            width: 77px;
            height: 77px;
            right: ${({ theme }) => theme.spacing(45)};
          }
        }
      }

      & .accordion-section-content {
        opacity: 0;
        padding: 32px;
        & ul {
          list-style: initial;

          & li {
            margin-bottom: ${({ theme }) => theme.spacing(24)};
          }
        }
        & img {
          max-width: 100%;
          height: auto !important;
        }
      }

      & .section-wrap {
        padding: ${({ theme }) =>
          theme.spacing({
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          })};

        & button {
          padding: ${({ theme }) => theme.spacing(35)};
        }
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        & .section-wrap {
          padding: ${({ theme }) =>
            theme.spacing({
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            })};
        }

        margin: ${({ theme }) =>
          theme.spacing({
            top: 0,
            left: 110,
            right: 110,
            bottom: 0,
          })};
      }
    }

    & .active {
      margin: ${({ theme }) =>
        theme.spacing({
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })};
      padding-bottom: 55px;
      background-color: ${({ theme }) => theme.colours.white};
      border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

      svg {
        transform: rotate(-180deg);
      }

      & .section-wrap {
        padding: ${({ theme }) =>
          theme.spacing({
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          })};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        & .section-wrap {
          padding: ${({ theme }) =>
            theme.spacing({
              top: 0,
              left: 110,
              right: 110,
              bottom: 0,
            })};
        }
      }

      & .accordion-section-content {
        opacity: 1;
      }
    }
  }

  .active + li {
    border-top: none;
  }
`;

export const AccordionList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccordionItem = styled.li`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  max-width: ${({ theme }) => `calc(100% - ${theme.spacing(40)})`};
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  border-top: ${({ theme }) => `4px solid ${theme.colours.red}`};
  border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

  &:not(:last-child) {
    border-bottom: none;
  }

  &.active {
    max-width: 100%;
    background-color: ${({ theme }) => theme.colours.white};
    border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

    & + li {
      border-top: 0;
    }

    svg {
      transform: rotate(-180deg);
    }

    p {
      opacity: 1;
    }

    & 
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: ${({ isFullWidth }) => (!isFullWidth ? `100%` : `800px`)};

    &.active {
      > div {
        width: 100%;
        max-width: ${({ isFullWidth }) => (isFullWidth ? '90%' : `800px`)};
        margin: 0 auto;
      }

      button {
        padding: ${({ theme, isFullWidth }) =>
          isFullWidth
            ? `${theme.spacing(55)} ${theme.spacing(0)}`
            : `${theme.spacing(55)} ${theme.spacing(0)}`};
      }

      svg {
        right: 110px;
      }

      p {
        max-width: ${({ isFullWidth }) => (isFullWidth ? '50%' : '80%')};
        padding-bottom: ${({ theme }) => theme.spacing(55)};
        padding-left: ${({ theme, isFullWidth }) => (isFullWidth ? theme.spacing(0) : theme.spacing(0))};
      }
    }
  }

  button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    padding: ${({ theme }) => theme.spacing(20)} ${({ theme }) => theme.spacing(0)};
    cursor: pointer;

    &:focus {
      outline: none;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex-direction: unset;
      justify-content: space-between;
      padding: ${({ theme }) => `${theme.spacing(55)} ${theme.spacing(0)}`};
    }

    h2 {
      margin: 0 0 ${({ theme }) => theme.spacing(10)};
      color: ${({ theme }) => theme.colours.red};
      text-align: center;
      text-transform: uppercase;
      pointer-events: none;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin: 0 ${({ theme }) => theme.spacing(35)} 0 0;
        text-align: left;
      }
    }

    svg {
      width: 36px;
      height: 36px;
      color: ${({ theme }) => theme.colours.blue};
      transition: all ${animationTransition};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: 77px;
        height: 77px;
      }
    }
  }
`;
