import React, { useState, useRef } from 'react';
import { TextInputComponent } from '../Input.styles.js';
import { Text } from 'components/shared/JssOverrides';
import Tooltip from 'components/shared/Tooltip';

const TextInput = ({ label, placeholder, error, errorMessage, details, required, inputRef, ...props }) => {
  /* TODO figure out how to extend the label value if field is required */
  return (
    <TextInputComponent {...props}>
      {label && <label>{`${required ? '*' : ''}${label}`}</label>}
      <input {...props} placeholder={placeholder} ref={inputRef} />
      {error && <p className="error-message">{error}</p>}
      {details && <Tooltip {...details} />}
    </TextInputComponent>
  );
};

TextInput.propTypes = {};

export default TextInput;
