import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {VideoTimelineWrapper, VideoHolder, VideoButtonWrapper} from './VideoTimelineComponent.styles.js';
import VideoTimelineYoutubePlayer from './VideoTimelineYoutubePlayer.jsx';
import PlayBtnSvg from "./InlineSvg/PlayBtnSvg.jsx";
import DukeInBeanSvg from "./InlineSvg/DukeInBeanSvg.jsx";
import SoDarnGoodCSvg from "./InlineSvg/SoDarnGoodCSvg.jsx";
import {RichText} from "../../shared/JssOverrides";
import {CenteredVideoComponentWrapper, CenteredVideoOuterWrapper} from "./CenteredVideoComponent.styles";
import InlineSVG from "react-inlinesvg";
import Button from "../../shared/Button";



const VideoTimelineComponent = (
    {
        title,
        field,
        tag,
        editable,
        poster,
        timeLineVideo1,
        timeLineVideo2,
        timeLineVideo3,
        timeLineVideo4,
        timeLineVideo5,
        timeLineVideo6,
        sitecoreContext,
        ...props
    }
    ) => {
    const videoData = [];
    function processVideoString(videoString) {
        let parts = videoString.split("|");
        let itemDetails = {
            year: parts[0],
            id: parts[1],
            title: parts[2],
            poster: 'http://img.youtube.com/vi/' + parts[1] + '/sddefault.jpg'
        };
        videoData.push(itemDetails);
    }
    if (timeLineVideo1) {
        processVideoString(timeLineVideo1);
    }
    if (timeLineVideo2) {
        processVideoString(timeLineVideo2);
    }
    if (timeLineVideo3) {
        processVideoString(timeLineVideo3);
    }
    if (timeLineVideo4) {
        processVideoString(timeLineVideo4);
    }
    if (timeLineVideo5) {
        processVideoString(timeLineVideo5);
    }
    if (timeLineVideo6) {
        processVideoString(timeLineVideo6);
    }
    if (videoData.length === 0) {
        console.warn('Missing required video data for timeline');
        videoData.push(
            {year: '1994', id: 'PbesbQYJ1V0', title: 'Title for 1994 Video'},
            {year: '1995', id: '3hpLEQKtwjs', title: 'Title for 1995 Video'},
            {year: '2003', id: 'EayoWM6vtA0', title: 'Title for 2003 Video'},
            {year: '2007', id: '1OTPFK8Ow5o', title: 'Title for 2007 Video'},
            {year: '2017', id: 'zPNxipPZakk', title: 'Title for 2017 Video'},
            {year: '2024', id: 'SSctuVri3ys', title: 'Title for 2024 Video'},
        );
        console.log('Default video data:', videoData);
    }

    const [currentVideo, setCurrentVideo] = useState(videoData[0]);
    const buttonRefs = useRef([]);
    const [isVideoClicked, setIsVideoClicked] = useState(false);
    const [vidPoster, setVidPoster] = useState(poster ? poster : '');

    const handleVideoClick = () => {
        setIsVideoClicked(true);
    };

    const handleYearClick = (year) => {
        const newVideo = videoData.find((video) => video.year === year);
        setCurrentVideo(newVideo);
        setIsVideoClicked(true);
        setVidPoster(newVideo.poster);
    };

    const videoEnded = () => {
        setIsVideoClicked(false);
    };
    const experienceEditor = isExperienceEditorActive();

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <VideoTimelineWrapper className="video-timeline-component">
                <img className="so-darn-good-circle-plate"
                     src="https://www.bushbeans.com/-/media/BushsBeans/rtbbf/so-darn-good-circle-plate.png"
                     alt="So Darn Good" />
                {title && <h2 className="chunk">{title}</h2>}
                <RichText field={field} tag={tag} editable={editable}/>
                <VideoHolder>
                    <div
                        className="video-div"
                        style={{backgroundImage: `url(${vidPoster})`}}
                        onClick={handleVideoClick}
                    >

                            <div className="inner-content">
                                <button className="play-video-button">
                                    <span className="shadow"></span>
                                    <span className="self"><PlayBtnSvg/></span>
                                </button>
                            </div>

                    </div>
                </VideoHolder>
                <VideoButtonWrapper className="year-selector">
                </VideoButtonWrapper>
            </VideoTimelineWrapper>
        );
    }

    return (
        <VideoTimelineWrapper className="video-timeline-component">
            <img className="so-darn-good-circle-plate"
                 src="https://www.bushbeans.com/-/media/BushsBeans/rtbbf/so-darn-good-circle-plate.png"
                 alt="So Darn Good"
                 aria-label="So Darn Good"
            />
            <DukeInBeanSvg/>
            <SoDarnGoodCSvg/>
            {title && <h2 className="chunk">{title}</h2>}
            <RichText field={field} tag={tag} editable={editable}/>
            <VideoHolder>
                <div
                    className="video-div"
                    style={{backgroundImage: `url(${vidPoster})`}}
                    onClick={handleVideoClick}
                >
                    {!isVideoClicked ? (
                        <div className="inner-content">
                            <button className="play-video-button" aria-label="Play video">
                                <span className="shadow"></span>
                                <span className="self"><PlayBtnSvg/></span>
                            </button>
                        </div>
                    ) : currentVideo ? (
                        <VideoTimelineYoutubePlayer
                            isClicked={isVideoClicked}
                            videoId={currentVideo.id}
                            onVideoEnd={videoEnded}
                            autoplay
                        />
                    ) : (
                        <div>No video selected</div>
                    )}
                </div>
            </VideoHolder>

            <VideoButtonWrapper className="year-selector">
                {videoData.map((video, index) => (
                    <button
                        className="push-button chunk"
                        key={video.year}
                        ref={el => buttonRefs.current[index] = el}
                        onClick={() => handleYearClick(video.year)}
                        aria-label={`Select video for year ${video.year}`}
                    >
                        <span className="shadow"></span>
                        <span className="self">
              <span>{video.year}</span>
            </span>
                    </button>
                ))}
            </VideoButtonWrapper>
        </VideoTimelineWrapper>
    );
};

VideoTimelineComponent.propTypes = {
    title: PropTypes.string,
    field: PropTypes.object,
    tag: PropTypes.string,
    editable: PropTypes.bool,
    poster: PropTypes.string,
    timeLineVideo1: PropTypes.arrayOf(PropTypes.string),
    timeLineVideo2: PropTypes.arrayOf(PropTypes.string),
    timeLineVideo3: PropTypes.arrayOf(PropTypes.string),
    timeLineVideo4: PropTypes.arrayOf(PropTypes.string),
    timeLineVideo5: PropTypes.arrayOf(PropTypes.string),
    timeLineVideo6: PropTypes.arrayOf(PropTypes.string),
};

export default VideoTimelineComponent;