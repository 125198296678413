import React from 'react';
import styled from 'styled-components';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import translate from 'helpers/translate';

const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 80px 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colours.tan};

  h1 {
    max-width: 800px;
    margin: 0 auto 32px;
    color: ${({ theme }) => theme.colours.red};
    text-transform: uppercase;
  }
`;

// needs to be two states
// when a filter is applied & not
const Error = ({ sitecoreContext = {} }) => {
  const { viewBag = {} } = sitecoreContext;
  return (
    <Wrapper>
      <h1>{translate(viewBag, 'BushsBeans.Search.Labels.ErrorTitle', 'Oops, theres been an error')}</h1>
      <p>{translate(viewBag, 'BushsBeans.Search.Labels.ErrorDescription', 'Sorry, please try again')}</p>
    </Wrapper>
  );
};

export default withSitecoreContext()(Error);
