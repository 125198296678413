import styled from 'styled-components';

export const NutritionLabelTableComponent = styled.div`
  #facts {
    z-index: 10;
    background: #fff;
    border: 3px solid #000;
    padding: 20px 0px;
    font-size: 15px;
    line-height: 15px;
    box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
    font-family: helvetica, arial, sans-serif;
    color: #000;
    width: 300px;
  }

  #facts .facts_title {
    font-size: 35px;
    line-height: 38px;
    color: #231f20;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  #facts .facts_serving_size {
    padding: 0 10px 10px;
    font-size: 16px;
    line-height: 18px;
  }

  #facts .facts_serving_pre {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #facts .txt_right {
    text-align: right;
  }

  #facts .facts_box {
    border-top: 5px solid #000;
    margin-left: 10px;
    margin-right: 10px;
  }

  #facts .facts_box.thin {
    border-width: 5px;
  }

  #facts .fact_row {
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 14px;
    border-bottom: 1px solid #000;
    position: relative;

    &.no-border {
      padding-bottom: 0;
      border-bottom: none;
    }

    &.fact-row__sub-center-percentage {
      p {
        margin: 5px 0 5px;
        font-size: 14px;
        line-height: 14px;
      }

      .fact_row__percentage {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  #facts .fact_row_norule {
    padding-bottom: 2px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 14px;
    position: relative;
  }

  #facts .fact_row.sub {
    padding-left: 18px;
  }
  #facts .fact_row.sublv2 {
    padding-left: 18px;
  }
  #facts .fact_row.sublv3 {
    padding-left: 36px;
  }

  #facts .fact_row small {
    font-size: 12px;
    line-height: 12px;

    color: #000;
  }

  #facts .fact_row div {
    float: right;
  }

  #facts strong {
    color: #000;
    font-weight: bold;
  }
`;
