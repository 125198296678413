import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';

export const ContactUsFormComponent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colours.tan};

  h1 {
    margin-top: ${({ theme }) => theme.spacing(16)};
    margin-bottom: 0;
    color: ${({ theme }) => theme.colours.yellow};
    font-size: ${({ theme }) => `clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(100)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(44)}, 6vw, ${theme.spacing(108)})`};
    text-transform: uppercase;
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(40)};
    color: ${({ theme }) => theme.colours.red};
    text-transform: uppercase;

    &.require-title {
      margin-bottom: ${({ theme }) => theme.spacing(10)};
    }
  }

  p {
    &.require-title {
      font-size: 16px;
    }
  }

  & .intro-copy {
    text-align: center;
    margin: 0 auto;
    margin-top: ${({ theme }) => `clamp(${theme.spacing(44)}, 4vw, ${theme.spacing(60)})`};
    padding-bottom: ${({ theme }) => `clamp(${theme.spacing(44)}, 4vw, ${theme.spacing(60)})`};
    max-width: ${({ theme }) => theme.spacing(700)};
  }

  & .subject-section {
    padding-bottom: ${({ theme }) => `clamp(${theme.spacing(44)}, 4vw, ${theme.spacing(80)})`};
    text-align: center;

    & > div {
      max-width: 320px;
      margin: 0 auto;
      text-align: left;
    }
  }

  & .about-section {
    padding-bottom: ${({ theme }) => `clamp(${theme.spacing(44)}, 4vw, ${theme.spacing(80)})`};
  }

  & .field-row {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing(840)};
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex-flow: row wrap;
    }

    & > div {
      width: 100%;
      text-align: left;
      margin-bottom: ${({ theme }) => theme.spacing(24)};

      & > div {
        max-width: 320px;
        margin: 0 auto;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: 50%;
      }
    }
  }

  & #usps-note {
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(24)};
    line-height: 20px;
    text-align: left;
  }

  & .contact-form-divider {
    max-width: ${({ theme }) => theme.spacing(325)};
    height: 2px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colours.red};
    margin-top: ${({ theme }) => theme.spacing(32)};
    margin-bottom: ${({ theme }) => theme.spacing(48)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: ${({ theme }) => theme.spacing(730)};
    }
  }

  & .textarea {
    max-width: ${({ theme }) => theme.spacing(328)};
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: ${({ theme }) => theme.spacing(730)};
    }
  }

  & .form-footer {
    max-width: ${({ theme }) => theme.spacing(320)};
    margin: 0 auto;
    text-align: left;
    padding-bottom: ${({ theme }) => `clamp(${theme.spacing(44)}, 4vw, ${theme.spacing(60)})`};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: ${({ theme }) => theme.spacing(730)};
    }

    & button.clear-button {
      background-color: transparent;
      border: none;
    }
  }

  div {
    font-size: 14px;
    line-height: 20px;
  }

  & .submit-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(44)})`};
    padding-bottom: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(44)})`};
  }

  & .success-message {
    margin-top: ${({ theme }) => theme.spacing(100)};
    & p {
      margin: 0 auto;
      padding: ${({ theme }) => theme.spacing(24)};
      max-width: ${({ theme }) => theme.spacing(750)};
      text-align: center;
    }

    & .contact-form-divider {
      margin-bottom: ${({ theme }) => theme.spacing(0)};
    }
  }

  & .failure-message {
    margin-top: ${({ theme }) => theme.spacing(100)};
    & p {
      margin: 0 auto;
      padding: ${({ theme }) => theme.spacing(24)};
      max-width: ${({ theme }) => theme.spacing(750)};
      text-align: center;
    }
  }
`;

export const FormContent = styled.div`
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('1024px')} {
    max-width: 840px;
  }
`;

export const ContactUsCardsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing({ top: 32, bottom: 0, left: 0, right: 0 })};
  padding: ${({ theme }) => theme.spacing({ top: 64, bottom: 64, left: 0, right: 0 })};
  background-color: ${({ theme }) => hexToRgba(theme.colours.white, 0.25)};

  article {
    h3,
    .content {
      text-align: center;
    }

    h3 {
      font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 6vw, ${theme.spacing(32)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 6vw, ${theme.spacing(36)})`};
    }
  }
`;
