export const colours = {
  white: '#faf8f4',
  yellow: '#ffca2e',
  tan: '#f1e8d7',
  brown: '#944000',
  red: '#d43631',
  blue: '#1471d2',
  copper: '#bb5400',
  adaBrown: '#8d2f18',
  adaTan: '#faf8f4',
  transparent: 'transparent',
};

export const checkColourExists = (colour) => (colour in colours ? colours[colour] : false);
