import styled from 'styled-components';

export const DesktopNavComponent = styled.header`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.spacing(85)};
  background-color: ${({ theme }) => theme.colours.yellow};
  z-index: ${({ theme }) => theme.zindex.navigation.navbar};

  & .hamburger {
    margin-left: ${({ theme }) => theme.spacing(24)};
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    flex: 1 0 0;
    margin-left: ${({ theme }) => theme.spacing(-50)};
    img {
      width: ${({ theme }) => theme.spacing(100)};
      height: ${({ theme }) => theme.spacing(56)};
    }
  }
	#nav-menu-dropdown {
		@media (max-width: 1199px) {
			display: none;
		}
	}
	nav {
    display: inherit;
    height: 100%;
    margin-left: ${({ theme }) => theme.spacing(40)};

    @media (min-width: 1401px) and (max-width: 1600px) {
      margin-left: ${({ theme }) => theme.spacing(30)};
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
      margin-left: ${({ theme }) => theme.spacing(20)};
    }
    a {
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 100%;
      margin-left: ${({ theme }) => theme.spacing(40)};
      color: #fff;
      font-size: ${({ theme }) => theme.spacing(20)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      line-height: ${({ theme }) => theme.spacing(85)};
      text-transform: uppercase;
      text-decoration: none;
      word-break: break all;
      text-align: center;

      @media (min-width: 1401px) and (max-width: 1600px) {
        margin-left: ${({ theme }) => theme.spacing(25)};
      }
      @media (min-width: 1200px) and (max-width: 1400px) {
        margin-left: ${({ theme }) => theme.spacing(20)};
        max-width: ${({ theme }) => theme.spacing(120)};
        line-height: ${({ theme }) => theme.spacing(20)};
      }
		&::before {
            transition: all 0.4s ease-in-out;
			position: absolute;
			bottom: 0;
			width: 100%;
			height: ${({ theme }) => theme.spacing(4)};
			background-color: transparent;
			content: '';
		}
      &:hover {
        &::before {
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }

      &:first-of-type {
        @media (min-width: 1200px) and (max-width: 1400px) {
          margin-left: ${({ theme }) => theme.spacing(10)};
          margin-right: ${({ theme }) => theme.spacing(30)};
        }
      }

      &:last-of-type {
        @media (min-width: 1200px) and (max-width: 1400px) {
          margin-right: ${({ theme }) => theme.spacing(20)};
        }
      }

      svg {
        position: relative;
        left: 8px;
        color: #fff;
      }
    }

    button {
      font-size: ${({ theme }) => theme.spacing(20)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      word-break: break all;
      display: inline-flex;
      @media (min-width: 600px) and (max-width: 1200px) {
        margin-left: ${({ theme }) => theme.spacing(25)};
      }
      @media (min-width: 1200px) {
        margin-left: ${({ theme }) => theme.spacing(30)};
        max-width: ${({ theme }) => theme.spacing(120)};
        line-height: ${({ theme }) => theme.spacing(20)};
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .nav-end {
    display: inherit;
    height: 100%;
    margin-left: ${({ theme }) => theme.spacing(25)};
    a {
      text-align: center;
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 100%;
      margin-left: ${({ theme }) => theme.spacing(50)};
      color: #fff;
      font-size: ${({ theme }) => theme.spacing(18)};
      line-height: ${({ theme }) => theme.spacing(85)};
      text-transform: uppercase;
      text-decoration: none;
      padding-top: 1px;
      transition: all 0.4s ease-in-out;
		&::before {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: ${({ theme }) => theme.spacing(4)};
			background-color: transparent;
			content: '';
			transition: all 0.4s ease-in-out;
		}  
      &:hover {
        &::before {
          background-color: ${({ theme }) => theme.colours.yellow};
        }
      }
      @media (min-width: 1200px) and (max-width: 1400px) {
        margin-left: ${({ theme }) => theme.spacing(40)};
      }
		@media (max-width: 1199px) {
			display: none;
		}
    }
    .dropdown-button {
		transition: all 0.4s ease-in-out;
		&::before {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: ${({ theme }) => theme.spacing(4)};
			background-color: transparent;
			content: '';
			transition: all 0.4s ease-in-out;
		}
		&:hover {
			&::before {
				background-color: ${({theme}) => theme.colours.yellow};
			}
		}
	}    
    button {
		font-family: ${({ theme }) => theme.typography.fontFamily.heading};
		color: #fff;
		svg{
			color: #fff;
		}
	}    
      
    .button-link {
        margin-left: 10px;
        &:before {
            display: none;
		}
        &:hover {
			&:before {
				display: none;
			}
		}    
	}    
    #MainNavBtn{
        border: 2px solid ${({ theme }) => theme.colours.yellow};
        color: ${({ theme }) => theme.colours.yellow};
        font-size: 18px !important;
		span {
			font-size: 18px !important;
			&:nth-child(1) {
				font-size: 18px !important;
			}
			svg {
				color: ${({theme}) => theme.colours.yellow};
			}
		}
        &:hover{
            color: ${({ theme }) => theme.colours.blue};
			border: 2px solid ${({ theme }) => theme.colours.blue};
			span {
				svg {
					color: ${({theme}) => theme.colours.blue};
				}
			}
		}
    }
    .reg-link {
        font-size: ${({ theme }) => theme.spacing(20)};
        font-family: ${({ theme }) => theme.typography.fontFamily.heading};
        margin-left: 0.625rem;
	    margin-right: 0.9375rem;
        padding: 1px;
		transition: all 0.4s ease-in-out;
		&::before {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: ${({ theme }) => theme.spacing(4)};
			background-color: transparent;
			content: '';
			transition: all 0.4s ease-in-out;
		}
		&:hover {
			&::before {
				background-color: ${({theme}) => theme.colours.yellow};
			}
		}
		@media (max-width: 1199px) {
			display: none;
		}
	}
  
    .nav-end-link {
      text-align: center;
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: ${({ theme }) => theme.spacing(0)};
      color: #fff;
      font-size: ${({ theme }) => theme.spacing(20)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      text-transform: uppercase;
      text-decoration: none;

      @media (max-width: 1199px) {
        display: none;
      }

      @media (min-width: 1401px) and (max-width: 1600px) {
        margin-left: ${({ theme }) => theme.spacing(15)};
      }

      @media (min-width: 1200px) and (max-width: 1400px) {
        margin-left: ${({ theme }) => theme.spacing(10)};
        margin-right: ${({ theme }) => theme.spacing(15)};
        max-width: ${({ theme }) => theme.spacing(195)};
        line-height: ${({ theme }) => theme.spacing(20)};
      }
    }

    & button {
      &:focus {
        outline: none;
      }
        margin-left: ${({ theme }) => theme.spacing(10)};
        margin-right: ${({ theme }) => theme.spacing(15)};
      }
    }
  }
`;

export const GlobalSubNav = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(85)};
  width: 1300px;
  max-width: 100%;
  left: 0;
  right: 0;
  margin: auto;  
  max-height: ${({ active }) => (active ? '800px' : '0px')};
  overflow: hidden;
  text-align: center;
  background-color: #f1e8d7;
  transition: max-height 0.5s ease-in-out;
  z-index: ${({ theme }) => theme.zindex.navigation.content};
  .inner{
    padding: 80px;
  }  
  .card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: 30px;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      flex-direction: row;
      flex-flow: row;  
      flex-wrap: wrap;
      gap: 60px;
    }
	&.card-no-3{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 60px;
        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
			  gap: 60px;
		}
	}  
	&.card-no-4{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 30px;
		${({ theme }) => theme.breakPoints.minBp('tablet')} {
			gap: 30px;
		}
	}
    & a {
      text-decoration: none;
      color: ${({ theme }) => theme.colours.adaBrown};
    }

    & > div {
      width: 100%;
      margin: 0 auto;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
		  width: 100%;
		  margin: 0;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
		  width: 100%;
		  margin: 0;
      }
    }

    @media (min-width: 845px) and (max-width: 1024px) {
      & > div:nth-child(even) {
        width: calc(50% - 30px);
        padding: ${({ theme }) =>
    theme.spacing({
        top: 0,
        left: 30,
        right: 0,
        bottom: 0,
    })};
      }
    }
  }
`;

export const SubNavTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subnav-title{
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colours.brown};
    }
`;

export const ProductsSubNav = styled.div`
  /* display: ${({ active }) => (active ? 'block' : 'none')}; */
  position: absolute;
  z-index: ${({ theme }) => theme.zindex.navigation.content};
  top: ${({ theme }) => theme.spacing(85)};
  width: 100%;
  max-height: ${({ active }) => (active ? '800px' : '0px')};
  overflow: hidden;
  background-color: #f1e8d7;
  transition: max-height 0.25s;

  & .container {
    height: auto;
    min-height: ${({ theme }) => theme.spacing(781)};
    color: ${({ theme }) => theme.colours.brown};
    text-align: center;

    @media (min-width: 1125px) {
      min-height: 100%;
    }

    & h1 {
      width: 100%;
      max-width: 90%;
      margin-top: ${({ theme }) => theme.spacing(36)};
      margin-bottom: ${({ theme }) => theme.spacing(40)};
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};
      text-transform: uppercase;

      u {
        position: relative;
        bottom: 6px;
        font-size: 70%;
        vertical-align: top;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        width: 400px;
        font-size: ${({ theme }) => theme.spacing(40)};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: auto;
        margin: ${({ theme }) => theme.spacing(60)} auto ${({ theme }) => theme.spacing(48)};
        font-size: ${({ theme }) => `clamp(${theme.spacing(60)}, 5vw, ${theme.spacing(88)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(64)}, 5vw, ${theme.spacing(94)})`};
      }
    }

    & .h1 {
      width: 100%;
      max-width: 90%;
      display: block;
      margin-top: ${({ theme }) => theme.spacing(36)};
      margin-bottom: ${({ theme }) => theme.spacing(40)};
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};
      text-transform: uppercase;

      u {
        position: relative;
        bottom: 6px;
        font-size: 70%;
        vertical-align: top;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        width: 400px;
        font-size: ${({ theme }) => theme.spacing(40)};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: auto;
        margin: ${({ theme }) => theme.spacing(60)} auto ${({ theme }) => theme.spacing(48)};
        font-size: ${({ theme }) => `clamp(${theme.spacing(60)}, 5vw, ${theme.spacing(88)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(64)}, 5vw, ${theme.spacing(94)})`};
      }
    }

    & .product-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: ${({ theme }) => theme.spacing(300)};
      margin: ${({ theme }) => theme.spacing(0, 'auto')};

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: ${({ theme }) => theme.spacing(670)};
        margin-bottom: ${({ theme }) => theme.spacing(100)};
      }

      @media (min-width: 1125px) {
        flex-flow: row wrap;
        justify-content: flex-start;
        max-width: ${({ theme }) => theme.spacing(1640)};
      }

      & > div {
        width: 50%;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          width: 25%;
        }

        @media (min-width: 1125px) {
          flex: 1 1 0;
          width: auto;
        }

        & button {
          margin: 0 auto;
        }
      }
    }
  }
`;