import React, {useState} from 'react';
import {SocialVideoSlideComponent} from './SocialVideoSlide.styles.js';
import YoutubePlayer from "./SubTemplates/YoutubePlayer.jsx";
import {isExperienceEditorActive, withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import CirclePlayBtnSvg from "./SubTemplates/CirclePlayBtnSvg.jsx";


const SocialVideoSlide = ({fields = {}, sitecoreContext, params = {}, ...props}) => {
    const experienceEditor = isExperienceEditorActive();
    const {instagramVideoId = {}, youtubeVideoId = {}, title = {}} = fields;
    const [isVideoClicked, setIsVideoClicked] = useState(false);
    const handleVideoClick = () => {
        setIsVideoClicked(true);
    };
    const getYouTubeThumbnailUrl = (youtubeVideoId) => {
        return youtubeVideoId ? `https://img.youtube.com/vi/${youtubeVideoId.value}/sddefault.jpg` : '';
    };
    const vidPoster = getYouTubeThumbnailUrl(youtubeVideoId);

    const videoEnded = () => {
        setIsVideoClicked(false);
        isVideoClicked(false);
    };
    // console.log('fields', fields);
    // console.log('instagramVideoId', instagramVideoId);
    // console.log('youtubeVideoId', youtubeVideoId);
    // console.log('title', title);

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <SocialVideoSlideComponent className="social-video-slide">
                <div className="video-holder">
                    <h1>Video Slide</h1>
                </div>
            </SocialVideoSlideComponent>
        );
    }

    return (
        <SocialVideoSlideComponent className="social-video-slide">
            <div className="video-holder">
                <div
                    className="video-div"
                    style={{backgroundImage: `url(${vidPoster})`}}
                    onClick={handleVideoClick}
                >

                    {!isVideoClicked ? (
                        <div className="inner-content">
                            <button className="play-video-button" aria-label="Play video">
                                <CirclePlayBtnSvg />
                            </button>
                        </div>
                    ) : (
                        <YoutubePlayer title={title.value} isClicked={isVideoClicked} videoId={youtubeVideoId.value}
                                       onVideoEnd={videoEnded}/>
                    )}
                </div>
            </div>
        </SocialVideoSlideComponent>
    );
};

SocialVideoSlide.propTypes = {};

export default withSitecoreContext()(SocialVideoSlide);