import React from 'react';
import PropTypes from 'prop-types';

import { RadioComponent } from '../Input.styles.js';
import { Text } from 'components/shared/JssOverrides';

const Radio = ({ label, placeholder, error, errorMessage, details, checked, onChange, ...props }) => {
  /* TODO figure out how to extend the label value if field is required */
  return (
    <RadioComponent {...props}>
      <Text tag="label" field={label} />
      <input type="radio" checked={checked} onChange={onChange} />
      <span className="check-mark"></span>
    </RadioComponent>
  );
};

Radio.propTypes = {};

export default Radio;
