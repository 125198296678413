export const zindex = {
  global: {
    cookiePolicy: 1000,
    alert: 800,
    loadingSpinner: 1000,
  },

  modal: {
    background: 900,
    content: 950,
  },

  navigation: {
    navbar: 850,
    content: 800,
    overlay: 500,
  },

  search: {
    main: 700,
    overlay: 500,
  },

  filters: {
    main: 600,
    header: 700,
  },

  scrollingText: {
    scroll: 100,
    content: 200,
  },
};
