import styled from "styled-components";
const getVariantColours = ({ variant, theme } = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
            text: '#fff',
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.yellow,
        },
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
        },
        brown: {
            background: theme.colours.brown,
            text: theme.colours.yellow,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
    const alignments = {
        left: 'left',
        center: 'center',
        right: 'right',
    };

    if (alignment in alignments) return alignments[alignment];

    return alignments.left;
};

export const SocialOuterWrapper = styled.section`
    position: relative;
    .sticker1{
        z-index: 1;
        left: auto !important;
        right: 5%;
		transition: top 0.3s ease-in-out;
        cursor: pointer;
        &.active{
            top: -110px;
        }
    }
    .duke-talk-bubble{
		z-index: 1;
		right: 5%;
		cursor: pointer;
		position: absolute;
		top: -21px;
		transition: all 0.5s ease-in-out 0s;
		animation: 0.5s ease 0s infinite normal none running bob;
		left: auto !important;
		width: 460px;
		height: 130px;
		&.active{
			top: -110px;
            animation: none;
		}
        @media (max-width: 900px) {
            right: 10px;
			top: -9px;
			left: auto !important;
			width: 260px;
			height: 70px;
            &.active{
				top: -70px;
            }
        }
		@media (max-width: 400px) {
			right: auto;
            left: 0;
		}
    }
`;

export const SocialComponentWrapper = styled.div`
  position: relative;
  z-index: 2;  
  width: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ variant: backgroundColor, theme }).background};
  padding: ${({ theme }) => theme.spacing({ top: 64, bottom: 64, left: 0, right: 0 })};
  @media (max-width: 900px) {
    padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 32, left: 0, right: 0 })};
  }
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
      span{
		  color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
		  line-height: 1;
          &.span-hl{
			  font-style: normal;
			  font-weight: 700 !important;
			  font-size: 40px;
			  text-align: center !important;
			  text-transform: uppercase;
			  margin-bottom: 40px;
			  display: block;
          }
		  @media (max-width: 900px) {
			  &.span-hl{
                  font-size: 20px;
              }
		  }
      }
      h2{
          color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
		  line-height: 1;
          font-family: 'chunk';
          &.h2-hl{
			  font-style: normal;
			  font-weight: 700 !important;
			  font-size: 69px;
			  align-items: center;
			  text-align: center !important;
			  text-transform: capitalize;
			  display: block;
          }
		  @media (max-width: 900px) {
			  &.h2-hl{
				  font-size: 32px;
			  }
		  }
      }
      p{
		  color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
          a{
			  color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
          }
      }
  }
	.social-media-wrapper{
		margin-top: 50px;
		ul{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 40px;
			li{
                margin: 0;
				a{
					button{
						svg{
							color: ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).background};
						}
					}
				}
			}
		}
	}
`;