import styled from 'styled-components';

export const IconButtonComponent = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colours.adaBrown};
  border-radius: 99px;
  min-width: ${({ theme }) => theme.spacing(38)};
  min-height: ${({ theme }) => theme.spacing(38)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colours.yellow};

    & svg {
      color: ${({ theme }) => theme.colours.adaBrown};
    }
  }

  &:focus {
    outline: none;
  }

  & svg {
    color: ${({ theme }) => theme.colours.yellow};
    position: absolute;
    top: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
