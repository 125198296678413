import React from 'react';
import PropTypes from 'prop-types';
import { useDeviceResize } from 'hooks';

import { RenderCardType } from './CardTypes';

const checkPromoCondition = ({ type, device, index }) => {
  const mobileOrTablet = device === 'mobile' || device === 'tablet';
  const conditions = {
    recipe: !!(mobileOrTablet ? index % 4 === 0 : index % 4 === 0),
    //product: !!(mobileOrTablet ? index % 5 === 5 || index % 5 === 0 : index % 10 === 4 || index % 10 === 0),
  };

  if (type in conditions) return conditions[type];

  return false;
};

const Card = (props) => {
  const { type, promo, index, className } = props;
  const device = useDeviceResize();

  return (
    <RenderCardType type={type} promo={promo || checkPromoCondition({ type, device, index })} className={className} props={props} />
  );
};

export default Card;

Card.propTypes = {
  type: PropTypes.oneOfType(['product', 'recipe', 'content', 'related', 'article']),
  promo: PropTypes.bool,
  index: PropTypes.number,
};