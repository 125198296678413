import styled, { css } from 'styled-components';

const FeatureProductStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;

  h5 {
    margin: 0 10px;
    color: ${({ theme }) => theme.colours.adaBrown};
    text-align: center;
    text-transform: uppercase;
  }
`;

export const FeaturedProductComponentLink = styled.a`
  ${FeatureProductStyles}
  text-decoration: none;
`;

export const FeaturedProductComponent = styled.div`
  ${FeatureProductStyles}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const NewTextWrapper = styled.div`
  position: absolute;
  top: -20px;
  z-index: 1;
  padding: 8px;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
		left:40px;
  }

  > span {
    color: ${({ theme }) => theme.colours.yellow};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    text-transform: uppercase;
  }

	.featured-product-badge {
		width: 60px;
	}
`;
