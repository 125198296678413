import React from 'react';
const SVGComponent = (props) => (
    <svg
        width={28}
        height={28}
        fill="none"
        {...props}
    >
        <circle cx={14} cy={14} r={14} fill="#944000" />
        <path
            d="M22.7 10.883c.139.137.222.302.222.466 0 .192-.084.357-.222.521l-4 3.701c-.14.11-.306.192-.473.192-.166 0-.333-.11-.472-.219-.25-.274-.25-.685.028-.932l2.778-2.605h-4.528c-.833-.027-1.5.302-2.056.823a2.797 2.797 0 0 0-.833 2.029v2.412c0 .384-.306.63-.667.63-.389 0-.666-.273-.666-.63V14.86a4.12 4.12 0 0 1 1.25-2.961 3.864 3.864 0 0 1 2.972-1.18h4.528l-2.778-2.577c-.278-.246-.278-.658-.028-.932s.667-.274.945-.027l4 3.701Zm-5.778 6.608c.361 0 .666.301.666.712v1.29c0 .822-.722 1.507-1.555 1.507H8.477c-.86 0-1.555-.685-1.555-1.508v-7.43c0-.822.694-1.508 1.555-1.508h1.556c.361 0 .667.302.667.63 0 .357-.306.631-.667.631H8.477c-.138 0-.222.083-.222.192v7.43c0 .082.084.192.222.192h7.556c.111 0 .222-.082.222-.192V18.15c0-.357.278-.658.667-.658Z"
            fill="#F1E8D7"
        />
    </svg>
);
export default SVGComponent;