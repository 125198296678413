import React, { useEffect, useRef } from 'react';
import { DestiniFrameWrapper } from './FindInStoreSearchDestiny.styles';

const FindInStoreSearchDestiny = () => {
  const destiniDivRef = useRef(null);

  useEffect(() => {
    const destiniIFrame = document.querySelector('#destini');

    if (destiniIFrame && destiniDivRef.current) {
      destiniDivRef.current.appendChild(destiniIFrame);
    }
  }, []);

  return <DestiniFrameWrapper ref={destiniDivRef} id='destinidiv' />;
};

export default FindInStoreSearchDestiny;
