import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.red,
    },
    tan: {
      background: theme.colours.tan,
      text: theme.colours.brown,
    },
    blue: {
      background: theme.colours.blue,
      text: theme.colours.yellow,
    },
    brown: {
      background: theme.colours.yellow,
      text: theme.colours.tan,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.yellow;
};

export const LandingHeroComponent = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ variant: backgroundColor, theme }).background};
`;

export const TextWrapper = styled.div`
  max-width: 98%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
  text-align: center;

  h1,
  span {
    display: block;
    margin: 0;
    font-size: ${({ theme }) => `clamp(${theme.spacing(64)}, 12vw, ${theme.spacing(240)})`};
    text-transform: uppercase;

    @supports (-webkit-text-stroke: 1px) {
      -webkit-text-stroke: 2px
        ${({ theme, backgroundColor }) => getVariantColours({ variant: backgroundColor, theme }).text};
    }
  }

  > div {
    &:nth-of-type(3n + 2) {
      > * {
        @supports (-webkit-text-stroke: 1px) {
          -webkit-text-fill-color: ${({ theme, backgroundColor }) =>
            getVariantColours({ variant: backgroundColor, theme }).text};
        }
      }
    }
  }

  .vanity {
    &:nth-of-type(3n + 2) {
      @supports (-webkit-text-stroke: 1px) {
        -webkit-text-fill-color: ${({ theme, backgroundColor }) =>
          getVariantColours({ variant: backgroundColor, theme }).text};
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  .image-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 100%;
    transform: translate(-50%, 0%);

    // ${({ theme }) => theme.breakPoints.minBp('480px')} {
    //   max-width: 70vw;
    // }

    // ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    //   max-width: 50vw;
    // }

    img {
      width: 100%;
      height: auto;
    }
  }
`;