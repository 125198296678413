import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import {
  HoverWrapper,
  MobileButtonWrapper,
  RecipeCardComponent,
  RecipeFooter,
  RecipePosterImg,
  ViewButtonWrapper
} from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

import translate from 'helpers/translate';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const RecipeCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  link = '',
  itemId = '',
  type = 'recipe',
  recipeTotalTimeHours = '',
  recipeTotalTimeMinutes = '',
  classValue = '',
  }) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;

  const device = useDeviceResize();

  const renderTime = () => {
    const time = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Time', 'Time');
    const hour = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Hours', 'hr');
    const min = translate(viewBag, 'BushsBeans.Search.Grids.Recipes.Minutes', 'm');

    if (
      recipeTotalTimeHours &&
      recipeTotalTimeHours > 0 &&
      recipeTotalTimeMinutes &&
      recipeTotalTimeMinutes > 0
    )
      return `${time}: ${recipeTotalTimeHours}${hour} ${recipeTotalTimeMinutes}${min}`;

    if (recipeTotalTimeHours && recipeTotalTimeHours > 0) return `${time}: ${recipeTotalTimeHours}${hour}`;

    if (recipeTotalTimeMinutes && recipeTotalTimeMinutes > 0)
      return `${time}: ${recipeTotalTimeMinutes}${min}`;
  };

  return (
    <RecipeCardComponent type={type} className={`${classValue} recipe-default`}>
      {!isServer() && (
        <ConditionalComponentWrapper
          condition={link}
          wrapper={children => (
            <Link href={link} className="button-link">
              {children}
            </Link>
          )}
        >
          <RecipePosterImg className="recipe-image-container">
            {image?.url && <img src={image?.url} alt={image?.alt} />}
            {device === 'desktop' && (
              <HoverWrapper>
                {promoImage?.url && (
                  <img src={promoImage?.url} alt={promoImage?.alt} className="hover-image" />
                )}
                <Ribbon variant="yellow" />
              </HoverWrapper>
            )}
          </RecipePosterImg>

          <RecipeFooter className="recipe-footer">
            {title && (
              <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
            )}
          </RecipeFooter>
        </ConditionalComponentWrapper>
      )}
    </RecipeCardComponent>
  );
};

export default withSitecoreContext()(RecipeCard);

RecipeCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};