import styled from 'styled-components';

export const PlaceholderWrapper = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing(900)};
`;

export const HomepageHeroCarouselComponent = styled.div`
  position: relative;
  min-height: ${({ minHeight, theme }) => (minHeight ? `${minHeight}px` : theme.spacing(900))};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colours.yellow};

  & .tagline {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
	background-color: ${({ theme }) => theme.colours.red};

    .tagline-wrap {
      padding-right: ${({ theme }) => theme.spacing(20)};
      padding-left: ${({ theme }) => theme.spacing(20)};
      padding-bottom: 15px;
      padding-top: 20px;
		white-space: nowrap;
		overflow: visible;  
      @media (min-width: 1200px) {
        padding-right: ${({ theme }) => theme.spacing(120)};
        padding-left: ${({ theme }) => theme.spacing(120)};
      }

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        padding-right: ${({ theme }) => theme.spacing(55)};
        padding-left: ${({ theme }) => theme.spacing(55)};
      }
		img{
			display: none;
		}  
    }
    & h1{
		color: ${({ theme }) => theme.colours.yellow};
		display: block;
		text-align: center;
		margin-bottom: 0;
		text-align: center;
		width: 100%;
		font-size: 35px;
		line-height: 1;
		font-style: normal;
		font-weight: 500 !important;
		font-family: 'apercu pro';
		@media (min-width: 450px) {
			font-size: 40px;
		}
		@media (min-width: 600px) {
			font-size: 60px;
		}
		@media (min-width: 900px) {
			font-size: 70px;
		}
		@media (min-width: 1400px) {
			font-size: 90px;
		}
		@media (min-width: 1600px) {
			font-size: 140px;
		}
        .span{
            text-decoration: underline;
        }
    }
    & img {
      width: 100%;
      height: auto;
      margin: ${({ theme }) => theme.spacing(8, 'auto')};
      display: none;  
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: ${({ theme }) => theme.spacing(32, 'auto')};
      }
    }
  }

  & .slide {
    position: absolute;
    width: 100%;
  }


  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.yellow};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:before {
      display: block;
      width: 100%;
      padding-top: calc((8 / 16) * 100%);
      content: '';
    }
  }
  .fs-image-container {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		text-align: center;
		background-color: ${({ theme }) => theme.colours.yellow};
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&:before {
			display: block;
			width: 100%;
			padding-top: calc((4 / 10) * 100%);
			content: '';
		}
  }  

  .front-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 25%;
    height: auto;
    transform: translate(-50%, 0%);

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: 28%;
    }
  }

  .controls {
    position: absolute;
    right: ${({ theme }) => theme.spacing(20)};
    bottom: ${({ theme }) => theme.spacing(20)};
    z-index: 999;
    display: flex;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: absolute;
      bottom: ${({ theme }) => theme.spacing(40)};
      right: ${({ theme }) => theme.spacing(40)};
    }

    ${({ theme }) => theme.breakPoints.minBp('1440px')} {
      position: absolute;
      bottom: ${({ theme }) => theme.spacing(70)};
      right: ${({ theme }) => theme.spacing(70)};
    }

    &.controls-align-left {
      right: unset;
      left: ${({ theme }) => theme.spacing(20)};
      bottom: ${({ theme }) => theme.spacing(20)};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        bottom: ${({ theme }) => theme.spacing(40)};
        left: ${({ theme }) => theme.spacing(40)};
      }

      ${({ theme }) => theme.breakPoints.minBp('1440px')} {
        bottom: ${({ theme }) => theme.spacing(70)};
        left: ${({ theme }) => theme.spacing(70)};
      }
    }

    button {
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing(14)};
      }
    }
  }
`;