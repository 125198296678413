import styled from 'styled-components';

export const MainNavComponent = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: ${({ theme }) => theme.spacing(85)};
  width: 100%;
  background-color: ${({ theme }) => theme.colours.yellow};
  z-index: ${({ theme }) => theme.zindex.navigation.content};
	.hamburger {
		span {
			background: #fff;
		}
		&.active {
			span {
				background: ${({theme}) => theme.colours.yellow};
			}
		}
	}
	nav {
		a {
			color: #fff;

			&:hover {
				&::before {
					background-color: #fff;
				}
			}
		}
	}
	ul {
		&:after {
			background-color: #fff;
		}
	}
	& .hamburger {
		margin-left: ${({theme}) => theme.spacing(24)};
        span{
            background-color: #fff;
        }
		@media (min-width: 1200px) {
			display: none;
		}
	}
    #nav-bar-search-button{
        display: none;
    }
	.logo {
		flex: 1 0 0;
		position: absolute;
		left: 50%;
		margin-left: ${({theme}) => theme.spacing(-50)};
		img {
			height: ${({theme}) => theme.spacing(56)};
			width: ${({ theme }) => theme.spacing(100)};
        }
        @media (max-width: 1600px) and (min-width: 1200px) {
          position: static;
          margin-left: ${({ theme }) => theme.spacing(0)};
          padding-left: ${({ theme }) => theme.spacing(50)};
        }
    }

  nav {
    margin-left: ${({ theme }) => theme.spacing(50)};
    height: 100%;

    a {
      line-height: ${({ theme }) => theme.spacing(85)};
      font-size: ${({ theme }) => theme.spacing(24)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      display: inline-block;
      align-items: center;
      position: relative;
      height: 100%;
      text-transform: uppercase;
      color: #fff;
      margin-left: ${({ theme }) => theme.spacing(50)};
      text-decoration: none;
      &:hover {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          height: ${({ theme }) => theme.spacing(4)};
          width: 100%;
          background-color: ${({ theme }) => theme.colours.blue};
        }
      }

      & svg {
        position: relative;
        top: -1px;
        padding-left: ${({ theme }) => theme.spacing(8)};
        color: ${({ theme }) => theme.colours.yellow};
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .nav-end {
    .find-in-store {
      line-height: ${({ theme }) => theme.spacing(85)};
      font-size: ${({ theme }) => theme.spacing(24)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      display: inline-block;
      align-items: center;
      position: relative;
      height: 100%;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.brown};
      text-decoration: none;
      margin-right: ${({ theme }) => theme.spacing(50)};

      @media (max-width: 1199px) {
        display: none;
      }
    }

    & button {
      &:focus {
        outline: none;
      }
    }
  }
`;


export const MobileNavComponentFs = styled.div`
  position: absolute;
  width: 100%;
  top: ${({ theme }) => theme.spacing(85)};
  overflow: hidden;
  height: ${({ theme, active }) => (active ? theme.spacing(1085) : '0px')};
  transition: height 0.5s;
  z-index: ${({ theme }) => theme.zindex.navigation.content};
  background-color: ${({theme}) => theme.colours.blue};
	  & .sub-links {
		  h1 {
			  color: ${({theme}) => theme.colours.yellow};
		  }
		  & ul {
			  & a {
				  color: #fff;
			  }
		  }
	  }
	  & ul:first-child {
		  &::after {
			  background-color: ${({ theme }) => theme.colours.tan};
		  }
	  }

	ul {
    > li {
		border-bottom: 1px solid rgb(76 155 238);
        position: relative;
        &.active{
            color: ${({ theme }) => theme.colours.yellow};
            a{
                color: ${({ theme }) => theme.colours.yellow};
            }
            button{
                color: ${({ theme }) => theme.colours.yellow};
                svg{
					transform: rotate(-90deg);
                }
            }
        }
      > button,
      > a {
        font-size: ${({ theme }) => theme.spacing(24)};
        font-family: ${({ theme }) => theme.typography.fontFamily.heading};
        color: #fff;  
        margin-bottom: 0;  
        transition: all 0.4s ease-in-out;
        padding: 15px 0;
        width: 100%;
        text-align: left;  
        text-transform: capitalize;
        transition: all 0.4s ease-in-out;  
		  svg{
			  position: absolute;
			  right: 15px;
			  left: auto;
			  color: #fff;
			  transform: rotate(90deg);
              transition: all 0.4s ease-in-out;
		  }
          &:hover{
			  color: ${({ theme }) => theme.colours.yellow};
          }
          &.active{
			  svg{
				  transform: rotate(-90deg);
                  color: ${({ theme }) => theme.colours.yellow};
			  }
			  color: ${({ theme }) => theme.colours.yellow};
              ul{
                  li{
                      a{
						 color: #fff;   
                      }
                  }
              }
          }
	  }
      .subMenuList{
          display: none;
          margin-bottom: 15px;
          &.active{
              display: block;
          }
          li{
              border-bottom: 0;
              a{
                  color: #fff;
				  font-size: ${({ theme }) => theme.spacing(18)};
                  padding: 10px 0;
              }
          }
      }  
    }
  }

  & ul:first-child {
    padding: ${({ theme }) =>
      theme.spacing({
        top: 0,
        left: 24,
        right: 24,
        bottom: 0,
      })};
    list-style: none;
    margin: ${({ theme }) => theme.spacing(40)} auto;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #3698FF;
    }
  }

  & .sub-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.spacing(30)};
    margin-bottom: ${({ theme }) => theme.spacing(60)};
    padding: ${({ theme }) =>
      theme.spacing({
        top: 0,
        left: 24,
        right: 24,
        bottom: 0,
      })};

    h1 {
      font-size: ${({ theme }) => theme.spacing(24)};
      color: ${({ theme }) => theme.colours.yellow};
      margin-bottom: ${({ theme }) => theme.spacing(25)};

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        font-size: ${({ theme }) => theme.spacing(30)};
      }
    }

    & ul {
      & a {
        font-size: ${({ theme }) => theme.spacing(16)};
        font-weight: 400;
        color: #fff;
      }

      & li {
        font-size: ${({ theme }) => theme.spacing(16)};
        font-weight: 400;
        color: #fff;
        margin-bottom: ${({ theme }) => theme.spacing(16)};
      }
    }

    & div:first-child {
      margin-right: ${({ theme }) => theme.spacing(60)};
      text-align: center;
    }
  }
  .mobile-nav-button{
      margin: 0 0 20px 20px;
      display: flex;
  }  
`;