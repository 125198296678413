import ApercuCondensedBoldPro from 'assets/fonts/apercu-condensed-bold-pro.woff';
import ApercuCondensedMedPro from 'assets/fonts/apercu-condensed-medium-pro.woff';
//import ApercuCondensedRegPro from 'assets/fonts/apercu-condensed-regular-pro.woff';
import ApercuBoldPro from 'assets/fonts/apercu-bold-pro.woff';
import ApercuMedPro from 'assets/fonts/apercu-medium-pro.woff';
import ApercuRegularPro from 'assets/fonts/apercu-regular-pro.woff';
import Bordonaro from 'assets/fonts/bordonaro.woff';
import ChunkBlack from 'assets/fonts/Chunk-Black.woff';

export const typography = {
  fontFamily: {
    heading: ['apercu condensed pro', 'impact'].join(','),
    main: ['apercu pro', 'helvetica', 'verdana', 'sans-serif'].join(','),
    primary: ['apercu pro', 'helvetica', 'verdana', 'sans-serif'].join(','),
    secondary: ['ApercuMonoRegularPro', 'sans-serif'].join(','),
    emphasis: ['bordonaro', 'sans-serif'].join(','),
  },
  fonts: `
    @font-face {
      font-family: 'apercu condensed pro';
      src: url(${ApercuCondensedBoldPro}) format('woff');
      font-weight: 700;
      font-style: normal;
    }
    @font-face {
      font-family: 'apercu condensed pro';
      src: url(${ApercuCondensedMedPro}) format('woff');
      font-weight: 600;
      font-style: normal;
    }

    @font-face {
      font-family: 'apercu pro';
      src: url(${ApercuRegularPro}) format('woff');
      font-weight: 400;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'apercu pro';
      src: url(${ApercuMedPro}) format('woff');
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: 'apercu pro';
      src: url(${ApercuBoldPro}) format('woff');
      font-weight: 600;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'bordonaro';
      src: url(${Bordonaro}) format('woff');
      font-weight: 600;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'chunk';
      src: url(${ChunkBlack}) format('woff');
      font-weight: 900;
      font-style: normal;
    }
  `,
};