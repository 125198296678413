import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {CheckboxComponent} from '../Input.styles.js';
import {Text} from 'components/shared/JssOverrides';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import BeanCheck from "./BeanCheck.jsx";

const Checkbox = (
    {
        label,
        placeholder,
        error,
        errorMessage,
        details,
        checked,
        onChange,
        onClick,
        name,
        sitecoreContext: {site, viewBag = {}},
        ...props
    }
) => {
    const isBushsBeansFoodservices = site?.name === 'bushs-beans-foodservices';

    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        if(onChange) {
            onChange(event);
        }
    };

    const beanCheckMarkClass = `bean-check-mark${isChecked ? ' active' : ''}`;

    if (isBushsBeansFoodservices) {
        return (
            <CheckboxComponent {...props}>
                <Text tag="label" field={label} htmlFor={`checkbox-${name}`}/>
                <input
                    type="checkbox"
                    id={`checkbox-${name}`}
                    name={`checkbox-${name}`}
                    checked={isChecked}
                    onChange={handleChange}
                    onClick={onClick}
                />
                <span className={beanCheckMarkClass}>
                  <BeanCheck className="beanCheck"/>
              </span>
            </CheckboxComponent>
        );
    } else {
        return (
            <CheckboxComponent {...props}>
                <Text tag="label" field={label} htmlFor={`checkbox-${name}`}/>
                <input
                    type="checkbox"
                    id={`checkbox-${name}`}
                    name={`checkbox-${name}`}
                    checked={isChecked}
                    onChange={handleChange}
                    onClick={onClick}
                />
                <span className="check-mark"></span>
            </CheckboxComponent>
        );
    }
};

export default withSitecoreContext()(Checkbox);