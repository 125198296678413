import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {renderLanguage} from 'helpers/languageHelper';
import translate from 'helpers/translate';
import {checkBadges, languageFilter} from 'helpers/badgeHelpers.js';

import {
    ProductDescriptionWrapper,
    ProductDescriptionInner,
    ProductDetailBox,
    DetailBoxList
} from './ProductDescription.styles.js';
import DownloadSvg from "../RelatedCarouselSlide/svg/DownloadSvg.jsx";
import Modal from 'components/shared/Modal';
import NutritionLabelTable from 'components/NutritionLabelTable';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const ProductDescription = (
    {
        title,
        backgroundColor,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
        ...props
    }
) => {
    const {fields = { }, params = {}} = props;
    const {salsify = {}, globalContent = {}, language = '', viewBag = {},trademarkReplacements = [], site} = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const isFoodserviceSite = site.name === 'bushs-beans-foodservices';
    const { productSellSheet = {}, productFormulation = {}, productSpecificationSheet = {} } = fields;
    //console.log('fields', fields);
    //console.log('productSellSheet', productSellSheet);
    //console.log('productFormulation', productFormulation);
    const {
        productName = {},
        consumerUnitFrontStraightOnTransparentPNGFR,
        consumerUnitFrontStraightOnTransparentPNG,
        tradeItemMarketingMessageWeb = {},
        canSizeCollectionList,
        salsifyid,
        isNewItem,
        webBadge,
        countryofOrigin,
        ingredientStatement,
        specPackagingStorage,
        specServingsPerPackage,
        specServingSize,
        specCaseGrossWeightForEach,
        servingSuggestionSIMMERen,
        specNetWeight,
        specNetWeightUOM,
        caseSpecTiHiForEach,
        minimumTradeItemLifespanFromProduction,

    } = salsify;

    // console.log('product description title:', title);
    // console.log('product description field:', field);
    // console.log('product description tag:', tag);
    // console.log('product description id:', id);
    // console.log('product description fields:', fields);
    // console.log('product description params:', params);
    // console.log('product description salsify', salsify);

    const shapeData = {
        title: {
            value: renderLanguage({language, object: productName}),
        },
        copy: {
            value: `${renderLanguage({language, object: tradeItemMarketingMessageWeb})}`,
        },
        ingredients: salsify?.ingredientStatement ?? '',
        storage: salsify?.specPackagingStorage ?? '',
        servingsPerPackage: salsify?.specServingsPerPackage ?? '',
        specServingSize: salsify?.specServingSize ?? '',
        caseGrossWeight: salsify?.specCaseGrossWeightForEach ?? '',
        servingSuggestionSIMMERen: salsify?.servingSuggestionSIMMERen ?? '',
        netWeight: salsify?.specNetWeight ?? '',
        netWeightUOM: salsify?.specNetWeightUOM ?? '',
        canSizeCollectionList: salsify?.canSizeCollectionList ?? '',
        caseSpecTiHiForEach: salsify?.caseSpecTiHiForEach ?? '',
        upc: salsify?.salsifyid ?? '',
        lifeSpan: salsify?.minimumTradeItemLifespanFromProduction ?? '',
    };
    const [showNutrition, setShowNutrition] = useState(false);

    const handleNutritionClick = (e) => {
        e.preventDefault();
        setShowNutrition(true);
    };

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <ProductDescriptionWrapper backgroundColor={backgroundColor}
                                       className={`product-detail ${isFoodserviceSite ? 'fs-product' : ''}`} id={id}>
                <ProductDescriptionInner>
                    <div className="container" style={{minHeight: '200px', width: '100%', display: 'block'}}>
                        <h1>Product Description Module</h1>
                        <div>Product description content</div>
                    </div>
                </ProductDescriptionInner>
            </ProductDescriptionWrapper>
        );
    }

    return (
        <ProductDescriptionWrapper backgroundColor={backgroundColor} className={`product-detail ${isFoodserviceSite ? 'fs-product' : ''}`} id={id}>
            <ProductDescriptionInner>
                <div className="container">
                    <div className="col product-description">
                        <h2>PRODUCT DESCRIPTION</h2>
                        {shapeData.copy && shapeData.copy.value !== null && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: trademarkFilterHelper(shapeData.copy.value, trademarkReplacements)
                                }}
                            />
                        )}


                        {productSellSheet && productSellSheet.value && productSellSheet.value.src && (
                            <a href={productSellSheet.value.src} className="download-link" download>
                                <span className="link-text">SELL SHEET</span>
                                <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                            </a>
                        )}
                        {productFormulation && productFormulation.value && productFormulation.value.src && (
                            <a href={productFormulation.value.src} className="download-link" download>
                                <span className="link-text">PRODUCT FORMULATION SHEET</span>
                                <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                            </a>
                        )}
                        {productSpecificationSheet && productSpecificationSheet.value && productSpecificationSheet.value.src && (
                            <a href={productSpecificationSheet.value.src} className="download-link" download>
                                <span className="link-text">NUTRITIONAL SHEET</span>
                                <span className="circle">
                                    <DownloadSvg className="downloadSvg"/>
                                </span>
                            </a>
                        )}

                                {/*<a href="#" className="download-link" onClick={handleNutritionClick}>*/}
                                {/*     <span className="link-text">NUTRITIONAL SHEET</span>*/}
                                {/*     <span className="circle">*/}
                                {/*         <DownloadSvg className="downloadSvg"/>*/}
                                {/*     </span>*/}
                                {/* </a>*/}
                                {/* {showNutrition && (*/}
                                {/*     <Modal callback={() => setShowNutrition(false)}>*/}
                                {/*         <NutritionLabelTable*/}
                                {/*             sitecoreContext={sitecoreContext}*/}
                                {/*             translate={translate}*/}
                                {/*         />*/}
                                {/*     </Modal>*/}
                                {/* )}*/}
                                {/* */}

                    </div>
                    <div className="col product-details">
                        <ProductDetailBox>
                            <h3>PRODUCT DETAILS</h3>
                            <DetailBoxList>
                            <div className="info-cols">
                                    {shapeData.upc && (
                                        <div className="product-details-item">
                                            <span className="label">UPC #</span>
                                            <span className="value">{shapeData.upc}</span>
                                        </div>
                                    )}
                                    {shapeData.canSizeCollectionList && (
                                        <div className="product-details-item">
                                            <span className="label">CAN SIZE</span>
                                            <span className="value">{shapeData.canSizeCollectionList}</span>
                                        </div>
                                    )}
                                    {shapeData.netWeight && (
                                        <div className="product-details-item">
                                            <span className="label">NET WEIGHT</span>
                                            <span className="value">{shapeData.netWeight}</span>
                                        </div>
                                    )}
                                    {shapeData.caseGrossWeight && (
                                        <div className="product-details-item">
                                            <span className="label">CASE WEIGHT</span>
                                            <span className="value">{shapeData.caseGrossWeight}</span>
                                        </div>
                                    )}
                                    {shapeData.specServingSize && (
                                        <div className="product-details-item">
                                            <span className="label">SERVING SIZE</span>
                                            <span className="value">{shapeData.specServingSize}</span>
                                        </div>
                                    )}
                                    {shapeData.servingsPerPackage && (
                                        <div className="product-details-item">
                                            <span className="label">SERVINGS/CONTAINER</span>
                                            <span className="value">{shapeData.servingsPerPackage}</span>
                                        </div>
                                    )}
                                    {shapeData.caseSpecTiHiForEach && (
                                        <div className="product-details-item">
                                            <span className="label">PALLET CONFIG.</span>
                                            <span className="value">{shapeData.caseSpecTiHiForEach}</span>
                                        </div>
                                    )}
                                    {shapeData.netWeight && (
                                        <div className="product-details-item">
                                            <span className="label">CASE WEIGHT</span>
                                            <span className="value">{shapeData.netWeight}</span>
                                        </div>
                                    )}
                                    {shapeData.lifeSpan && (
                                        <div className="product-details-item">
                                            <span className="label">SHELF LIFE</span>
                                            <span
                                                className="value">{Math.floor(parseInt(shapeData.lifeSpan, 10) / 365)} years</span>
                                        </div>
                                    )}
                                    {shapeData.storage && (
                                        <div className="product-details-item">
                                            <span className="label">STORAGE</span>
                                            <span className="value">{shapeData.storage}</span>
                                        </div>
                                    )}

                                </div>
                                {shapeData.ingredients && (
                                    <div className="product-details-item-full-width">
                                        <span className="label">INGREDIENTS:</span>
                                        <span className="value">{shapeData.ingredients}</span>
                                    </div>
                                )}
                            </DetailBoxList>
                        </ProductDetailBox>
                    </div>
                </div>
            </ProductDescriptionInner>
        </ProductDescriptionWrapper>
    );
};

ProductDescription.propTypes = {
    sitecoreContext: PropTypes.object,

};


export default withSitecoreContext()(ProductDescription);