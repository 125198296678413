import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import { CardPromoComponent, HoverWrapper } from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const ArticleCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  link = '',
  itemId = '',
  classValue = '',
  searchFriendlyDate: date = '',
}) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;
  const device = useDeviceResize();

  return (
    <CardPromoComponent className={classValue}>
      {!isServer() && <Button type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}
      <ConditionalComponentWrapper
        condition={link}
        wrapper={children => (
          <Link href={link} className="button-link">
            {children}
          </Link>
        )}
      >
        <div className="image-container article">
          {image?.url && <img src={image?.url} alt={image?.alt} />}
          {!isServer() && device === 'desktop' && (
            <HoverWrapper>
              {promoImage?.url && <img src={promoImage?.url} alt={promoImage?.alt} className="hover-image" />}
              <Ribbon variant="yellow" />
            </HoverWrapper>
          )}
        </div>

        <footer>
          {title && (
            <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
          )}
          {date && <p className="date">{date}</p>}
        </footer>
      </ConditionalComponentWrapper>
    </CardPromoComponent>
  );
};

export default withSitecoreContext()(ArticleCard);

ArticleCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};