import React from 'react';

import { MainNavRibbonComponent } from './MainNavRibbon.styles.js';

import MainNavRibbonCta from '../MainNavRibbonCta';

const MainNavRibbon = ({ supportingLinks, className, text, buttonText, href }) => {
  return (
    <>
      {supportingLinks?.length && (
        <MainNavRibbonComponent className={className}>
          {supportingLinks.map((link, index) => (
            <MainNavRibbonCta
              key={index}
              text={link?.title}
              buttonText={link?.text}
              href={link?.path}
              className={className}
            />
          ))}
        </MainNavRibbonComponent>
      )}
    </>
  );
};

export default MainNavRibbon;