import React from 'react';
import { NutritionLabelTableComponent } from './NutritionLabelTableUS.styles';

const NutritionLabelTableUS = ({ sitecoreContext, translate }) => {
  const { viewBag = {}, salsify = {} } = sitecoreContext;

  const {
    specCalories,
    specTotalFat,
    specTotalFatPercent,
    specSatFat,
    specSatFatPercent,
    specTransFat,
    specTransFatPercent,
    specCholesterol,
    specCholesterolPercent,
    specSodium,
    specSodiumPercent,
    specTotalCarb,
    specTotalCarbPercent,
    specDietFiber,
    specDietFiberPercent,
    specSugars,
    specSugarsPercent,
    specAddedSugars,
    specAddedSugarsPercent,
    specProtein,
    specProteinPercent,
    specVitD,
    specVitDPercent,
    specCalcium,
    specCalciumPercent,
    specIron,
    specIronPercent,
    specPotass,
    specPotassPercent,
    specServingsPerPackage,
    specServingSize,
  } = salsify;

  const formatAddedSugarsLabel =
    specAddedSugars &&
    translate(viewBag, 'Products.NutritionLabelPromo.Description.specAddedSugars').replace(
      '{0}',
      specAddedSugars
    );

  return (
    <NutritionLabelTableComponent>
      <div id="facts" className="nfl-front">
        <div className="facts_title">
          <strong>
            {translate(viewBag, 'Products.Nutrition.Label.Promo.Spec.Title', 'Nutrition Facts')}
          </strong>
        </div>
        {specServingsPerPackage && (
          <div className="facts_serving_pre">
            {translate(viewBag, 'BushsBeans.Products.NutritionLabelPromo.ContainerServingsPrefix', 'about')}{' '}
            {specServingsPerPackage}{' '}
            {translate(
              viewBag,
              'Products.NutritionLabelPromo.Description.specServingsPerPackage',
              'servings per container'
            )}
          </div>
        )}
        {specServingSize && (
          <div className="facts_serving_size">
            {translate(viewBag, 'Products.NutritionLabelPromo.Description.specServingSize', 'Serving Size')}{' '}
            {specServingSize}
          </div>
        )}
        {specCalories && (
          <div className="facts_box">
            <div className="fact_row_norule">
              <small>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.Description.amountPerServing',
                  'Amount Per Serving'
                )}
              </small>
            </div>
            <div className="fact_row calories">
              <div className="calorieamt">
                <strong>{specCalories}</strong>
              </div>
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.Description.specCalories', 'Calories')}
              </strong>
            </div>
          </div>
        )}
        <div className="facts_box thin">
          <div className="fact_row txt_right">
            <strong>
              <small>
                {translate(viewBag, 'Products.NutritionLabelPromo.Description.dailyValue', '% Daily Value*')}
              </small>
            </strong>
          </div>
          {specTotalFat && specTotalFatPercent && (
            <div className="fact_row">
              <div>
                <strong>{specTotalFatPercent}</strong>
              </div>
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.Description.specTotalFat', 'Total Fat')}
              </strong>{' '}
              {specTotalFat}
            </div>
          )}
          {specSatFat && specSatFatPercent && (
            <div className="fact_row sub">
              <div>
                <strong>{specSatFatPercent}</strong>
              </div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specSatFat', 'Saturated Fat')}{' '}
              {specSatFat}
            </div>
          )}
          {specTransFat && (
            <div className="fact_row sub">
              <div></div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specTransFat', 'Trans Fat')}{' '}
              {specTransFat}
            </div>
          )}
          {specCholesterol && specCholesterolPercent && (
            <div className="fact_row">
              <div>
                <strong>{specCholesterolPercent}</strong>
              </div>
              <strong>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.Description.specCholesterol',
                  'Cholesterol'
                )}
              </strong>{' '}
              {specCholesterol}
            </div>
          )}
          {specSodium && specSodiumPercent && (
            <div className="fact_row">
              <div>
                <strong>{specSodiumPercent}</strong>
              </div>
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.Description.specSodium', 'Total Sodium')}
              </strong>{' '}
              {specSodium}
            </div>
          )}
          {specTotalCarb && specTotalCarbPercent && (
            <div className="fact_row">
              <div>
                <strong>{specTotalCarbPercent}</strong>
              </div>
              <strong>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.Description.specTotalCarb',
                  'Total Carbohydrates'
                )}
              </strong>{' '}
              {specTotalCarb}
            </div>
          )}
          {specDietFiber && specDietFiberPercent && (
            <div className="fact_row sub">
              <div>
                <strong>{specDietFiberPercent}</strong>
              </div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specDietFiber', 'Dietary Fiber')}{' '}
              {specDietFiber}
            </div>
          )}
          {specSugars && (
            <div className="fact_row sublv2">
              <div></div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specSugars', 'Total Sugars')}{' '}
              {specSugars}
            </div>
          )}
          {formatAddedSugarsLabel && specAddedSugarsPercent && (
            <div className="fact_row sublv3">
              <div>
                <strong>{specAddedSugarsPercent}</strong>
              </div>
              {formatAddedSugarsLabel}
            </div>
          )}
          <div className="fact_row">
            {specProteinPercent && (
              <div>
                <strong>{specProteinPercent}</strong>
              </div>
            )}
            <strong>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specProtein', 'Protein')}
            </strong>{' '}
            {specProtein ? specProtein : '0g'}
          </div>
        </div>
        <div className="facts_box">
          {specVitD && specVitDPercent && (
            <div className="fact_row">
              <div>{specVitDPercent}</div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specVitD', 'Vitamin D')}{' '}
              {specVitD}
            </div>
          )}
          {specCalcium && specCalciumPercent && (
            <div className="fact_row">
              <div>{specCalciumPercent}</div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specCalcium', 'Calcium')}{' '}
              {specCalcium}
            </div>
          )}
          {specIron && specIronPercent && (
            <div className="fact_row">
              <div>{specIronPercent}</div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specIron', 'Iron')} {specIron}
            </div>
          )}
          {specPotass && specPotassPercent && (
            <div className="fact_row">
              <div>{specPotassPercent}</div>
              {translate(viewBag, 'Products.NutritionLabelPromo.Description.specPotass', 'Potassium')}{' '}
              {specPotass}
            </div>
          )}
        </div>
        <div className="facts_descr">
          {translate(
            viewBag,
            'Products.NutritionLabelPromo.Description.dailyValueAdditionalInfo',
            'The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.'
          )}
        </div>
      </div>
    </NutritionLabelTableComponent>
  );
};

export default NutritionLabelTableUS;
