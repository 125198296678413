import styled from 'styled-components';

export const TvFrame = styled.div`
    position: relative;
	padding-top: 84.32%;
	.screen {
		width: 82%;
		position: absolute;
		bottom: 12%;
		margin: auto;
		z-index: 5;
		display: block;
		left: 0;
		right: 0;
		.inner {
			width: 100%;
			padding-top: 56.6%;
			background-color: #000;
			position: relative;
			background-size: cover;
			background-position: center center;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: end;
            cursor: pointer;
            .inner-content{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				text-align: center;
				align-content: end;
				justify-content: center;
            }
			iframe{
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}
			span {
				font-family: Chunk, 'chunk';
				font-style: normal;
				font-weight: 900;
				font-size: 45px;
				line-height: 1;
				text-align: center;
				text-transform: uppercase;
				color: rgb(255, 255, 255);
				margin: 0px auto 10px;
				max-width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
		.play-video-button {
			width: 70px;
			height: 70px;
			min-width: 70px;
			min-height: 70px;
			margin: auto;
			position: absolute;
			border: 0;
			background: none;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
			.shadow {
				width: 70px;
				height: 70px;
				min-width: 70px;
				min-height: 70px;
				border-radius: 50%;
				content: "";
				background-color: #000;
				position: absolute;
				left: -20px;
				top: 0;
				bottom: 0;
				z-index: 0;
			}
			.self {
				background-color: #1671d2;
				text-align: center;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				content: "";
				width: 70px;
				height: 70px;
				min-width: 70px;
				min-height: 70px;
				align-self: center;
				color: #fff;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				animation-timing-function: cubic-bezier(.3, .7, .4, 1.5);
				transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
                border: 1px solid #000;
				svg {
					width: 31px;
					height: 38px;
					fill: #fff;
				}
				&:hover {
					animation: bounce .4s ease-in-out;
					transform: translate(-10px, 0);
                    background-color: #0c54a2;
				}
			}
			@media (max-width: 650px){
				width: 50px;
				height: 50px;
				min-width: 50px;
				min-height: 50px;
				.shadow{
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
				}
				.self{
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
					svg{
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		@media (max-width: 900px) {
			border-radius: 25px;
			.inner{
				span{
					font-size: 28px;
					max-width: 80%;
				}
			}
		}
		@media (max-width: 650px) {
			.inner{
				span{
					font-size: 20px;
				}
			}
		}
	}
`;

export const Frame = styled.svg`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
`;