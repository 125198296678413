import { useEffect, useState } from 'react';

const config = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const useObserver = ({ ref, options, removeAfterInView = false }) => {
  const [isElementInView, setElementInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setElementInView(entry.isIntersecting);
        if (entry.isIntersecting) {
          setElementInView(entry.isIntersecting);
          if (removeAfterInView) observer.unobserve(ref.current);
        }
      },
      { ...config, ...options }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.unobserve(ref.current);
    };
  }, [isElementInView, options, ref, removeAfterInView]);

  return isElementInView;
};

export default useObserver;
