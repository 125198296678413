import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from 'components/shared/Link';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Icon from 'components/shared/Icon';

const NavContainer = styled.div`
	background-color: ${({ theme }) => theme.colours.blue};
	display: flex;
	justify-content: end;
	align-items: center;
	padding: 10px 20px;
	color: white;
	position: relative;
	z-index: 991;
	border-bottom: 1px solid #66A8EE;
`;

const NavItem = styled.div`
	margin: 0 0 0 15px;
`;

const ALink = styled(Link)`
	color: white;
	text-decoration: none;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
    transition: all 0.3s ease-in-out;
	font-family: "apercu condensed pro", impact;
	${({ theme }) => theme.breakPoints.minBp('tablet')}{
		font-size: 16px;
		text-transform: uppercase;
		transition: all 0.3s ease-in-out;
    }
	&:hover {
		text-decoration: underline;
        color: #fff;
	}
`;

const SearchButton = styled.button`
	background: none;
	border: none;
	color: white;
	cursor: pointer;
    padding-right: 0;
    svg{
        color: #fff;
		transition: all 0.3s ease-in-out;
    }
    &:hover{
        svg{
            color: ${({ theme }) => theme.colours.yellow};
        }
    }
`;

const UtilityNav = ({ onSearchClick, search, className, sitecoreContext,  setSearch, ...props }) => {
    const { site, route, globalContent } = sitecoreContext;
    const handleLanguageSelector = (newLangCode) => {
        if (!newLangCode) return;

        if (!isServer()) {
            let splitPath = window.location.pathname.split('/');
            splitPath[1] = newLangCode;
            return (window.location.href = splitPath.join('/'));
        }
    };
    const mobileUtilityLinks = globalContent?.mobileUtilityLinks || [];
    const languageSelector = globalContent?.languageSelectorItems;
    //console.log('global in utility nav', globalContent);
    return (
        <NavContainer>
            {/*{Array.isArray(languageSelector) && languageSelector.length*/}
            {/*    ? languageSelector.map(({ text, code }, index) => {*/}
            {/*        return (*/}
            {/*            <NavItem>*/}
            {/*                <NavLink*/}
            {/*                    className="nav-end-link"*/}
            {/*                    role="menuitem"*/}
            {/*                    key={`languageSelector-${index}-${text}`}*/}
            {/*                    text={text}*/}
            {/*                    onClick={() => handleLanguageSelector(code)}*/}
            {/*                />*/}
            {/*            </NavItem>*/}
            {/*        );*/}
            {/*    })*/}
            {/*    : null}*/}
            {Array.isArray(mobileUtilityLinks) &&
                mobileUtilityLinks.map(({ title, path, href }, index) => (
                    <NavItem key={`${index}-${title}`}>
                        {path || href ? (
                            <ALink className="pagelink" to={path} href={href}>
                                {title.toUpperCase()}
                            </ALink>
                        ) : (
                            <span>{title.toUpperCase()}</span>
                        )}
                    </NavItem>
                ))
            }
            {/*<NavItem>*/}
            {/*    <NavLink className="pagelink" to="/en_US/#btm-cta">MARKETING TOOLS</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink to="/en_US/about-our-beans">ABOUT OUR BEANS</NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
                <>
                    <SearchButton
                        id="search-button"
                        className="search-button"
                        disabled={route?.name === 'search'}
                        aria-label="Open Search"
                        title="Open Search"
                        onClick={() => {
                            setSearch((prevState) => !prevState);
                        }}
                    >
                        <Icon className="search" icon={search ? 'cross' : 'search'} size="lg" />
                    </SearchButton>
                </>
            </NavItem>
        </NavContainer>
    );
};

export default withSitecoreContext()(UtilityNav);