import styled from 'styled-components';

export const NutritionLabelTableComponent = styled.div`
  #facts {
    z-index: 10;
    background: #fff;
    border: 1px solid #000;
    padding: 20px 0px;
    font-size: 15px;
    line-height: 15px;
    box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);
    font-family: helvetica, arial, sans-serif;
    color: #000;
    width: 300px;
  }

  #facts .facts_title {
    font-size: 35px;
    line-height: 16px;
    color: #231f20;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
  }

  #facts .facts_serving_size {
    padding-top: 4px;
    padding-bottom: 3px;
    font-size: 16px;

    line-height: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #facts .facts_serving_pre {
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #facts .calories {
    padding-top: 4px;
    padding-bottom: 12px;
    font-size: 1.5em !important;

    line-height: 1em !important;
    position: relative;
  }
  #facts .calorieamt {
    padding-bottom: 12px;
    font-size: 1.8em !important;

    line-height: 14px;
  }
  #facts .txt_right {
    text-align: right;
  }
  #facts strong {
  }
  #facts .facts_box {
    border-top: 13px solid #231f20;
    margin-left: 10px;
    margin-right: 10px;
  }

  #facts .facts_box.thin {
    border-width: 5px;
  }

  #facts .fact_row {
    padding-bottom: 2px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 14px;
    border-bottom: 1px solid #838181;
    position: relative;
  }
  #facts .fact_row_norule {
    padding-bottom: 2px;
    padding-top: 5px;
    font-size: 14px;
    line-height: 14px;
    position: relative;
  }

  #facts .fact_row.sub {
    padding-left: 18px;
  }
  #facts .fact_row.sublv2 {
    margin-left: 18px;
  }
  #facts .fact_row.sublv3 {
    padding-left: 36px;
  }

  #facts .fact_row small {
    font-size: 12px;
    line-height: 12px;

    color: #000;
  }

  #facts .fact_row div {
    float: right;
  }

  #facts strong {
    color: #000;
    font-weight: bold;
  }

  #facts .facts_descr {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 11px;
    line-height: 12px;
    margin: 8px 0 8px;
    position: relative;
    color: #000;
  }

  #facts .facts_descr:before {
    content: '*';
    font-size: 12px;
    position: absolute;
    top: 2px;
    left: 12px;
  }
`;
