import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {SocialOuterWrapper, SocialComponentWrapper} from './SocialMediaComponent.styles.js';
import SocialLinks from 'components/shared/SocialLinks';
import DukeTalkBubbleSvg from './InlineSvg/DukeTalkBubbleSvg.jsx';
import {RichText} from 'components/shared/JssOverrides';

const SocialMediaComponent = ({ className, field, tag, editable, sitecoreContext, ...props }) => {
    const { fields = {}, params = {} } = props;
    const { backgroundColor = 'blue', alignment = 'center' } = params;
    const { globalContent = {} } = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();

    const { footer = {} } = globalContent;
    const { socialLinks = [] } = footer;

    const dukeRef = useRef(null);
    const [isDukeTalkBubbleActive, setIsDukeTalkBubbleActive] = useState(false);

    const handleDukeTalkBubbleClick = () => {
        setIsDukeTalkBubbleActive(prevState => {
            const newState = !prevState;
            return newState;
        });
    };

    useEffect(() => {

    }, [isDukeTalkBubbleActive]);

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <SocialOuterWrapper className="social-component-container">
                <SocialComponentWrapper backgroundColor={backgroundColor}>
                    <div className="container">
                        <RichText field={field} tag={tag} editable={editable}/>
                        {socialLinks && (
                            <div className="social-media-wrapper">
                                <SocialLinks links={socialLinks}/>
                            </div>
                        )}
                    </div>
                </SocialComponentWrapper>
            </SocialOuterWrapper>
        );
    }

    return (
        <SocialOuterWrapper className="social-component-container">
            <DukeTalkBubbleSvg
                onClick={handleDukeTalkBubbleClick}
                className={`duke-talk-bubble ${isDukeTalkBubbleActive ? 'active' : ''}`}
            />
            <SocialComponentWrapper backgroundColor={backgroundColor}>
                <div className="container">
                    <RichText field={field} tag={tag} editable={editable}/>
                    {socialLinks && (
                        <div className="social-media-wrapper">
                            <SocialLinks links={[
                                ...socialLinks,
                                {
                                    "title": "Tiktok",
                                    "path": "",
                                    "href": "https://www.tiktok.com/@bushsbeans?lang=en",
                                "target": "_blank",
                                "text": "tiktok social link for BushBeans",
                                "external": true,
                                "icon": "tiktok"
                            }
                        ]}/>
                    </div>
                )}
            </div>
        </SocialComponentWrapper>
    </SocialOuterWrapper>
);


};

SocialMediaComponent.propTypes = {
    sitecoreContext: PropTypes.object,
    fields: PropTypes.shape({
        socialMediaContent: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export default withSitecoreContext()(SocialMediaComponent);