import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zindex.modal.background};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  backdrop-filter: blur(1px);
`;

export const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: ${({ theme }) => theme.zindex.modal.content};
  transform: translate(-50%, -50%);
`;

export const ButtonWrapper = styled.span`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zindex.modal.content};
  margin: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin: ${({ theme }) => theme.spacing(16)};
  }
`;
