import React from 'react';

const SVGComponent = (props) => (
    <svg
        width={73}
        height={42}
        viewBox="0 0 73 42"
        fill="none"
        {...props}
    >
        <path
            d="M1 22.8511L8.73139 28.6496"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M71.2349 22.8511L63.5035 28.6496"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M7.82422 15.1719L14.5216 22.1391"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M64.4126 15.1719L57.7152 22.1391"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M16.9995 8.59131L20.9911 17.3927"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M55.2368 8.59131L51.2452 17.3927"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M26.4561 5.84521L28.5071 15.2893"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M35.9844 3.90942L35.9844 13.5737"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M40.8159 1.03662L39.8495 18.4323"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M45.7788 5.84521L43.7277 15.2893"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M1.34082 15.7458L14.565 27.7115"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M70.894 15.7458L57.6699 27.7115"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M9.80713 8.46729L19.9229 23.1549"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M62.4297 8.46729L52.3139 23.1549"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M20.4932 3.74463L26.5669 20.5126"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M51.7422 3.74463L45.6685 20.5126"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M32.1191 1L32.4841 18.8303"
            stroke="#1471D2"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M34.748 41.7977C43.5163 41.7977 47.2741 37.9958 46.0215 34.194C45.6335 33.0165 44.0173 30.3921 41.0111 30.3921C37.2532 30.3921 37.2532 32.9267 34.748 32.9267C32.2428 32.9267 32.2428 31.6594 29.7375 31.6594C27.2323 31.6594 25.9797 34.194 25.9797 35.4613C25.9797 36.7285 25.9797 41.7977 34.748 41.7977Z"
            fill="#1471D2"
        />
    </svg>
);
export default SVGComponent;