import styled from 'styled-components';

export const MainNavComponent = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: ${({ theme }) => theme.spacing(85)};
  width: 100%;
  background-color: ${({ theme }) => theme.colours.yellow};
  z-index: ${({ theme }) => theme.zindex.navigation.content};

	&.nav-bushs-beans-foodservices,
	&.mobile-nav-bushs-beans-foodservices {
		.hamburger {
			span {
				background: #fff;
			}

			&.active {
				span {
					background: ${({theme}) => theme.colours.yellow};
				}
			}
		}

		nav {
			a {
				color: #fff;

				&:hover {
					&::before {
						background-color: #fff;
					}
				}
			}
		}

		ul {
			&:after {
				background-color: #fff;
			}
		}
	}

	@media (min-width: 1200px) {
		display: none;
	}

	& .hamburger {
		margin-left: ${({theme}) => theme.spacing(24)};
		@media (min-width: 1200px) {
			display: none;
		}
	}

	.logo {
		flex: 1 0 0;
		position: absolute;
		left: 50%;
		margin-left: ${({theme}) => theme.spacing(-50)};

		img {
			height: ${({theme}) => theme.spacing(56)};
			width: ${({ theme }) => theme.spacing(100)};
    }
    @media (max-width: 1600px) and (min-width: 1200px) {
      position: static;
      margin-left: ${({ theme }) => theme.spacing(0)};
      padding-left: ${({ theme }) => theme.spacing(50)};
    }
  }

  nav {
    margin-left: ${({ theme }) => theme.spacing(50)};
    height: 100%;

    a {
      line-height: ${({ theme }) => theme.spacing(85)};
      font-size: ${({ theme }) => theme.spacing(24)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      display: inline-block;
      align-items: center;
      position: relative;
      height: 100%;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.brown};
      margin-left: ${({ theme }) => theme.spacing(50)};
      text-decoration: none;
      &:hover {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          height: ${({ theme }) => theme.spacing(4)};
          width: 100%;
          background-color: ${({ theme }) => theme.colours.red};
          :hover {
            background-color: green;
          }
        }
      }

      & svg {
        position: relative;
        top: -1px;
        padding-left: ${({ theme }) => theme.spacing(8)};
        color: ${({ theme }) => theme.colours.red};
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .nav-end {
    .find-in-store {
      line-height: ${({ theme }) => theme.spacing(85)};
      font-size: ${({ theme }) => theme.spacing(24)};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
      display: inline-block;
      align-items: center;
      position: relative;
      height: 100%;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.brown};
      text-decoration: none;
      margin-right: ${({ theme }) => theme.spacing(50)};

      @media (max-width: 1199px) {
        display: none;
      }
    }

    & button {
      &:focus {
        outline: none;
      }
    }
  }
`;

export const ProductsSubNav = styled.section`
  position: absolute;
  width: 100%;
  background-color: #f1e8d7;
  transition: transform 0.5s;
  top: 0;
  bottom: 0;
  left: ${({ active }) => (active ? '0' : '-100%')};
  transition: left 1s;
  z-index: 2;
  overflow: scroll;

  & .back-button {
    width: 100%;
    position: relative;
    height: ${({ theme }) => theme.spacing(66)};
    background-color: ${({ theme }) => theme.colours.red};

    & button {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: ${({ theme }) => theme.spacing(25)};
      line-height: ${({ theme }) => theme.spacing(66)};
      color: ${({ theme }) => theme.colours.yellow};
      text-align: center;

      & svg {
        position: absolute;
        left: ${({ theme }) => theme.spacing(20)};
        top: 50%;
        margin-top: ${({ theme }) => theme.spacing(-10)};
        transform: rotate(180deg);
        color: ${({ theme }) => theme.colours.white};
      }
    }
  }

  & .container {
    min-height: ${({ theme }) => theme.spacing(781)};
    text-align: center;
    color: ${({ theme }) => theme.colours.red};
    height: auto;

    @media (min-width: 1125px) {
      min-height: 100%;
    }

    & h1 {
      width: 100%;
      max-width: 400px;
      margin: ${({ theme }) => theme.spacing(38)} auto;
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};
      text-transform: uppercase;

      u {
        position: relative;
        bottom: 4px;
        font-size: 70%;
        vertical-align: top;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: ${({ theme }) => theme.spacing(40)} auto ${({ theme }) => theme.spacing(40)};
        font-size: ${({ theme }) => theme.spacing(40)};
      }
    }

    & .h1 {
      width: 100%;
      max-width: 400px;
      margin: ${({ theme }) => theme.spacing(38)} auto;
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};
      text-transform: uppercase;

      u {
        position: relative;
        bottom: 4px;
        font-size: 70%;
        vertical-align: top;
      }

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        margin: ${({ theme }) => theme.spacing(40)} auto ${({ theme }) => theme.spacing(40)};
        font-size: ${({ theme }) => theme.spacing(40)};
      }
    }

    & .product-list {
      width: 100%;
      max-width: 90%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: ${({ theme }) => theme.spacing(0, 'auto')};

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 90%;
        margin-bottom: ${({ theme }) => theme.spacing(70)};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: auto;
        flex: 1 1 0;
        justify-content: flex-start;
        flex-flow: row wrap;
        max-width: ${({ theme }) => theme.spacing(1640)};
      }

      & > div {
        width: 50%;
        margin-bottom: ${({ theme }) => theme.spacing(44)};

        & button {
          margin: 0 auto;
        }

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          width: 25%;
        }
      }
    }
  }
`;

//About Us template
export const AboutUsSubNav = styled.section`
  /* display: ${({ active }) => (active ? 'block' : 'none')}; */
  position: absolute;
  overflow: scroll;
  height: 100%;
  width: 100%;
  background-color: #f1e8d7;
  text-align: center;
  left: ${({ active }) => (active ? '0' : '-100%')};
  transition: left 1s;
  z-index: 3;
  top: 0;
  bottom: 0;

  & .back-button {
    width: 100%;
    position: relative;
    height: ${({ theme }) => theme.spacing(66)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    background-color: ${({ theme }) => theme.colours.red};

    & button {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: ${({ theme }) => theme.spacing(25)};
      line-height: ${({ theme }) => theme.spacing(66)};
      color: ${({ theme }) => theme.colours.yellow};
      text-align: center;

      & svg {
        position: absolute;
        left: ${({ theme }) => theme.spacing(20)};
        top: 50%;
        margin-top: ${({ theme }) => theme.spacing(-10)};
        transform: rotate(180deg);
        color: ${({ theme }) => theme.colours.white};
      }
    }
  }

  .card-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1e8d7;
    max-width: 90%;
    margin: ${({ theme }) =>
      theme.spacing({
        top: 36,
        left: 24,
        right: 24,
        bottom: 64,
      })};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 96%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: ${({ theme }) => theme.spacing(48, 'auto')};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-width: ${({ theme }) => theme.spacing(1715)};
    }

    > div {
      width: 100%;
      margin: 0 auto;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        width: calc(50% - 48px);
        margin: 0 24px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        width: calc(20% - 48px);
        margin: 0 24px;
      }
    }

    @media (min-width: 845px) and (max-width: ${({ theme }) => theme.breakPoints.desktop}) {
      & > div:nth-child(even) {
        width: calc(50% - 30px);
        padding: ${({ theme }) =>
          theme.spacing({
            top: 0,
            left: 30,
            right: 0,
            bottom: 0,
          })};
      }
    }
  }
`;

export const MobileNavComponent = styled.div`
  position: absolute;
  width: 100%;
  top: ${({ theme }) => theme.spacing(85)};
  background-color: ${({ theme }) => theme.colours.yellow};
  overflow: hidden;
  height: ${({ theme, active }) => (active ? theme.spacing(685) : '0px')};
  transition: height 0.5s;
  z-index: ${({ theme }) => theme.zindex.navigation.content};
  &.mobile-content-bushs-beans-foodservices {
	  background-color: ${({theme}) => theme.colours.blue};
	  & .sub-links {
		  h1 {
			  color: ${({theme}) => theme.colours.yellow};
		  }
		  & ul {
			  & a {
				  color: #fff;
			  }
		  }
	  }
	  & ul:first-child {
		  &::after {
			  background-color: ${({ theme }) => theme.colours.tan};
		  }
	  }
  }
  ul {
    > li {
      > button,
      > a {
        font-size: ${({ theme }) => theme.spacing(24)};
        font-family: ${({ theme }) => theme.typography.fontFamily.heading};
        padding: 0;
      }
    }
  }

  & ul:first-child {
    padding: ${({ theme }) =>
      theme.spacing({
        top: 0,
        left: 24,
        right: 24,
        bottom: 0,
      })};
    list-style: none;
    margin: ${({ theme }) => theme.spacing(40)} auto;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colours.red};
    }
  }

  & .sub-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.spacing(30)};
    margin-bottom: ${({ theme }) => theme.spacing(60)};
    padding: ${({ theme }) =>
      theme.spacing({
        top: 0,
        left: 24,
        right: 24,
        bottom: 0,
      })};

    h1 {
      font-size: ${({ theme }) => theme.spacing(24)};
      color: ${({ theme }) => theme.colours.red};
      margin-bottom: ${({ theme }) => theme.spacing(25)};

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        font-size: ${({ theme }) => theme.spacing(30)};
      }
    }

    & ul {
      & a {
        font-size: ${({ theme }) => theme.spacing(16)};
        font-weight: 400;
        color: ${({ theme }) => theme.colours.adaBrown};
      }

      & li {
        font-size: ${({ theme }) => theme.spacing(16)};
        font-weight: 400;
        color: ${({ theme }) => theme.colours.adaBrown};
        margin-bottom: ${({ theme }) => theme.spacing(16)};
      }
    }

    & div:first-child {
      margin-right: ${({ theme }) => theme.spacing(60)};
      text-align: center;
    }
  }
`;