import styled from 'styled-components';

export const MainNavCardLinkComponent = styled.div`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 0;
    
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.adaBrown};
  }

  & h2 {
    font-size: ${({ theme }) => theme.spacing(16)};
    margin-bottom: ${({ theme }) => theme.spacing(18)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      font-size: ${({ theme }) => theme.spacing(20)};
      margin-bottom: ${({ theme }) => theme.spacing(22)};
    }
  }

  & .image {
    position: relative;
    & img {
      width: 100%;
      object-fit: cover;
      background-position: center;
    }

    & div {
      width: 100%;
      height: ${({ theme }) => theme.spacing(28)};
      margin-top: -3px;
      color: ${({ theme }) => theme.colours.blue};
      font-size: ${({ theme }) => theme.spacing(16)};
      line-height: ${({ theme }) => theme.spacing(32)};
      background-color: ${({ theme }) => theme.colours.yellow};

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        opacity: 0;
        position: absolute;
        display: block;
        transition: all 0.4s ease-in-out;  
        z-index: 0;  
      }
    }
	  ${({ theme }) => theme.breakPoints.minBp('desktop')}{
          padding-top: ${({ theme }) => theme.spacing(44)};
      }  

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      &:hover {
        & div {
          position: absolute;
          bottom: 0;
          display: block;
          z-index: 5;  
        }
      }
    }
  }
`;

export const LinkWrapper = styled.div`
    display: block;
    width: 100%;
    max-width: 100%;
    margin: auto;
    .image{
        width: 100%;
		padding-top: 48%;
        position: relative;
        .card-image{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .card-label{
        padding: 16px;
        background-color: ${({ theme }) => theme.colours.blue};
		color: ${({ theme }) => theme.colours.yellow};
        font-size: 20px;
        display: flex;
        text-align: left;
        max-width: 100%;
        line-height: 28px;
        font-family: "apercu condensed pro", impact;
        .circle{
            height: 28px;
            width: 28px;
            justify-content: center;
            align-content: center;
            background-color: ${({ theme }) => theme.colours.yellow};
            margin-left: 10px;
            display: flex;
            border-radius: 100%;
            svg{
                color: ${({ theme }) => theme.colours.blue};
				align-self: center;
            }
        }
    }
`;