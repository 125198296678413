import styled from 'styled-components';

/* the !important rule is used several times to override CSS styles, and is employed only for formatting the printed recipe page */

export const RecipeIngredientsDetailsComponent = styled.section`
  color: ${({ theme }) => theme.colours.adaBrown};
  background-color: ${({ theme }) => theme.colours.yellow};
  @media print {
    float: left !important;
    width: 50vw !important;
  }

  .grid {
    max-width: ${({ theme }) => theme.grid.maxWidth.default};
    margin: 0 auto;
    margin-top: 1rem;
    background-color: ${({ theme }) => theme.colours.tan};
    @media print {
      display: block !important;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-top: 0;
      text-align: left;
    }
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(16)};
    font-weight: 400;
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      text-align: left;
    }
  }

  h4 {
    margin-bottom: ${({ theme }) => theme.spacing(16)};
  }

  button {
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: 0;
    }
  }

  footer {
    margin-bottom: ${({ theme }) => theme.spacing(32)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(40)};
    }
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    order: 2;
  }
	.socialShare{
        padding: 0;
		background: none;
		h3{
			text-align: left;
			margin: 0 0 20px 0;
		}
		.social-links{
			justify-content: flex-start;
            li{
                padding: 0;
            }
		}
        @media (max-width: 1199px){
            display: none;
        }
	}  
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    order: 1;
  }
	.socialShareCenter{
        display: none;
		background: none;
        padding: 0;
        position: relative;
        top: -30px;
		h3{
			text-align: center;
			margin-bottom: 20px;
		}
		.social-links{
			justify-content: center;
            li{
                padding: 0;
            }
		}
		@media (max-width: 1199px){
            display: block;
		}
	}

	.ingredients-container-on-print {
    @media print {
      margin: 0;
      border: none !important;
      display: block !important;
    }
  }

  .ingredients-on-print {
    @media print {
      color: black !important;
      padding: 0 !important;
      h2 {
        @media print {
          font-size: 18px !important;
          line-height: 0 !important;
          padding-bottom: 10px !important;
        }
      }
      ul {
        margin: 0 !important;
      }
      li {
        margin: 0rem 0rem 0.5rem 0.5rem !important;
        font-size: 12px !important;
      }
    }
  }

  .format-ingredients-list-on-print {
    @media print {
      margin-bottom: 0 !important;
    }
  }
`;

export const Ingredients = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(40)};
  border: 2px solid ${({ theme }) => theme.colours.red};

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(20)};
    background-color: ${({ theme }) => theme.colours.red};

    h2 {
      margin: 0;
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-align: left;
      text-transform: uppercase;
    }

    span {
      padding: ${({ theme }) => theme.spacing(10)};
      color: ${({ theme }) => theme.colours.tan};
      border: 2px solid ${({ theme }) => theme.colours.yellow};
      border-radius: 100px;
      font-size: 20px;
      font-weight: 900;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        padding: 0.8rem;
        font-size: 20px;
        font-weight: 900;
      }
    }
  }

  > div {
    padding: ${({ theme }) => theme.spacing({ top: 40, bottom: 40, left: 20, right: 20 })};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(40)};
    }
  }

  ul {
    margin-bottom: ${({ theme }) => theme.spacing(32)};
    list-style: disc;

    h4 {
      text-align: left;
      @media print {
        font-size: 12px !important;
        font-weight: normal !important;
        margin-bottom: 5px !important;
      }
    }

    li {
      margin: ${({ theme }) => theme.spacing({ left: 16, bottom: 16, top: 0, right: 0 })};

      sup {
        top: -3px;
      }
    }
  }

  button {
    margin-top: ${({ theme }) => theme.spacing(30)};
    color: ${({ theme }) => theme.colours.red};
    text-decoration: underline;
  }
`;

export const Nutrition = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(46)};
  text-align: center;
  border-bottom: ${({ isNutritionalInfoEmpty, theme }) =>
    !isNutritionalInfoEmpty ? `2px solid ${theme.colours.red};` : `none`};
  @media print {
    border-bottom: none !important;
  }
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    text-align: left;
    border-bottom: 0;
  }

  p {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
  }

  button {
    span {
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    }
  }

  @media print {
    float: left !important;
    color: black !important;
    padding: 0 !important;
    h2 {
      font-size: 18px !important;
      font-weight: normal !important;
      line-height: 0 !important;
      text-align: left !important;
      margin: 10px 0px 10px 0px !important;
    }
    p {
      text-align: left !important;
      font-size: 12px !important;
      line-height: 1rem !important;
    }
  }
`;

export const Basketful = styled.div`
  margin-top: ${({ theme }) => theme.spacing(36)};
  margin-bottom: ${({ theme }) => theme.spacing(70)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    ${({ hide }) => (hide ? `display: none;` : '')}
    margin-top: 0;
  }

  button {
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-top: ${({ theme }) => theme.spacing(36)};
    }
  }
`;

export const Tools = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(20)} 0;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin: ${({ showBasketfulButton, theme }) =>
      showBasketfulButton ? `0` : `0 0 ${theme.spacing(40)} auto`};
  }

  .button-circle {
    margin: 0 ${({ theme }) => theme.spacing(15)};
    padding: 0;
  }
`;

export const FlexContainer = styled.div`
  display: none;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: flex;
    justify-content: space-between;
  }
`;

export const NutritionContent = styled.div`
  h1 {
    color: ${({ theme }) => theme.colours.white};
  }
`;

export const ButtonWrapper = styled.div`
  @media print {
    display: none;
  }
`;