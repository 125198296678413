import React from 'react';
const BeanCheck = (props) => (
    <svg
        width={23}
        height={23}
        viewBox="0 0 23 23"
        fill="none"
        {...props}
    >
        <g>
            <rect
                x={0}
                y={0}
                width={23}
                height={23}
                fill="#8d2e18"
                stroke="#8d2e18"
            />
            <path
                d="M8.5 12.1216C8.5 17.7433 10.8333 20.1525 13.1667 19.3494C13.8893 19.1007 15.5 18.0645 15.5 16.1371C15.5 13.7278 13.9444 13.7278 13.9444 12.1216C13.9444 10.5154 14.7222 10.5154 14.7222 8.90927C14.7222 7.30309 13.1667 6.5 12.3889 6.5C11.6111 6.5 8.5 6.50001 8.5 12.1216Z"
                fill="#F1E8D7"
            />
        </g>
    </svg>
);
export default BeanCheck;