import styled from 'styled-components';

const getVariantColours = ({ type, theme, invertColor = false } = {}) => {
    const variants = {
        red: {
            background: invertColor ? theme.colours.red : theme.colours.yellow,
            text: invertColor ? theme.colours.yellow : theme.colours.red,
            hover: {
                background: invertColor ? theme.colours.yellow : theme.colours.red,
                text: invertColor ? theme.colours.red : theme.colours.yellow,
            },
        },
        yellow: {
            background: invertColor ? theme.colours.yellow : theme.colours.red,
            text: invertColor ? theme.colours.red : theme.colours.yellow,
            hover: {
                background: invertColor ? theme.colours.red : theme.colours.yellow,
                text: invertColor ? theme.colours.yellow : theme.colours.red,
            },
        },
        blue: {
            background: invertColor ? theme.colours.blue : theme.colours.yellow,
            text: invertColor ? theme.colours.yellow : theme.colours.blue,
            hover: {
                background: invertColor ? theme.colours.yellow : theme.colours.blue,
                text: invertColor ? theme.colours.blue : theme.colours.yellow,
            },
        },
        brown: {
            background: invertColor ? theme.colours.brown : theme.colours.yellow,
            text: invertColor ? theme.colours.yellow : theme.colours.brown,
            hover: {
                background: invertColor ? theme.colours.yellow : theme.colours.brown,
                text: invertColor ? theme.colours.brown : theme.colours.yellow,
            },
        },
    };

    const types = {
        product: variants.yellow,
        recipe: variants.red,
    };

    if (type in types) return types[type];

    return types.product;
};

export const RelatedCarouselSlideComponent = styled.div`
  position: relative;
    
`;

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export const CardComponent = styled.article`
	position: relative;
	overflow: hidden;
    a{
        text-decoration: none;
    }
	&.bg-red{
		background-color: ${({ theme }) => theme.colours.red};
        footer{
            .circle{
                svg{
                    color: ${({ theme }) => theme.colours.red};
                    &.downloadSvg{
                        fill: ${({ theme }) => theme.colours.red};
                    }
                }
            }
        }
	}
	&.bg-brown{
		background-color: ${({ theme }) => theme.colours.brown};
		footer{
			h3{
				.circle{
					svg{
						color: ${({ theme }) => theme.colours.brown};
						&.downloadSvg{
							fill: ${({ theme }) => theme.colours.brown};
						}
					}
				}
			}
		}
	}
	&.bg-blue{
		background-color: ${({ theme }) => theme.colours.blue};
		footer{
			h3{
				.circle{
					svg{
						color: ${({ theme }) => theme.colours.blue};
						&.downloadSvg{
							fill: ${({ theme }) => theme.colours.blue};
						}
					}
				}
			}
		}
	}
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		max-width: 560px;
	}
	&:hover {
		footer{
			h3{

			}
		}
    }

  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.blue};
    max-height: 200px;
	min-height: 0;  
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 60%;
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 360px;
    }

    > a {
      display: flex;
      flex-direction: column;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: 0.4s;

      ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }

  footer {
	  height: 100%;
	  padding: 20px;
	  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
		  padding: 32px;
	  }
	  &.card-footer-solid{
		  padding: 20px;
		  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
			  padding: 32px;
		  }
	  }
	  h2 {
          margin-bottom: ${({ theme }) => theme.spacing(16)};
          color: ${({ theme }) => theme.colours.yellow};
          font-weight: 400;
          text-transform: uppercase;
          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            margin-bottom: 0;
          }
      }
    h3 {
		margin-bottom: 0;
		color: ${({ theme }) => theme.colours.yellow};
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
        text-align: left;
		.circle {
			display: inline-block;
			border-radius: 50%;
			height: 30px;
			width: 30px;
            position: relative;
            top: -1px;
			margin: 0 0 0 20px;
            text-align: center;
			background-color: ${({ theme }) => theme.colours.yellow};
            svg{
                position: relative;
				top: -4px;
				left: 1px;
                &.downloadSvg{
					width: 60%;
					margin: auto;
                    left: 0;
                }
            }
		}
		@media (max-width: 1298px) {
			margin-bottom: 0;
			font-size: 25px;
            line-height: 30px;
            .circle{
                svg{
                    top: 0;
                }
            }
		}
		@media (max-width: 768px){
			font-size: 18px;
			font-weight: 700;
			line-height: 25px;
            .circle{
				margin: 0 0 0 10px;
				height: 25px;
				width: 25px;
				top: -1px;
				svg{
					top: 1px;
					height: 11px;
				}
            }
		}
    }
    span {
      &.emphasis.small {
        display: block;
        color: ${({ theme }) => theme.colours.tan};
        text-align: center;
        margin-bottom: ${({ theme }) => theme.spacing(32)};

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin-bottom: ${({ theme }) => theme.spacing(48)};
        }
      }
    }

    button {
      margin: 0 auto;
    }
  }
`;


export const ProductCardComponent = styled.article`
	position: relative;
    width: 468px;
	max-width: 90%;
	overflow: hidden;
    margin: auto;
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 300px;
	min-height: 0;  
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 80%;
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 360px;
    }
    
    > a {
      display: flex;
      flex-direction: column;
      text-decoration: none;  
    }
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: contain;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      background-color: ${({ theme }) => theme.colours.yellow};
      ${({ type }) => (type === 'product' ? `max-width: 45%;` : '')}

      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }
    

  footer {
    height: 100%;
    padding: 16px 0;
    text-align: center;
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		padding: 32px 0;
	}  
    h2 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: 0;
      }
    }
    h3 {
		margin-bottom: 10px;
		color: ${({ theme }) => theme.colours.blue};
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
        text-align: center;
		${({ theme }) => theme.breakPoints.minBp('desktop')} {
			margin-bottom: 10px;
		}
		@media (max-width: 768px){
			font-size: 32px;
			font-weight: 700;
			line-height: 32px;
		}
		sup{
			position: relative;
			top: 0;
			left: 1px;
			font-size: 60%;
			line-height: 0.5em;
			vertical-align: super;
			font-family: 'apercu pro';
			font-weight: 400;
		}
    }
    p{
		text-align: center;
		font-family: "Apercu Pro";
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
        display: block;
		color: ${({ theme }) => theme.colours.brown};
        margin-bottom: 0;
        @media (max-width: 768px){
			font-size: 16px;
			line-height: 20px;
        }
    }
  }
`;


export const ProductCardComponentLanding = styled.article`
	position: relative;
    width: 100%;
	overflow: hidden;
    margin: auto;
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    max-height: 300px;
	min-height: 0;  
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 80%;
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 360px;
    }
    .ybar{
        position: absolute;
        height: 50%;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${({ theme }) => theme.colours.yellow};
        width: 100%;
        margin: auto;
    }
    > a {
      display: flex;
      flex-direction: column;
      text-decoration: none;  
    }
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: contain;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      background-color: transparent;
      max-width: 80%;  
      z-index: 5;  
      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
  }
    

  footer {
    height: 100%;
    padding: 16px 0;
    text-align: center;
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		padding: 32px 0;
	}  
    h2 {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: 0;
      }
    }
    h3 {
		margin-bottom: 10px;
		color: ${({ theme }) => theme.colours.blue};
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;
        text-align: center;
		${({ theme }) => theme.breakPoints.minBp('desktop')} {
			margin-bottom: 10px;
		}
		@media (max-width: 768px){
			font-size: 32px;
			font-weight: 700;
			line-height: 32px;
		}
        sup{
			position: relative;
			top: 0;
			left: 1px;
			font-size: 60%;
			line-height: 0.5em;
			vertical-align: super;
			font-family: 'apercu pro';
			font-weight: 400;
        }
    }
    p{
		text-align: center;
		font-family: "Apercu Pro";
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
        display: block;
		color: rgb(92, 91, 91);
		max-width: 250px;
		margin: auto;
        @media (max-width: 768px){
			font-size: 16px;
			line-height: 20px;
        }
    }
  }
`;