import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ScrollingImagesPromoSlide from 'components/ScrollingImagesPromoSlide/ScrollingImagesPromoSlide.jsx';
import Button from 'components/shared/Button';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { underlineText } from 'helpers/stringHelpers';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  ScrollingImagesPromoComponent,
  SliderContainer,
  PromoImage,
  PromoImageContainer,
  PromoTextArea,
  PromoText,
  PromoTitle,
} from './ScrollingImagesPromo.styles.js';

const ScrollingImagesPromo = ({ rendering = {}, params = {} }) => {
    const fields = rendering?.fields || {};
    const {
        backgroundColor = fields.backgroundColour?.value || '',
        backgroundImage = fields.backgroundImage?.value || {},
        foregroundSelected = fields.foregroundSelected?.value || false,
        foregroundTitle = fields.foregroundTitleText?.value || '',
        foregroundText = fields.foregroundText?.value || '',
        showCTA = fields.showCTA?.value || false,
        ctaText = fields.ctaText?.value || '',
        ctaLink = fields.ctaLink?.value || {},
    } = params;

    const slideItems = rendering?.placeholders?.['scrolling-images-promo-content'] || [];

    let settings = {};

  slideItems.length >= 4
    ? (settings = {
        autoplay: true,
        autoplaySpeed: 0,
        speed: 6000,
        cssEase: 'linear',
        slidesToShow: 3.5,
        slidesToScroll: 1,
        pauseOnHover: true,
        draggable: false,
        swipe: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              adaptiveHeight: true,
            },
            breakpoint: 767,
            settings: {
              slidesToShow: 1.5,
            }
          },
        ],
      })
    : (settings = {
        autoplay: false,
        slidesToShow: slideItems.length - 0.5,
        swipe: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              swipe: true,
              adaptiveHeight: true,
            },
          },
        ],
      });

  return (
    <ScrollingImagesPromoComponent backgroundColor={backgroundColor}>
      <PromoImageContainer backgroundColor={backgroundColor} foregroundSelected={foregroundSelected}>
        {foregroundSelected ? (
          <PromoTextArea backgroundColor={backgroundColor}>
            <PromoTitle>{foregroundTitle}</PromoTitle>
            <PromoText
              dangerouslySetInnerHTML={{
                __html: foregroundText
                  ? underlineText(foregroundText, {
                      openTag: '<u>',
                      closeTag: '</u>',
                    })
                  : '',
              }}
            />
            {showCTA && <Button text={ctaText} href={ctaLink?.url} target={ctaLink?.target} />}
          </PromoTextArea>
        ) : (
            <PromoImage src={backgroundImage.src || ''} alt={backgroundImage.alt || ''} />
        )}
      </PromoImageContainer>
      <SliderContainer>
          <Slider {...settings}>
              {slideItems.map((data, index) => (
                  <ScrollingImagesPromoSlide key={`${data?.fields?.slidingImage?.value?.src || 'slide'}${index}`} data={data} />
              ))}
          </Slider>
      </SliderContainer>
        <Placeholder name="scrolling-images-promo-content" rendering={rendering} />
    </ScrollingImagesPromoComponent>
  );
};

ScrollingImagesPromo.propTypes = {
    rendering: PropTypes.shape({
        fields: PropTypes.object,
        placeholders: PropTypes.object,
    }),
    params: PropTypes.object,
};

export default ScrollingImagesPromo;