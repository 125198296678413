import styled from 'styled-components';

export const FavoritesGridComponent = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing(350)};
  background-color: ${({ theme }) => theme.colours.tan};
  padding: ${({ theme }) => theme.spacing(60)} 0;

  & .no-favorites-message {
    max-width: 100%;
    margin: 0 auto;
    padding: 80px 0;
    text-align: center;
    background-color: ${({ theme }) => theme.colours.tan};

    h1 {
      max-width: 800px;
      margin: 0 auto 32px;
      color: ${({ theme }) => theme.colours.red};
      text-transform: uppercase;
    }
  }

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing(80)} 0;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing(100)} 0;
  }

  & .load-more-container {
    width: 100%;
    & button {
      margin: 0 auto;
    }
  }
`;
