import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { ProductIngredientBenefitsPromoComponent, Wrapper } from './ProductIngredientBenefitsPromo.styles.js';

import Icon from 'components/shared/Icon';
import translate from 'helpers/translate';
import { renderLanguage } from 'helpers/languageHelper';
import { globalizationConfig } from 'helpers/searchHelpers';

import { config } from './config';

export const ProductIngredientBenefitsPromo = ({ sitecoreContext = {}, fields = {}, params = {} }) => {
  const { viewBag = {}, salsify = {}, site } = sitecoreContext;
  const { title = {} } = fields;
  const { benefitsCount = 4, customHeading = '', listItemsString = '', showBeanBurst= '', invert = '' } = params;
  const { locale } = globalizationConfig();
  const checkInConfig = key => (key && key in config ? config[key] : {});
  const order = salsify?.webClaimOrder || [];

  const generateBenefits = () => {
    const benefits = [];

    order.forEach(item => {
      if (benefits.length >= benefitsCount) return;
      if (item in salsify) {
        const benefit = {
          ...checkInConfig(item),
          text: renderLanguage({ language: locale, object: salsify[item] }),
        };
        benefits.push(benefit);
      }
    });

    return benefits;
  };

  const ingredientBenefits = generateBenefits();

  const sodiumArrayContains = [
    'webClaimProtein',
    'webClaimFiber',
    'webClaimFat',
    'webClaimSodium',
    'webClaimCholesterol',
    'webClaimProtein',
    'webClaimFiber',
  ];

  const fatArrayContains = ['webClaimFiber'];

  const checkDisclaimerCondition = array =>
  ingredientBenefits && ingredientBenefits.some(item => array.includes(item?.key));
  const listItemsObject = listItemsString ? JSON.parse(listItemsString) : {};


  if ((!ingredientBenefits || ingredientBenefits.length === 0) && (!listItemsObject || Object.keys(listItemsObject).length === 0)) {
    return null;
  }

  return (
    <ProductIngredientBenefitsPromoComponent className={`benefit-list-${site.name}`} id={invert ? 'invert' : undefined} >
      <Wrapper>
        {customHeading ? (
            <h2>
              {showBeanBurst === 'true' && <Icon icon="shine" className="bean-burst" />}
              <span dangerouslySetInnerHTML={{ __html: customHeading }} />
            </h2>
        ) : (
            <h2>
              {(site && site.name === 'bushs-beans-foodservices') &&
                  <Icon icon="shine" className="bean-burst" />
              }
              <Text
                  field={
                      (title?.value && title) || {
                        value: site && site.name === 'bushs-beans-foodservices'
                            ? 'What makes these beans so <b>beautiful</b>?'
                            : translate(
                                viewBag,
                                'BushsBeans.Products.BenefitPromo.BenefitTitle',
                                'What makes these beans so beautiful'
                            ),
                      }
                  }
                  tag="span"
              />
            </h2>
        )}

        {listItemsObject?.length > 0 && (
            <ul>
              {listItemsObject.map((benefit, index) => (
                  <li className="benefit-icon" key={`ingredientBenefits-${benefit?.key}-${index}`}>
                    {benefit?.icon && (
                        // Render as <img> if benefit.icon is a URL, otherwise use <Icon>
                        /^https?:\/\//.test(benefit.icon) ? (
                            <img src={benefit.icon} alt={`${benefit.text || 'benefit'} icon`} />
                        ) : (
                            <Icon icon={benefit.icon} />
                        )
                    )}
                    {benefit?.text && <h3>{benefit.text}</h3>}
                  </li>
              ))}
            </ul>
        )}
        {ingredientBenefits?.length > 0 && (
          <ul>
            {ingredientBenefits.map((benefit, index) => (
              <li className="benefit-icon" key={`ingredientBenefits-${benefit?.key}-${index}`}>
                {benefit?.icon && <Icon icon={benefit.icon} />}
                {benefit?.text && <h3>{benefit.text}</h3>}
              </li>
            ))}
            <li>
            {!!salsify?.webClaimSodiumDisclaimer && checkDisclaimerCondition(sodiumArrayContains) && (
              <span className="disclaimer">
                {renderLanguage({ language: locale, object: salsify['webClaimSodiumDisclaimer'] })}
              </span>
            )}
            {!!salsify?.webClaimFatDisclaimer && checkDisclaimerCondition(fatArrayContains) && (
              <span className="disclaimer">
                {renderLanguage({ language: locale, object: salsify['webClaimFatDisclaimer'] })}
              </span>
            )}
            </li>
          </ul>
        )}
      </Wrapper>
    </ProductIngredientBenefitsPromoComponent>
  );
};

export default withSitecoreContext()(ProductIngredientBenefitsPromo);