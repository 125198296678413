export const checkArrayForValueMatch = ({ data = [], key = '', value }) =>
  data && data.some((obj) => key && obj[key] && obj[key] === value);

export const findValueIndex = ({ data = [], key = '', value }) =>
  data && data.findIndex((obj) => key && obj[key] && obj[key] === value);

export const fillArrayWithData = ({ data, fill = 10 }) => {
  if (!data) return [];

  let array = [];
  array.length = fill;
  array.fill(data);

  return array;
};
