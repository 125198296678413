import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { SearchPageComponent, NoResults } from './SearchPage.styles.js';

import Input from 'components/shared/Input';
import SearchResultComponent from 'components/shared/SearchResultComponent';
import SearchBox from 'components/SearchBox';
import Icon from 'components/shared/Icon';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Button from 'components/shared/Button';

import { useQueryParams, useFetch, useDeviceResize } from 'hooks';
import translate from 'helpers/translate';
import { searchConfig, globalizationConfig } from 'helpers/searchHelpers';
import { underlineText } from 'helpers/stringHelpers';
import { usePagination } from '@material-ui/lab/Pagination';

const SearchPage = ({ sitecoreContext = {} }) => {
  const { viewBag = {}, site } = sitecoreContext;
  const params = useQueryParams();

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState(params.get('searchTerm') || '');
  const [searchState, setSearchState] = useState(params.get('searchTerm') || '');
  const [noSearchValue, setNoSearchValue] = useState(false);
  const [filters, setFilters] = useState([]);
  const { endpoint } = searchConfig({ type: 'search' });
  const maxResults = 10;
  const [take, setTake] = useState(maxResults);
  const [skip, setSkip] = useState(0);
  const globalization = globalizationConfig();
  const isBushsBeansFoodservices = site?.name === 'bushs-beans-foodservices';
  const device = useDeviceResize();
  const resultsRef = useRef(null);

  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        term: searchTerm,
        filters: {
          generalFilters: {
            pageTypeFilters: filters.length === 0 ? ['article', 'general', 'product', 'recipe'] : filters,
          },
        },
        take: take,
        skip: skip,
      },
      context: {
        globalization,
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site.name
          },
        },
        content: {},
      },
    },
  });

  const { items } = usePagination({
    count:
      typeof data?.subject?.totalResultsCount === 'number'
        ? Math.ceil(data?.subject?.totalResultsCount / maxResults)
        : 0,
    siblingCount: device === 'mobile' ? 0 : 1,
    boundaryCount: device === 'mobile' ? 1 : 1,
  });

  useEffect(() => {
    window.history.replaceState(null, null, `?type=all&searchTerm=${searchTerm}`);
  }, [searchTerm]);

  useLayoutEffect(() => {
    if (resultsRef?.current)
      resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }, [skip]);

  return (
    <SearchPageComponent showOptions={showFilterOptions}>
      <div className="search-box-container">
        <h1 className="search-top-heading h3">{translate(viewBag, 'BushsBeans.Search.Labels.YouSearchedFor')}</h1>
        <SearchBox
          searchValue={searchState}
          initialInputValue={searchTerm}
          showError={noSearchValue}
          icon="search"
          onChange={(event) => {
            setSearchState(event);
            if (noSearchValue) setNoSearchValue(false);
          }}
          onSubmit={() => {
            if (searchState.length > 0) {
              if (searchTerm !== searchState) setSearchTerm(searchState);
            } else {
              setNoSearchValue(true);
            }
          }}
        />
      </div>
      <div className="filters-container">
        <div className="filters-container-inner">
          <div className="filters">
            <div>
              <div className="filter-note">
                <Icon icon="filter" size="xl" />
                <h4>{translate(viewBag, 'BushsBeans.Search.Labels.Filter')}</h4>
              </div>
              <button
                className="filter-button"
                onClick={() => {
                  setShowFilterOptions((prevState) => (prevState ? false : true));
                }}
              >
                <Icon icon="filter" size="xl" />
                <h3>{translate(viewBag, 'BushsBeans.Search.Labels.Filter')}</h3>
              </button>
            </div>
            <div className="search-options">
              <Input
                type="checkbox"
                checked={filters.length === 0}
                onClick={() => {
                  setFilters([]);
                }}
                label={{ value: translate(viewBag, 'BushsBeans.Search.Filters.General.All') }}
              />
              <Input
                type="checkbox"
                checked={filters.includes('product')}
                onChange={() => {
                  setFilters((prevState) =>
                    prevState.includes('product')
                      ? prevState.filter((item) => item !== 'product')
                      : [...prevState, ...['product']]
                  );
                }}
                label={{ value: translate(viewBag, 'BushsBeans.Search.Filters.General.Products') }}
                name="products"
              />
              <Input
                type="checkbox"
                checked={filters.includes('recipe')}
                onChange={() => {
                  setFilters((prevState) =>
                    prevState.includes('recipe')
                      ? prevState.filter((item) => item !== 'recipe')
                      : [...prevState, ...['recipe']]
                  );
                }}
                label={{ value: translate(viewBag, 'BushsBeans.Search.Filters.General.Recipes') }}
                name="recipes"
              />
              {isBushsBeansFoodservices ? (
                  <Input
                      type="checkbox"
                      onChange={() => {
                        setFilters((prevState) =>
                            prevState.includes('article')
                                ? prevState.filter((item) => item !== 'article')
                                : [...prevState, 'article']
                        );
                      }}
                      checked={filters.includes('article')}
                      label={{ value: 'Insights' }}
                      name="articles"
                  />
              ) : (
                  <Input
                      type="checkbox"
                      onChange={() => {
                        setFilters((prevState) =>
                            prevState.includes('article')
                                ? prevState.filter((item) => item !== 'article')
                                : [...prevState, 'article']
                        );
                      }}
                      checked={filters.includes('article')}
                      label={{ value: translate(viewBag, 'BushsBeans.Search.Filters.General.Articles') }}
                      name="articles"
                  />
              )}
            </div>
          </div>
          <div className="search-counter">
            {typeof data?.subject?.totalResultsCount === 'number' && (
              <h4>{`${data?.subject?.totalResultsCount} Results`}</h4>
            )}
          </div>
        </div>
      </div>
      <div className="heading" ref={resultsRef}>
        {!error && data?.subject?.totalResultsCount > 0 && (
          <h2
            dangerouslySetInnerHTML={{
              __html: underlineText(
                translate(viewBag, 'BushsBeans.Search.Labels.LookingForThis', 'LOOKING FOR ~THIS?~'),
                {}
              ),
            }}
          />
        )}
      </div>
      <div className="search-results">
        {error ? (
          <div>{translate(viewBag, 'BushsBeans.Search.Labels.ErrorTitle', 'Oops, there was an error')}</div>
        ) : loading ? (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        ) : data?.subject?.totalResultsCount > 0 ? (
          Array.isArray(data?.subject?.results) &&
          data.subject.results.map(
            ({ itemId, searchImage, searchDescription, searchTitle, itemUrl, pageType }) => {
              return (
                <SearchResultComponent
                  key={itemId}
                  image={{ src: searchImage?.url, alt: searchImage?.alt }}
                  title={searchTitle}
                  searchDescription={searchDescription}
                  itemUrl={itemUrl}
                  pageType={pageType}
                />
              );
            }
          )
        ) : (
          <NoResults>
            <h1>{`${translate(
              viewBag,
              'BushsBeans.Search.Labels.SorryWeCouldntFindTitle'
            )} "${searchTerm}"`}</h1>
          </NoResults>
        )}
      </div>
      {!loading && data?.subject?.totalResultsCount > 0 ? (
        <div className="pagination-section">
          <h3>{translate(viewBag, 'BushsBeans.Search.Labels.PaginationTitle', 'MORE BEAN RESULTS')}</h3>
          <div className="pagination-wrap">
            <ul>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = '…';
                } else if (type === 'page') {
                  children = (
                    <button
                      {...item}
                      onClick={(event) => {
                        item.onClick(event);
                        // setTake(maxResults * page);
                        setSkip(page === 1 ? 0 : maxResults * page - maxResults);
                      }}
                      type="button"
                      className={selected ? 'active' : ''}
                    >
                      {page}
                    </button>
                  );
                } else {
                  if (type === 'previous') {
                    children = (
                      <Button
                        {...item}
                        onClick={(event) => {
                          item.onClick(event);
                          if (take > maxResults) {
                            // setTake((prevState) => prevState - 10);
                            setSkip((prevState) => prevState - 10);
                          }
                        }}
                        icon="chevron-left"
                        type="circle"
                        variant="red"
                        size="large"
                        ariaLabel="Previous page"
                      />
                    );
                  }

                  if (type === 'next') {
                    children = (
                      <Button
                        {...item}
                        onClick={(event) => {
                          item.onClick(event);
                          if (take < data?.subject?.totalResultsCount) {
                            // setTake((prevState) => prevState + 10);
                            setSkip((prevState) => prevState + 10);
                          }
                        }}
                        icon="chevron-right"
                        type="circle"
                        variant="red"
                        size="large"
                        ariaLabel="Next page"
                      />
                    );
                  }
                }

                return (
                  <li key={index} className={`pagination-${type}`}>
                    {children}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </SearchPageComponent>
  );
};

SearchPage.propTypes = {};

export default withSitecoreContext()(SearchPage);