import React from 'react';
import {NinetysSvg} from "./NineteenNfourSvg.styles";


const NineteenNfourSvg = () => (
    <NinetysSvg className="NineteenNfourSticker" version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 676.3 864" style="enable-background:new 0 0 676.3 864;">
    <g>
	<g>
		<path className="st13"
              d="M382.4,64.2C382.4,64.2,382.4,64.2,382.4,64.2C382.4,64.2,382.4,64.2,382.4,64.2L382.4,64.2z"/>
        <polygon className="st13" points="58,93.2 56.9,93.6 58,93.2"/>
        <polygon className="st13" points="616.7,490.6 616.7,490.6 617.1,490.5 		"/>
        <polygon className="st13" points="616.7,776.3 617.1,776.1 616.7,776.3 		"/>
        <polygon className="st31" points="153.9,819.8 153.9,819.8 154.3,819.8 		"/>
        <path className="st2" d="M662.5,180.6c-1.7-5.6-4.5-11.7-8.5-17.6c-0.1-0.2-0.3-0.4-0.4-0.6c0-20,0-39.9,0-59.9c0-6-0.7-12.9-3.2-20.6
			c-1.8-5.7-4.7-11.9-8.8-17.7c-3.1-4.4-6.9-8.6-11.2-12.2c-6.5-5.5-14.1-9.6-21.4-12c-7.4-2.4-14.5-3.3-20.7-3.3
			c-8.2,0-14.9,1.4-20.5,3.2l0,0c-14.9,4.7-29.8,7.7-45.3,9.4l0,0c-4.3,0.5-9.5,1.4-15.1,3.3c-4.2,1.4-8.5,3.3-12.7,5.8
			c-6.3,3.7-12,8.5-16.2,13.3c-2.5,2.8-4.5,5.5-6.2,8.1c-3.6-3.6-7.4-7-11.4-10.2C450.4,61.2,438.6,54.3,426,49
			c-12.4-5.2-25.6-9-39.4-11.5l0,0c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2,0-0.3-0.1l0,0c-10.5-2-20.9-3-31.1-3c-14.3,0-28.5,2.1-42.2,7
			c-0.8,0.3-1.6,0.6-2.3,0.9c-10.6-8.4-22.8-15.3-35.8-20.3c-13.4-5.2-27.7-8.4-42.6-10l-1-0.1l-1-0.1c-4.3-0.3-8.5-0.5-12.7-0.5
			c-15.5,0-30.6,2.3-44.6,7.2c-1.5-0.6-3-1.2-4.5-1.7c-7.2-2.3-14.2-3.2-20.6-3.2c-2.7,0-5.2,0.2-7.7,0.4l0,0
			c-18.4,2.1-37.1,5.6-55.9,10.9l0,0c-6,1.7-12.3,4.3-18.4,8.2c-4.5,2.8-8.8,6.3-12.5,10.1c-5.6,5.7-9.8,12.1-12.7,18.2
			c-2.9,6-4.6,11.7-5.6,16.7l-0.4,1.8l-0.3,1.8c0,0.3-0.2,1.3-0.5,2.3c0,0.1-0.1,0.3-0.1,0.4c-0.6,0.6-1.2,1.2-1.9,2
			c-1.4,1.5-3,3.3-4.9,5.7c-2.8,3.6-6.2,8.6-9.1,15.6c-1.4,3.5-2.7,7.5-3.7,11.8c-0.9,4.3-1.4,9-1.4,13.6c0,2.7,0,5.3,0,8
			c0,2.7,0,5.3,0,8c0,5.7,0.7,12.4,3,20c1.7,5.6,4.5,11.7,8.5,17.6c1.9,2.8,4.1,5.5,6.6,8c0,34.5,0,69.1,0,103.6
			c0,5.6,0.7,12.1,2.8,19.2c-0.6,0.6-1.2,1.2-1.9,1.9c-1.4,1.5-3,3.3-4.9,5.7c-2.8,3.6-6.2,8.6-9.1,15.6c-1.4,3.5-2.7,7.5-3.7,11.8
			c-0.9,4.3-1.4,9-1.4,13.6c0,2.7,0,5.3,0,8c0,2.7,0,5.3,0,8c0,5.7,0.7,12.4,3,20c1.7,5.6,4.5,11.7,8.5,17.6c1.9,2.8,4.1,5.5,6.6,8
			c0,34.5,0,69.1,0,103.6c0,5.6,0.7,12.1,2.8,19.2c-0.6,0.6-1.2,1.2-1.9,1.9c-1.4,1.5-3,3.3-4.9,5.7c-2.8,3.6-6.2,8.6-9.1,15.6
			c-1.4,3.5-2.7,7.5-3.7,11.8c-0.9,4.3-1.4,9-1.4,13.6c0,2.7,0,5.3,0,8c0,2.7,0,5.3,0,8c0,5.7,0.7,12.4,3,20
			c1.7,5.6,4.5,11.7,8.5,17.6c1.9,2.8,4.1,5.5,6.6,8c0,34.5,0,69.1,0,103.6c0,6.1,0.8,13.1,3.3,20.9c1.9,5.8,4.8,11.9,9,17.8
			c3.1,4.4,7,8.5,11.3,12.1c6.5,5.4,14,9.4,21.3,11.8c7.3,2.4,14.4,3.3,20.6,3.3c7.4,0,13.7-1.2,19.1-2.8l0,0
			c12.7-3.7,25.6-6.2,38.8-7.7l0,0c3.7-0.4,7.5-1.2,11.3-2.3c2.5,0.3,4.9,0.4,7.2,0.4c0.8,0,1.7,0,2.6,0l-0.1,0
			c3.8-0.1,7.7-0.2,11.6-0.2c7.5,0,15.1,0.3,22.9,0.7l0,0c6.3,0.5,12.5,0.8,18.7,0.8c11.2,0,22.2-0.9,33.3-3.1
			c4.2-0.8,8.4-1.9,12.5-3.2c1.8,2,3.8,3.8,5.8,5.5c9.1,7.6,20.1,12.6,30.9,14.6l0,0c13.3,2.6,26.9,5.2,40.7,7.9l0.2,0
			c11.6,2.2,23.1,3.4,34.7,3.4c11.2,0,22.5-1.2,33.7-4c9.8-2.5,19.5-6.2,28.5-11.3c7.9-4.4,15.2-9.8,21.6-15.9
			c1.3-1.2,2.6-2.5,3.8-3.8c0.1,0.3,0.2,0.6,0.3,1c2.1,6,5.3,12.2,9.6,17.8c3.2,4.2,7.1,8.2,11.3,11.6c6.4,5.1,13.6,8.9,20.8,11.2
			c7.2,2.3,14.2,3.2,20.6,3.2c3.2,0,6.2-0.2,9-0.6l0,0c19.7-2.6,39.7-6.9,59.8-13.3l0,0c13.7-4.3,25.8-12.9,34.3-24.3
			c4.3-5.7,7.7-12.2,10.1-19.1c2.4-7,3.7-14.5,3.7-22.1c0-8.6,0-17.2,0-25.8c1.6-2.3,3-4.7,4.3-7.2c4.8-9.3,7.7-20.1,7.7-31.6
			c0-14.5,0-28.9,0-43.4c0-5.7-0.7-12.4-3-20c-1.7-5.6-4.5-11.7-8.5-17.6c-0.1-0.2-0.3-0.4-0.4-0.6c0-20,0-39.9,0-59.9
			c0-4.1-0.4-8.7-1.4-13.6c0.9-4.4,1.4-8.9,1.4-13.5c0-8.6,0-17.2,0-25.8c1.6-2.3,3-4.7,4.3-7.2c4.8-9.3,7.7-20.1,7.7-31.6
			c0-14.5,0-28.9,0-43.4v0c0-5.7-0.7-12.4-3-19.9c-1.7-5.6-4.5-11.7-8.5-17.6c-0.1-0.2-0.3-0.4-0.4-0.6c0-20,0-39.9,0-59.9
			c0-4.1-0.4-8.7-1.4-13.6c0.9-4.4,1.4-8.9,1.4-13.5c0-8.6,0-17.2,0-25.8c1.6-2.3,3-4.7,4.3-7.2c4.8-9.3,7.7-20.1,7.7-31.6
			c0-14.5,0-28.9,0-43.4v0C665.5,194.8,664.9,188.2,662.5,180.6z M382.4,64.2L382.4,64.2C382.4,64.2,382.4,64.2,382.4,64.2
			C382.4,64.2,382.4,64.2,382.4,64.2z M616.7,490.6l0.4-0.1L616.7,490.6L616.7,490.6z M58,93.2L58,93.2l-1.1,0.4L58,93.2z
			 M616.7,776.3C616.7,776.3,616.7,776.3,616.7,776.3l0.5-0.2L616.7,776.3z"/>
	</g>
    <g>
		<path className="st9" d="M93.1,764.4c0-48.8,0-97.6,0-146.4c0-2.8-2.8-3.8-7.1-2.3c-2.2,0.7-4.3,1.5-6.5,2.3c-2.8,1-4.6-0.1-4.6-2.9
			c0-5.3,0-10.7,0-16c0-2.5,2.5-4.3,5.2-6.9c9.2-8,14.1-25,15.7-35.3c0.6-2.9,2.2-5,4.9-5.7c15.4-4.3,30.7-7.2,46.1-8.9
			c2.8-0.3,4.6,1.4,4.6,4.1c0,68.8,0,137.5,0,206.3c0,2.8-1.8,4.8-4.6,5.1c-16.4,1.9-32.8,5-49.2,9.8
			C94.9,768.4,93.1,767.2,93.1,764.4z"/>
        <path className="st9" d="M168.1,751.1c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.7,4.6-4.8c76.3,0.2,84.9,4.8,84.9-16.4c0-1.3,0-2.7,0-4
			c-0.3,0-1.2,1.4-3.7,2.9c-6.5,4.1-17.8,6.9-33.2,5.7c-17.8-2.2-60.3-8.5-60.3-42.3c0-20.4,16.6-50.2,64.9-46.5
			c64.3,6.9,77.5,55.1,77.5,102.1c0,79.1-15.1,120.5-89.5,113.8c-13.5-0.8-27.1-1.1-40.6-0.6C169.9,755.7,168.1,753.9,168.1,751.1z
			 M234.2,583.2c0-7.1-5.2-15.3-12.9-15.9c-8-0.6-12.9,6.9-12.9,13.9c0,6.8,3.7,12.4,12.9,13.1C231.7,595.2,234.2,590,234.2,583.2z"
        />
        <path className="st9" d="M317.8,766.3c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.3,4.6-3.7c76.3,15,84.9,13.5,84.9-6c0-1.3,0-2.7,0-4
			c-0.3,0-1.2,1.3-3.7,2.8c-6.5,3.9-17.8,6.3-33.2,3.4c-17.8-3.3-60.3-19-60.3-53.4c0-21,16.6-44.1,64.9-35
			c64.3,11.5,77.5,54,77.5,102.1c0,77.5-15.1,126.3-89.5,112.4c-13.5-2.6-27.1-5.2-40.6-7.8C319.6,771.3,317.8,769.1,317.8,766.3z
			 M383.9,608.8c0-7.1-5.2-15.7-12.9-17.1c-8-1.5-12.9,5.2-12.9,12.3c0,6.8,3.7,13,12.9,14.7C381.4,620.7,383.9,615.6,383.9,608.8z"
        />
        <path className="st9" d="M532.7,787.8c0-17,0-34,0-51c0-2.8-1.8-4.4-4.6-4.1c-20.3,2.1-40.6,2.3-60.9,1.1c-2.8-0.2-4.6-2.1-4.6-4.9
			c0-14.9,0-29.7,0-44.6c0-2.2,0.3-3.4,1.5-5.4c19.9-31.1,39.8-63.5,59.6-97.7c1.2-2.3,2.8-3.3,5.2-3.6c19.2-2.1,38.3-5.9,57.5-11.9
			c2.8-0.9,4.6,0.3,4.6,3.1c0,31.1,0,62.1,0,93.2c0,2.8,1.8,4,4.6,3c0.9-0.3,1.8-0.7,2.8-1c2.8-1,4.6,0.1,4.6,2.9
			c0,14.5,0,28.9,0,43.4c0,2.8-1.8,5.3-4.6,6.4c-0.9,0.3-1.8,0.7-2.8,1c-2.8,1-4.6,3.4-4.6,6.2c0,17,0,34,0,51
			c0,2.8-1.8,5.2-4.6,6.1c-16.4,5.2-32.8,8.7-49.2,10.9C534.5,792.2,532.7,790.5,532.7,787.8z M532.7,674.9c0-14.2,0-28.5,0-42.7
			c0-1.5-1.8-1.3-2.8,0c-8.1,14.9-16.2,29.6-24.3,43.9c-0.6,0.9-0.9,1.9-0.9,2.8c0,1.8,1.5,2.7,4,2.6c6.5-0.3,12.9-0.8,19.4-1.4
			C530.9,679.8,532.7,677.7,532.7,674.9z"/>
	</g>
    <path className="st0" d="M93.1,298.2c0-48.8,0-97.6,0-146.4c0-2.8-2.8-3.8-7.1-2.3c-2.2,0.7-4.3,1.5-6.5,2.3c-2.8,1-4.6-0.1-4.6-2.9
		c0-5.3,0-10.7,0-16c0-2.5,2.5-4.3,5.2-6.9c9.2-8,14.1-25,15.7-35.3c0.6-2.9,2.2-5,4.9-5.7c15.4-4.3,30.7-7.2,46.1-8.9
		c2.8-0.3,4.6,1.4,4.6,4.1c0,68.8,0,137.5,0,206.3c0,2.8-1.8,4.8-4.6,5.1c-16.4,1.9-32.8,5-49.2,9.8
		C94.9,302.2,93.1,300.9,93.1,298.2z M168.1,284.9c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.7,4.6-4.8c76.3,0.2,84.9,4.8,84.9-16.4
		c0-1.3,0-2.7,0-4c-0.3,0-1.2,1.4-3.7,2.9c-6.5,4.1-17.8,6.9-33.2,5.7c-17.8-2.2-60.3-8.5-60.3-42.3c0-20.4,16.6-50.2,64.9-46.5
		c64.3,6.9,77.5,55.1,77.5,102.1c0,79.1-15.1,120.5-89.5,113.8c-13.5-0.8-27.1-1.1-40.6-0.6C169.9,289.4,168.1,287.7,168.1,284.9z
		 M234.2,117c0-7.1-5.2-15.3-12.9-15.9c-8-0.6-12.9,6.9-12.9,13.9c0,6.8,3.7,12.4,12.9,13.1C231.7,129,234.2,123.8,234.2,117z
		 M317.8,300.1c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.3,4.6-3.7c76.3,15,84.9,13.5,84.9-6c0-1.3,0-2.7,0-4c-0.3,0-1.2,1.3-3.7,2.8
		c-6.5,3.9-17.8,6.3-33.2,3.4c-17.8-3.3-60.3-19-60.3-53.4c0-21,16.6-44.1,64.9-35c64.3,11.5,77.5,54,77.5,102.1
		c0,77.5-15.1,126.3-89.5,112.4c-13.5-2.6-27.1-5.2-40.6-7.8C319.6,305.1,317.8,302.9,317.8,300.1z M383.9,142.6
		c0-7.1-5.2-15.7-12.9-17.1c-8-1.5-12.9,5.3-12.9,12.3c0,6.8,3.7,13,12.9,14.7C381.4,154.5,383.9,149.4,383.9,142.6z M532.7,321.6
		c0-17,0-34,0-51c0-2.8-1.8-4.4-4.6-4.1c-20.3,2.1-40.6,2.3-60.9,1.1c-2.8-0.2-4.6-2.1-4.6-4.9c0-14.9,0-29.7,0-44.6
		c0-2.2,0.3-3.4,1.5-5.4c19.9-31.1,39.8-63.5,59.6-97.7c1.2-2.3,2.8-3.3,5.2-3.6c19.2-2.1,38.3-5.9,57.5-11.9
		c2.8-0.9,4.6,0.3,4.6,3.1c0,31.1,0,62.1,0,93.2c0,2.8,1.8,4,4.6,3c0.9-0.3,1.8-0.7,2.8-1c2.8-1,4.6,0.1,4.6,2.9
		c0,14.5,0,28.9,0,43.4c0,2.8-1.8,5.3-4.6,6.4c-0.9,0.3-1.8,0.7-2.8,1c-2.8,1-4.6,3.4-4.6,6.2c0,17,0,34,0,51c0,2.8-1.8,5.2-4.6,6.1
		c-16.4,5.2-32.8,8.7-49.2,10.9C534.5,326,532.7,324.3,532.7,321.6z M532.7,208.7c0-14.2,0-28.5,0-42.7c0-1.5-1.8-1.3-2.8,0
		c-8.1,14.9-16.2,29.6-24.3,43.9c-0.6,0.9-0.9,1.9-0.9,2.8c0,1.8,1.5,2.7,4,2.6c6.5-0.3,12.9-0.8,19.4-1.4
		C530.9,213.6,532.7,211.5,532.7,208.7z"/>
    <path className="st1" d="M93.1,531.3c0-48.8,0-97.6,0-146.4c0-2.8-2.8-3.8-7.1-2.3c-2.2,0.7-4.3,1.5-6.5,2.3c-2.8,1-4.6-0.1-4.6-2.9
		c0-5.3,0-10.7,0-16c0-2.5,2.5-4.3,5.2-6.9c9.2-8,14.1-25,15.7-35.3c0.6-3,2.2-5,4.9-5.7c15.4-4.3,30.7-7.2,46.1-8.9
		c2.8-0.3,4.6,1.4,4.6,4.1c0,68.8,0,137.5,0,206.3c0,2.8-1.8,4.8-4.6,5.1c-16.4,1.9-32.8,5-49.2,9.8C94.9,535.3,93.1,534,93.1,531.3
		z M168.1,518c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.7,4.6-4.8c76.3,0.2,84.9,4.8,84.9-16.4c0-1.3,0-2.7,0-4c-0.3,0-1.2,1.4-3.7,2.9
		c-6.5,4.1-17.8,6.9-33.2,5.7c-17.8-2.2-60.3-8.5-60.3-42.3c0-20.4,16.6-50.2,64.9-46.5c64.3,6.9,77.5,55.1,77.5,102.1
		c0,79.1-15.1,120.5-89.5,113.8c-13.5-0.8-27.1-1.1-40.6-0.6C169.9,522.5,168.1,520.8,168.1,518z M234.2,350.1
		c0-7.1-5.2-15.3-12.9-15.9c-8-0.6-12.9,6.9-12.9,13.9c0,6.8,3.7,12.4,12.9,13.1C231.7,362.1,234.2,356.9,234.2,350.1z M317.8,533.2
		c0-35.2,0-70.3,0-105.5c0-2.8,1.8-4.3,4.6-3.7c76.3,15,84.9,13.5,84.9-6c0-1.3,0-2.7,0-4c-0.3,0-1.2,1.3-3.7,2.8
		c-6.5,3.9-17.8,6.3-33.2,3.4c-17.8-3.3-60.3-19-60.3-53.4c0-21,16.6-44.1,64.9-35c64.3,11.5,77.5,54,77.5,102.1
		c0,77.5-15.1,126.3-89.5,112.4c-13.5-2.6-27.1-5.2-40.6-7.8C319.6,538.2,317.8,536,317.8,533.2z M383.9,375.7
		c0-7.1-5.2-15.7-12.9-17.1c-8-1.5-12.9,5.3-12.9,12.3c0,6.8,3.7,13,12.9,14.7C381.4,387.6,383.9,382.5,383.9,375.7z M532.7,554.7
		c0-17,0-34,0-51c0-2.8-1.8-4.4-4.6-4.1c-20.3,2.1-40.6,2.3-60.9,1.1c-2.8-0.2-4.6-2.1-4.6-4.9c0-14.9,0-29.7,0-44.6
		c0-2.2,0.3-3.4,1.5-5.4c19.9-31.1,39.8-63.5,59.6-97.7c1.2-2.3,2.8-3.3,5.2-3.6c19.2-2.1,38.3-5.9,57.5-11.9
		c2.8-0.9,4.6,0.3,4.6,3.1c0,31.1,0,62.1,0,93.2c0,2.8,1.8,4,4.6,3c0.9-0.3,1.8-0.7,2.8-1c2.8-1,4.6,0.1,4.6,2.9
		c0,14.5,0,28.9,0,43.4c0,2.8-1.8,5.3-4.6,6.4c-0.9,0.3-1.8,0.7-2.8,1c-2.8,1-4.6,3.4-4.6,6.2c0,17,0,34,0,51c0,2.8-1.8,5.2-4.6,6.1
		c-16.4,5.2-32.8,8.7-49.2,10.9C534.5,559.1,532.7,557.4,532.7,554.7z M532.7,441.8c0-14.2,0-28.5,0-42.7c0-1.5-1.8-1.3-2.8,0
		c-8.1,14.9-16.2,29.6-24.3,43.9c-0.6,0.9-0.9,1.9-0.9,2.8c0,1.8,1.5,2.7,4,2.6c6.5-0.3,12.9-0.8,19.4-1.4
		C530.9,446.7,532.7,444.6,532.7,441.8z"/>
</g>
</NinetysSvg>
);

export default NineteenNfourSvg;