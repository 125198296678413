export const breakPointValues = {
  desktop: '1200px',
  tablet: '767px',
  mobile: '480px',
};

const setBreakpoint = (breakpoint) =>
  breakpoint in breakPointValues ? breakPointValues[breakpoint] : breakpoint;

export const breakPoints = {
  minBp: (breakpoint) => `@media (min-width: ${setBreakpoint(breakpoint)})`,
  maxBp: (breakpoint) => `@media (max-width: ${setBreakpoint(breakpoint)})`,
  minMaxBp: (minBreakpoint, maxBreakpoint) =>
    `@media (min-width: ${setBreakpoint(minBreakpoint)}) and (max-width: ${setBreakpoint(maxBreakpoint)})`,
};
