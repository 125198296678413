import React from 'react';

import Card from 'components/shared/Card';

const ContentCard = (props) => {
  const { fields = {}, params = {} } = props;

  return <Card type="content" {...fields} {...params} />;
};

export default ContentCard;
