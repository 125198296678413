import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SVG from 'react-inlinesvg';

import {
    FooterComponent,
    SubscribeWrapper,
    SocialWrapper,
    FsSocialWrapper,
    LinksWrapper,
    Row,
    Slogan,
    Grid,
    FsGrid,
    ColumnsWrapper,
} from './Footer.styles.js';

import { useDeviceResize } from 'hooks';

import Subscribe from 'components/shared/Subscribe';
import SocialLinks from 'components/shared/SocialLinks';
import LinksList from './LinksList';
import Button from 'components/shared/Button';
import translate from 'helpers/translate';

export const Footer = (
    {
      footerContext = {},
      sitecoreContext: {globalContent = {},site, viewBag = {}},
      variant = 'red',
      hideSubscribeCanada,
    }
) => {
  if (footerContext) {
    if (Array.isArray(footerContext.globalContent)) {
      globalContent = [...globalContent, ...footerContext.globalContent];
    } else if (typeof footerContext.globalContent === 'object' && footerContext.globalContent !== null) {
      globalContent = {...globalContent, ...footerContext.globalContent};
    } else {
      const globalContent = [footerContext.globalContent];
    }
  }

  const {footer = {}, homepageLink} = globalContent;
  const {
    companyTagLineSvg = {},
    linkColumns = [],
    socialLinks = [],
    utilityLinks = [],
    widgets = [],
  } = footer;
  const device = useDeviceResize();



  hideSubscribeCanada = homepageLink === '/en_CA/' || homepageLink === '/fr_CA/';
  if (site && site.name === 'bushs-beans-foodservices') {
    const endpoint = widgets.find((widget) => widget?.type === 'industry-subscribe-form')?.actionDestination;
    return (
        <FooterComponent variant={variant} className="hide-on-print">
          <FsGrid hideSubscribe={hideSubscribeCanada}>
            {!hideSubscribeCanada && (
                <SubscribeWrapper>
            <span className="h1">
              sign up for industry updates
            </span>
                  <Subscribe
                      endpoint={endpoint}
                      placeholder={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribePlaceholder',
                          'enter your email'
                      )}
                      success={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribeSuccessMessage',
                          'thanks for subscribing'
                      )}
                      error={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribeErrorMessage',
                          'please enter valid email'
                      )}
                  />
                </SubscribeWrapper>
            )}


                <ColumnsWrapper className="fs-foter-columns"  variant="blue">
                  {linkColumns &&
                      linkColumns?.map((links, index) => <LinksList key={`LinksList-${index}`} {...links} />)}
                </ColumnsWrapper >


            {socialLinks && (
                <FsSocialWrapper variant={variant}>
                  <h3 className="list-title emphasis small">
                    {translate(viewBag, 'BushsBeans.Global.Footer.SocialTitle', 'Connect')}
                  </h3>
                  {socialLinks && <SocialLinks links={socialLinks} />}
                </FsSocialWrapper>
            )}
          </FsGrid>

          <Row>
            <Slogan>
              <SVG src={companyTagLineSvg?.src} />
            </Slogan>
          </Row>
          <Row variant={variant}>
            {utilityLinks && (
                <>
                  <nav>
                    <ul>
                      {utilityLinks &&
                          utilityLinks.map(({ href, path, target, title }, index) => (
                              <li key={`utilityLink-${index}_${href || path}`}>
                                <a href={href || path} target={target}>
                                  {title}
                                </a>
                              </li>
                          ))}
                    </ul>
                  </nav>
                  <small>
                    {translate(
                        viewBag,
                        'BushsBeans.Global.Footer.copywrite',
                        '© BUSH BROTHERS & COMPANY. ALL RIGHTS RESERVED.'
                    )}
                  </small>
                </>
            )}
          </Row>
        </FooterComponent>
    );
  } else{
    const endpoint = widgets.find((widget) => widget?.type === 'subscribe-form')?.actionDestination;
    return (
        <FooterComponent variant={variant} className="hide-on-print">
          <Grid hideSubscribe={hideSubscribeCanada}>
            {!hideSubscribeCanada && (
                <SubscribeWrapper>
            <span className="h1">
              {translate(viewBag, 'BushsBeans.Global.Footer.SubscribeTitle', 'subscribe for bean news')}
            </span>
                  <Subscribe
                      endpoint={endpoint}
                      placeholder={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribePlaceholder',
                          'enter your email'
                      )}
                      success={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribeSuccessMessage',
                          'Thanks for signing up!'
                      )}
                      error={translate(
                          viewBag,
                          'BushsBeans.Global.Footer.SubscribeErrorMessage',
                          'please enter valid email'
                      )}
                  />
                </SubscribeWrapper>
            )}

            {socialLinks && (
                <SocialWrapper variant={variant}>
                  <h3 className="list-title emphasis small">
                    {translate(viewBag, 'BushsBeans.Global.Footer.SocialTitle', 'Connect')}
                  </h3>
                  {socialLinks && <SocialLinks links={socialLinks} />}
                </SocialWrapper>
            )}
            {device === 'desktop' && (
                <LinksWrapper variant={variant}>
                  {linkColumns &&
                      linkColumns?.map((links, index) => <LinksList key={`LinksList-${index}`} {...links} />)}
                </LinksWrapper>
            )}
          </Grid>

          <Row>
            <Slogan>
              <SVG src={companyTagLineSvg?.src} />
            </Slogan>
          </Row>
          <Row variant={variant}>
            {utilityLinks && (
                <>
                  <nav>
                    <ul>
                      {utilityLinks &&
                          utilityLinks.map(({ href, path, target, title }, index) => (
                              <li key={`utilityLink-${index}_${href || path}`}>
                                <a href={href || path} target={target}>
                                  {title}
                                </a>
                              </li>
                          ))}
                    </ul>
                  </nav>
                  <small>
                    {translate(
                        viewBag,
                        'BushsBeans.Global.Footer.copywrite',
                        '© BUSH BROTHERS & COMPANY. ALL RIGHTS RESERVED.'
                    )}
                  </small>
                </>
            )}
          </Row>
        </FooterComponent>
    );
  }
};

Footer.propTypes = {
  sitecoreContext: PropTypes.shape({
    globalContent: PropTypes.shape({
      footer: PropTypes.shape({
        companyTagLineSvg: PropTypes.object,
        utilityLinks: PropTypes.shape({
          title: PropTypes.string,
          links: PropTypes.array,
        }),
        linkColumns: PropTypes.shape({
          columnTitle: PropTypes.string,
          links: PropTypes.array,
        }),
        socialLinks: PropTypes.shape({
          title: PropTypes.string,
          links: PropTypes.array,
        }),
        widgets: PropTypes.array,
      }),
    }),
  }),
};

export default withSitecoreContext()(Footer);