import React from 'react';
import { NutritionLabelTableComponent } from './NutritionLabelTableCA.styles';

const NutritionLabelTableCA = ({ sitecoreContext, translate }) => {
  const { viewBag = {}, salsify = {} } = sitecoreContext;

  const {
    specCalories,
    specTotalFat,
    specTotalFatPercent,
    specSatFat,
    specTransFat,
    specSatTransFatPercent,
    specCholesterol,
    specCholesterolPercent,
    specSodium,
    specSodiumPercent,
    specTotalCarb,
    specTotalCarbPercent,
    specDietFiber,
    specDietFiberPercent,
    specSugars,
    specProtein,
    specProteinPercent,
    specVitA,
    specVitAPercent,
    specVitC,
    specVitcPercent,
    specCalcium,
    specCalciumPercent,
    specIron,
    specIronPercent,
    servingSizeDescriptionUNPREPAREDBY_SERVINGSERVINGSIZE1: specServingSize,
  } = salsify;

  // Add space between number and unit
  const formatSalsifyData = salsifyValue => salsifyValue.replace(/[a-zA-Z]/, ' $&');
  const formatSalsifyPercentageData = salsifyValue => salsifyValue.replace('%', ' %');

  return (
    <NutritionLabelTableComponent>
      <div id="facts" className="nfl-front">
        <div className="facts_title">
          <strong>{translate(viewBag, 'Products.NutritionLabelPromo.CA.TitleEn', 'Nutrition Facts')}</strong>
        </div>
        <div className="facts_title">
          <strong>{translate(viewBag, 'Products.NutritionLabelPromo.CA.TitleFr', 'Valeur Nutritive')}</strong>
        </div>
        {specServingSize && <div className="facts_serving_size">{specServingSize}</div>}
        <div className="facts_box thin">
          <div className="fact_row no-border">
            <div>
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.CA.PercentDailyValueEn', '% Daily Value')}
              </strong>
            </div>
            <strong>{translate(viewBag, 'Products.NutritionLabelPromo.CA.AmountEn', 'Amount')}</strong>
          </div>
          <div className="fact_row">
            <div>
              <strong>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.CA.PercentDailyValueFr',
                  '% valeur quotidienne'
                )}
              </strong>
            </div>
            <strong>{translate(viewBag, 'Products.NutritionLabelPromo.CA.AmountFR', 'Teneur')}</strong>
          </div>
          {specCalories && (
            <div className="fact_row">
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.CA.specCalories', 'Calories / Calories')}
              </strong>{' '}
              <strong>{specCalories}</strong>
            </div>
          )}
          {(specTotalFat || specTotalFatPercent) && (
            <div className="fact_row">
              {specTotalFatPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specTotalFatPercent)}</strong>
                </div>
              )}
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.CA.specTotalFat', 'Fat / Lipides')}
              </strong>{' '}
              {specTotalFat && formatSalsifyData(specTotalFat)}
            </div>
          )}
          {(specSatFat || specTransFat) && (
            <div className="fact_row fact-row__sub-center-percentage sub">
              {
                specSatTransFatPercent && (
                  <div className="fact_row__percentage">
                    <strong>{formatSalsifyPercentageData(specSatTransFatPercent)}</strong>
                  </div>
                )
              }
              <div></div>
              {specSatFat && (
                <p>
                  {translate(viewBag, 'Products.NutritionLabelPromo.CA.specSatFat', 'Saturated / saturés')}{' '}
                  {formatSalsifyData(specSatFat)}
                </p>
              )}
              {specTransFat && (
                <p>
                  {translate(viewBag, 'Products.NutritionLabelPromo.CA.specTransFat', 'Trans / trans')}{' '}
                  {formatSalsifyData(specTransFat)}
                </p>
              )}
            </div>
          )}
          {(specCholesterol || specCholesterolPercent) && (
            <div className="fact_row">
              {specCholesterolPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specCholesterolPercent)}</strong>
                </div>
              )}
              <strong>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.CA.specCholesterol',
                  'Cholesterol / Cholestérol'
                )}
              </strong>{' '}
              {specCholesterol && formatSalsifyData(specCholesterol)}
            </div>
          )}
          {(specSodium || specSodiumPercent) && (
            <div className="fact_row">
              {specSodiumPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specSodiumPercent)}</strong>
                </div>
              )}
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.CA.specSodium', 'Sodium / Sodium')}
              </strong>{' '}
              {specSodium && formatSalsifyData(specSodium)}
            </div>
          )}
          {(specTotalCarb || specTotalCarbPercent) && (
            <div className="fact_row">
              {specTotalCarbPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specTotalCarbPercent)}</strong>
                </div>
              )}
              <strong>
                {translate(
                  viewBag,
                  'Products.NutritionLabelPromo.CA.specTotalCarb',
                  'Carbohydrate / Glucides'
                )}
              </strong>{' '}
              {specTotalCarb && formatSalsifyData(specTotalCarb)}
            </div>
          )}
          {(specDietFiber || specDietFiberPercent) && (
            <div className="fact_row sub">
              {specDietFiberPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specDietFiberPercent)}</strong>
                </div>
              )}
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specDietFiber', 'Fibre / Fibres')}{' '}
              {specDietFiber && formatSalsifyData(specDietFiber)}
            </div>
          )}
          {specSugars && (
            <div className="fact_row sublv2">
              <div></div>
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specSugars', 'Sugars / Sucres')}{' '}
              {formatSalsifyData(specSugars)}
            </div>
          )}
          {(specProtein || specProteinPercent) && (
            <div className="fact_row">
              {specProteinPercent && (
                <div>
                  <strong>{formatSalsifyPercentageData(specProteinPercent)}</strong>
                </div>
              )}
              <strong>
                {translate(viewBag, 'Products.NutritionLabelPromo.CA.specProtein', 'Protein / Protéines')}
              </strong>{' '}
              {specProtein ? formatSalsifyData(specProtein) : '0 g'}
            </div>
          )}
        </div>
        <div className="facts_box">
          {(specVitA || specVitAPercent) && (
            <div className="fact_row">
              {specVitAPercent && <div>{formatSalsifyPercentageData(specVitAPercent)}</div>}
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specVitA', 'Vitamin A / Vitamine A')}{' '}
              {specVitA && formatSalsifyData(specVitA)}
            </div>
          )}
          {(specVitC || specVitcPercent) && (
            <div className="fact_row">
              {specVitcPercent && <div>{formatSalsifyPercentageData(specVitcPercent)}</div>}
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specVitC', 'Vitamin C / Vitamine C')}{' '}
              {specVitC && formatSalsifyData(specVitC)}
            </div>
          )}
          {(specCalcium || specCalciumPercent) && (
            <div className="fact_row">
              {specCalciumPercent && <div>{formatSalsifyPercentageData(specCalciumPercent)}</div>}
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specCalcium', 'Calcium / Calcium')}{' '}
              {specCalcium && formatSalsifyData(specCalcium)}
            </div>
          )}
          {(specIron || specIronPercent) && (
            <div className="fact_row">
              {specIronPercent && <div>{formatSalsifyPercentageData(specIronPercent)}</div>}
              {translate(viewBag, 'Products.NutritionLabelPromo.CA.specIron', 'Iron / Fer')}{' '}
              {specIron && formatSalsifyData(specIron)}
            </div>
          )}
        </div>
      </div>
    </NutritionLabelTableComponent>
  );
};

export default NutritionLabelTableCA;
