import React from 'react';
import PropTypes from 'prop-types';

import {
  Text as JssText,
  isExperienceEditorActive,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const Text = ({ sitecoreContext, ...props }) => {
  const experienceEditor = isExperienceEditorActive();
  if (experienceEditor || sitecoreContext?.pageEditing) {
    return <JssText {...props} />;
  }

  //TODO replace the sitecoreContext property with the correct one when data is available
  const text = trademarkFilterHelper(props?.field?.value, sitecoreContext?.trademarkReplacements);
  return React.createElement(props.tag, {
    dangerouslySetInnerHTML: {
      __html: text,
    },
    ...props,
  });
};

Text.propTypes = {
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(Text);
