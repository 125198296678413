import styled from 'styled-components';

export const SubscribeComponent = styled.div`
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 400px;
  }

  form {
    > div {
      position: relative;
      margin: ${({ theme }) => theme.spacing({ top: 30, bottom: 0, left: 0, right: 0 })};

      button {
        position: absolute;
        top: 50%;
        right: 12px;
        border: none;
        outline: none;
        transform: translate(0, -50%);
      }
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: ${({ theme }) => theme.spacing(52)};
    color: ${({ theme }) => theme.colours.adaBrown};
    text-indent: ${({ theme }) => theme.spacing(30)};
    border: 0;
    border-radius: 50px;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      height: ${({ theme }) => theme.spacing(72)};
      font-size: ${({ theme }) => theme.spacing(24)};
      text-indent: 50px;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colours.adaBrown};
      text-transform: capitalize;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colours.yellow};
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colours.blue};
      outline: none;
    }
  }
`;

export const Error = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(16)};
  margin-left: ${({ theme }) => theme.spacing(46)};
  color: ${({ theme }) => theme.colours.tan};
  font-size: 1rem;
  text-align: left;
`;

export const Success = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colours.tan};
  font-size: 1.25rem;
  text-align: left;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    justify-content: start;
  }

  svg {
    position: relative;
    left: ${({ theme }) => theme.spacing(16)};
    color: ${({ theme }) => theme.colours.yellow};
  }
`;
