const storageType = (type) => {
  const types = {
    session: 'sessionStorage',
    local: 'localStorage',
  };

  if (type in types) return types[type];

  return types.local;
};

export const addToStorage = ({ key, value, type = 'local' }) =>
  typeof window !== 'undefined' && window[storageType(type)].setItem(key, value);

export const getFromStorage = ({ key, type = 'local' }) =>
  typeof window !== 'undefined' && window[storageType(type)].getItem(key);
