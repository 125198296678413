import styled from 'styled-components';

import beautifulBeanYellow from './svg/beautiful-bean-co-yellow.svg';
import beautifulBeanRed from './svg/beautiful-bean-co-red.svg';
import beautifulBeanBrown from './svg/beautiful-bean-co-brown.svg';

const getVariant = (variant, theme) => {
  switch (variant) {
    case 'tan':
    case 'yellowOnTan':
      return {
        svg: beautifulBeanYellow,
        backgroundColor: theme.colours.tan,
      };
    case 'tan:brown':
    case 'brownOnTan':
      return {
        svg: beautifulBeanBrown,
        backgroundColor: theme.colours.tan,
      };
    case 'red':
    case 'yellowOnRed':
      return {
        svg: beautifulBeanYellow,
        backgroundColor: theme.colours.red,
      };
    case 'yellow':
    case 'redOnYellow':
      return {
        svg: beautifulBeanRed,
        backgroundColor: theme.colours.yellow,
      };
    case 'brown':
    case 'yellowOnBrown':
      return {
        svg: beautifulBeanYellow,
        backgroundColor: theme.colours.brown,
      };
    case 'blue':
    case 'yellowOnBlue':
      return {
        svg: beautifulBeanYellow,
        backgroundColor: theme.colours.blue,
      };
    default:
      return {
        svg: beautifulBeanYellow,
        backgroundColor: theme.colours.tan,
      };
  }
};

export const InterruptorRibbonComponent = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing(36)};
  background-color: ${({ variant, theme }) => getVariant(variant, theme)?.backgroundColor};
  background-image: ${({ variant, theme }) => `url(${getVariant(variant, theme)?.svg})`};
  background-repeat: repeat-x;
  background-position: 0px 6px;
  background-size: auto 24px;
  transform: ${({ orientation }) => (orientation === 'vertical' ? 'rotate(90deg)' : 'rotate(0)')};
  animation: animatedBackground 5s linear infinite;

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    animation: animatedBackground 20s linear infinite;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    animation: animatedBackground 40s linear infinite;
  }

  ${({ theme }) => theme.breakPoints.minBp('1200px')} {
    height: ${({ theme }) => theme.spacing(48)};
    background-position: 0px 7px;
    background-size: auto 34px;

    @keyframes animatedBackground {
      from {
        background-position: 0 7px;
      }
      to {
        background-position: 100% 7px;
      }
    }
  }

  @keyframes animatedBackground {
    from {
      background-position: 0 6px;
    }
    to {
      background-position: 100% 6px;
    }
  }
`;
