export const sortByAlphabetical = (array) => {
  if (!array) return null;

  return array.sort((a, b) => {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
};
