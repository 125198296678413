import React from 'react';
import PropTypes from 'prop-types';
import { InterruptorRibbonComponent } from './InterruptorRibbon.styles.js';

const InterruptorRibbon = ({ variant = 'tan', orientation = 'horizontal', className }) => {
  return <InterruptorRibbonComponent variant={variant} orientation={orientation} className={className} />;
};

InterruptorRibbon.propTypes = {
  variant: PropTypes.oneOf(['tan', 'tan:brown', 'red', 'yellow', 'brown', 'blue']),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  className: PropTypes.string,
};

export default InterruptorRibbon;
