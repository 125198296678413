import React, { useState, useEffect } from 'react';
import { useTransition } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { isExperienceEditorActive, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HomepageHeroCarouselComponent, PlaceholderWrapper } from './HomepageHeroCarousel.styles.js';

import HomepageHeroCarouselSlide from 'components/HomepageHeroCarouselSlide/HomepageHeroCarouselSlide.jsx';

const adjustFontSize = () => {
    const h1 = document.querySelector('.tagline-h1');
    if (!h1) {
        return;
    }

    const screenWidth = window.innerWidth;
    const maxWidth = 140; // Maximum font size
    const minFontSize = 30; // Minimum font size

    // Calculate available width for the text
    const availableWidth = screenWidth * 1.8; // Use 190% of the screen width

    // Calculate font size based on available width
    let fontSize = availableWidth / h1.textContent.length; // Adjust this value based on your preference
    fontSize = Math.min(maxWidth, fontSize); // Ensure font size doesn't exceed maximum
    fontSize = Math.max(minFontSize, fontSize); // Ensure font size doesn't go below the minimum

    h1.style.fontSize = `${fontSize}px`;
};

const options = {
  transform: {
    initial: { transform: 'translateX(0)' },
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(-100%)' },
  },
};

const HomepageHeroCarousel = ({ ...props }) => {
  const {
    fields = {},
    params: { autoplay = '0' },
    sitecoreContext,
    rendering = {},
  } = props;
    const { site } = sitecoreContext;
  const experienceEditor = isExperienceEditorActive();
  const carouselItems = rendering?.placeholders?.['homepage-hero-carousel-content'];

  const slides = Array.isArray(carouselItems)
      ? carouselItems.map((item, index) => ({ ...item, slideIndex: index }))
      : [];
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = slides.length;
  const transitions = useTransition(slides[currentSlide - 1], (item) => item?.slideIndex, options.transform);
  const [pause, setPause] = useState(false);
  const [minHeight, setMinHeight] = useState('auto');

  const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel, distance }) => {
    const swipeDirection = xDir > 0 ? -1 : 1;
    if (active && distance > window.innerWidth / 2 && swipeDirection > 0) {
      cancel(
          setCurrentSlide((currentSlide) => {
            setPause(true);
            return currentSlide !== 1 ? currentSlide - 1 : totalSlides;
          })
      );
    }
  });

    useEffect(() => {
        adjustFontSize();
        window.addEventListener('resize', adjustFontSize);
        if (autoplay === '1' && !experienceEditor) {
            const timer = !pause
                ? setTimeout(() => {
                    setCurrentSlide((currentSlide) => {
                        return currentSlide === totalSlides ? 1 : currentSlide + 1;
                    });
                }, 7000)
                : null;
            return () => {
                window.removeEventListener('resize', adjustFontSize);
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }
    }, [currentSlide, pause, autoplay, totalSlides, experienceEditor]);

  if (experienceEditor || props?.sitecoreContext?.pageEditing) {
    return (
        <div className="home-hero-carousel">
          <div>
            <h1>Start Hero Home Carousel</h1>
          </div>
          <PlaceholderWrapper>
            <Placeholder name="homepage-hero-carousel-content" rendering={rendering} />
          </PlaceholderWrapper>
          <div>
            <h1>End Hero Home Carousel</h1>
          </div>
        </div>
    );
  }

  return (
      <HomepageHeroCarouselComponent minHeight={minHeight} site={site.name} className={site.name}>
        {transitions.map(({ item, key, props }) => {
          return (
              <HomepageHeroCarouselSlide
                  rendering={{ ...item }}
                  setPause={setPause}
                  key={key}
                  props={props}
                  bind={bind}
                  setCurrentSlide={setCurrentSlide}
                  totalSlides={totalSlides}
                  setMinHeight={setMinHeight}
                  site={site.name}
                  {...fields}
              />
          );
        })}
      </HomepageHeroCarouselComponent>
  );
};

export default withSitecoreContext()(HomepageHeroCarousel);