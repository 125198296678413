import styled from 'styled-components';

export const SearchBoxComponent = styled.div`
  max-width: 90%;
  margin: 0 auto;

  & .input-container {
    position: relative;

    & input {
      width: 100%;
      color: ${({ theme }) => theme.colours.adaBrown};
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(24)};
      line-height: ${({ theme }) => theme.spacing(64)};
      text-indent: 30px;
      background-color: ${({ theme }) => theme.colours.white};
      border: 2px solid transparent;
      border-radius: 100px;
      box-sizing: border-box;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        font-size: ${({ theme }) => theme.spacing(32)};
      }

      &:focus {
        border: 2px solid ${({ theme }) => theme.colours.adaBrown};
        outline: none;
      }

      &::placeholder {
        color: ${({ theme }) => theme.colours.adaBrown};
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
    & button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      width: 48px;
      height: 48px;
      padding: 0.8rem;
    }
  }

  & .results-list {
    height: ${({ theme }) => theme.spacing(450)};
    margin: ${({ theme }) => theme.spacing(60, 'auto')};
    padding: ${({ theme }) =>
      theme.spacing({
        top: 0,
        left: 30,
        right: 30,
        bottom: 0,
      })};
    overflow: scroll;

    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colours.adaBrown};
      border-radius: 10px;
    }

    & .list-label {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
      color: ${({ theme }) => theme.colours.red};
      font-size: ${({ theme }) => theme.spacing(18)};
    }

    & li {
      margin-bottom: ${({ theme }) => theme.spacing(16)};
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(32)};

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        font-size: ${({ theme }) => theme.spacing(40)};
      }

      &:hover {
        color: ${({ theme }) => theme.colours.red};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .error-label {
    position: absolute;
    left: 28px;
    bottom: -28px;
    font-size: 16px;
  }
`;
