import React, { useState, useRef, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import {
    RelatedCarouselComponent,
    PlaceholderWrapper,
    RelatedCarouselControls,
    RelatedCarouselProdouctGrid,
    SurveyPageRelatedWrapper, SpHeadingRow
} from './RelatedCarousel.styles.js';
import RelatedCarouselSlide from 'components/RelatedCarouselSlide/RelatedCarouselSlide.jsx';
import {isExperienceEditorActive, Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'components/shared/Button';
import Grid from "../shared/Grid";
import Card from "../shared/Card";

const slugify = (text) => {
    return text
        ? text
            .toString()
            .toLowerCase()
            .trim()
            .replace(/[\s\W-]+/g, '-')
        : '';
};


const RelatedCarousel = ({ sitecoreContext, params = {}, ...props }) => {
    const { site = {} } = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const { rendering = {} } = props;
    const carouselItems = rendering?.placeholders?.['related-carousel-content'] || [];
    const {
        cardType = '',
        surveyPage = '',
        id = '',
    } = params;

    const slides = Array.isArray(carouselItems)
        ? carouselItems.map((item, index) => ({ ...item, slideIndex: index }))
        : [];

    const { fields } = rendering;
    const { title, copy } = fields || {};
    const generatedId = id || (title && slugify(title.value));

    const sliderSettings = {
        dots: false,
        infinite: slides.length > 4,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    infinite: slides.length > 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: slides.length > 1,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                },
            },
        ],
    };

    const sliderRef = useRef();

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    // console.log('related carousel props', props);
    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <RelatedCarouselComponent >
                {(title?.value || copy?.value) && (
                    <div className="heading-row">
                        {title?.value && <h2>{title.value}</h2>}
                        {copy?.value && <p>{copy.value}</p>}
                    </div>
                )}
                <div className="related-carousel">
                    <div style={{height: '80px', maxHeight: '80px', borderBottom: '2px solid #944000'}}>
                        <h1>Start Related Carousel</h1>
                    </div>
                    <PlaceholderWrapper>
                        <Placeholder name="related-carousel-content" rendering={rendering}/>
                    </PlaceholderWrapper>
                    <div style={{ height: '80px', maxHeight: '80px', borderTop: '2px solid #944000' }}>
                        <h1>End Related Carousel</h1>
                    </div>
                </div>
            </RelatedCarouselComponent>
        );
    }

    if (surveyPage === 'true') {
        return (
            <SurveyPageRelatedWrapper class="surveyPage" id={generatedId}>
                {(title?.value || copy?.value) && (
                    <SpHeadingRow>
                        {title?.value && <h2>{title.value}</h2>}
                        {copy?.value && <p>{copy.value}</p>}
                    </SpHeadingRow>
                )}
                <RelatedCarouselProdouctGrid>
                    <Grid type="related" className="related-grid">
                            {slides.map((item, index) => {
                                let background = 'yellow';
                                return <RelatedCarouselSlide key={item.id} type="landingCard" rendering={{...item}} background={background}/>;
                            })}
                    </Grid>
                </RelatedCarouselProdouctGrid>
            </SurveyPageRelatedWrapper>
        );
    }

    return (
        <RelatedCarouselComponent id={generatedId}>
            {(title?.value || copy?.value) && (
                <div className="heading-row">
                    {title?.value && <h2>{title.value}</h2>}
                    {copy?.value && <p>{copy.value}</p>}
                </div>
            )}
            <div className={`related-cards-container ${cardType}-card-container`}>

                {cardType === 'product' && slides.length > 0 && (
                    <Slider {...sliderSettings}>
                        {slides.map((item, index) => {
                            let background = 'yellow';
                            return <RelatedCarouselSlide key={item.id} type={cardType} rendering={{...item}}
                                                         background={background}/>;
                        })}
                    </Slider>
                )}

                {cardType === 'page' && slides.length > 0 && (
                    <div className="related-carousel">
                        <Grid type="related" className="related-grid">
                            {slides.map((item, index) => {
                                let background;
                                if (index === 0) {
                                    background = "brown";
                                } else if (index === 1) {
                                    background = "red";
                                } else if (index === 2) {
                                    background = "blue";
                                }
                                return <RelatedCarouselSlide key={item.id} type={cardType} rendering={{...item}}
                                                             background={background}/>;
                            })}
                        </Grid>
                    </div>
                )}
                {cardType === 'resource' && slides.length > 0 && (
                    <div className="related-carousel">
                        <Grid type="related" className="related-grid">
                            {slides.map((item, index) => {
                                let background;
                                let icon = 'download';
                                if (index === 0) {
                                    background = "brown";
                                } else if (index === 1) {
                                    background = "red";
                                } else if (index === 2) {
                                    background = "blue";
                                }
                                return <RelatedCarouselSlide key={item.id} type={cardType} rendering={{...item}}
                                                             background={background} icon={icon} />;
                            })}
                        </Grid>
                    </div>
                )}
            </div>
            {cardType === 'recipe' && slides.length > 0 &&  (
                <div className="button-container">
                    <Button id="related-pages-btn" target="_blank" text="View All Recipes" href="/recipes"
                            variant="yellowBrown" />
                </div>
            )}
            {site.name === 'bushs-beans-foodservices' && cardType === 'product' && slides.length > 0 && (
                <div className="button-container">
                    <Button id="related-pages-btn" target="_blank" text="View All Products" href="/bulk-products"
                            variant="yellowBrown" />
                </div>
            )}

        </RelatedCarouselComponent>
    );
};

RelatedCarousel.propTypes = {};

export default withSitecoreContext()(RelatedCarousel);