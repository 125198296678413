import React from 'react';
import Icon from 'components/shared/Icon';
import BasketfulImage from 'assets/images/Click2CartLogo_2Color_Horz.png';

const iconSizes = ({ size }) => {
  const sizes = {
    small: 'sm',
    large: 'lg',
    xlarge: 'xl',
  };

  if (size in sizes) return sizes[size];

  return sizes.small;
};
const DefaultButton = ({ text, children, icon = 'chevron-right', size = 'small', noIcon = false }) => (
  <>
    <span>{text || children}</span>
    {!noIcon && (
      <>
        <span></span>
        <span>
          <Icon icon={icon} className={icon} size={size === 'large' ? 'lg' : null} />
        </span>
      </>
    )}
  </>
);

const BasketfulButton = ({ text, icon = 'chevron-right', size = 'small' }) => (
  <>
    <span>
      {text} <img src={BasketfulImage} alt="Click2Cart shopping" />
    </span>
    <span></span>
    <span>
      <Icon icon={icon} className={icon} size={size === 'large' ? 'lg' : null} />
    </span>
  </>
);

const CircleButton = ({ icon = 'chevron-right', size = 'small' }) => (
  <Icon icon={icon} className={icon} size={iconSizes({ size })} />
);

const LoadMoreButton = ({ text = 'load more', icon = 'plus', size = 'small' }) => (
  <>
    <span>{text}</span>
    <Icon icon={icon} className={icon} size={iconSizes({ size })} />
  </>
);

const RecipeButton = ({ text = 'tap for new recipe!' }) => <span>{text}</span>;

const LoveButton = ({ icon = 'heart-outline', size = 'small' }) => (
  <>
    <Icon icon={icon} className={icon} size={iconSizes({ size })} />
    <Icon icon="heart" className="heart-overlay" size={iconSizes({ size })} />
  </>
);

const ClearButton = ({ text = '' }) => <>{text && <span>{text}</span>}</>;

const ChipButton = ({ text = '', icon = 'cross', size = 'small' }) => (
  <>
    {text && <span>{text}</span>}
    <Icon icon={icon} size="sm" />
  </>
);

export const RenderButtonType = ({ type, props }) => {
  const types = {
    default: <DefaultButton {...props} />,
    submit: <DefaultButton {...props} />,
    circle: <CircleButton {...props} />,
    loadmore: <LoadMoreButton {...props} />,
    recipe: <RecipeButton {...props} />,
    love: <LoveButton {...props} />,
    basketful: <BasketfulButton {...props} />,
    clear: <ClearButton {...props} />,
    chip: <ChipButton {...props} />,
  };

  if (type in types) return types[type];

  return types.default;
};
