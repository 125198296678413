import styled from 'styled-components';

export const MainNavRibbonComponent = styled.div`
  /* position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.spacing(61)};
  background-color: ${({ theme }) => theme.colours.red};

  & > div {
    width: 50%;
  }

  & p {
    line-height: ${({ theme }) => theme.spacing(75)};
  }

  & button {
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-right: ${({ theme }) => theme.spacing(24)};
  }

  & div:last-child {
    display: none;
  }

  @media (min-width: 1280px) {
    & div:first-child {
      &::after {
        content: '';
        display: inline;
        width: ${({ theme }) => theme.spacing(1)};
        height: ${({ theme }) => theme.spacing(40)};
        margin-top: ${({ theme }) => theme.spacing(16)};
        background-color: ${({ theme }) => theme.colours.yellow};
      }
    }
    & div:last-child {
      display: inline-flex;
    }
  } */
  display: flex;
  background-color: ${({ theme }) => theme.colours.red};
  height: ${({ theme }) => theme.spacing(61)};
	&.fs-ribbon {
		background-color: ${({ theme }) => theme.colours.blue};
	}

  @media (max-width: 470px) {
    height: ${({ theme }) => theme.spacing(150)};
  }

  & > div {
    position: relative;
    width: 100%;
    bottom: ${({ theme }) => theme.spacing(7)};  
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};

    & .content {
      margin-top: ${({ theme }) => theme.spacing(14)};
    }
  }

  & div:not(:first-child) {
    display: none;
  }
  @media (min-width: 1280px) {
    & div:first-child {
      &::after {
        content: '';
        display: inline;
        width: ${({ theme }) => theme.spacing(1)};
        height: ${({ theme }) => theme.spacing(40)};
        position: absolute;
        top: 50%;
        margin-top: ${({ theme }) => theme.spacing(-20)};
        right: 0;
        background-color: ${({ theme }) => theme.colours.yellow};
        top: ${({ theme }) => theme.spacing(37)};
      }
    }
    & div:not(:first-child) {
      display: inline-flex;
    }
  }
`;