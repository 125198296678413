import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { RichText } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { CookieAlertComponent } from './CookiePolicy.styles.js';
import Button from 'components/shared/Button';

import translate from 'helpers/translate';

export const CookiePolicy = ({ sitecoreContext: { globalContent = {}, viewBag = {} } }) => {
  const { cookiePolicy = {} } = globalContent;
  const { policyContent = '' } = cookiePolicy;
  const [visible, setVisible] = useState(true);
  const cookieId = 'cookie-policy';

  useEffect(() => {
    if (Cookies.get(cookieId)) {
      setVisible(false);
    }
  }, []);

  const handleClick = () => {
    setVisible(false);
    Cookies.set(cookieId, true, { expires: 28 });
  };
    
  return null;
};

CookiePolicy.propTypes = {
  sitecoreContext: PropTypes.shape({
    globalContent: PropTypes.shape({
      cookiePolicy: PropTypes.shape({
        policyContent: PropTypes.string,
      }),
    }),
  }),
};

export default withSitecoreContext()(CookiePolicy);
