import styled, {keyframes} from "styled-components";

const spinAnimation = keyframes`
	0% {
	  transform: rotate(0deg);
      opacity: 0;  
	}
	100% {
      transform: rotate(360deg);
      opacity: 1;  
    }
`;

export const SvgSpiral = styled.svg`
  position: absolute;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  transition: all 0.8s ease-in-out;
  opacity: 0;
  &.active {
    animation: ${spinAnimation} 1s ease-in-out;
	opacity: 1;
  }
`;