import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { SocialShareComponent } from './SocialShare.styles.js';

import SocialLinks from 'components/shared/SocialLinks';

import { getUrl, getMetaDescription } from 'helpers/metadataHelpers';
import { useDeviceResize } from 'hooks/index.js';

const generateShareLink = type => {
  const social = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${getUrl()}`,
    twitter: `https://twitter.com/intent/tweet?url=${getUrl()}&text=${getMetaDescription()}&hashtags=BushsBeans,Recipe`,
    pinterest: `http://pinterest.com/pin/create/button/?url=${getUrl()}&description=${getMetaDescription()}`,
    mail: `mailto:?subject=Check out this Bean Recipe&body=${getMetaDescription()} - ${getUrl()}`,
  };

  if (type in social) return social[type];

  return '';
};

const socialTypes = ({ type, title }) => {
  const types = {
    facebook: {
      title,
      href: generateShareLink(type),
      target: '_blank',
      external: true,
      icon: 'facebook',
      rel: 'noopener noreferrer',
    },
    twitter: {
      title,
      href: generateShareLink(type),
      target: '_blank',
      external: true,
      icon: 'twitter',
      rel: 'noopener noreferrer',
    },
    pinterest: {
      title,
      href: generateShareLink(type),
      target: '_blank',
      external: true,
      icon: 'pinterest',
      rel: 'noopener noreferrer',
    },
    instagram: {
      title,
      href: 'https://www.instagram.com',
      target: '_blank',
      external: true,
      icon: 'instagram',
      rel: 'noopener noreferrer',
    },
    mail: {
      title,
      href: generateShareLink(type),
      target: '_blank',
      external: true,
      icon: 'mail',
      rel: 'noopener noreferrer',
    },
  };

  if (type.toLowerCase() in types) return types[type.toLowerCase()];

  return null;
};

const generateSocialShare = links =>
  Array.isArray(links)
    ? links.map(field => {
        // eslint-disable-next-line array-callback-return
        if (typeof field?.value !== 'string') return;
        const { title = '', value = '' } = field;

        return socialTypes({ type: value, title });
      })
    : [];

const SocialShare = ({ fields = {}, className = '', ...props }) => {
  const { shareOptions = [], title = '' } = fields;
  const [links, setLinks] = useState([]);
  const device = useDeviceResize();

  useEffect(() => {
    if (!isServer()) {
      const shareLinks = shareOptions.length > 0 && shareOptions;
      setLinks(generateSocialShare(shareLinks));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mailShareArray = {
    "title": "Email",
    "href": `mailto:?subject=Check out this Bean Recipe&body=${getMetaDescription()} - ${getUrl()}`,
    "target": "_blank",
    "external": true,
    "icon": "mail",
    "rel": "noopener noreferrer",
    "className": "mail"
  }

  if (!links.some(link => JSON.stringify(link) === JSON.stringify(mailShareArray))) {
    links.push(mailShareArray);
  }

  return (
      <SocialShareComponent className={className}>
        {title && <Text field={{value: title}} tag="h3"/>}
        {links && links.length > 0 && (
            <SocialLinks
                className="social-links hide-on-print"
                variant="tan"
                invertColor
                links={links}
                size={device === 'laptop' || device === 'desktop' ? 'xlarge' : 'large'}
            />
        )}
      </SocialShareComponent>

  );
};

SocialShare.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

export default SocialShare;