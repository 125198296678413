import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import {ButtonHoverWrapper, HoverWrapper, ProductCardPromoFooter, ProductPromoComponent} from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import { checkBadges, languageFilter } from 'helpers/badgeHelpers';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import Icon from "../../../Icon";

const ProductPromoCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  description = '',
  link = '',
  itemId = '',
  type = 'product',
  webBadge = '',
  productIsNew = false,
  globalization = '',
  classValue = '',
}) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;
  const device = useDeviceResize();
  const { country: badgeCountry, language: badgeLanguage } = globalization;
  const filteredLanguage = languageFilter(badgeLanguage);
  const activeImgSrc = checkBadges(productIsNew, webBadge, filteredLanguage, badgeCountry);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <ProductPromoComponent type={type} className={`product ${classValue} ${isHovered ? 'active' : ''}`}
                           onMouseEnter={handleMouseEnter}
                           onMouseLeave={handleMouseLeave}>
      {!isServer() && <Button type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}

      {!isServer() && (
        <ConditionalComponentWrapper
          condition={Link}
          wrapper={(children) => (
            <Link href={link} className="button-link">
              {children}
            </Link>
          )}
        >
          <div className="image-container">
            <div className="product-inner-image-container">
              {image?.url && <img src={image?.url} alt={image?.alt} />}
              {activeImgSrc && <img className="product-card-badge" src={activeImgSrc} alt={'New Look'} />}
            </div>
          </div>

          <ProductCardPromoFooter className="productCardPromoFooter">
            {title && (
                <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
            )}
            {description && (
                <p
                    dangerouslySetInnerHTML={{
                      __html: trademarkFilterHelper(description, trademarkReplacements),
                    }}
                />
            )}
            <ButtonHoverWrapper className="hoverButton">
              <button className="view-button"><span>View Beans</span> <Icon icon="chevron-right" size="md" /></button>
            </ButtonHoverWrapper>
          </ProductCardPromoFooter>
        </ConditionalComponentWrapper>
      )}
    </ProductPromoComponent>
  );
};

export default withSitecoreContext()(ProductPromoCard);

ProductPromoCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};