import React from 'react';
import PropTypes from 'prop-types';
import {PromoBoxComponent, PromoBoxInner} from './GeneralPromo.styles.js';
import {Text} from 'components/shared/JssOverrides';

const GeneralPromoBox = ({style, title, copy,styleOverride}) => {

    //console.log('style override value', styleOverride);

    const variant = styleOverride && styleOverride !== '' ? styleOverride : style;
    const bgClass = styleOverride ? `bg-${styleOverride}` : '';
    const variantClass = `variant-${variant}`;

    return (
        <PromoBoxComponent variant={variant} className={`${bgClass} ${variantClass}`}>
            <PromoBoxInner>
                {title && <Text tag="h1" field={title}/>}
                {copy?.value && (
                    <div className="p" dangerouslySetInnerHTML={{__html: copy.value}}/>
                )}
            </PromoBoxInner>
        </PromoBoxComponent>
    );
};

GeneralPromoBox.propTypes = {
    title: PropTypes.object,
    copy: PropTypes.object,
    style: PropTypes.string,
  styleOverride: PropTypes.string,
};

export default GeneralPromoBox;