import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {Placeholder, VisitorIdentification} from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import Button from 'components/shared/Button';
import DogImage from 'assets/images/duke.png';
import FourOhFourImage from 'assets/images/404.svg';
import translate from 'helpers/translate';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useLocation } from 'react-router-dom';
import GlobalStyle from 'style/global/GlobalStyles';
import MainNav from 'components/MainNav';
import Footer from 'components/Footer/';
import SearchBox from "components/SearchBox";
import UtilityNav from "./components/UtilityNav/UtilityNav.jsx";




const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colours.tan};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing(70)} ${theme.spacing(30)} 0 `};
  }

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => theme.spacing(90)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    font-family: "apercu pro",apercu pro;
    font-weight: 900;
    line-height: 1;
  }
  h3{
    line-height: 2;
  }
  p {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
    color: ${({ theme }) => theme.colours.adaBrown};
    font-family: ${({ theme }) => theme.typography.fontFamily.main};
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    margin-top: ${({ theme }) => theme.spacing(20)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-top: ${({ theme }) => theme.spacing(65)};
    }
  }
`;

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = ({ route, context, scontext, sitecoreContext }) => {
  const { viewBag = {} } = context || {};
  const { navigation = {}, footer = {} } = context || {};
  const { mainNav = [] } = navigation;
  const { footerContent = {} } = footer;
  const recipePagePath = 'bean-recipes';
  const pagePath = useLocation().pathname;
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [noSearchValue, setNoSearchValue] = useState(false);
  const searchRef = useRef(null);
  //console.log('context before adding globalContent', context);
  const globalContent = {
    "siteName": "BUSH'S® Beans",
    "homepageLink": "https://www.bushbeans.com/",
    "metadata": {
      "pageTitle": "Page Not Found",
      "metadataTitle": "404",
      "metadataDescription": "Page not found",
      "metadataImage": null,
      "metadataUrl": "/en_US/NotFound",
      "canonicalUrl": "",
      "siteDomain": "https://www.bushbeans.com"
    },
    "cookiePolicy": {
      "policyContent": "<p>This website uses cookies and similar technologies to provide functionality that supports your browsing experience, to analyze site traffic, to personalize content, and to serve targeted advertisements to you. By clicking the \"I accept\" button, you consent to the use of cookies and similar technologies and the sharing of the information collected as described in our&nbsp;<a href=\"privacy-policy\" target=\"_blank\">Privacy Policy</a></p>"
    },
    "logo": {
      "src": "/-/media/BushsBeans/MegaMenu/Bush_logo_2020_rgb-(1).png",
      "alt": "Bush's Beans Logo",
      "name": "Bush_logo_2020_rgb (1)",
      "extension": "png",
      "type": "image/png",
      "height": "3278",
      "width": "5826",
      "size": 296039
    },
    "heartIcon": {
      "src": "/-/media/bbco_nav_favorites_heart.svg",
      "alt": "heart",
      "data": "",
      "name": "bbco_nav_favorites_heart",
      "extension": "svg",
      "type": "image/svg+xml",
      "size": 443
    },
    "heartLink": {
      "title": "",
      "path": "/en_US/Favorites",
      "href": "",
      "target": "",
      "text": "",
      "external": false
    },
    "companyTagLineText": "That ~Beautiful~ Bean Co.",
    "companyTagLineSvg": {
      "src": "/-/media/BushsBeans/Dev-Sample-Images/BUB_TBBC_tag_3C_HOR_Bean_yellow_2.svg",
      "alt": "That Beautiful Bean Co.",
      "data": "",
      "name": "BUB_TBBC_tag_3C_HOR_Bean_yellow_2",
      "extension": "svg",
      "type": "image/svg+xml",
      "size": 56850
    },
    "utilityLinks": [
      {
        "title": "Find in-store",
        "path": "/en_US/product-locator/find-in-store",
        "href": "",
        "target": "_blank",
        "text": "Store Locator",
        "external": false
      },
      {
        "title": "Shop Bean Swag",
        "path": "",
        "href": "https://shop.bushbeans.com",
        "target": "",
        "text": "",
        "external": true
      }
    ],
    "mobileUtilityLinks": [
      {
        "title": "Find in-store",
        "path": "/en_US/product-locator/find-in-store",
        "href": "",
        "target": "_blank",
        "text": "Store Locator",
        "external": false
      },
      {
        "title": "Shop Bean Merch",
        "path": "",
        "href": "https://shop.bushbeans.com",
        "target": "",
        "text": "",
        "external": true
      },
      {
        "title": "My Favorites",
        "path": "/en_US/Favorites",
        "href": "",
        "target": "",
        "text": "",
        "external": false
      }
    ],
    "findInStoreLink": {
      "title": "Find in-store",
      "path": "/en_US/product-locator/find-in-store",
      "href": "",
      "target": "",
      "text": "Find in-store",
      "external": false
    },
    "searchResultsPage": {
      "title": "Search Results Page",
      "path": "/en_US/search",
      "href": "",
      "target": "",
      "text": "Search Results",
      "external": false
    },
    "languageSelectorItems": [
      {
        "text": "En Español",
        "code": "es_US"
      }
    ],
    "navigation": [
      {
        "title": "Recipes",
        "path": "/en_US/bean-recipes",
        "href": "",
        "target": "",
        "text": "Recipes",
        "external": false,
        "type": "default"
      },
      {
        "title": "Products",
        "path": "/en_US/products",
        "href": "",
        "target": "",
        "text": "Explore ~Those~ Beautiful Bean Products",
        "external": false,
        "type": "megamenu-cards",
        "supportingLinks": [
          {
            "title": "ALL OF THE BEANS.",
            "path": "/en_US/products",
            "href": "",
            "target": "",
            "text": "See All Products",
            "external": false
          },
          {
            "title": "Find Our Beans In-Store.",
            "path": "/en_US/product-locator/find-in-store",
            "href": "",
            "target": "",
            "text": "Find In Store",
            "external": false
          }
        ],
        "cards": [
          {
            "title": "Baked",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/baked-beans",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/01A_Bushs_bakedBeans_Orig_340x340.png?sc_lang=en",
              "alt": "Bush's Original Baked Beans",
              "name": "01A_Bushs_bakedBeans_Orig_340x340",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 130990
            }
          },
          {
            "title": "Grillin'",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/grillin-beans",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/Bushs_GrillinBeansBourBwnSug_340x340.png?sc_lang=en",
              "alt": "Can of Bush's Grillin' Beans",
              "name": "Bushs_GrillinBeansBourBwnSug_340x340",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 28079
            }
          },
          {
            "title": "DIPS",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/bean-dips",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/1_MegaMenu_Snack_BeanDip_340x340.png?sc_lang=en",
              "alt": "Original Bean Dip",
              "name": "1_MegaMenu_Snack_BeanDip_340x340",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 69404
            }
          },
          {
            "title": "Chili & Starters",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/chili-beans-starters",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/KdnyBnsMild-ChiliMgc-340x340-menu.png",
              "alt": "Chili Beans & Chili Magic",
              "name": "KdnyBnsMild-ChiliMgc-340x340-menu",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 175332
            }
          },
          {
            "title": "Recipe",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/recipe-beans",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/black-beans-new-menu.png",
              "alt": "Can of black beans",
              "name": "black-beans-new-menu",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 112505
            }
          },
          {
            "title": "Sidekicks",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/sidekicks-seasoned-beans",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/Bushs_Sidekicks_Southwest_Zest_Pinto_Beans_15_5oz_FRONT_340X340.png",
              "alt": "A can of Bush's Southwest Zest Sidekicks Beans",
              "name": "Bushs_Sidekicks_Southwest_Zest_Pinto_Beans_15_5oz_FRONT_340X340",
              "extension": "png",
              "type": "image/png",
              "height": "340",
              "width": "340",
              "size": 141573
            }
          },
          {
            "title": "Blue Zones",
            "type": "product",
            "link": {
              "title": "Meet Them",
              "path": "/en_US/products/category/blue-zones",
              "href": "",
              "target": "",
              "text": "Meet Them",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/Bushs_Blue_Zones_Zesty_Black_Bean_Bowl_Topper_15_5oz.png",
              "alt": "Blue Zones Organic Beans",
              "name": "Bushs_Blue_Zones_Zesty_Black_Bean_Bowl_Topper_15_5oz",
              "extension": "png",
              "type": "image/png",
              "height": "1000",
              "width": "1000",
              "size": 282745
            }
          }
        ]
      },
      {
        "title": "Bean Hub",
        "path": "/en_US/bean-hub",
        "href": "",
        "target": "",
        "text": "Bean Hub",
        "external": false,
        "type": "default"
      },
      {
        "title": "About Us",
        "path": "/en_US/about-us",
        "href": "",
        "target": "",
        "text": "About Us",
        "external": false,
        "type": "megamenu-cards",
        "cards": [
          {
            "title": "That Beautiful Bean Co.",
            "type": "page",
            "link": {
              "title": "That Beautiful Bean Co.",
              "path": "/en_US/about-us",
              "href": "",
              "target": "",
              "text": "That Beautiful Bean Co.",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/02_Beautiful_389x219.jpg?sc_lang=en",
              "alt": "Bush's Original Baked Beans over the word \"Beautiful\" on a sky background",
              "name": "02_Beautiful_389x219",
              "extension": "jpg",
              "type": "image/jpeg",
              "height": "219",
              "width": "389",
              "size": 22394
            }
          },
          {
            "title": "Visitor Center",
            "type": "page",
            "link": {
              "title": "Visitors Center",
              "path": "/en_US/about-us/visitor-center",
              "href": "",
              "target": "",
              "text": "Visitors Center",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/VisitorsCenter2x_389x219.jpg",
              "alt": "A photo of Bush’s Beans Visitor Center in the A.J. Bush and Company General Store",
              "name": "VisitorsCenter2x_389x219",
              "extension": "jpg",
              "type": "image/jpeg",
              "height": "219",
              "width": "389",
              "size": 49112
            }
          },
          {
            "title": "Duke",
            "type": "page",
            "link": {
              "title": "Duke",
              "path": "/en_US/about-us/bush-beans-dog-duke",
              "href": "",
              "target": "",
              "text": "Duke",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/12_BBCO_DukeFlag_389x219.jpg",
              "alt": "Headshot of a golden retriever dog on a blue background",
              "name": "12_BBCO_DukeFlag_389x219",
              "extension": "jpg",
              "type": "image/jpeg",
              "height": "219",
              "width": "389",
              "size": 63521
            }
          },
          {
            "title": "History",
            "type": "page",
            "link": {
              "title": "History",
              "path": "/en_US/about-us/history",
              "href": "",
              "target": "",
              "text": "History",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/9_History_389x219.jpg",
              "alt": "An old black and white photo of Bush’s Beans founder A.J. Bush and his two children",
              "name": "9_History_389x219",
              "extension": "jpg",
              "type": "image/jpeg",
              "height": "507",
              "width": "901",
              "size": 241638
            }
          },
          {
            "title": "Sustainability",
            "type": "page",
            "link": {
              "title": "Sustainability",
              "path": "/en_US/bushs-sustainability",
              "href": "",
              "target": "",
              "text": "Sustainability",
              "external": false
            },
            "image": {
              "src": "/-/media/BushsBeans/MegaMenu/sustainability-header.jpg",
              "alt": "Bush's Sustainability",
              "name": "sustainability-header",
              "extension": "jpg",
              "type": "image/jpeg",
              "height": "219",
              "width": "389",
              "size": 99124
            }
          }
        ]
      }
    ],
    "footer": {
      "copyright": "&copy; Bush Brothers & Company. All rights reserved.",
      "companyTagLineSvg": {
        "src": "/-/media/BushsBeans/Dev-Sample-Images/BUB_TBBC_tag_3C_HOR_Bean_yellow_2.svg",
        "alt": "That Beautiful Bean Co.",
        "data": "",
        "name": "BUB_TBBC_tag_3C_HOR_Bean_yellow_2",
        "extension": "svg",
        "type": "image/svg+xml",
        "size": 56850
      },
      "socialLinks": [
        {
          "title": "Instagram",
          "path": "",
          "href": "https://www.instagram.com/bushsbeans/",
          "target": "_blank",
          "text": "Instagram",
          "external": true,
          "icon": "instagram"
        },
        {
          "title": "Facebook",
          "path": "",
          "href": "https://www.facebook.com/bushbeans",
          "target": "_blank",
          "text": "Facebook",
          "external": true,
          "icon": "facebook"
        },
        {
          "title": "Pinterest",
          "path": "",
          "href": "https://www.pinterest.co.uk/bushsbeans/",
          "target": "_blank",
          "text": "Pinterest",
          "external": true,
          "icon": "pinterest"
        },
        {
          "title": "YouTube",
          "path": "",
          "href": "https://www.youtube.com/user/bushsbeans",
          "target": "_blank",
          "text": "YouTube",
          "external": true,
          "icon": "youtube"
        },
        {
          "title": "Twitter",
          "path": "",
          "href": "https://twitter.com/BushsBeans",
          "target": "_blank",
          "text": "lorem ipsum sample text",
          "external": true,
          "icon": "twitter"
        }
      ],
      "utilityLinks": [
        {
          "title": "Privacy Policy",
          "path": "/en_US/privacy-policy",
          "href": "",
          "target": "",
          "text": "Privacy Policy",
          "external": false
        },
        {
          "title": "Terms & Conditions",
          "path": "/en_US/terms-conditions",
          "href": "",
          "target": "",
          "text": "Terms & Conditions",
          "external": false
        },
        {
          "title": "Compliance",
          "path": "/en_US/compliance",
          "href": "",
          "target": "",
          "text": "Compliance",
          "external": false
        }
      ],
      "linkColumns": [
        {
          "title": "Company",
          "text": "Lorem ipsum",
          "links": [
            {
              "title": "Contact Us",
              "path": "/en_US/contact-us",
              "href": "",
              "target": "",
              "text": "Contact Us",
              "external": false
            },
            {
              "title": "Visitor Center",
              "path": "/en_US/about-us/visitor-center",
              "href": "",
              "target": "",
              "text": "Visitor Center",
              "external": false
            },
            {
              "title": "Media Relations",
              "path": "/en_US/media-relations",
              "href": "",
              "target": "",
              "text": "Media Relations",
              "external": false
            },
            {
              "title": "Partnerships",
              "path": "/en_US/about-us/partnerships",
              "href": "",
              "target": "",
              "text": "Partnerships",
              "external": false
            },
            {
              "title": "Sustainability",
              "path": "/en_US/bushs-sustainability",
              "href": "",
              "target": "_blank",
              "text": "Sustainability",
              "external": false
            },
            {
              "title": "FAQ",
              "path": "/en_US/about-us/faq",
              "href": "",
              "target": "",
              "text": "FAQ",
              "external": false
            }
          ]
        },
        {
          "title": "Other Sites",
          "text": "Lorem ipsum",
          "links": [
            {
              "title": "Frijoles Bush",
              "path": "",
              "href": "http://www.bushbeans.com/es_US/",
              "target": "_blank",
              "text": "Frijoles Bush",
              "external": true
            },
            {
              "title": "Foodservice",
              "path": "",
              "href": "https://www.bushbeansfoodservice.com/",
              "target": "_blank",
              "text": "Food Service",
              "external": true
            },
            {
              "title": "Bush's Canada",
              "path": "",
              "href": "https://www.bushbeans.ca/",
              "target": "",
              "text": "Bush's Canada",
              "external": true
            },
            {
              "title": "Shop the Store",
              "path": "",
              "href": "https://shop.bushbeans.com/",
              "target": "",
              "text": "",
              "external": true
            }
          ]
        }
      ],
      "widgets": [
        {
          "type": "subscribe-form",
          "action": "post",
          "actionDestination": "http://the-experience.api/forms/subscribe"
        }
      ]
    },
    "productCanImageField": "consumerUnitFrontStraightOnTransparentPNG",
    "nutritionPanelFormat": "us"
  };
  const { site } = sitecoreContext;
  // console.log('sitecoreContext', site);
  // console.log('sitecoreContext', site.name);
  const updatedContext = {
    ...context,
    globalContent,
  };


  const handleRedirectRouting = () => {
    if (!isServer()) {
      const splitPath = window.location.pathname.split('/');
      const regex = new RegExp(/^([a-z]{2}_[A-Z]{2})/);

      if (regex.test(splitPath[1])) {
        if (splitPath[2] === recipePagePath) {
          return `/${splitPath[1]}/${splitPath[2]}/`;
        }
        return `/${splitPath[1]}/`;
      }
      return '/';
    }
  };

  const getPageType = () => {
    if (pagePath.includes(recipePagePath)) return 'recipe';

    return 'default';
  };

  const getRelevantMessaging = (pageType = 'default') => {
    const messaging = {
      default: {
        text: translate(
            viewBag,
            'BushsBeans.Global.404.Text',
            'Whoops. Looks like you went a little too fast for your skis here.'
        ),
        buttonText: translate(viewBag, 'BushsBeans.Global.404.VisitHomepage', 'Visit Homepage'),
      },
      recipe: {
        text: translate(
            viewBag,
            'BushsBeans.Global.404.RecipeText',
            'Whoops! Looks like something went wrong. Try again or select the See All Recipes option below.'
        ),
        buttonText: translate(viewBag, 'BushsBeans.Global.404.SeeAllRecipes', 'See All Recipes'),
      },
    };

    if (pageType in messaging) return messaging[pageType];

    return messaging.default;
  };

  const pageType = getPageType();

  useEffect(() => {
    const searchButton = document.getElementById('search-button');
    if (searchButton) {
      const svgElement = searchButton.querySelector('svg');
      if (svgElement) {
        svgElement.style.display = 'none';
      }
    }
  }, []);
  //console.log('updatedContext', updatedContext);
  switch (site && site.name) {
    case 'bushs-beans-foodservices':
      return (
          <React.Fragment>
            <GlobalStyle />
            <UtilityNav search={search} setSearch={setSearch} />
            <MainNav headerContext={updatedContext} />
            <main className="container f-s" id="four-oh-four">
              <Container className="inner">
                <section aria-label="Search Container" className="search-on-p">
                  <SearchBox
                      searchValue={searchTerm}
                      showError={noSearchValue}
                      onChange={(event) => {
                        setSearchTerm(event);
                        if (noSearchValue) setNoSearchValue(false);
                      }}
                      onSubmit={(event, inputValue) => {
                        if (searchTerm.length > 0) {
                          window.location.href = `${globalContent.homepageLink}search/?type=all&searchTerm=${searchTerm}`;
                          setSearch(false);
                        } else {
                          setNoSearchValue(true);
                        }
                      }}
                      className="search-box"
                  />
                </section>
                <div>
                  <h1 className="blue text-center 404h1">
                    <img width="200" src={FourOhFourImage} alt="404 Page Not Found"/>
                  </h1>
                  <h2 className="blue">Sorry we couldn't find this page.</h2>
                  <p>Search our site or explore some links below:</p>
                  <ul className="buttonUl foodservice">
                    <li>
                      <Button className="fourOfourButton" target="_blank" text="Back to Home" href="/"
                              variant="yellowBlue"/>
                    </li>
                    <li>
                      <Button className="fourOfourButton" target="_blank" text="Explore Recipes" href="/recipes"
                              variant="yellowBlue"/>
                    </li>
                    <li>
                      <Button className="fourOfourButton" target="_blank" text="Explore Products" href="/products"
                              variant="yellowBlue"/>
                    </li>
                  </ul>
                  <div className="hidden">
                    <p>{getRelevantMessaging(pageType)?.text}</p>
                    <Button text={getRelevantMessaging(pageType)?.buttonText} href={handleRedirectRouting()}/>
                  </div>
                </div>
              </Container>

              <Footer footerContext={updatedContext}/>
            </main>

          </React.Fragment>
      );
    default:
      return (
          <React.Fragment>
            <GlobalStyle/>
            <MainNav headerContext={updatedContext}/>
            <main className="container" id="four-oh-four">
              <Container className="inner">
                <section aria-label="Search Container" className="search-on-p">
                  <SearchBox
                      searchValue={searchTerm}
                      showError={noSearchValue}
                      onChange={(event) => {
                        setSearchTerm(event);
                        if (noSearchValue) setNoSearchValue(false);
                      }}
                      onSubmit={(event, inputValue) => {
                        if (searchTerm.length > 0) {
                          window.location.href = `${globalContent.homepageLink}search/?type=all&searchTerm=${searchTerm}`;
                          setSearch(false);
                        } else {
                          setNoSearchValue(true);
                        }
                      }}
                      className="search-box"
                  />
                </section>
                <div>
                  <h1>{translate(viewBag, 'BushsBeans.Global.404.Title', '404')}</h1>
                  <h2>THIS PAGE IS NOWHERE TO BEAN FOUND</h2>
                  <h3>HERE ARE SOME OTHER PLACES TO SNIFF OUT</h3>
                  <ul className="buttonUl">
                    <li><a href="https://www.bushbeans.com/en_US/">Home Page</a></li>
                    <li><a href="https://www.bushbeans.com/en_US/products">All Products</a></li>
                    <li><a href="https://www.bushbeans.com/en_US/about-us">About Us</a></li>
                    <li><a href="https://www.bushbeans.com/en_US/bean-recipes">Bean Recipes</a></li>
                  </ul>
                  <div className="hidden">
                    <p>{getRelevantMessaging(pageType)?.text}</p>
                    <Button text={getRelevantMessaging(pageType)?.buttonText} href={handleRedirectRouting()}/>
                  </div>
                  <img src={DogImage} alt="Duke"/>
                </div>
              </Container>
              <Footer footerContext={updatedContext} />
            </main>

          </React.Fragment>
      );
  }
};

export default withSitecoreContext()(NotFound);