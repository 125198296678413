import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      text: theme.colours.yellow,
      svg: theme.colours.yellow,
    },
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.red,
      svg: theme.colours.red,
    },
    blue: {
      background: theme.colours.blue,
      text: theme.colours.yellow,
      svg: theme.colours.yellow,
    },
    brown: {
      background: theme.colours.brown,
      text: theme.colours.yellow,
      svg: theme.colours.yellow,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 16, left: 0, right: 0 })};
  text-align: center;
  background-color: ${({ theme, variant }) => getVariantColours({ theme, variant }).background};

  > span {
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).text};
    font-size: 120px;
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  }

  svg {
    color: ${({ theme, variant }) => getVariantColours({ theme, variant }).svg};
    fill: ${({ theme, variant }) => getVariantColours({ theme, variant }).svg};
  }
`;