import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ResultsComponent, ButtonWrapper, LoadingWrapper } from './Results.styles.js';

import Grid from 'components/shared/Grid';
import Card from 'components/shared/Card';
import Button from 'components/shared/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NoResults from './NoResults';
import { useDeviceResize } from 'hooks/index.js';
import translate from 'helpers/translate.js';
import SVGspiral from "../svg/SVGspiral.jsx";

const Results = ({
	sitecoreContext = {},
	results = [],
	type = 'product',
	loadMoreOnClick = () => { },
	currentResults = 0,
	totalResultsCount = 0,
	dataLoading = true,
	initialRequestMade = false,
	globalization = ''
}) => {
	// load more func
	// needs to check there are more results to pull
	const { viewBag = {} } = sitecoreContext;
	const device = useDeviceResize();
	const [isHovered, setIsHovered] = useState(false);
	const loadMore = () => {
		const text = {
			recipe: translate(viewBag, 'BushsBeans.Search.Grids.Recipes.ShowMore', 'Show More'),
			product: translate(viewBag, 'BushsBeans.Search.Grids.Products.ShowMore', 'Show More'),
			article: translate(viewBag, 'BushsBeans.Search.Grids.Articles.ShowMore', 'Show More'),
			default: 'Show More',
		};

		if (type in text) return text[type];

		return text.default;
	};

	const checkResultsCondition = results && results.length > 0;
	return (
		<ResultsComponent>
			{checkResultsCondition ? (
				<>
					<Grid type={type}>
						{results?.map((result, index) => {
							const {
								searchTitle = '',
								searchDescription = '',
								searchImage = {},
								searchHoverImage = {},
								itemId = '',
								itemUrl = '',
							} = result;

							return (
								<Card
									key={`result-${type}-${itemUrl}-${index}`}
									type={type}
									{...result}
									title={searchTitle}
									description={searchDescription}
									image={searchImage}
									promoImage={searchHoverImage}
									itemId={itemId}
									link={itemUrl}
									index={index + 1}
									globalization={globalization}
								/>
							);
						})}
					</Grid>
					{!isServer() && totalResultsCount > currentResults && !dataLoading && (
						<ButtonWrapper
							className={isHovered ? 'active' : ''}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
						>
							{type === 'product' && <SVGspiral isActive={isHovered} />}
							<Button
								type="loadmore"
								variant="yellow"
								size={device === 'desktop' ? 'large' : 'small'}
								text={loadMore()}
								onClick={() => loadMoreOnClick()}
							/>
						</ButtonWrapper>
					)}
				</>
			) : (
				<>{initialRequestMade ? <NoResults /> : <LoadingSpinner />}</>
			)}
		</ResultsComponent>
	);
};

Results.propTypes = {
	type: PropTypes.string,
	results: PropTypes.array,
};

export default withSitecoreContext()(Results);