import styled from 'styled-components';

export const MediaCarouselVideoSlideComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  z-index: 10;
  box-sizing: border-box;
  width: 85%;
  height: auto;
  margin: 0 auto;
`;

export const Media = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:before {
    display: block;
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    content: '';
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;
