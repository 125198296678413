export const formConfig = (args = {}) => {
  const { type = '' } = args;
  const key = process.env.REACT_APP_SEARCH_API_KEY || 'no key';
  const url = process.env.REACT_APP_EXP_API_DOMAIN || 'no url';

  const paths = {
    subscribe: '/api/experience/forms/subscribe',
    industrySubscribe: '/api/experience/forms/industry-subscribe',
  };

  if (type in paths) {
    return {
      key,
      endpoint: `${url}${paths[type]}`,
    };
  }

  return {
    key,
  };
};
