import React from 'react';
import SVG from 'react-inlinesvg';
import wave from 'assets/separator/wave.svg';

const separatorTypes = ({ type }) => {
  const types = {
    wave,
  };

  if (type in types) return types[type];

  return types.wave;
};

const Separator = ({ type = 'wave' }) => {
  return <SVG src={separatorTypes(type)} />;
};

export default Separator;
