import { useEffect, useState } from 'react';

const isBrowser = typeof window !== `undefined`;
const scripts = [];

const useAsyncScript = (args = {}) => {
  const { src, crossOrigin } = args;

  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (!isBrowser || !src) return;
    if (scripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      scripts.push(src);

      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      if (crossOrigin) script.crossorigin = true;

      const onLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onError = () => {
        const index = scripts.indexOf(src);
        if (index >= 0) scripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onLoad);
      script.addEventListener('error', onError);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onLoad);
        script.removeEventListener('error', onError);
      };
    }
  }, [crossOrigin, src]);

  return [state.loaded, state.error];
};

export default useAsyncScript;
