import { useEffect, useState } from 'react';
import { breakPointValues as breakPoints } from '../theme/@config/break-points';

const isBrowserCondition = typeof window !== `undefined`;

const checkBreakpoints = ({ breakpoints, isBrowser = isBrowserCondition }) => {
  if (!isBrowser) {
    return '!browser';
  }

  const tablet = parseInt(breakpoints?.tablet, 10);
  const desktop = parseInt(breakpoints?.desktop, 10);
  const width = window.innerWidth;

  let device = 'mobile';
  if (width < tablet) {
    device = 'mobile';
  } else if (width >= tablet && width < desktop) {
    device = 'tablet';
  } else if (width >= desktop) {
    device = 'desktop';
  }
  return device;
};

const useDeviceResize = ({ isBrowser } = {}) => {
  const [isDevice, setDevice] = useState(checkBreakpoints({ breakpoints: breakPoints, isBrowser }));

  useEffect(() => {
    const handleChangeSize = () => {
      const getDevice = checkBreakpoints({ breakpoints: breakPoints, isBrowser });
      setDevice(getDevice);
    };

    window.addEventListener('resize', handleChangeSize);

    return () => {
      window.removeEventListener('resize', handleChangeSize);
    };
  }, [isBrowser, isDevice]);

  return isDevice;
};

export default useDeviceResize;
