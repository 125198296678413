import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ContentOverTheTopComponent,
  OverTheTopSurveyWrapper,
} from './OverTheTopSurvey.styles.js';

const OverTheTopSurvey = ({ backgroundColor, alignment, id }) => {

  return (
      <OverTheTopSurveyWrapper id={id} className="over-the-top-survey-wrapper">
          <ContentOverTheTopComponent backgroundColor={backgroundColor} alignment={alignment}>
            <div dangerouslySetInnerHTML={{
              __html: `
              <script type="text/javascript">
                document.write('<script src="http' + (("https:" == document.location.protocol) ? "s" : "") + '://survey.alchemer.com/s3/8067419/Bean-Experience?__output=embedjs&__ref=' + escape(document.location.href) + '" type="text/javascript" ></scr' + 'ipt>');
              </script>
              <noscript>
                Business solutions designed for organizations of all sizes - <a href="https://www.alchemer.com/plans-pricing/">Alchemer</a>. <a href="https://survey.alchemer.com/s3/8067419/Bean-Experience??jsfallback=true">Please take my survey now</a>
              </noscript>
              <style>.sg-survey{display:none;}</style>
            `,
            }} />
          </ContentOverTheTopComponent>
      </OverTheTopSurveyWrapper>
  );
};

OverTheTopSurveyWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  alignment: PropTypes.string,
  id: PropTypes.string,
};

export default OverTheTopSurvey;