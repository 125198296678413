import styled from 'styled-components';

const getVariantColours = ({ variant = 'tan', theme } = {}) => {
  const variants = {
    tan: {
      background: theme.colours.tan,
      text: theme.colours.red,
      linearGradient:
        'linear-gradient(90deg, rgba(241,232,215,0) 0%, rgba(241,232,215,0.8) 15%, rgba(241,232,215,1) 30%, rgba(241,232,215,1) 70%, rgba(241,232,215,0.8) 85%, rgba(241,232,215,0) 100%)',
    },
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.red,
      linearGradient:
        'linear-gradient(90deg, rgba(255,202,46,0) 0%, rgba(255,202,46,0.8) 15%, rgba(255,202,46,1) 30%, rgba(255,202,46,1) 70%, rgba(255,202,46,0.8) 85%, rgba(255,202,46,0) 100%)',
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const SliderContainer = styled.section`
  position: relative;
  height: 0;
  padding-top: 80%;

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    position: relative;
    height: 0;
    padding-top: 36.5%;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    position: static;
    height: 100%;
    padding-top: 0;
  }
`;

export const ScrollingImagesPromoComponent = styled.section`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 30px;
  overflow: hidden;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ theme, variant: backgroundColor }).background};

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .slick-list {
    height: 100%;
    margin: 0 -20px;
  }

  .slick-track {
    &,
    div {
      height: 100%;
    }
  }

  .slick-slide {
    margin: 0;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin: 0 15px;
    }
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    height: 0;
    padding-top: 36.5%;

    .slick-slider {
      padding-top: 20px;
    }
  }
`;

export const PromoImageContainer = styled.div`
  position: ${(props) => (props.foregroundSelected ? 'static' : 'absolute')};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0;
  width: ${(props) => (props.foregroundSelected ? '' : '100%')};
  height: ${(props) => (props.foregroundSelected ? '' : '100%')};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: ${(props) => (props.foregroundSelected ? '3' : '1')};
    width: 900px;
    height: 100%;
    padding: 20px 0;
    background: ${({ theme, backgroundColor }) =>
      getVariantColours({ theme, variant: backgroundColor }).linearGradient};
    transform: translateX(-50%);
  }
`;

export const PromoImage = styled.img`
  display: block;
  max-width: 500px;
  margin: 0 auto;
`;

export const PromoTextArea = styled.div`
  display: block;
  max-width: 500px;
  margin: 0 auto;
  color: ${({ theme, backgroundColor }) => getVariantColours({ theme, variant: backgroundColor }).text};
  text-align: center;

  button {
    margin: 1rem auto 0;
    font-size: 1rem;
    background-color: ${({ theme, backgroundColor }) =>
      getVariantColours({ theme, variant: backgroundColor }).background};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-top: 2rem;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      font-size: 1.5rem;
    }
  }

  & .button-default, .button-submit {
    border: solid 3px;
  }
  
  & .button-default span:nth-child(2) {
    width: 3px;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 0.15em;
    }
  }
`;

export const PromoTitle = styled.span.attrs(props => ({className: "h1"}))`
  margin-bottom: 5rem !important;
  font-size: 9rem !important;
  font-family: bordonaro !important;
  display: block;
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: 5rem !important;
    font-size: 11rem !important;
  }
`;

export const PromoText = styled.h2`
  margin-bottom: 3rem;
  font-weight: bold;
  font-size: 3rem;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: 4rem;
    font-size: 3.75rem;
  }
  span, u {
    font-size: smaller;
    vertical-align: 0.2em;
  }
`;
