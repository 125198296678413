import React from 'react';

const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={123}
        height={124}
        viewBox="0 0 123 124"
        fill="none"
        {...props}
    >
        <path
            d="M123 62.0977C123 96.2109 95.373 123.598 61.5 123.598C27.3867 123.598 0 96.2109 0 62.0977C0 28.2246 27.3867 0.597656 61.5 0.597656C95.373 0.597656 123 28.2246 123 62.0977ZM45.1641 36.1523C43.2422 37.1133 42.2812 39.0352 42.2812 40.957V83.2383C42.2812 85.4004 43.2422 87.3223 45.1641 88.2832C46.8457 89.4844 49.248 89.2441 50.9297 88.2832L85.5234 67.1426C87.2051 65.9414 88.4062 64.2598 88.4062 62.0977C88.4062 60.1758 87.2051 58.2539 85.5234 57.293L50.9297 36.1523C49.248 34.9512 46.8457 34.9512 45.1641 35.9121V36.1523Z"
            fill="#F1E8D7"
        />
    </svg>
);
export default SVGComponent;