import React from 'react';
import PropTypes from 'prop-types';

const LinksList = ({ title, links }) => (
  <div>
    {title && <h4 className="emphasis small list-title">{title}</h4>}
    {links && (
      <ul>
        {links.map(({ href, path, target, title }, index) => (
          <li key={`Link-${index}_${href || path}`}>
            <a href={href || path} target={target}>
              {title}
            </a>
          </li>
        ))}
      </ul>
    )}
  </div>
);

LinksList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
};

export default LinksList;
