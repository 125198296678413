import React, { useState } from 'react';
import { TextareaComponent } from './Textarea.styles.js';
import {v4 as uuid} from 'uuid';

const field_id = uuid().replace('-', '');

const Textarea = ({ label, required, error, onChange, maxLength, className, inputRef, helpText, ...props }) => {
  const [counter, setCounter] = useState(0);
  return (
    <TextareaComponent className={className}>
      {label && <label>{`${required ? '*' : ''}${label}`}</label>}
      {helpText && <span id={'help-' + field_id} className={'help-text'}>{helpText}</span>}
      <textarea
        {...props}
        aria-describedby={helpText ? 'help-' + field_id : ''}
        aria-label={label}
        ref={inputRef}
        onChange={event => {
          if (event.target.value) {
            setCounter(event.target.value.length - 1);
          }
          if (typeof onChange === 'function') {
            onChange(event);
          }
        }}
      />
      {error && <p className="error-message">{error}</p>}
      {maxLength && <p className="counter">{`${counter}/${maxLength}`}</p>}
    </TextareaComponent>
  );
};

Textarea.propTypes = {};

export default Textarea;
