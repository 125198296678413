import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: ${({ theme }) => theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
  color: ${({ theme }) => theme.colours.adaBrown};
  background-color: ${({ theme }) => theme.colours.yellow};
`;
export const WrapperInner = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 90%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing({ top: 10, bottom: 10, left: 0, right: 0 })};
  background-color: ${({ theme }) => theme.colours.tan};
  @media print {
    max-width: ;
    background-color: transparent !important;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ top: 80, bottom: 60, left: 0, right: 0 })};
  }

  .grid {
    max-width: 100%;
  }
`;

export const Wave = styled.span`
  position: absolute;
  width: 100%;

  ${({ position }) =>
    position === 'top'
      ? `top: -10px;
left: 0;`
      : ''}

  ${({ position }) =>
    position === 'bottom'
      ? `bottom: -10px;
  left: 0;`
      : ''}
`;
