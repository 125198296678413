export const config = {
  webClaimCholesterol: {
    key: 'webClaimCholesterol',
    sort: 6,
    icon: 'cholesterol-free',
  },
  webClaimFat: {
    key: 'webClaimFat',
    sort: 9,
    icon: 'fat-free',
  },
  webValueFiber: {
    key: 'webValueFiber',
    sort: 15,
    icon: 'fiber',
  },
  webClaimFiber: {
    key: 'webClaimFiber',
    sort: 4,
    icon: 'fiber',
  },
  webClaimGlutenfree: {
    key: 'webClaimGlutenfree',
    sort: 2,
    icon: 'gluten-free',
  },
  webClaimKosher: {
    key: 'webClaimKosher',
    sort: 7,
    icon: 'kosher',
  },
  webClaimSodium: {
    key: 'webClaimSodium',
    sort: 8,
    icon: 'low-sodium',
  },
  webClaimArtificialColor: {
    key: 'webClaimArtificialColor',
    sort: 13,
    icon: 'no-artificial-colors',
  },
  webClaimArtificialFlavors: {
    key: 'webClaimArtificialFlavors',
    sort: 12,
    icon: 'no-artificial-flavors',
  },
  webClaimArtificialPreservatives: {
    key: 'webClaimArtificialPreservatives',
    sort: 11,
    icon: 'no-artificial-preservatives',
  },
  webClaimOrganic: {
    key: 'webClaimOrganic',
    sort: 10,
    icon: 'organic',
  },
  webValueProtein: {
    key: 'webValueProtein',
    sort: 3,
    icon: 'protein',
  },
  webClaimProtein: {
    key: 'webClaimProtein',
    sort: 14,
    icon: 'protein',
  },
  webClaimBeans: {
    key: 'webClaimBeans',
    sort: 5,
    icon: 'usa',
  },
  webClaimVegetarian: {
    key: 'webClaimVegetarian',
    sort: 1,
    icon: 'vegetarian',
  },
  webClaimVegan: {
    key: 'webClaimVegan',
    sort: 1,
    icon: 'vegetarian',
  },
};
