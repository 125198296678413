import React from 'react';
import {ThirtyKSvgObject} from './ThirtyKSvg.styles.js';

const ThirtyKSvg = ({ onClick, className, props }) => {
    return (
        <ThirtyKSvgObject
            width={240}
            height={66}
            fill="none"
            onClick={onClick}
            className={className}
            {...props}
        >
            <g filter="url(#a)">
                <path
                    fill="#EC2931"
                    d="M232.158 29.605c.124-.426.229-.869.335-1.312l2.923-13.757v-.212c.528-3.9-.951-6.542-2.272-8.066-3.01-3.457-7.465-3.865-10.546-3.865-3.081 0-7.588.408-10.617 3.865-.088.106-.176.23-.281.337-3.469-2.216-7.747-3.457-12.642-3.457-6.673 0-12.377 2.641-16.392 7.073-3.926-4.503-9.718-7.073-16.831-7.073-6.673 0-12.378 2.641-16.392 7.073-3.926-4.503-9.719-7.073-16.832-7.073-12.694 0-21.973 9.555-22.448 22.868-.3-.018-.599-.035-.898-.035-.511 0-1.021.035-1.532.088-.423-13.951-9.12-22.904-22.466-22.904-5.933 0-11.11 2.11-15.018 5.69C66.99 5.567 61.85 3.28 54.579 3.28c-6.075 0-11.198 1.595-14.737 4.378a12.865 12.865 0 00-2.095-1.028c-1.039-.408-2.254-.762-3.61-1.064A9.4 9.4 0 0025.547 0h-.529a9.427 9.427 0 00-8.768 5.992C8.714 8.509 4.12 14.27 4.12 21.184c0 2.907.65 5.496 1.954 7.747-.088.106-.159.23-.247.354-2.06 3.067-2.394 7.074-.915 10.761 1.126 2.677 4.137 7.392 11.814 9.449a9.406 9.406 0 008.257 4.84h.528c3.733 0 7.096-2.181 8.592-5.496 1.743-.674 3.328-1.525 4.719-2.553 3.292 2.765 8.275 4.698 15.388 4.698 7.113 0 12.571-2.074 16.092-5.123 3.803 3.297 8.892 5.123 14.93 5.123 4.314 0 8.223-1.117 11.55-3.102a8.033 8.033 0 00.247 4.36c1.162 3.369 4.49 5.726 8.117 5.726 1.303 0 2.553-.301 3.838-.922 3.275-1.666 8.011-5.052 10.898-9.554 3.487 2.233 7.765 3.474 12.695 3.474 6.673 0 12.377-2.641 16.391-7.055 3.927 4.485 9.719 7.055 16.832 7.055 6.673 0 12.378-2.641 16.392-7.055 3.926 4.485 9.719 7.055 16.832 7.055 5.159 0 9.754-1.595 13.434-4.36 2.447 2.658 6.039 4.36 10.035 4.36 7.378 0 13.381-5.673 13.381-12.657 0-3.262-1.355-6.382-3.732-8.722l.017.018z"
                ></path>
                <path
                    fill="#FFC200"
                    d="M37 31.633c0 5.568-4.114 10.115-10.651 11.08v1.837c0 1.41-1.007 2.45-2.304 2.45h-.512c-1.298 0-2.305-1.04-2.305-2.45v-1.652c-6.247-.556-9.627-3.118-10.873-6.31-1.075-2.877.342-5.939 3.55-5.939 2.305 0 3.602 1.28 4.78 3.62 1.178 2.32 2.936 3.618 5.36 3.618 2.202 0 3.891-.928 3.891-2.876 0-1.838-1.911-2.692-6.418-4.232-8.176-2.821-11.385-5.642-11.385-11.024 0-5.382 4.507-9.057 11.095-9.8V7.45c0-1.355 1.007-2.45 2.305-2.45h.512c1.297 0 2.304 1.095 2.304 2.45v2.505c3.26.242 5.752.928 7.032 1.467 1.912.798 2.032 1.41 2.032 4.844 0 3.433-1.007 3.916-2.202 3.916-1.349 0-2.031-1.04-3.21-3.007-1.126-1.782-2.645-3.118-6.025-3.118-2.816 0-4.847.984-4.847 2.635 0 1.893 2.031 2.822 6.469 3.916 8.108 2.08 11.385 5.216 11.385 11.025H37zm31 2.589C68 38.685 63.932 44 53.576 44c-8.313 0-13.085-3.426-13.543-8.13-.3-3.11 1.462-5.555 4.526-5.555 2.624 0 3.84 1.648 4.245 4.148.58 3.426 2.2 4.889 4.015 4.889 2.325 0 3.84-1.593 3.84-5.019 0-3.61-2.854-6.592-6.517-6.592-2.202 0-2.325-2.815 0-2.815 2.73 0 5.935-2.87 5.935-7.093 0-2.444-.986-4.703-2.853-4.703-1.69 0-3.135 1.407-4.244 5.018-.529 1.834-1.691 3.426-4.121 3.37-2.554-.055-4.368-2.314-3.84-5.37C41.724 11.926 46.655 9 53.93 9c8.946 0 12.909 4.518 12.909 8.982 0 3.851-2.853 6.722-7.855 8.259 5.759 1.277 9.017 4.037 9.017 8v-.019zm34-7.685C102 36.513 95.998 44 86.5 44 77.002 44 71 37.675 71 26.537 71 16.56 77.002 9 86.5 9c9.498 0 15.5 6.399 15.5 17.537zm-11.422 0c0-8.335-1.571-13.517-4.078-13.517-2.736 0-4.131 5.053-4.131 13.517 0 8.464 1.571 13.517 4.131 13.517 2.56 0 4.078-4.574 4.078-13.517z"
                ></path>
                <path
                    fill="#F6C443"
                    d="M106.673 51.976c-1.145.934-2.983-.4-1.906-1.766 6.567-8.518-1.798-4.666-2.732-10.652-.491-3.143 1.711-6.23 4.918-6.9 3.207-.669 6.162 1.399 6.824 5.645.663 4.247-3.516 10.548-7.123 13.658l.019.015z"
                ></path>
                <path
                    fill="#FFC200"
                    d="M147 26.537C147 36.513 141.385 44 132.5 44S118 37.675 118 26.537C118 16.56 123.615 9 132.5 9S147 15.399 147 26.537zm-10.685 0c0-8.335-1.47-13.517-3.815-13.517-2.56 0-3.864 5.053-3.864 13.517 0 8.464 1.469 13.517 3.864 13.517 2.395 0 3.815-4.574 3.815-13.517zm44.685 0C181 36.513 175.191 44 166 44c-9.191 0-15-6.325-15-17.463C151 16.56 156.809 9 166 9c9.191 0 15 6.399 15 17.537zm-11.054 0c0-8.335-1.52-13.517-3.946-13.517-2.648 0-3.998 5.053-3.998 13.517 0 8.464 1.521 13.517 3.998 13.517 2.477 0 3.946-4.574 3.946-13.517zm43.054 0C213 36.513 207.385 44 198.5 44S184 37.675 184 26.537C184 16.56 189.615 9 198.5 9S213 15.399 213 26.537zm-10.685 0c0-8.335-1.47-13.517-3.815-13.517-2.56 0-3.864 5.053-3.864 13.517 0 8.464 1.469 13.517 3.864 13.517 2.395 0 3.815-4.574 3.815-13.517zm18.926 19.529c-3.267 0-5.738-2.633-5.569-5.948a6.184 6.184 0 016.172-5.873c3.266 0 5.804 2.633 5.639 5.873-.166 3.24-2.976 5.948-6.242 5.948zm7.205-32.565l-3.404 14.322c-.76 3.258-1.65 3.561-3.019 3.561-1.369 0-2.192-.681-2.705-3.561l-1.94-14.322c-.208-3.372 2.44-4.187 5.772-4.187 3.333 0 5.831.815 5.28 4.187h.016z"
                ></path>
            </g>
            <defs>
                <filter
                    id="a"
                    width="239.873"
                    height="65.968"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_12_41"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_12_41"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </ThirtyKSvgObject>
    );
};

export default ThirtyKSvg;