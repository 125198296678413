import React from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    FeaturedHeroWithVideoComponent,
    MediaDiv,
    CopyDiv
} from './FeaturedDetailHeroWithVideo.styles.js';
import {isServer} from "@sitecore-jss/sitecore-jss";
import {RichText} from "../shared/JssOverrides";
import Button from "../shared/Button";
import TvVideoFrame from './TvVideoFrame.jsx';
import {
    CenteredVideoComponentWrapper,
    CenteredVideoOuterWrapper
} from "../Text/SubTemplates/CenteredVideoComponent.styles";
import InlineSVG from "react-inlinesvg";


const FeaturedDetailHeroWithVideo = props => {
    const {fields = {}, params = {}, sitecoreContext = {}} = props;
    const {viewBag = {}, route = {}} = sitecoreContext;

    const {
        heroType = '',
        title = {},
        longName = {},
        description = {},
        backgroundImage = {},
        heroImage = {},
        foregroundImage = {},
        videoId = '',
        author = '',
        date = '',
        recipeHighlights = [],
        copy = {},
        cta = {},
        ctaVisible = false,
        ctaColor = '',
        variant = '',
        onClick = () => {
        },
        children,
    } = fields;
    const {style = '', backgroundColor = '', heroSvg = ''} = params;

    const promoProps = {
        heroType,
        title,
        copy: description,
        author,
        date,
        recipeHighlights,
        style,
        longName,
        viewBag,
        itemId: route?.itemId,
    };
    const experienceEditor = isExperienceEditorActive();

    const imageToUse = heroImage?.value?.src || backgroundImage?.value?.src;
    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <FeaturedHeroWithVideoComponent backgroundColor={backgroundColor} className="shrink-on-print"
                                            data-use={imageToUse} aria-label="Featured hero section with video">
                <div className="container">
                    <CopyDiv>
                        <div className="inner">
                            {foregroundImage?.value?.src && (
                                <img src={foregroundImage.value.src} alt={foregroundImage.value.alt}/>
                            )}
                            {description?.value && (
                                <p dangerouslySetInnerHTML={{__html: description?.value}}></p>
                            )}
                            {children}
                            {!isServer() && cta && ctaVisible && (
                                <Button
                                    text={cta?.text}
                                    href={cta?.href}
                                    variant={ctaColor || variant}
                                    onClick={onClick}
                                    {...cta?.value}
                                    size="large"
                                />
                            )}
                        </div>
                    </CopyDiv>
                    <MediaDiv>
                        {videoId?.value && (
                            <TvVideoFrame
                                videoId={videoId.value}
                                posterText={title?.value}
                                vidPoster={backgroundImage?.value?.src}
                            />
                        )}
                    </MediaDiv>
                </div>
            </FeaturedHeroWithVideoComponent>
        );
    }
    return (
        <FeaturedHeroWithVideoComponent backgroundColor={backgroundColor} className="shrink-on-print"
                                        data-use={imageToUse}>
            <div className="container">
                <CopyDiv>
                    <div className="inner">
                        {foregroundImage?.value?.src && (
                            <img src={foregroundImage.value.src} alt={foregroundImage.value.alt}/>
                        )}
                        {description?.value && (
                            <p dangerouslySetInnerHTML={{__html: description?.value}}></p>
                        )}
                        {children}
                        {!isServer() && cta && ctaVisible && (
                            <Button
                                text={cta?.text}
                                href={cta?.href}
                                variant={ctaColor || variant}
                                onClick={onClick}
                                {...cta?.value}
                                size="large"
                            />
                        )}
                    </div>
                </CopyDiv>
                <MediaDiv>
                {videoId?.value && (
                        <TvVideoFrame
                            videoId={videoId.value}
                            posterText={title?.value}
                            vidPoster={backgroundImage?.value?.src}
                        />
                    )}
                </MediaDiv>
            </div>

        </FeaturedHeroWithVideoComponent>
    );
};

export default withSitecoreContext()(FeaturedDetailHeroWithVideo);