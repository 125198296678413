import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import {
    MediaCarouselImageSlideComponent,
    ContentWrapper,
    Media,
    BackgroundImage,
} from './MediaCarouselImageSlide.styles.js';
import Button from "../shared/Button";

const MediaCarouselImageSlide = ({ fields = {}, params = {} }) => {
    const { slideImage = { value: {} }, backgroundImage = { value: {} } } = fields;
    const slideImageAlt = slideImage.value?.alt || '';
    const backgroundImageAlt = backgroundImage.value?.alt || '';
    const slideImageTitle = slideImage.value?.title || '';
    const slideImageTitleSplit = slideImageTitle.split(':');
    const backgroundImageTitle = backgroundImage.value?.title || '';
    const backgroundImageTitleSplit = backgroundImageTitle.split(':');
    const textContentExists = backgroundImageTitleSplit.length > 1;
    // console.log('Slide image values:', slideImage);
    // console.log('Background image values', backgroundImage);

    return (
        <MediaCarouselImageSlideComponent className={`media-carousel-image-slide ${textContentExists ? 'w-text-content' : ''}`}>
            <BackgroundImage>
                <Image field={backgroundImage} className="background-image" draggable={false} />
            </BackgroundImage>
            {
                textContentExists && (
                    <div className="contentWrap">
                        <div className="carouselCopyWrap">
                            <h2>{backgroundImageTitleSplit[0]}</h2>
                            <p>{backgroundImageTitleSplit[1]}</p>
                        </div>
                    </div>
                )
            }
            <ContentWrapper>
                <Media>
                    <Image field={slideImage} className="media-image" draggable={false} />
                </Media>
            </ContentWrapper>
        </MediaCarouselImageSlideComponent>
    );
};

MediaCarouselImageSlide.propTypes = {};

export default MediaCarouselImageSlide;