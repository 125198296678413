import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './Accordion.styles';
import { Placeholder, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';

const Accordion = ({ fields = {}, params = {}, rendering, sitecoreContext }) => {
  const experienceEditor = isExperienceEditorActive();
  const { title } = fields;
  const { isFullWidth } = params;

  return (
    <Container isFullWidth={isFullWidth} id={'accordion-faq'} experienceEditor={experienceEditor || sitecoreContext?.pageEditing}>
      {title && <Text field={title} tag="h1" />}
      <ul>
        <Placeholder name="accordion-content" rendering={rendering} />
      </ul>
    </Container>
  );
};

Accordion.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  rendering: PropTypes.object,
  isFullWidth: PropTypes.string,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(Accordion);