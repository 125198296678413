import React from 'react';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import DesktopNav from './components/DesktopNav';
import FsDesktopNav from './components/FsDesktopNav';
import MobileNav from './components/MobileNav';
import FsMobileNav from './components/FsMobileNav';
import { useDeviceResize } from 'hooks';

export const MainNav = (
    {
      headerContext = {},
      sitecoreContext: { globalContent = {},site={}, viewBag = {} },
      ...props
    }
) => {
    const device = useDeviceResize();

    if (device === 'mobile') {
        if (site.name === 'bushs-beans-foodservices') {
            return <FsMobileNav className={site.name} headerContext={headerContext} {...props} />;
        } else {
            return <MobileNav className={site.name} headerContext={headerContext} {...props} />;
        }
    }
    if (device === 'tablet') {
        if (site.name === 'bushs-beans-foodservices') {
            return <FsMobileNav className={site.name} headerContext={headerContext} {...props} />;
        } else {
            return <MobileNav className={site.name} headerContext={headerContext} {...props} />;
        }
    }

    if (site.name === 'bushs-beans-foodservices') {
        return <FsDesktopNav className={site.name} headerContext={headerContext} {...props} />;
    } else {
        return <DesktopNav className={site.name} headerContext={headerContext} {...props} />;
    }

};

MainNav.propTypes = {};

export default withSitecoreContext()(MainNav);