import React, { useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import { ButtonWrapper } from './Filters.styles';
import Button from 'components/shared/Button';
import translate from 'helpers/translate';
import { useDeviceResize } from 'hooks';

const FilterFooter = ({ sitecoreContext = {}, applyFilters = () => {}, toggleFilters = () => {} }) => {
  const { viewBag = {} } = sitecoreContext;
  const device = useDeviceResize();
  const [activeButton, setActiveButton] = useState(null);
  return (
    <footer>
      {!isServer() && (
        <ButtonWrapper>
          <Button
            text={translate(viewBag, 'BushsBeans.Search.Filters.General.Close', 'Close')}
            type="clear"
            variant="tan"
            noIcon
            size={device === 'mobile' ? 'small' : 'large'}
            onClick={() => {
              toggleFilters(false);
              setActiveButton(null); // Add this line to remove the active class
            }}
          />
          <Button
            text={translate(viewBag, 'BushsBeans.Search.Filters.General.Apply', 'Apply Filters')}
            variant="yellowBlue"
            size={device === 'mobile' ? 'small' : 'large'}
            onClick={() => applyFilters()}
          />
        </ButtonWrapper>
      )}
    </footer>
  );
};

export default withSitecoreContext()(FilterFooter);