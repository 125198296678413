import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';

import { Wrapper } from './ScrollingText.styles';

import { useScrollPosition, useObserver, useDeviceResize } from 'hooks';
import { fillArrayWithData } from 'helpers/arrayHelpers';

const ScrollingElement = ({ scrollingVanityTextElements = [] }) => {
  const innerRef = useRef(null);
  const originalElRef = useRef(null);
  const [startLeftValue, setStartLeftValue] = useState(0);
  const [fillAmount, setFillAmount] = useState(0);

  const device = useDeviceResize();
  const scroll = useScrollPosition();
  const elInView = useObserver({ ref: innerRef });
  const startValue = useCallback(() => {
    return window && window.innerWidth - (window.innerWidth * 2 + (window.innerWidth / 100) * 50);
  }, []);

  const [animateProps, setAnimate] = useSpring(() => ({
    y: startValue(),
    config: { mass: 30, tension: 180, friction: 120 },
  }));

  const calcMovement = (scroll, speed = 1) => {
    const win = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const move = {
      width: window.innerWidth / 100,
      height: window.innerHeight / 100,
    };

    const elementY = innerRef?.current?.getBoundingClientRect().top + window.scrollY;
    const elStartVisible = elementY - win.height;
    const scrollCalc = (scroll - elStartVisible) * (move.width / move.height);

    return +(startLeftValue + scrollCalc * speed).toFixed(0);
  };

  // calcs left start value from window width
  useEffect(() => {
    if (!isServer()) {
      setStartLeftValue(startValue());
    }
  }, [startValue]);

  // set scroll Y value on scroll & calc left value
  useEffect(() => {
    if (elInView) {
      setAnimate({ y: calcMovement(scroll) });
    }
  }, [scroll, elInView, calcMovement]);

  // controls rendering on resize
  // make sures theres enough copys of the elements
  useEffect(() => {
    if (!isServer() && originalElRef?.current?.offsetWidth > 0) {
      const fillToRender = Math.floor(window.innerWidth / originalElRef?.current?.offsetWidth);
      setFillAmount(fillToRender ? fillToRender * 5 : 5);
      setStartLeftValue(startValue());
      setAnimate({
        y: startValue(),
      });
    }
  }, [originalElRef, device, startValue, setAnimate]);

  const TextAndImage = () => {
    const [first = {}, second = {}] = scrollingVanityTextElements;

    return (
        <div>
          {first?.fields?.text && <Text field={first?.fields?.text} tag="span" className="vanity" />}
          {first?.fields?.graphic && <Image field={first?.fields?.graphic} />}
          {second?.fields?.text && <Text field={second?.fields?.text} tag="span" className="vanity" />}
          {second?.fields?.graphic && <Image field={second?.fields?.graphic} />}
        </div>
    );
  };

  if (scrollingVanityTextElements && scrollingVanityTextElements.length > 0) {
    return (
        <animated.div ref={innerRef} class="inner" style={{ left: animateProps.y }}>
          <div ref={originalElRef}>
            <TextAndImage key={`original`} />
          </div>

          {fillAmount &&
              fillArrayWithData({ data: {}, fill: fillAmount })?.map((item, index) => {
                return <TextAndImage key={`copy-${index}`} {...item} />;
              })}
        </animated.div>
    );
  }

  return null;
};

// makes sure element only renders on frontend
const ScrollingText = (props) => <Wrapper>{!isServer() && <ScrollingElement {...props} />}</Wrapper>;

export default ScrollingText;

ScrollingText.propTypes = {
  scrollingVanityTextElements: PropTypes.array,
};

ScrollingElement.propTypes = {
  scrollingVanityTextElements: PropTypes.array,
};