import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

import {
  RecipeIngredientsDetailsComponent,
  ImageWrapper,
  FlexContainer,
  ContentWrapper,
  Ingredients,
  Nutrition,
  Basketful,
  Tools,
  NutritionContent,
  ButtonWrapper,
} from './RecipeIngredientsDetails.styles.js';

import SocialShare from 'components/SocialShare';
import Button from 'components/shared/Button';
import Grid from 'components/shared/Grid';
import Modal from 'components/shared/Modal';
import RecipeWrapper from 'components/shared/RecipeWrapper';
import NutritionTable from 'components/shared/NutritionTable';

import { useDeviceResize } from 'hooks/index.js';
import { printElement } from 'helpers/printHelpers';
import translate from 'helpers/translate';

// TODO: nutrition button & modal has been commented out until post launch
const RecipeIngredientsDetails = ({ fields = {}, params = {}, sitecoreContext = {} }) => {
  const {
    viewBag = {},
    globalContent = {},
    recipeInfo: { showBasketfulButton = false },
    trademarkReplacements = [],
  } = sitecoreContext;
  const { metadata = {} } = globalContent;
  const { siteDomain = '', metadataUrl = '' } = metadata;
  const {
    ingredientsImage = {},
    optionalToppings = {},
    recipeIngredientGroups = [],
    shareText = 'Share this recipe with your bean friends',
    shareOptions = [
      {
        title: 'Facebook',
        value: 'facebook',
      },
      {
        title: 'Twitter',
        value: 'twitter',
      },
      {
        title: 'Pinterest',
        value: 'pinterest',
      },
    ],
    downloadPdf = {},
    nutritionalInformation = {},
  } = fields;

  const device = useDeviceResize();
  const [showNutrition, setShowNutrition] = useState(false);
  const [recipeUrl, setRecipeUrl] = useState(`${siteDomain}${metadataUrl}`);
  const isNutritionalInfoEmpty = !Object.values(nutritionalInformation).some((x) => x !== '');

  const printId = 'recipe-ingredients';
  //TODO: temp print func until more time to build out

  // useEffect(() => {
  //   if (!isServer()) {
  //     if (window.location.href !== recipeUrl) setRecipeUrl(window.location.href);
  //   }
  // }, []);

  // TODO: serving size should be dynamic / with selector
  const tempServiceSize =
    (recipeIngredientGroups && recipeIngredientGroups[0].fields?.groupServingSize?.value) || '';

  return (
    <RecipeIngredientsDetailsComponent id="recipe-ingredients" className="shrink-on-print">
      <RecipeWrapper>
        <Grid type="ingredients" columns="2">
          <ImageWrapper>
            {ingredientsImage && <Image field={ingredientsImage} className="hide-on-print" />}

            {(device === 'mobile' || device === 'tablet') && (
              <Tools>
                <Button
                  type="circle"
                  size="large"
                  variant="tan"
                  icon="print"
                  ariaLabel="Print Recipe"
                  onClick={() => printElement({ selector: printId })}
                />
                {downloadPdf?.value && <Button type="circle" size="large" variant="tan" icon="download" />}
              </Tools>
            )}

            <FlexContainer className="hide-on-print">
              {showBasketfulButton && (
                <Basketful>
                  <h2>
                    {translate(
                      viewBag,
                      'BushsBeans.Recipes.IngredientsDetails.NeedIngredients',
                      'Need Ingredients?'
                    )}
                  </h2>

                  <Button
                    id="basketful-button-lg"
                    text={translate(viewBag, 'BushsBeans.Recipes.IngredientsDetails.ShopWith', 'Shop With')}
                    type="basketful"
                    variant="tan"
                    size="large"
                    noIcon
                    WithDefaultStyles
                    data-basketful-recipe-url={recipeUrl}
                  />
                </Basketful>
              )}

              <Tools showBasketfulButton={showBasketfulButton}>
                <Button
                  type="circle"
                  size="large"
                  variant="tan"
                  icon="print"
                  ariaLabel="Print Recipe"
                  onClick={() => printElement({ selector: printId })}
                />
                {downloadPdf?.value && <Button type="circle" size="large" variant="tan" icon="download" />}
              </Tools>
            </FlexContainer>
            {shareOptions && <SocialShare className="socialShare" fields={{ shareOptions, title: shareText }} />}
          </ImageWrapper>
          <ContentWrapper>
            <Ingredients className="ingredients-container-on-print">
              <header className="ingredients-on-print">
                <h2 className="ingredients-on-print">
                  {translate(
                    viewBag,
                    'BushsBeans.Recipes.IngredientsDetails.IngredientsTitle',
                    'Ingredients'
                  )}
                </h2>
                <span className="hide-on-print">
                  {`${translate(
                    viewBag,
                    'BushsBeans.Recipes.IngredientsDetails.ServingSize',
                    'Serving Size:'
                  )} ${tempServiceSize}`}
                </span>
              </header>
              <div className="ingredients-on-print">
                {recipeIngredientGroups?.map((group, index) => {
                  const { groupTitle = {}, ingredients = [] } = group?.fields;
                  return (
                    <ul key={`ingredients-group-${index}`}>
                      {groupTitle && (
                          <li className="no-li h4">
                            <Text field={groupTitle} tag="span" />
                          </li>
                      )}
                      {Array.isArray(ingredients) &&
                        ingredients?.map(
                          (ingredient, index) =>
                            ingredient && (
                              <li
                                key={`ingredient-${index}`}
                                dangerouslySetInnerHTML={{
                                  __html: trademarkFilterHelper(ingredient, trademarkReplacements),
                                }}
                                className="ingredients-list-on-print"
                              />
                            )
                        )}
                    </ul>
                  );
                })}
              </div>
            </Ingredients>
            <Nutrition isNutritionalInfoEmpty={isNutritionalInfoEmpty}>
              {optionalToppings && optionalToppings?.value?.length > 0 && (
                <>
                  <h2>
                    {translate(
                      viewBag,
                      'BushsBeans.Recipes.IngredientsDetails.OptionalToppings',
                      'Optional Toppings'
                    )}
                  </h2>
                  <Text field={optionalToppings} tag="p" />
                </>
              )}
              {!isServer() && !isNutritionalInfoEmpty && (
                <ButtonWrapper>
                  <Button
                    text={translate(
                      viewBag,
                      'BushsBeans.Recipes.NutritionalInformation.Title',
                      'NUTRITION INFORMATION'
                    )}
                    variant="tan"
                    size={device === 'desktop' ? 'large' : 'small'}
                    noIcon
                    onClick={() => setShowNutrition(!showNutrition)}
                  />
                </ButtonWrapper>
              )}
            </Nutrition>
            {showBasketfulButton && (
              <Basketful hide className="hide-on-print">
                <h2>
                  {translate(
                    viewBag,
                    'BushsBeans.Recipes.IngredientsDetails.NeedIngredients',
                    'Need Ingredients?'
                  )}
                </h2>

                <Button
                  id="basketful-button"
                  text={translate(viewBag, 'BushsBeans.Recipes.IngredientsDetails.ShopWith', 'Shop With')}
                  type="basketful"
                  variant="tan"
                  size="small"
                  noIcon
                  WithDefaultStyles
                  data-basketful-recipe-url={recipeUrl}
                />
              </Basketful>
            )}
            {shareOptions && <SocialShare className="socialShareCenter" fields={{ shareOptions, title: shareText }} />}

          </ContentWrapper>
        </Grid>

      </RecipeWrapper>

      {showNutrition && (
        <Modal callback={() => setShowNutrition(!showNutrition)}>
          <NutritionContent>
            <NutritionTable nutritionalInformation={nutritionalInformation} />
          </NutritionContent>
        </Modal>
      )}
    </RecipeIngredientsDetailsComponent>
  );
};

RecipeIngredientsDetails.propTypes = {
  ingredientsImage: PropTypes.object,
  optionalToppings: PropTypes.object,
  recipeIngredientGroups: PropTypes.array,
  shareOptions: PropTypes.array,
  shareText: PropTypes.string,
};

export default withSitecoreContext()(RecipeIngredientsDetails);