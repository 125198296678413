import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSpring, animated } from 'react-spring';
import { FiltersComponent } from './Filters.styles.js';

import FilterOptions from './FilterOptions';
import FilterHeader from './FilterHeader';
import FilterFooter from './FilterFooter';

import { filterConfig } from './config';
import { useDeviceResize, useOnClickOutside } from 'hooks';
import { generateQueryString } from 'helpers/queryStringHelpers';
import { sortByAlphabetical } from 'helpers/filterHelpers';

const Filters = ({
  sitecoreContext = {},
  facets = {},
  filters = {},
  type = '',
  originalFilterSettings = {},
  state: topLevelState = {},
  setState = null,
}) => {
  const { viewBag = {} } = sitecoreContext;
  const filterRef = useRef(null);
  const headerRef = useRef(null);
  const mainRef = useRef(null);
  const device = useDeviceResize();
  const [filterState, setFilterState] = useState(filters);
  const [facetOptions, setFacetOptions] = useState([]);
  const [mobileFacetOptions, setMobileFacetOptions] = useState([]);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [chipClicked, setChipClicked] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [mainProps, setMainAnimation] = useSpring(() => ({ top: -1000 }));
  // from filter config
  const { facetsKey, filtersKey, options: typeOptions, sortOptions } = filterConfig({ type });

  const applyFilters = () => {
    setToggleFilters(false);

    if (setState) {
      setState((state) => {
        return { ...state, filters: { ...filterState }, skip: 0 };
      });
    }

    // update window history state
    window.history.replaceState(null, null, generateQueryString({ ...filterState[filtersKey] }));
  };

  const toggleAppliedFilter = ({ filtersKey, filterTypeKey, option }) => {
    const filterArray = filterState[filtersKey][filterTypeKey] || [];

    if (filterArray.includes(option?.name)) {
      const valueIndex = filterArray.findIndex((value) => option?.name === value);
      filterArray.splice(valueIndex, 1);
    } else {
      filterArray.push(option?.name);
    }

    setFilterState((state) => {
      return {
        ...state,
        [filtersKey]: {
          ...{ ...state[filtersKey] },
          [filterTypeKey]: [...filterArray],
        },
      };
    });
  };

  const clearFilters = () => {
    setToggleFilters(false);

    if (setState)
      setState((state) => {
        return {
          ...state,
          filters: { ...originalFilterSettings },
          skip: 0,
        };
      });

    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Remove all query parameters related to filters
    currentURL.search = '';
    // Remove other filter-related query parameters as needed

    // Update the window history state with the modified URL
    window.history.replaceState(null, null, currentURL.toString());
  };


  const clearChip = ({ option }) => {
    toggleAppliedFilter({ filtersKey, filterTypeKey: option?.filterTypeKey, option });
    setChipClicked(true);

    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Remove the specific query parameter related to the cleared chip
    currentURL.searchParams.delete(option?.filterTypeKey);

    // Update the window history state with the modified URL
    window.history.replaceState(null, null, currentURL.toString());
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Iterate over the query parameters and update filterState
    for (const [key, value] of urlParams.entries()) {
      const filterTypeKey = key;
      const filterValue = value;

      // Check if originalFilterSettings[filtersKey] is an array
      if (Array.isArray(originalFilterSettings[filtersKey])) {
        // Find the corresponding option based on the filterTypeKey and filterValue
        const option = originalFilterSettings[filtersKey].find(
            (option) => option.filterTypeKey === filterTypeKey && option.value === filterValue
        );

        if (option) {
          // Update the filterState with the applied filter
          toggleAppliedFilter({ filtersKey, filterTypeKey, option });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (chipClicked) {
      setChipClicked(false);
      applyFilters();
    }
  }, [chipClicked]);

  const typeOptionClick = ({ option }) => {
    if (device === 'desktop' && !toggleFilters) setToggleFilters(true);

    // desktop update facets options
    if (facets && facets[facetsKey] && facets[facetsKey][option?.facetKey])
      setFacetOptions({ type: option, options: facets[facetsKey][option?.facetKey] });
  };

  const generateAppliedFilters = (filters) => {
    const appliedFilterArr = [];

    if (filters[filtersKey]) {
      for (const [key, value] of Object.entries(filters[filtersKey])) {
        if (value && value.length > 0) {
          value.forEach((item) => appliedFilterArr.push({ filterTypeKey: key, name: item }));
        }
      }
    }

    setAppliedFilters(appliedFilterArr);
  };

  const applySort = (sortKey) => {
    if (sortKey === topLevelState?.sortOrder) return;

    setState((state) => {
      return {
        ...state,
        sortOrder: sortKey,
      };
    });
  };

  useEffect(() => {
    if (filters) {
      setFilterState(filters);
      generateAppliedFilters(filters);
    }
  }, [filters]);

  // toggle animation
  useLayoutEffect(() => {
    if (!isServer())
      setMainAnimation({
        top: toggleFilters
          ? headerRef?.current?.offsetHeight || 160
          : -Math.abs(mainRef?.current?.offsetHeight + 200) || -1000,
      });
  }, [toggleFilters]);

  useOnClickOutside(filterRef, () => {
    setToggleFilters(false);
  });

  const generateMobileOptions = () => {
    const options = [];

    if (facets[facetsKey]) {
      typeOptions.forEach((type) => {
        options.push({
          ...type,
          options: sortByAlphabetical(facets[facetsKey][type?.facetKey]) || [],
        });
      });
    }

    return options;
  };

  useEffect(() => {
    if (device !== 'desktop') setMobileFacetOptions(generateMobileOptions());
  }, [facets]);

  return (
    <FiltersComponent ref={filterRef}>
      <header ref={headerRef}>
        <FilterHeader
          type={type}
          filterState={filterState}
          filtersKey={filtersKey}
          typeOptions={typeOptions}
          sortOptions={sortOptions}
          appliedFilters={sortByAlphabetical(appliedFilters)}
          typeOptionClick={typeOptionClick}
          clearFilters={clearFilters}
          toggleFilters={setToggleFilters}
          clearChip={clearChip}
          applySort={applySort}
        />
      </header>

      <animated.div ref={mainRef} style={mainProps} className="animated">
        <div className="filters">
          <FilterOptions
            filtersKey={filtersKey}
            filterType={facetOptions?.type}
            filterState={filterState}
            options={sortByAlphabetical(facetOptions?.options)}
            mobileOptions={mobileFacetOptions}
            toggleAppliedFilter={toggleAppliedFilter}
            toggleFilters={toggleFilters}
          />
        </div>

        <FilterFooter applyFilters={applyFilters} toggleFilters={setToggleFilters} />
      </animated.div>
    </FiltersComponent>
  );
};

Filters.propTypes = {
  type: PropTypes.string,
  results: PropTypes.array,
};

export default withSitecoreContext()(Filters);